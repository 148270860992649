import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Warning from '../../components/Warning';
import { ROUTES } from '../../config';
import { useModal } from '../../context/modal';
import { useUser } from '../../context/user';
import { FormProp, THEMES } from '../../interfaces';
import VerifyPhone from '../../modals/VerifyPhone';
import classes from '../../styles/VerifyContact.module.scss';
import Backdrop from 'assets/backdrop.png';
import ChangeUserData from 'modals/ChangeUserData';
import Footer from 'components/common/Footer';

const emailEntry: FormProp[] = [
  [
    {
      label: 'Email',
      name: 'email',
      placeholder: '',
      type: 'email',
    },
  ],
];

const phoneEntry: FormProp[] = [
  [
    {
      label: 'Phone Number',
      name: 'phone_no',
      phoneNumberInput: true,
      type: 'tel',
      country: 'at',
    },
  ],
];

export default function VerifyContact() {
  const { user, headers } = useUser();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [emailCounter, setEmailCounter] = useState(0);
  const { openModal } = useModal();

  useEffect(() => {
    if (isEmailSent && emailCounter > -1) {
      const timer = setInterval(() => {
        if (emailCounter === 1 && isEmailSent) {
          console.log({ emailCounter, isEmailSent });
          setIsEmailSent(false);
        }
        setEmailCounter((prev) => {
          return prev > 0 ? prev - 1 : 0;
        });
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [emailCounter]);

  const sendVerifySMS = async () => {
    openModal(VerifyPhone);
    setSendingSMS(true);
    try {
      const { data } = await axios.post(
        ROUTES.SEND_SMS_VERIFY,
        {},
        { headers },
      );
      toast.success(data.message);
      openModal(VerifyPhone);
    } catch (error) {
      console.error({ error });
    } finally {
      setSendingSMS(false);
    }
  };

  // link choose function
  function mode(type: string) {
    switch (type) {
      case 'development':
        return `http://localhost:3000/termsCondition`;
      case 'staging':
        return 'https://nncblkxdm.encryptus.io/termsCondition';
      case 'production':
        return 'https://app.encryptus.io/termsCondition';
    }
  }

  const sendVerifyEmail = async () => {
    try {
      if (!isEmailSent) {
        setSendingEmail(true);
        // eslint-disable-next-line
        let baseurl = mode(process.env.REACT_APP_ENVI as string);
        const { data } = await axios.get(ROUTES.SEND_EMAIL_VERIFY, { headers });
        setIsEmailSent(true);
        toast.success(data.message);
        setEmailCounter(30);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setSendingEmail(false);
    }
  };

  const changeEmailModal = async () => {
    try {
      openModal(() => (
        <ChangeUserData
          entries={emailEntry}
          title="Change Email"
          onSubmit={(body: any) => updateUser(body)}
        />
      ));
    } catch (error) {
      console.error(error);
    }
  };

  const changePhoneModal = async () => {
    try {
      openModal(() => (
        <ChangeUserData
          entries={phoneEntry}
          title="Change Phone Number"
          onSubmit={(body: any) => updateUser(body)}
        />
      ));
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (body: any) => {
    try {
      const data = await axios.patch(ROUTES.UPDATE_USER, body, { headers });
      toast.success(data.data.message);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  if (!user)
    return (
      <div style={{ margin: 'auto' }}>
        <Loader type="ThreeDots" color="rgb(115, 100, 217)" height={20} />
      </div>
    );

  const { isEmailVerified, isPhoneVerified } = user;
  return (
    <div className={classes.container}>
      <div className={classes.backdrop}>
        <img src={Backdrop} alt="backdrop"></img>
      </div>
      <Warning
        title={isEmailVerified ? 'Email Verified' : 'Verify Your Email'}
        description={
          isEmailVerified
            ? ''
            : 'In order to start using your encryptus account, you need to confirm your email address'
        }
        action={isEmailVerified ? undefined : 'Verify Your E-mail'}
        onAction={isEmailVerified ? undefined : sendVerifyEmail}
        updateAction={isEmailVerified ? undefined : 'Change E-mail'}
        onUpdateAction={isEmailVerified ? undefined : changeEmailModal}
        emailSent={isEmailSent}
        loading={!isEmailVerified && sendingEmail}
        theme={THEMES.PRIMARY}
        imgtype="email"
        counter={emailCounter}
      />
      <Warning
        title={isPhoneVerified ? 'Phone Verified' : 'Verify Your Phone Number'}
        description={
          isPhoneVerified
            ? ''
            : 'By clicking on verify button you will receive an OTP to verify your phone'
        }
        action={isPhoneVerified ? undefined : 'Verify Your Number'}
        onAction={isPhoneVerified ? undefined : sendVerifySMS}
        updateAction={isPhoneVerified ? undefined : 'Change Phone'}
        onUpdateAction={isPhoneVerified ? undefined : changePhoneModal}
        loading={!isPhoneVerified && sendingSMS}
        theme={THEMES.PRIMARY}
        imgtype="password"
      />
      <Footer />
    </div>
  );
}
