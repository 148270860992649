import React from 'react';
import classes from '../styles/Header.module.scss';

const Dropdown: React.FC<{ handleClick?: any; handleShut?: any }> = ({
  children,
  handleClick,
  handleShut,
}) => {
  return (
    <div
      onClick={() => handleClick()}
      className={classes.dropdown}
      onMouseLeave={handleShut}
    >
      {children}
    </div>
  );
};

export default Dropdown;
