import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ModalProvider } from './context/modal';
import { UserProvider } from './context/user';
import { BrowserRouter as Router } from 'react-router-dom';
import { SearchProvider } from './context/search';
import { AdminProvider } from 'context/admin';
import { NotificationProvider } from 'context/notification';
import { CovarioProvider } from 'context/covario';
import 'styles/tailwind.css';
import { ShdigitalProvider } from 'context/SHdigital';
import { FalconxProvider } from 'context/falconx';

ReactDOM.render(
  <React.StrictMode>
    <SearchProvider>
      <Router>
        <UserProvider>
          <AdminProvider>
            <FalconxProvider>
              <NotificationProvider>
                <CovarioProvider>
                  <ShdigitalProvider>
                    <ModalProvider>
                      <App />
                    </ModalProvider>
                  </ShdigitalProvider>
                </CovarioProvider>
              </NotificationProvider>
            </FalconxProvider>
          </AdminProvider>
        </UserProvider>
      </Router>
    </SearchProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
