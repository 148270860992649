import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AdminScreen from '../../components/AdminScreen';
import Button from '../../components/Button';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { ROUTES } from '../../config';
import { useSearch } from '../../context/search';
import { useUser } from '../../context/user';
import useFilters from '../../hooks/useFilters';
import usePaginate from '../../hooks/usePaginate';
import { FilterListItem } from '../../interfaces';
import classes from '../../styles/KycApplications.module.scss';
import { capitalize } from '../../utils/format';
import { useModal } from '../../context/modal';
import KYCDetail from '../../modals/KYCDetail';

const filterList: FilterListItem[] = [
  {
    field: 'status',
    label: 'Status',
    options: [
      { value: 'approved', label: 'Approved' },
      { value: 'pending', label: 'Pending' },
      { value: 'rejected', label: 'Rejected' },
    ],
  },
];

export default function KycApplications() {
  const { headers } = useUser();
  const { search } = useSearch();
  const { filters, updateFilters } = useFilters();
  const { openModal } = useModal();
  const [loader, setLoader] = useState<boolean[]>([]);
  const [kycDetail, { reload, paginateProps, loading }] = usePaginate<any>(
    {
      route: ROUTES.FETCH_ALL_KYC_APPLICATIONS,
      params: {
        filters: { 'user.email': { $regex: search }, ...filters },
      },
    },
    [search, filters],
  );
  const init = () => {
    let i = 0,
      temp = [];
    while (i < kycDetail.length) {
      temp.push(false);
      i++;
    }
    setLoader(temp);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const approve = async (kycApplicationId: string, index: number) => {
    let temp = loader;
    temp[index] = true;
    setLoader(temp);
    try {
      const { data } = await axios.patch(
        ROUTES.APPROVE_KYC(kycApplicationId),
        {},
        { headers },
      );
      console.log({ data });
      await reload();
    } catch (error) {
      console.log({ error });
    }
    init();
  };

  const reject = async (kycApplicationId: string, index: number) => {
    let temp = loader;
    temp[index] = true;
    setLoader(temp);
    try {
      const { data } = await axios.patch(
        ROUTES.REJECT_KYC(kycApplicationId),
        {},
        { headers },
      );
      console.log({ data });
      await reload();
    } catch (error) {
      console.log({ error });
    }
    init();
  };

  return (
    <AdminScreen className={classes.container}>
      <h1>KYC Applications</h1>
      <h6>My Account / KYC Applications</h6>
      <Table
        filters={filters}
        updateFilters={updateFilters}
        filterList={filterList}
        title="All KYC Applications"
        loading={loading}
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>User Email</th>
            <th>Status</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {kycDetail.map(
            (
              {
                createdAt,
                status,
                _id,
                user,
                user: [{ email }],
                userKycDocuments,
              },
              i,
            ) => (
              <tr key={createdAt}>
                <td>{new Date(createdAt).toLocaleDateString()}</td>

                <td>{email}</td>
                <td className={[classes[status], classes.status].join(' ')}>
                  {loader[i] ? (
                    <div className={classes.loader} />
                  ) : (
                    capitalize(status)
                  )}
                </td>
                <td>
                  <Button
                    size="s"
                    onClick={() =>
                      openModal(() => (
                        <KYCDetail
                          data={{
                            createdAt: createdAt,
                            status: status,
                            user: user[0],
                            _id: _id,
                            kycDocuments: userKycDocuments,
                          }}
                          approve={approve}
                          reject={reject}
                          index={i}
                        />
                      ))
                    }
                  >
                    View
                  </Button>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
      <Paginate {...paginateProps} />
    </AdminScreen>
  );
}
