import { useEffect, useState } from 'react';
import axios from 'axios';
import ClientScreen from '../../components/ClientScreen';
import { ROUTES } from '../../config';
import classes from '../../styles/Balance.module.scss';
import { useUser } from 'context/user';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import Footer from 'components/common/Footer';

export default function Balance() {
  const [cryptoBalance, setCryptoBalance] = useState({
    BTC: '0',
    ETH: '0',
    USDT: '0',
    Matic: '0',
    USDC: '0',
  });
  //eslint-disable-next-line
  const [fiatBalance, setFiatBalance] = useState({
    USD: '0',
    EUR: '0',
    GBP: '0',
    // AED: '0',
  });

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [value, setValue] = useState('1');
  const { headers } = useUser();

  useEffect(() => {
    const numberOfColumns = () => {
      let screen = window.matchMedia('(max-width: 600px)').matches
        ? true
        : false;
      setIsSmallScreen(screen);
      console.log('Screen ', screen);
    };

    window.addEventListener('resize', numberOfColumns);
    window.addEventListener('load', numberOfColumns);
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const { data: fiatBalanceData } = await axios.get(ROUTES.Fiat_Balance, {
          headers,
        });
        console.log(fiatBalanceData);

        if (fiatBalanceData?.data?.fiat_bal == null) {
          setFiatBalance({
            USD: CURRENCY_CONV.fiatAmtConv(0),
            // AED: CURRENCY_CONV.fiatAmtConv(0),
            EUR: CURRENCY_CONV.fiatAmtConv(0),
            GBP: CURRENCY_CONV.fiatAmtConv(0),
          });
        } else {
          const USDBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'USD',
          );
          // const AEDBalance = fiatBalanceData.data.fiat_bal.find(
          //   (f: any) => f.fiat_curr === 'AED',
          // );
          const GBPBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'GBP',
          );
          const EURBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'EUR',
          );
          setFiatBalance({
            USD: CURRENCY_CONV.fiatAmtConv(USDBalance.fiat_coins),
            // AED: CURRENCY_CONV.fiatAmtConv(AEDBalance.fiat_coins),
            GBP: CURRENCY_CONV.fiatAmtConv(GBPBalance.fiat_coins),
            EUR: CURRENCY_CONV.fiatAmtConv(EURBalance.fiat_coins),
          });
        }
        let balanceObj = { ...cryptoBalance };

        const { data: cryptoUSDTBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'USDT',
          },
          { headers },
        );

        if (cryptoUSDTBalanceData?.data?.crypto_coins == null) {
          balanceObj.USDT = '0.0000';
        } else {
          balanceObj.USDT = CURRENCY_CONV.cryptoAmtConv(
            cryptoUSDTBalanceData.data.crypto_coins,
          );
        }

        const { data: cryptoETHBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'ETH',
          },
          { headers },
        );

        if (cryptoETHBalanceData?.data?.crypto_coins == null) {
          balanceObj.ETH = '0.0000';
        } else {
          balanceObj.ETH = CURRENCY_CONV.cryptoAmtConv(
            cryptoETHBalanceData.data.crypto_coins,
          );
        }

        const { data: cryptoBTCBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'BTC',
          },
          { headers },
        );
        if (cryptoBTCBalanceData?.data?.crypto_coins == null) {
          balanceObj.BTC = '0.0000';
        } else {
          balanceObj.BTC = CURRENCY_CONV.cryptoAmtConv(
            cryptoBTCBalanceData.data.crypto_coins,
          );
        }

        const { data: cryptoMaticBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'Matic',
          },
          { headers },
        );
        if (cryptoMaticBalanceData?.data?.crypto_coins == null) {
          balanceObj.Matic = '0.0000';
        } else {
          balanceObj.Matic = CURRENCY_CONV.cryptoAmtConv(
            cryptoMaticBalanceData.data.crypto_coins,
          );
        }

        const { data: cryptoUSDCBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'USDC',
          },
          { headers },
        );
        if (cryptoUSDCBalanceData?.data?.crypto_coins == null) {
          balanceObj.USDC = '0.0000';
        } else {
          balanceObj.USDC = CURRENCY_CONV.cryptoAmtConv(
            cryptoUSDCBalanceData.data.crypto_coins,
          );
        }
        setCryptoBalance(balanceObj);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBalance();
    //eslint-disable-next-line
  }, [headers]);

  return (
    <>
      <ClientScreen
        head={
          <div className={classes.head}>
            <h1>My Balances</h1>
          </div>
        }
        hideTransact
      >
        {!isSmallScreen ? (
          <div className={classes.cryptoContainer}>
            <div className={classes.cryptoCurrency}>
              <div className={classes.header}>CRYPTO</div>
              <div>
                <div>BTC</div>
                <div>{cryptoBalance.BTC}</div>
              </div>
              <div>
                <div>ETH</div>
                <div>{cryptoBalance.ETH}</div>
              </div>
              <div>
                <div>USDT</div>
                <div>{cryptoBalance.USDT}</div>
              </div>
              <div>
                <div>Matic</div>
                <div>{cryptoBalance.Matic}</div>
              </div>
              <div>
                <div>USDC</div>
                <div>{cryptoBalance.USDC}</div>
              </div>
            </div>

            <div className={classes.cryptoCurrency}>
              <div className={classes.header}>FIAT</div>
              <div>
                <div>USD</div>
                <div>{fiatBalance.USD}</div>
              </div>
              {/* <div>
                <div>AED</div>
                <div>{fiatBalance.AED}</div>
              </div> */}
              <div>
                <div>GBP</div>
                <div>{fiatBalance.GBP}</div>
              </div>
              <div>
                <div>EUR</div>
                <div>{fiatBalance.EUR}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.cryptoContainer}>
            <div className={classes.cryptoCurrency}>
              <div className={classes.currencyContainer}>
                <div
                  className={value === '1' ? classes.selected : classes.header}
                  onClick={() => setValue('1')}
                >
                  CRYPTO
                </div>
                <div
                  className={value !== '1' ? classes.selected : classes.header}
                  onClick={() => setValue('2')}
                >
                  FIAT
                </div>
              </div>
              {value === '1' ? (
                <>
                  <div>
                    <div>BTC</div>
                    <div>{cryptoBalance.BTC}</div>
                  </div>
                  <div>
                    <div>ETH</div>
                    <div>{cryptoBalance.ETH}</div>
                  </div>
                  <div>
                    <div>USDT</div>
                    <div>{cryptoBalance.USDT}</div>
                  </div>
                  <div>
                    <div>USDC</div>
                    <div>{cryptoBalance.USDC}</div>
                  </div>
                  <div>
                    <div>Matic</div>
                    <div>{cryptoBalance.Matic}</div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div>USD</div>
                    <div>{fiatBalance.USD}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </ClientScreen>
      <Footer />
    </>
  );
}
