import axios from 'axios';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import ClientScreen from '../../components/ClientScreen';
import Warning from '../../components/Warning';
import { ROUTES } from '../../config';
import { useModal } from '../../context/modal';
import { useUser } from '../../context/user';
import AddBankAccount from '../../modals/AddBankAccount';
import classes from '../../styles/BankAccounts.module.scss';
import Table from '../../components/Table';
import usePaginate from '../../hooks/usePaginate';
import BankApplicationStatus from '../../modals/BankApplicationStatus';
import { anyObject, THEMES } from '../../interfaces';
import ReEdit from 'assets/refresh.svg';
import { useHistory } from 'react-router-dom';
import Footer from 'components/common/Footer';

export default function BankAccounts() {
  const { openModal } = useModal();
  const { headers, user } = useUser();
  const [accounts, { reload, loading }] = usePaginate<any>({
    route: ROUTES.FETCH_USER_BANK_APPLICATIONS,
  });
  const { push } = useHistory();

  const deleteAccount = async (id: string) => {
    const { data } = await axios.delete(ROUTES.DELETE_BANK_ACCOUNT(id), {
      headers,
    });
    console.log(data.message);
    if (data.message) toast.success(data.message);
    window.location.reload();
    await reload();
  };

  const reApply = (id: string, i: number) => async (body: anyObject) => {
    let success = true;
    accounts[i].conflicting_keys.map((key: any) => {
      if (body[key] === accounts[i][key]) {
        success = false;
        return toast.error(
          `Conflicting! while reapplying please provide new details`,
        );
      }
      return null;
    });

    if (!success) return;
    try {
      const { data } = await axios.patch(ROUTES.EDIT_BANK_ACCOUNT(id), body, {
        headers,
      });
      await reload();
      return { success: data.message };
    } catch (err: any) {
      return { error: err?.response?.data?.message };
    }
  };
  // eslint-disable-next-line
  function copyText(accountNumber: any) {
    navigator.clipboard.writeText(accountNumber);
  }
  console.log(accounts);

  return (
    <>
      <ClientScreen
        head={
          <div className={classes.head}>
            <h1>Bank Accounts</h1>

            <div className={classes.btnSection}>
              <Button onClick={() => push('/dashboard')}>Buy Crypto</Button>

              <Button
                onClick={() =>
                  openModal(() => <AddBankAccount getAccounts={reload} />)
                }
              >
                Add New Bank Account
              </Button>
            </div>
          </div>
        }
        hideTransact
      >
        {accounts?.length ? (
          <>
            <Table loading={loading} className={classes.table} height="100%">
              <thead style={{ borderBottom: '1px solid white' }}>
                <tr>
                  <th>Account Holder Name</th>
                  <th>Bank Name</th>
                  <th>IBAN</th>
                  <th>Currency</th>
                  <th>Status</th>
                </tr>
              </thead>
              {accounts.map(
                (
                  {
                    currency,
                    bankName,
                    iban_number,
                    accountHolderName,
                    _id,
                    status,
                  },
                  i,
                ) => (
                  <>
                    <tr>
                      <td>{accountHolderName}</td>
                      <td>{bankName}</td>
                      <td
                        style={{
                          color: 'white',
                          fontSize: '12px',
                        }}
                      >
                        <>{iban_number}</>
                      </td>
                      <td>{currency}</td>
                      <td className={classes[status]}>
                        {status.toUpperCase()}
                      </td>
                      <td>
                        {status === 'approved' && (
                          <Button
                            variant={THEMES.ERROR}
                            onClick={() => deleteAccount(_id)}
                            size="s"
                          >
                            Remove
                          </Button>
                        )}
                      </td>
                      <td>
                        {status === 'rejected' && (
                          <Button
                            variant={THEMES.PRIMARY}
                            onClick={() =>
                              openModal(() => (
                                <BankApplicationStatus
                                  submit={reApply(_id, i)}
                                  account={accounts[i]}
                                />
                              ))
                            }
                            invert
                            size="s"
                            className={classes.viewAndApplyBtn}
                          >
                            <img src={ReEdit} alt="re-edit" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  </>
                ),
              )}
            </Table>
          </>
        ) : (
          <div className={classes.empty}>
            {user?.kycStatus ? (
              <>
                <p
                  style={{ color: 'white', fontSize: '1rem', margin: '35px 0' }}
                >
                  No bank accounts linked to this user
                </p>
              </>
            ) : (
              <Warning
                title="KYC not approved"
                description="You cannot add a bank account before your KYC is approved"
              />
            )}
          </div>
        )}
      </ClientScreen>
      <Footer />
    </>
  );
}
