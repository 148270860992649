import React, { useEffect, useState } from 'react';
import { Currency, CurrencyType, TransactionType } from '../interfaces';
import classes from '../styles/CoinCard.module.scss';
import { useHistory } from 'react-router-dom';
import { useUser } from 'context/user';
import { toast } from 'react-toastify';
import { ROUTES } from 'config';
import Loader from 'react-loader-spinner';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

interface Props {
  icon: string;
  name: string;
  symbol: string;
  price: number;
  change: number;
  transact?: boolean;
  changeSelected?: (currency: CurrencyType, object: Currency) => void;
}

export default function CoinCard({
  change,
  icon,
  name,
  price,
  symbol,
  transact,
  changeSelected,
}: Props) {
  const coin = { icon, name, price, symbol, change };
  const [coinPrice, setCoinPrice] = useState(price);
  const [access, setAccess] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useUser();
  const history = useHistory();

  const getUsdPrice = async () => {
    let id = '';
    if (name === 'Bitcoin') id = 'bitcoin';
    else if (name === 'Ethereum') id = 'ethereum';
    else if (name === 'Tether') id = 'tether';
    else if (name === 'Matic') id = 'matic-network';
    else if (name === 'USDC') id = 'usd-coin';

    try {
      const data = await fetch(ROUTES.Coins_Price_API(id), {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Cache-Control': 'max-age=0',
          TE: 'trailers',
        },
      }).then((res) => res.json());

      setCoinPrice(data[id].usd);
    } catch (e) {
      console.log(e);
    }

    setLoading(() => false);
  };

  useEffect(() => {
    if (!user) {
      setAccess(false);
    }
    if (user && user.associated_banks.length === 0) {
      setAccess(false);
    }
    if (user && user.associated_banks.length > 0) {
      setAccess(true);
    }
    getUsdPrice();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div
      style={{ cursor: transact ? 'auto' : 'pointer' }}
      onClick={() => !transact && changeSelected?.(CurrencyType.coin, coin)}
      className={classes.container}
    >
      {/* icons ++++++++++++++++++++++++++++++++++ */}
      <div className={classes.head}>
        <img src={icon} alt={`${symbol}`} />
        <div className={classes.text}>
          <h2>{name}</h2>
          <p>{symbol.toUpperCase()}</p>
        </div>
        <div className={classes.coinPrice}>
          {loading ? (
            <Loader type="ThreeDots" color={'#E8CB69'} height={10} />
          ) : (
            <>$ {CURRENCY_CONV.fiatAmtConv(coinPrice)}</>
          )}
        </div>
      </div>
      {/* detils++++++++++++++++++++++++++++++++++++ */}
      <div>
        {/* buttons ++++++++++++++++++++++++++++++++++++++ */}
        {transact && (
          <div className={classes.buttons}>
            {access ? (
              <>
                <button
                  onClick={() => {
                    changeSelected?.(CurrencyType.coin, coin);
                    history.push(
                      `/dashboard/?transact=${
                        TransactionType.Buy
                      }&coin=${symbol.toLowerCase()}`,
                    );
                  }}
                  className={classes.buybtn}
                >
                  Buy
                </button>
                {/* </NavLink> */}
              </>
            ) : (
              <button
                onClick={() => {
                  if (user && user.associated_banks.length === 0) {
                    toast.warning(
                      'Please Add Bank Account To Access This Feature',
                    );
                    history.push('/bank_accounts');
                  }
                }}
                className={classes.buybtn}
              >
                Buy
              </button>
            )}
            {/* Sell btn++++++++++++++++++++++++++++++++++++++++++++ */}
            {access ? (
              <button
                onClick={() => {
                  changeSelected?.(CurrencyType.coin, coin);
                  history.push(
                    `/dashboard/?transact=${
                      TransactionType.Sell
                    }&coin=${symbol.toLowerCase()}`,
                  );
                }}
                className={classes.sellbtn}
              >
                Sell
              </button>
            ) : (
              // </NavLink>
              <button
                onClick={() => {
                  if (user && user.associated_banks.length === 0) {
                    toast.warning(
                      'Please Add Bank Account To Access This Feature',
                    );
                    history.push('/bank_accounts');
                  }
                }}
                className={classes.sellbtn}
              >
                Sell
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
