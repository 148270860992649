import React from 'react';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { DATE_CONV } from 'utils/DateConv';
import classes from '../../styles/TransactionList.module.scss';

type TransactionItemProp = {
  _id: string;
  transc_status: any;
  lp_order_response: {
    lp_executedQuantity: number;
    lp_baseCurrency: string;
  };
  received_amt: any;
  received_amt_curr: any;
  createdAt: any;
  transac_type: any;
  withdraw_amt: any;
  withdraw_curr: any;
};

function TransactionItem(
  {
    _id,
    transc_status,
    lp_order_response,
    received_amt,
    received_amt_curr,
    createdAt,
    transac_type,
    withdraw_amt,
    withdraw_curr,
  }: TransactionItemProp,
  selection: any,
) {
  return (
    <div className={classes.transactionItem}>
      <div className={classes.transactionDetail}>
        <div className={classes.date}>{DATE_CONV.dateConvert(createdAt)}</div>
        <div className={classes.transactionMessage}>
          {lp_order_response?.lp_baseCurrency ||
            received_amt_curr ||
            withdraw_curr}
        </div>
      </div>
      <div className={classes.transactionStatus}>
        <div className={classes.amount}>
          {CURRENCY_CONV.cryptoAmtConv(
            lp_order_response?.lp_executedQuantity ||
              received_amt ||
              withdraw_amt,
          )}
        </div>
        <div
          className={classes.status}
          style={{
            color:
              transc_status === 'approved'
                ? '#5F9816'
                : transc_status === 'rejected'
                ? 'red'
                : '#D68B00',
          }}
        >
          {transc_status.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export default function CryptoTransactionList({
  cryptoTransactions,
  selection,
}: any) {
  console.log({
    cryptoTransactions,
    selection,
  });

  return (
    <div className={classes.transactionList}>
      {cryptoTransactions.length > 0 ? (
        cryptoTransactions.map((item: any, i: any) => {
          return (
            <div key={i.toString()}>
              <TransactionItem {...item} selection={selection} />
              {cryptoTransactions.length - 1 !== i && (
                <div className={classes.gutters} key={i.toString()}>
                  <div className={classes.dashedLine}></div>
                </div>
              )}
            </div>
          );
        })
      ) : selection !== 'All' ? (
        <div className={classes.no_transaction}>
          <h3>No Transactions found!</h3>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
