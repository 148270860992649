import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/UserAgreement.scss';
import React, { useEffect } from 'react';

export const CompliantHandlingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <div className="main_containter">
                <div className="container">
                    <div className="inner_div">
                        <h1>
                            Compliants <span style={{ color: '#fff' }}> Handling </span>
                        </h1>
                    </div>
                </div>
                <div className="container_first">
                    <p className="first_para">
                        Last revised: 10-September-2023
                    </p><br /><br />
                    <p className="second_para">
                        This Complaints Handling Policy (“Policy”) has been prepared in compliance with the Virtual Assets and Related Activities Regulations 2023 and accompanying rulebooks. This Policy is reviewed annually to ensure that it is aligned with the changing business and regulatory landscape applicable to the global virtual assets sector. Encryptus DMCC (the “Company”) is committed to promptly, fairly, consistently and efficiently resolving all complaints received from any of the professional, retail or institutional investors who are users or potential users of its services (“Client(s)”). The Company views complaints as an opportunity to improve the quality of its services and processes and has established procedures to facilitate the handling of complaints. Any expression of dissatisfaction, whether oral or written, and whether justified or not, about a service or activity relating to the services provided by the Company must be dealt with in accordance with this Policy.
                        <br /> <br />
                        An employee of the Company will be appointed, who will be responsible for the day-to-day implementation and supervision of compliance with this Policy and any related procedures (such employee, the “Responsible Employee”). The Responsible Employee shall be appointed by the Senior Executive Officer who shall be responsible for the handling of complaints.

                    </p><br /><br />

                </div>
                <img src={UserAgreementTwo} alt="FooterImage" />
                <div className="container_second">
                    <ol className="ordered_list">
                        <h1>1. COMPLAINT HANDLING POLICY</h1>
                        <p>1.1 The Responsible Employee and the other concerned employees must:</p>
                        <ul>
                            <li><p>a. Be trained in handling complaints;</p></li>
                            <li><p>b. Comply with the complaints handling requirements specified in this Policy and the complaints handling requirements specified by VARA, from time to time;</p></li>
                            <li><p>c. Treat Clients who have filed complaints (“Complainants”) in a considerate manner and promptly respond to their complaints, or direct them to the appropriate individual to handle their complaint;</p></li>
                            <li><p>d. Showcase good interpersonal and communication skills;</p></li>
                            <li><p>e. Be aware of their roles, responsibilities, and authorities in respect of complaints;</p></li>
                            <li><p>f. Be aware of the procedures to be followed and the information to be given to Complainants;</p></li>
                        </ul>

                        <p>1.2 The board of directors of the Company (“Board”) is ultimately responsible for approving the Policy and the procedures contained herein.</p>
                        <p>1.3 The Compliance Officer as well as the Responsible Employee are responsible for bringing this Policy to the attention of all concerned employees of the Company.</p>

                        <h1>2. COMPLAINT RECEIPT AND RESOLUTION</h1>
                        <p>2.1 The employee of the Company who received a complaint must, after registering it on the same business day, transmit the complaint and all information related to such complaint to the Responsible Employee.</p>
                        <p>2.2 Complaints shall be investigated by the Company in accordance with the principles of reasonableness, fairness, justice, and cost-effectiveness, in cooperation with the Complainant and with a view to reaching a solution as amicably as possible.</p>

                        <p>2.3 The Company shall acknowledge all complaints within one (1) week of a complaint being made.</p>
                        <p>2.4 The Company shall ensure that all complaints are resolved, except in exceptional circumstances, within (four) 4 weeks from the date of receipt of the complaint. Where the complaint cannot be resolved within (four) 4 weeks, the Company must ensure that the Complainant is provided with an update on the status of the complaint along with an explanation of the extraordinary circumstances delaying the resolution of the complaint. In any case, the complaint must be resolved no later than eight (8) weeks from when the complaint was made.</p>

                        <p>2.5 All complaints (along with the requisite details) must be recorded in a register maintained by the Company in this respect (“Complaints Register”).</p>

                        <p>2.6 Upon conclusion of an investigation of a complaint, the Company must promptly:</p>
                        <ul>
                            <li><p>a. Inform the Complainant in writing, through a complete, reasoned, and documented response, of the Company’s resolution of the complaint;</p></li>
                            <li><p>b. Provide the Complainant with clear terms of redress, if applicable;</p></li>
                            <li><p>c. Comply with the terms of redress if accepted by the Complainant.</p></li>
                        </ul>

                        <p>2.7 Responses to the complaints must, in all cases be agreed with the Chief Executive Officer of the Company.</p>

                        <p>2.8 The Company must ensure that a response to a complaint is provided in a clear and easily understandable manner.</p>

                        <p>2.9 If the Complainant is not satisfied with the terms of redress offered by the Company, the Company must inform the Complainant of other avenues, if any, for resolution of the complaint and provide the Complainant with the appropriate contact details upon request. Other avenues for resolution may include an external dispute resolution scheme, arbitration under the local courts.</p>

                        <p>2.10 If a complaint concerns the actions (omissions) of the Responsible Employee or other employees responsible for the handling of such complaint, or the employees hierarchically higher than the Responsible Employee in the Company's organizational structure, or there are other circumstances that cause a conflict of interest, the Responsible Employee must notify the Senior Executive Officer who shall appoint another employee of the Company having no conflict of interest during the investigation of the complaint.</p>

                        <p>2.11 If during the investigation of a complaint, the Complainant waives their complaint in writing (or otherwise on a durable medium), the initiated complaint investigation shall be terminated, and this shall be noted in the Complaints Register.</p>

                        <p>2.12 The Responsible Employee shall collect and evaluate all documents and data related to the complaint under consideration, analyze and evaluate the historical data related to the servicing of the complaint, assess other information available and relevant to the complaint investigation (contract concluded by the Complainant, data related to the fulfillment of obligations to the Company, etc.), and take any other necessary actions to investigate the complaint properly.</p> <br /> <br />

                        <h1>3. CHANNELS FOR LODGING COMPLAINTS</h1>
                        <p>3.1 Any Client or a prospective Client who is discontented with any of the services provided by the Company, for any reason, may contact the Company to express their dissatisfaction by lodging a complaint with the Company through its website.</p>

                        <p>3.2 A Client may also be able to lodge a complaint against the Company by email to hello@encryptus.io  Click <strong><a href="https://sandboxsdk.s3.eu-central-1.amazonaws.com/ENCRYPTUS+CUSTOMER+COMPLAINT+FORM.pdf">here</a> </strong>  to download Complaints form for your convenience. </p>

                        <p>3.3 The Company does not impose any fees or charges for the submission or handling of any complaints.</p> <br /> <br />

                        <h1>4. COMPLAINTS INVOLVING ANOTHER FIRM</h1>
                        <p>4.1 If the Company considers that a third-party is entirely or partly responsible for the subject matter of a complaint, it may refer the complaint, or the relevant part of it, to the third-party.</p>

                        <p>4.2 The Company remains primarily responsible for resolving such complaints which have been referred to third parties.</p> <br /> <br />

                        <h1>5. RECORD KEEPING AND DISCLOSURE</h1>
                        <p>5.1 The Company shall maintain a record of all complaints and resolutions made for a minimum period of eight (8) years from the date of receipt of a complaint. The record must contain:</p>
                        <ul>
                            <li><p>a. All complaints received from Complainants;</p></li>
                            <li><p>b. Name, date, and contact details of the Complainant;</p></li>
                            <li><p>c. The subject matter, cause, and substance of the complaint;</

                            p></li>
                            <li><p>d. All measures taken by the Company in response to the complaints;</p></li>
                            <li><p>e. The Company’s responses and resolutions; and</p></li>
                            <li><p>f. The outcome of the complaint and any other relevant correspondence, documentation, or action taken by the Company to resolve the complaint.</p></li>
                        </ul>

                        <p>5.2 The Company shall publish its complaints handling procedures on its website in a clear and easy-to-understand manner, and update the same (as required).</p> <br /> <br />

                        <h1>6. ANALYSIS AND IMPROVEMENT</h1>
                        <p>The Company shall annually conduct a thorough analysis of all complaints received by it, in order to identify the common root causes, recurring or systemic problems as well as remedies and measures to be implemented to appropriately address and correct such root causes and avoid reoccurrences. The Company will also consider whether such root causes affect other processes, services or products of the Company, including those not directly related to the complaints received.</p>
                    </ol>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};
