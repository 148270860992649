import axios from 'axios';
import AdminScreen from '../../components/AdminScreen';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { ROUTES } from '../../config';
import classes from '../../styles/BankApplications.module.scss';
import { useModal } from '../../context/modal';
import AddNote from '../../modals/AddNote';
import Button from '../../components/Button';
import { useState } from 'react';
import { anyObject, FilterListItem } from '../../interfaces';
import { useSearch } from '../../context/search';
import useFilters from '../../hooks/useFilters';
import BankDetail from '../../modals/BankDetail';
import { useAdmin } from 'context/admin';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { DATE_CONV } from 'utils/DateConv';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
// import exportFromJSON from 'export-from-json';

const filterList: FilterListItem[] = [
  {
    field: 'status',
    label: 'Status',
    options: [
      { value: 'approved', label: 'Approved' },
      { value: 'pending', label: 'Pending' },
      { value: 'rejected', label: 'Rejected' },
    ],
  },
];

export default function BankApplications() {
  const [openSet, setOpenSet] = useState<boolean[]>([]);
  const { headers } = useAdmin();
  const { search } = useSearch();
  const [emailSearch, setEmailSearch] = useState('');
  const [emailSearchField, setEmailSearchField] =
    useState<string>('tagger_email');
  const { filters, updateFilters } = useFilters();
  const [loader, setLoader] = useState<boolean[]>([]);
  const [banks, { reload, paginateProps }] = useAdminPaginate<any>(
    {
      route: ROUTES.FETCH_ALL_BANK_APPLICATIONS,
      params: {
        filters: { tagger_email: emailSearch, ...filters },
      },
    },
    [search, filters],
  );
  const { openModal, closeModal } = useModal();

  const initBoolean = () => {
    let i = 0,
      temp = [];
    while (i < banks.length) {
      temp.push(false);
      i++;
    }
    setOpenSet(temp);
    setLoader(temp);
    reload();
  };

  const handleAcceptorReject = async (index: number) => {
    let i = 0,
      temp = [];
    while (i < banks.length) {
      if (index === i) temp.push(true);
      else temp.push(false);
      i++;
    }
    setOpenSet(temp);
  };

  const changeStatus = async (
    bankId: string,
    status: string,
    index: number,
    body: anyObject = {},
  ) => {
    let temp = loader;
    temp[index] = true;
    setLoader(temp);

    try {
      const { data } = await axios.patch(
        ROUTES.UPDATE_BANK_STATUS(bankId),
        {
          updateBAS: status,
          ...body,
        },
        { headers },
      );

      initBoolean();
      return { success: data?.message };
    } catch (error) {
      console.log({ error });
    }
    initBoolean();
  };

  const reject = (bank_id: string, index: number) => (body: anyObject) =>
    changeStatus(bank_id, 'rejected', index, body);

  // ? Methods to render the dropdown title
  const renderSearchDropdown = () => {
    switch (emailSearchField) {
      case 'tagger_email':
        return 'Email';
    }
  };

  // ? Method to Search Fiat Tickets
  const handleSearch = async (event: any) => {
    event.preventDefault();
    reload();
  };

  return (
    <AdminScreen className={classes.container}>
      <form className={classes.userSearchForm} onSubmit={handleSearch}>
        <div className={classes.searchBarSection}>
          <div className={classes.searchInputSection}>
            <input
              type="text"
              placeholder="Search Email"
              value={emailSearch}
              onChange={(event) => setEmailSearch(event?.target.value)}
            />
            <Menu
              menuButton={
                <MenuButton className={classes.dropdownBtn}>
                  <span>{renderSearchDropdown()}</span>
                  <img src={arrowDownBlack} alt="arrow-img" width={10} />
                </MenuButton>
              }
              transition
              menuClassName="drop-down"
              onItemClick={(e: any) => setEmailSearchField(e.value)}
            >
              <MenuItem
                value="tagger_email"
                className={
                  emailSearchField === 'tagger_email'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Email
              </MenuItem>
            </Menu>
          </div>
          <Button type="submit">Search</Button>
        </div>
      </form>
      <Table
        filters={filters}
        updateFilters={updateFilters}
        filterList={filterList}
        title="All Bank Applications"
        // loading={loading}
      >
        <thead>
          <tr>
            <th>Date</th>
            {/* <th>Beneficiary Bank Name</th>
            <th>Beneficiary Bank Address</th>
            <th>Country of Beneficiary Bank</th> */}
            <th>IBAN/Account Number</th>
            <th>Holder's Name</th>
            <th>Currency</th>
            <th>Status</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {banks === undefined
            ? ''
            : banks.map(
                (
                  {
                    createdAt,
                    accountHolderName,
                    accountHolderAddress,
                    bankIdentifierCode,
                    currency,
                    status,
                    tagger_email,
                    _id,
                    iban_number,
                    bankName,
                    bankAddress,
                    bankCountry,
                  },
                  i,
                ) => (
                  <tr key={i.toString()}>
                    <td
                      onDoubleClick={() =>
                        copyText(DATE_CONV.dateConvert(createdAt))
                      }
                    >
                      {DATE_CONV.dateConvert(createdAt)}
                    </td>
                    {/* <td onDoubleClick={() => copyText(bankName)}>
                      {bankName}
                    </td>
                    <td onDoubleClick={() => copyText(bankAddress)}>
                      {bankAddress}
                    </td>
                    <td onDoubleClick={() => copyText(bankCountry)}>
                      {bankCountry}
                    </td> */}
                    <td onDoubleClick={() => copyText(iban_number)}>
                      {iban_number}
                    </td>
                    <td onDoubleClick={() => copyText(accountHolderName)}>
                      {accountHolderName}
                    </td>
                    <td onDoubleClick={() => copyText(currency)}>{currency}</td>
                    <td
                      className={[classes[status], classes.status].join(' ')}
                      onDoubleClick={() => copyText(status.toUpperCase())}
                    >
                      {loader[i] ? (
                        <div className={classes.loader} />
                      ) : (
                        status.toUpperCase()
                      )}
                      {openSet[i] && (
                        <div className={classes.adminAcceptOrRejectDiv}>
                          <button
                            onClick={() => {
                              closeModal();
                              initBoolean();
                            }}
                            className={classes.closeBtn}
                          >
                            x
                          </button>
                          <button
                            className={classes.acceptOrRejectBtn}
                            style={{ color: '#14bc24' }}
                            onClick={() => {
                              handleAcceptorReject(i);
                              changeStatus(_id, 'approved', i);
                            }}
                          >
                            Approve
                          </button>
                          <hr
                            style={{
                              width: '100%',
                              border: '1px solid #E2E1E1',
                            }}
                          />
                          <button
                            className={classes.acceptOrRejectBtn}
                            style={{ color: '#da1919' }}
                            onClick={() => {
                              openModal(() => (
                                <AddNote reject={reject(_id, i)} />
                              ));
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      <Button
                        size="s"
                        onClick={() =>
                          openModal(() => (
                            <BankDetail
                              data={{
                                createdAt: createdAt,
                                bankName: bankName,
                                bankAddress: bankAddress,
                                bankCountry: bankCountry,
                                accountNumber: iban_number,
                                accountHolderName: accountHolderName,
                                accountHolderAddress: accountHolderAddress,
                                bankIdentifierCode: bankIdentifierCode,
                                currency: currency,
                                status: status,
                                tagger_email: tagger_email,
                                _id: _id,
                              }}
                              index={i}
                              changeStatus={changeStatus}
                              reject={reject}
                            />
                          ))
                        }
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ),
              )}
        </tbody>
      </Table>

      <Paginate {...paginateProps} />
    </AdminScreen>
  );
}
