import BTC from '../assets/btc.png';
import ETH from '../assets/eth.png';
import Matic from 'assets/matic.png';
import usePaginate from './usePaginate';
import { ROUTES } from '../config';
import { Coin } from '../interfaces';
import { useUser } from '../context/user';
import LTC from 'assets/cryptocurrencies/litecoin.svg';
import BCH from 'assets/cryptocurrencies/bitcoinCash.svg';
// eslint-disable-next-line
import Doge from 'assets/cryptocurrencies/dogecoin.svg';
// import useAdminPaginate from './useAdminPaginate';
import USDT from 'assets/usdt.png';
import USDC from 'assets/usdc.png';
import ADA from 'assets/cardano-ada-logo.png';

const temp = { price: 0, change: 0 };

// coins +++++++++++++++++++++++++++++++++++++++
const globalCoins = {
  BTC: { icon: BTC, name: 'Bitcoin' },
  ETH: { icon: ETH, name: 'Ethereum' },
  Matic: { icon: Matic, name: 'Matic' },
  Doge: { icon: Doge, name: 'Dogecoin' },
  BCH: { icon: BCH, name: 'BitcoinCash' },
  LTC: { icon: LTC, name: 'Litecoin' },
  USDT: { icon: USDT, name: 'Tether' },
  USDC: { icon: USDC, name: 'USDC' },
  ADA: { icon: ADA, name: 'Cardano' },
};

export default function useExchange() {
  const { user } = useUser();
  const [coins, coinOptions] = usePaginate<Coin>(
    {
      route: ROUTES.FETCH_ADMIN_COINS,
      params: {
        sort: { createdAt: 1 },
      },
    },
    [],
    (list) => list.map((item) => ({ ...temp, ...item })),
  );
  const fiats = [{ name: 'US Dollar', symbol: 'USD', price: 1, change: 0 }];

  const userCoins: Coin[] =
    coins.map(
      (
        { symbol, local_max_limit, local_min_limit, isGlobalActive }: any,
        idx: number,
      ) => {
        const currency = globalCoins[symbol as keyof typeof globalCoins];

        const coin: Coin = {
          _id: idx.toString(),
          icon: currency?.icon,
          name: currency?.name,
          change: +12.76,
          isGlobalActive: isGlobalActive,
          max_limit: local_max_limit,
          min_limit: local_min_limit,
          price: 30,
          symbol,
        };
        return coin;
      },
    ) || [];

  return {
    coins: user?.role === 'admin' ? coins : userCoins,
    fiats,
    coinOptions,
  };
}
