import React from 'react';
import classes from 'styles/Depositefiat.module.scss';
import Danger from 'assets/danger3.svg';
import axios from 'axios';
import { ROUTES } from '../config';
import { useUser } from 'context/user';
import { toast } from 'react-toastify';
import { useModal } from 'context/modal';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

// eslint-disable-next-line
interface Head {
  currency: string;
  symbol: string;
  type: string;
  withdrawAmount: number;
  uuid: string;
  receiverAddress?: string;
  bankName?: string;
}

function Withdrawfiat({
  currency,
  symbol,
  type,
  withdrawAmount,
  uuid,
  receiverAddress,
  bankName,
}: Head) {
  const { user, headers } = useUser();
  const { closeModal } = useModal();
  console.log('Currency fiat file ', currency, type);
  const handleSubmit = async () => {
    try {
      var result;
      if (type === 'fiat') {
        const data = {
          tagger_email: user.email,
          withdraw_amt: withdrawAmount,
          unq_ref_id: uuid,
          withdraw_curr: symbol,
          destination_address: receiverAddress,
        };
        result = await axios.post(ROUTES.FIAT_WITHDRAW_REQUEST, data, {
          headers,
        });
      } else {
        const data = {
          tagger_email: user.email,
          withdraw_amt: withdrawAmount,
          unq_ref_id: uuid,
          withdraw_curr: symbol,
          destination_address: receiverAddress,
        };
        result = await axios.post(ROUTES.CRYPTO_WITHDRAW_REQUEST, data, {
          headers,
        });
      }
      console.log(result);

      toast.success(
        `${result?.data?.message} | Your withdraw will be approved within 48 hours`,
      );
      closeModal();
      setTimeout(() => {
        return window.location.reload();
      }, 5000);
    } catch (err: any) {
      console.log(err);
      toast.warning(err?.response?.data?.message);
    }
  };

  const renderCopyBtn = (data: any) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="20px"
        height="20px"
        viewBox="0 0 24.000000 24.000000"
        preserveAspectRatio="xMidYMid meet"
        className={classes.copyBtn}
        onClick={() => {
          navigator.clipboard.writeText(data);
        }}
      >
        <g
          transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path d="M20 140 c0 -47 4 -80 10 -80 6 0 10 30 10 70 l0 70 70 0 c40 0 70 4 70 10 0 6 -33 10 -80 10 l-80 0 0 -80z" />
          <path d="M60 100 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z m140 0 l0 -60 -60 0 -60 0 0 60 0 60 60 0 60 0 0 -60z" />
        </g>
      </svg>
    );
  };

  // const BankName =
  //   currency === 'EUR' || 'GBP' ? 'Clear Junction Limited' : 'Silvergate Bank';

  return (
    <div className={classes.Container}>
      <h2>Withdraw {currency}</h2>
      <section className={classes.Payinfo}>
        {type !== 'fiat' ? (
          <>
            <h4> Withdraw Ticket Details </h4>
            <table>
              <tbody>
                <tr>
                  <td>Currency</td>
                  <td className={classes.values}>
                    {symbol}
                    {renderCopyBtn(symbol)}
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className={classes.values}>
                    {CURRENCY_CONV.cryptoAmtConv(withdrawAmount)}
                    {renderCopyBtn(CURRENCY_CONV.cryptoAmtConv(withdrawAmount))}
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td className={classes.values}>
                    {receiverAddress?.slice(0, 5) +
                      '...' +
                      receiverAddress?.slice(-5)}
                    {renderCopyBtn(receiverAddress)}
                  </td>
                </tr>
                <tr>
                  <td>Payment Reference ID</td>
                  <td className={classes.values}>
                    {uuid}
                    {renderCopyBtn(uuid)}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className={classes.division}>
              <div className={classes.innerdiv}>
                <h3>No Third Party Payouts</h3>
                <img src={Danger} alt="dangericon" />
              </div>
              <p>Third Party Payments are not supported.</p>
            </div>

            <h4>Withdraw To This Bank Account</h4>
            <table>
              <tbody>
                <tr>
                  <td>Account Number</td>
                  <td className={classes.values}>
                    {receiverAddress}
                    {renderCopyBtn(receiverAddress)}
                  </td>
                </tr>
                <tr>
                  <td>Currency</td>
                  <td className={classes.values}>
                    {symbol}
                    {/* {renderCopyBtn(symbol)} */}
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className={classes.values}>
                    {CURRENCY_CONV.fiatAmtConv(withdrawAmount)}
                    {/* {renderCopyBtn(CURRENCY_CONV.fiatAmtConv(withdrawAmount))} */}
                  </td>
                </tr>
                <tr>
                  <td>Payment Reference ID</td>
                  <td className={classes.values}>
                    {uuid}
                    {renderCopyBtn(uuid)}
                  </td>
                </tr>
                <tr>
                  <td>Bank Name</td>
                  <td className={classes.values}>
                    {bankName}
                    {renderCopyBtn(bankName)}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </section>

      <div className={classes.btndiv}>
        <button onClick={handleSubmit}>Make withdraw request</button>
      </div>
    </div>
  );
}

export default Withdrawfiat;
