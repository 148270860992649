import React, { useState } from 'react';
import classes from '../styles/stepbar.module.scss';
import { toast } from 'react-toastify';
import { Props } from './ExtraDiv';
import Stepbar from './Stepbars/Step1';
import Stepbar2 from './Stepbars/Stepbar2';

// Main parent handler of steps +++++++++++++++++++++++++++++++
function MainSteps({ submit, walletData, validation }: Props) {
  const [show, setShow] = useState(1);
  const handleClick = async () => {
    if (show === 2) {
      return;
    }
    toast.success(
      'Password Validated, Please Check Your Email For OTP Verification',
    );
    setShow(show + 1);
  };
  const changenext = () => {
    // setNext(false);
  };
  return (
    <div className={classes.Mainconatiner}>
      <h1 className="head">3FA Authorization</h1>
      <div className={classes.steps}>
        {show === 1 && <Stepbar handler={changenext} nextbtn={handleClick} />}
        {show === 2 && (
          <Stepbar2 walletdata={walletData} validate={validation} />
        )}
      </div>
    </div>
  );
}

export { MainSteps };
