import { useState } from 'react';
import { FormProp, OTPForm } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import { useUser } from '../context/user';
import { useAdmin } from '../context/admin';
import { useModal } from '../context/modal';

interface Props {
  email: string;
  isAdmin?: boolean;
}

const entries: FormProp[] = [
  {
    name: 'email',
    label: 'E-Mail',
    placeholder: 'Your E-Mail',
    type: 'email',
  },
  {
    name: 'OTP',
    label: 'OTP',
    placeholder: 'Your OTP',
    type: 'password',
  },
];

const schema = yup.object().shape({
  email: yup.string().email().required(),
  OTP: yup.string().required(),
});

export default function OTP({ email, isAdmin }: Props) {
  const [loading, setLoading] = useState(false);
  const { sendOTP } = useUser();
  const { sendAdminOTP } = useAdmin();
  const { closeModal } = useModal();
  const handleSubmit = (formData: OTPForm) => {
    if (isAdmin) {
      sendAdminOTP(formData, closeModal);
    } else {
      sendOTP(formData, closeModal);
    }
  };

  const styles = {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '50px',
    color: '#091A49',
  };

  return (
    <div>
      <h1 style={styles}>Enter OTP</h1>
      <Form
        defaultValues={{ email }}
        entries={entries}
        schema={schema}
        onSubmit={handleSubmit}
        setLoading={setLoading}
      >
        <Button loading={loading} type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
