import { useState } from 'react';
import { FormProp, OTPForm } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import { useUser } from '../context/user';
import { useModal } from '../context/modal';
import axios from 'axios';
import { ROUTES } from 'config';
import { toast } from 'react-toastify';
import ResetPassword from './ResetPassword';
import inputClass from 'styles/InputField.module.scss';
import { useAdmin } from 'context/admin';

interface Props {
  email: string;
  isAdmin?: boolean;
  selfChange?: boolean;
}

const entries: FormProp[] = [
  // {
  //   name: 'email',
  //   label: 'E-Mail',
  //   placeholder: 'Your E-Mail',
  //   type: 'email',
  //   disabled: true,
  // },
  // {
  //   name: 'OTP',
  //   label: 'OTP',
  //   placeholder: 'Your OTP',
  //   type: 'password',
  // },
];

const schema = yup.object().shape({
  // email: yup.string().email().required(),
  // OTP: yup.string().required(),
});

export default function ForgotPasswordOTP({
  email,
  isAdmin,
  selfChange = false,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { headers } = useUser();
  const { headers: adminHeaders } = useAdmin();

  // State
  const [emailOtp, setEmailOtp] = useState('');

  const { openModal } = useModal();

  const handleSubmit = async (formData: OTPForm) => {
    console.log('HANDLED SUBMIT');

    if (emailOtp === '') {
      toast.error('Enter an OTP to proceed');
    } else {
      try {
        let body: any = {
          email_otp: emailOtp,
        };
        if (isAdmin) body.adminEmail = email;
        else body.userEmail = email;
        let axiosHeaders = isAdmin ? adminHeaders : headers;
        const ROUTE = isAdmin
          ? ROUTES.ADMIN_VERIFY_OTP
          : ROUTES.RESET_PASSWORD_INIT;

        const { data } = await axios.post(ROUTE, body, axiosHeaders);

        console.log({
          data,
        });

        if (data && data?.validated) {
          toast.success(data?.message || 'OTP validated');
          openModal(() => (
            <ResetPassword
              isAdmin={isAdmin ? isAdmin : false}
              email={formData?.email}
              selfChange={selfChange}
            />
          ));
        } else {
          toast.error('Error Validating OTP');
        }
      } catch (error: any) {
        console.log({
          error,
        });
        toast.error(error?.response.data.message);
      }
    }
  };

  const styles = {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '50px',
    color: '#091A49',
  };

  return (
    <div className="forgot-password-container">
      <h1 style={styles}>
        {isAdmin || selfChange
          ? 'Change Password - Enter OTP'
          : 'Forgot Password - Enter OTP'}
      </h1>

      <Form
        defaultValues={{ email }}
        entries={entries}
        schema={schema}
        onSubmit={handleSubmit}
        setLoading={setLoading}
      >
        {/* EMAIL INPUT */}
        <div className={inputClass.container}>
          <label>E-Mail</label>
          <div className={inputClass.inputContainer}>
            <div className={inputClass.icon}></div>
            <input
              name="email"
              id="clean"
              placeholder="Your E-Mail"
              type="email"
              value={email}
              disabled
            />
            <div className={inputClass.tool}></div>
          </div>
        </div>

        {/* OTP INPUT */}
        <div className={inputClass.container}>
          <label>OTP</label>
          <div className={inputClass.inputContainer}>
            <div className={inputClass.icon}></div>
            <input
              name="OTP"
              id="clean"
              placeholder=""
              type="password"
              value={emailOtp}
              onChange={(event) => setEmailOtp(event.target.value)}
              autoComplete="new-password"
            />
            <div className={inputClass.tool}></div>
          </div>
        </div>

        <Button loading={loading} type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
