import md5 from 'md5';
import { createContext, useState, FC, useContext, useEffect } from 'react';
import { ANY, LPStreamRequest } from '../interfaces';

const login = process.env.REACT_APP_LOGIN_Shdigital as string;
const pass = process.env.REACT_APP_LOGIN_Password as string;
const shdURI = process.env.REACT_APP_SHDIGITAL_URL as string;
const md5pass = md5(pass);
const token = md5(login + ':' + md5pass);

export const SHDigitalContext = createContext({
  quote: ANY,
  changeRequest: async (data: LPStreamRequest, priceData: any) => ANY,
});

export const ShdigitalProvider: FC = ({ children }) => {
  const [streamRequest, setStreamRequest] = useState<any>();
  // eslint-disable-next-line
  const [streamQuote, setStreamQuote] = useState<any>();
  const [quote, setQuote] = useState<any>();
  const [PriceValues, setPriceValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (
      streamRequest &&
      streamRequest.quoteCurrency &&
      streamRequest.baseCurrency
    ) {
      open(
        `${streamRequest.baseCurrency}/${streamRequest.quoteCurrency}`,
        token,
      );
    }
    // eslint-disable-next-line
  }, [streamRequest]);

  async function open(subPair: string, token: string) {
    // subPair = subPair;
    const dataWs = JSON.stringify({
      request: 'ws-prices-settings',
      // userId,
      time: 0,
      token,
      rateMs: 300,
      // EURO and GBP needed here+++++
      pairs: [subPair],
    });
    const ws = new WebSocket(shdURI);
    ws.onopen = () => ws.send(dataWs.toString());
    ws.onmessage = (event) => {
      const result = JSON.parse(event.data);
      // setCurrency(subPair)
      if (result.bp && result.ap) {
        const modQuote = {
          buy: result.ap,
          sell: result.bp,
          quantity: streamRequest.quantity,
        };
        setStreamQuote(modQuote);
        quoteNormaliser(modQuote);
        ws.close();
      }
    };
    ws.onerror = (error) => {
      console.log(error);
    };

    ws.onclose = () => {
      console.log('connnection closed');
    };
  }

  const quoteNormaliser = (item: any) => {
    /*
     * Calculating user price by applying mergins
     * Buying or Selling Price is calculated for quantity of 1 only
     * Then for the given quantity, price is calculated in the buyCryptoFrom and sellCryptoForm files itself.
     */
    if (Object.keys(PriceValues).length !== 0 && item) {
      const buyingpriceFC: number = item['buy'];
      item['originalPrice'] = item['buy'];
      const addTF: number =
        (parseFloat(PriceValues.buisness_mp) / 100) * Math.ceil(buyingpriceFC);
      item['margins_applied'] = Number(addTF);
      const buyingpriceWM: number = Number(buyingpriceFC) + Number(addTF);
      const marginFee: number =
        (parseFloat(PriceValues.margin_fee) / 100) * buyingpriceWM;
      const buyingpriceWMTF: number = Number(buyingpriceWM) + Number(marginFee);
      item['buyingPriceForUser'] = buyingpriceWMTF.toFixed(4);
      item['transc_fee'] = PriceValues.buisness_mp;
      item['fees_charged'] = marginFee;
      // calculate the sellingPriceForUser at FE
      const sellingpriceFC: number = item['sell'];
      item['originalSellPrice'] = item['sell'];
      const deductMP: number =
        (parseFloat(PriceValues.margin_fee) / 100) * Math.ceil(sellingpriceFC);
      const deductTF: number =
        (parseFloat(PriceValues.buisness_mp) / 100) * Math.ceil(sellingpriceFC);
      const totalDeduction = Number(deductMP.toFixed(4)) + Number(deductTF);
      item['sellingPriceForUser'] = (
        Number(sellingpriceFC) - Number(totalDeduction)
      ).toFixed(4);
      item['unit_buyPrice_lp'] = item['buy'];
      item['unit_sellPrice_lp'] = item['sell'];
      setQuote(item);
      setStreamQuote(item);
      return item;
    }
  };

  const changeRequest = async (request: LPStreamRequest, priceData: any) => {
    setPriceValues(priceData);
    setStreamRequest(request);
  };

  return (
    <SHDigitalContext.Provider
      value={{
        quote,
        changeRequest,
      }}
    >
      {children}
    </SHDigitalContext.Provider>
  );
};

export const useSHdigital = () => useContext(SHDigitalContext);
