
import React from 'react';

import classes from "../../styles/AdminHeader.module.scss";

function Wallet() {
    return <div>
        <svg className={ classes.headerIcon } width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.9504 16.1943H20.7213C18.8013 16.1931 17.2451 14.5879 17.2439 12.606C17.2439 10.624 18.8013 9.01882 20.7213 9.01761H25.9504" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.3128 12.5238H20.9102" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00748 1H19.1719C22.9154 1 25.9501 4.13268 25.9501 7.99689V17.5663C25.9501 21.4305 22.9154 24.5631 19.1719 24.5631H8.00748C4.26403 24.5631 1.22925 21.4305 1.22925 17.5663V7.99689C1.22925 4.13268 4.26403 1 8.00748 1Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.08765 7.05088H14.0613" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>;
}

export default Wallet;
