import React, { useState, useEffect } from 'react';
import classes from '../styles/EditUserBalance.module.scss';
import { FormProp } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import axios from 'axios';
import { CRYPTO_CURR, EDIT_USER_BALANCE_FIAT_CURR, ROUTES } from '../config';
import { useAdmin } from 'context/admin';
import { toast } from 'react-toastify';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

const entries: FormProp[] = [
  // {
  //   name: 'upd_bal_to',
  //   label: 'Enter Fees Amount',
  //   placeholder: 'Enter Here',
  //   type: 'number',
  // },
];

const schema = yup.object().shape({
  // upd_bal_to: yup
  //   .number()
  //   .required('Transaction Fees is required.')
  //   .moreThan(0),
});

interface Props {
  user: any;
}

const EditUserBalance = ({ user }: Props) => {
  // FIAT DATA
  const [fiatData, setFiatData] = useState<{
    balance: number | undefined;
    currency: string;
    error: string;
    loading: boolean;
  }>({
    balance: undefined,
    currency: Object.keys(EDIT_USER_BALANCE_FIAT_CURR)[0],
    error: '',
    loading: false,
  });
  const [currentFiatBalance, setCurrentFiatBalance] = useState<any>();

  // CRYPTO DATA
  const [cryptoData, setCryptoData] = useState<{
    balance: number | undefined;
    currency: string;
    error: string;
    loading: boolean;
  }>({
    balance: undefined,
    currency: Object.keys(CRYPTO_CURR)[0],
    error: '',
    loading: false,
  });
  const [currentCryptoBalance, setCurrentCryptoBalance] = useState<any>();

  const { headers } = useAdmin();

  // ? Method to check the user verification
  // * If the user is unverified, admin will not be able to edit the balances
  const checkUserVerificationStatus = async (depositType: string) => {
    if (user?.isEmailVerified === false || user?.kycStatus === false) {
      toast.warning('Cannot set Balance for an Unverified User');
    } else {
      if (depositType === 'FIAT') {
        handleFiatSubmit();
      } else if (depositType === 'CRYPTO') {
        handleCryptoSubmit();
      }
    }
  };
  // * End of checkUserVerificationStatus();

  // ? Method to handle the API call for updating user fiat balance
  const handleFiatSubmit = async () => {
    setFiatData({
      ...fiatData,
      error: '',
    });
    try {
      setFiatData({
        ...fiatData,
        loading: true,
      });

      let reqBody = {
        upd_bal_to: fiatData.balance,
        fiat_curr: fiatData.currency,
      };

      const { data } = await axios.patch(
        ROUTES.UPDATE_FIAT_BALANCE(user?._id),
        reqBody,
        { headers },
      );

      setFiatData({
        ...fiatData,
        loading: false,
      });
      if (data.message) {
        fetchFiatBalance();
        return toast.success(
          `Updated User's ${fiatData.currency} Balance to ${fiatData.balance}`,
        );
      }

      toast.success(data.message);
    } catch (error: any) {
      setFiatData({
        ...fiatData,
        loading: false,
      });

      toast.error(error?.response?.data?.message);
    }
  };
  // * End of handleFiatSubmit();

  // ? Method to handle the API call for updating user crypto balance
  const handleCryptoSubmit = async () => {
    setCryptoData({
      ...cryptoData,
      error: '',
    });

    try {
      setCryptoData({
        ...cryptoData,
        loading: true,
      });

      let reqBody = {
        upd_bal_to: cryptoData.balance,
        crypto_currency: cryptoData.currency,
      };

      const { data } = await axios.patch(
        ROUTES.UPDATE_CRYPTO_BALANCE(user?._id),
        reqBody,
        { headers },
      );

      setCryptoData({
        ...cryptoData,
        loading: false,
      });
      if (data.message) {
        fetchCryptoBalance();
        return toast.success(
          `Updated User's ${cryptoData.currency} balance to ${cryptoData.balance}`,
        );
      }

      toast.success(data.message);
    } catch (error: any) {
      setCryptoData({
        ...cryptoData,
        loading: false,
      });

      toast.error(error?.response?.data?.message);
    }
  };
  // * End of handleCryptoSubmit();

  console.log({
    headers,
    user,
  });

  // ? Method to fetch the user's fiat balances
  const fetchFiatBalance = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_FIAT_BALANCE_ADMIN(user?._id),
      {
        headers,
      },
    );

    if (data) {
      console.log({
        fiatBal: data?.data?.fiat_bal,
      });

      setCurrentFiatBalance(data?.data?.fiat_bal);
    }
  };

  // ? Method to fetch the user's crypto balances
  const fetchCryptoBalance = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_CRYPTO_BALANCE_ADMIN(user?._id),
      {
        headers,
      },
    );

    if (data) {
      console.log({
        cryptoBal: data?.data?.crypto_bal,
      });

      setCurrentCryptoBalance(data?.data?.crypto_bal);
    }
  };

  useEffect(() => {
    fetchFiatBalance();

    fetchCryptoBalance();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.marginContainer}>
        <h1>Edit FIAT Balance</h1>
        <div className={classes.balancesSection}>
          Current balance:
          <br />
          {currentFiatBalance
            ? currentFiatBalance.map((fiatData: any) => {
                if (fiatData?.fiat_curr !== 'AED') {
                  return `${fiatData?.fiat_curr}: ${CURRENCY_CONV.fiatAmtConv(
                    fiatData?.fiat_coins,
                  )} | `;
                }
                return null;
              })
            : ''}
        </div>
        <div className={classes.form}>
          <Form
            onSubmit={() => checkUserVerificationStatus('FIAT')}
            // onSubmit={handleFiatSubmit}
            schema={schema}
            entries={entries}
          >
            <div className={classes.formContainer}>
              <input
                name="upd_bal_to"
                id="clean"
                placeholder="New Fiat Balance"
                type="number"
                step="any"
                value={fiatData.balance}
                onChange={(event) =>
                  setFiatData({
                    ...fiatData,
                    balance: Number(event.target.value),
                  })
                }
                required
              />

              <div className={classes.selectionDiv}>
                <select
                  onChange={(e) =>
                    setFiatData({
                      ...fiatData,
                      currency: e.target.value,
                    })
                  }
                  value={fiatData.currency}
                >
                  {Object.keys(EDIT_USER_BALANCE_FIAT_CURR).map((curr: any) => (
                    <option key={curr} value={curr}>
                      {curr}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={classes.formErrSection}>
              <span>{fiatData.error}</span>
            </div>

            <Button loading={fiatData.loading}>Submit</Button>
          </Form>
        </div>
      </div>

      <div className={classes.verticalDivider} />

      <div className={classes.commissionsContainer}>
        <h1>Edit Crypto Balance</h1>

        <div className={classes.balancesSection}>
          Current balance:
          <br />
          {currentCryptoBalance
            ? currentCryptoBalance.map((cryptoData: any) => {
                return `${
                  cryptoData?.crypto_curr
                }: ${CURRENCY_CONV.cryptoAmtConv(cryptoData?.crypto_coins)} | `;
              })
            : ''}
        </div>

        <div className={classes.form}>
          <Form
            onSubmit={() => checkUserVerificationStatus('CRYPTO')}
            schema={schema}
            entries={entries}
          >
            <div className={classes.formContainer}>
              <input
                name="comission_fee"
                id="clean"
                placeholder="New Crypto Balance"
                type="number"
                step="any"
                value={cryptoData.balance}
                onChange={(event) =>
                  setCryptoData({
                    ...cryptoData,
                    balance: Number(event.target.value),
                  })
                }
                required
              />
              <div className={classes.selectionDiv}>
                <select
                  onChange={(e) =>
                    setCryptoData({
                      ...cryptoData,
                      currency: e.target.value,
                    })
                  }
                  value={cryptoData.currency}
                >
                  {Object.keys(CRYPTO_CURR).map((curr: any) => (
                    <option key={curr} value={curr}>
                      {curr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={classes.formErrSection}>
              <span>{cryptoData.error}</span>
            </div>

            <Button loading={cryptoData.loading}>Submit</Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditUserBalance;
