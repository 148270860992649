import axios from 'axios';
import { useAdmin } from 'context/admin';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AdminScreen from '../../components/AdminScreen';
import NoDp from '../../components/NoDp';
import Tabs from '../../components/Tabs';
import { ROUTES } from '../../config';
import classes from '../../styles/User.module.scss';
import Arrow from 'assets/Navbar svg/Arrow';
import { Link } from 'react-router-dom';
import LAdminWallets from './LAdminWallets';
import LBankDetails from './LbankDetails';
import LKycDetails from './LkycDetails';
import Lfees from './Lfees';
import LadminUserBalance from './LadminUserBalance';

export default function User() {
  const [user, setUser] = useState<any>({});
  const { id } = useParams() as { id: string };
  const { headers } = useAdmin();

  const getUser = async () => {
    // console.log(headers);
    const { data } = await axios.get(ROUTES.L_USERS, {
      headers,
      params: { filters: { _id: id } },
    });
    setUser(data.list[0]);
    // console.log(data.list);
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  // console.log(user);

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.details}>
        <Link to="/admin/luser">
          <Arrow />
        </Link>
        <NoDp />
        <h1>{user.name}</h1>
      </div>
      <Tabs
        tabs={[
          {
            title: 'Bank Details',
            Component: () => <LBankDetails user={user} />,
          },
          {
            title: 'Wallets',
            Component: () => <LAdminWallets />,
          },
          {
            title: 'User Details',
            Component: () => <LKycDetails user={user} />,
          },
          {
            title: 'Fees & Margins',
            Component: () => <Lfees user={user} />,
          },
          {
            title: 'User Balance',
            Component: () => <LadminUserBalance user={user} />,
          },
          // {
          //   title: 'Login History',
          //   Component: () => <LloginHistory user={user} />,
          // },
        ]}
      />
    </AdminScreen>
  );
}
