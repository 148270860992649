import classes from '../styles/Notifications.module.scss';

const NotificationsDropdown = (props: {
  reference?: any;
  isOpen: any;
  close: () => void;
  data: any;
}) => {
  return (
    <div className={classes.selectContainer} ref={props.reference as any}>
      {props.isOpen && (
        <>
          <div>
            <div className={classes.dropdownArrow}></div>
            <div className={classes.dropdownListContainer}>
              <div className={classes.header}>
                {props.data.length > 0
                  ? `${props.data.length} new notifications`
                  : 'No new notifications'}
              </div>
              <div className={classes.notificationContainer}>
                {props.data.map((notif: any, index: number) => (
                  <div key={index} className={classes.notifications}>
                    <div>
                      {notif?.eventName} {notif?.by ? ` by ${notif.by}` : ''}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationsDropdown;
