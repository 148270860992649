import React from 'react';

//@ts-ignore
export default function CustomTable() {
    const tableStyles = {
        // borderCollapse: 'collapse',
        width: '80%',
        margin: '20px auto',
    };

    const thStyles = {
        backgroundColor: '#091A49', // Updated color
        color: 'white',
        padding: '10px',
        border: '2px solid #091A49', // Updated color
    };

    const tdStyles = {
        padding: '8px',
        color: 'black',
        border: '2px solid #091A49', // Updated color
    };

  const columns = [
    {
        name :'Ethereum', 
        abbr : 'ETH'
    }, 
    {
        name :'Tether', 
        abbr : 'USDT'
    },
    {
        name :'USD Coin', 
        abbr : 'USDC'
    },
    {
        name :'Bitcoin', 
        abbr : 'BTC'
    }
  ];

    return (
        <table style={tableStyles}>
            <thead>
                <tr>
                    <th style={thStyles}>Name of the Token</th>
                    <th style={thStyles}>Abbreviation</th>
                </tr>
            </thead>
            <tbody>
                {columns.map((column, index) => (
                    <tr key={index}>
                        <td style={tdStyles}><strong>{column.name}</strong></td>
                        <td style={tdStyles}>{column.abbr}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

