import { Link } from 'react-router-dom';
import classes from '../../styles/NavBar.module.scss';
import Logo from '../../assets/FinalLandingPage/logo.png';
import Button from '../Button';
import { useModal } from '../../context/modal';
import Login from '../../modals/Login';
import Register from 'modals/Register';
// import Dropdown from 'components/Dropdown';
// import { Menu, MenuItem } from '@szhsin/react-menu';

function NavBar() {
  const { openModal } = useModal();

  return (
    <div
      className={`${classes.navBar} w-screen px-16 md:px-0 flex md:grid md:flex-1 md:grid-rows-2`}
    >
      {/* logo */}
      <div className="lg:w-screen mx-auto px-4 sm:px-6">
        <div className="justify-between items-center py-6 lg:pb-0 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:flex-1 lg:justify-center">
            <Link to="/">
              <img src={Logo} alt="logo" className={classes.logo} />
            </Link>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <ul className="list-none lg:hidden flex items-center justify-start flex-1 lg:w-0 lg:h-0">
        <div className={classes.navList}>
          <li>
            {/* <div className={classes.navItem}>
              <a
                href="https://encryptus-api-4242.nonceblox.com/api"
                target="_blank"
                rel="noreferrer"
              >
                
              </a>
            </div> */}
            <div className={classes.dropdown}>
              <button className={classes.dropbtn}>Solutions</button>
              <div className={classes.dropdownContent}>
                <a
                  href="https://encryptus.gitbook.io/encryptus-sandbox"
                  target="_blank"
                  rel="noreferrer"
                >
                  API
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className={classes.navItem}>
              <a href="/#contact">Support</a>
            </div>
          </li>
        </div>
      </ul>

      {/* Buttons */}
      <div
        className={`flex items-center justify-end lg:w-screen lg:justify-center lg:my-2`}
      >
        <Button
          className="rounded-full p-2 uppercase"
          onClick={() => openModal(Login)}
        >
          Login
        </Button>
        <Button
          onClick={() => openModal(Register)}
          style={{ margin: '0px 10px' }}
        >
          Register
        </Button>
      </div>
    </div>
  );
}

export default NavBar;
