import React, { useState } from 'react';
import classes from 'styles/DepositeAmount.module.scss';
import { useUser } from 'context/user';
import { useModal } from 'context/modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROUTES } from 'config';

interface Props {
  name: string;
  address: string;
}

function CryptoDepositAmount({ name, address }: Props) {
  const { headers } = useUser();
  const [amount, setAmount] = useState<number>(0);
  const [hash, setHash] = useState<string>('');
  const { closeModal } = useModal();

  const currency = (value: string) => {
    switch (value) {
      case 'BITCOIN':
        return 'BTC';
      case 'ETHEREUM':
        return 'ETH';
      case 'TETHER':
        return 'USDT';
      case 'USDC':
        return 'USDC';
      case 'MaticCoin':
        return 'MATIC';
      default:
        return null;
    }
  };

  const body = {
    transaction_hash: hash,
    source_address: address,
    received_amt: Number(amount),
    received_amt_curr: currency(name.toUpperCase()),
  };

  const Requestamount = async () => {
    try {
      const { data } = await axios.post(ROUTES.CRYPTO_DEPOSIT_Request, body, {
        headers,
      });
      if (data) {
        toast.success('Created new Crypto Deposit Ticket Successfully!');
        closeModal();
      }
    } catch (error: any) {
      if (error.response.status === 500) {
        toast.warning('Please Enter Details to Complete Deposit flow');
      }
      toast.warning(
        'You have Entered Wrong Transaction hash or Deposit Amount',
      );
      console.log(error);
    }
  };
  const recivedamount = (e: any) => {
    setAmount(e.target.value);
  };
  const recivedamount2 = (e: any) => {
    setHash(e.target.value);
  };
  return (
    <>
      <div className={classes.Container}>
        <h2>Deposit {name}</h2>
        <div className={classes.depositForm}>
          <label>Deposit Amount</label>
          <input
            type="number"
            value={amount}
            onChange={recivedamount}
            placeholder="Please Enter Deposited Amount"
            required
          />
          <label>Transaction Hash</label>
          <input
            type="text"
            value={hash}
            onChange={recivedamount2}
            placeholder="Please Enter Transaction Hash"
            required
          />
          <label>Address</label>
          <input type="text" value={address} />
          <div className={classes.btndiv}>
            <button onClick={() => Requestamount()}>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CryptoDepositAmount;
