import React, { useEffect, useState } from 'react';
import classes from '../styles/SetFees.module.scss';
import { FormProp } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import axios from 'axios';
import { ROUTES } from '../config';
import { useAdmin } from 'context/admin';

const entries: FormProp[] = [
  // {
  //   name: 'transc_fee',
  //   label: 'Enter Fees Amount',
  //   placeholder: 'Enter Here',
  //   type: 'number',
  // },
];

const schema = yup.object().shape({
  // transc_fee: yup
  //   .number()
  //   .required('Transaction Fees is required.')
  //   .moreThan(0),
});

interface Props {
  user: any;
}

const SetFees = ({ user }: Props) => {
  const [loading, setLoading] = useState(false);

  // ? Marginal Percentage
  const [transcFee, setTranscFee] = useState<any>();
  const [currentTranscFee, setCurrentTranscFee] = useState<any>();
  const [formError, setFormError] = useState<string>('');

  // ? Margin Percentage
  const [marginFee, setMarginFee] = useState<any>();
  const [currentMarginFee, setCurrentMarginFee] = useState<any>();
  const [marginFormError, setMarginFormError] = useState<string>('');

  const { headers } = useAdmin();

  // ? Method to handle the API call for setting Marginal Percentage
  const handleMarginalSubmit = async (formData: any) => {
    setFormError(() => '');

    if (transcFee <= 0 || parseFloat(transcFee) <= 0) {
      setFormError(() => 'Transaction Fee must be greater than 0');
    } else {
      try {
        setLoading(true);

        let reqBody = {
          transc_fee: parseFloat(transcFee),
        };

        const { data } = await axios.patch(
          ROUTES.SET_TRANSACTION_FEE(user?._id),
          reqBody,
          { headers },
        );

        setLoading(() => false);

        fetchUser();

        return { success: data.message };
      } catch (error: any) {
        setLoading(() => false);

        return { error: error?.response?.data?.message };
      }
    }
  };
  // * End of handleMarginalSubmit();

  // ? Method to handle the API call for setting Margin Percentage
  const handleMarginSubmit = async (formData: any) => {
    setMarginFormError(() => '');

    if (marginFee <= 0 || parseFloat(marginFee) <= 0) {
      setMarginFormError(() => 'Margin Fee must be greater than 0');
    } else {
      try {
        setLoading(true);

        let reqBody = {
          margin_fee: parseFloat(marginFee),
        };

        const { data } = await axios.patch(
          ROUTES.SET_MARGIN_FEE(user?._id),
          reqBody,
          { headers },
        );

        setLoading(() => false);

        fetchUser();

        return { success: data.message };
      } catch (error: any) {
        setLoading(() => false);

        return { error: error?.response?.data?.message };
      }
    }
  };
  // * End of handleMarginalSubmit();

  console.log({
    headers,
    user,
  });

  const fetchUser = async () => {
    const { data } = await axios.get(ROUTES.GET_USER(user?._id), {
      headers,
    });

    console.log({
      userData: data,
    });

    if (data) {
      setCurrentMarginFee(data?.margin_fee);
      setCurrentTranscFee(data?.transc_fee);
    } else {
      setCurrentMarginFee(user?.margin_fee);
      setCurrentTranscFee(user?.transc_fee);
    }
  };

  useEffect(() => {
    fetchUser();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.marginContainer}>
        <h1>Set Fee Percentage</h1>
        <span>
          Current Fee Percentage: {currentTranscFee || user?.transc_fee}
        </span>
        <div className={classes.form}>
          <Form
            onSubmit={handleMarginalSubmit}
            schema={schema}
            entries={entries}
          >
            <input
              name="transc_fee"
              id="clean"
              placeholder="Enter Here"
              type="number"
              step="any"
              value={transcFee}
              onChange={(event) => setTranscFee(() => event.target.value)}
              required
            />

            <div className={classes.formErrSection}>
              <span>{formError}</span>
            </div>

            <Button loading={loading}>Submit</Button>
          </Form>
        </div>
      </div>

      <div className={classes.verticalDivider} />

      <div className={classes.commissionsContainer}>
        <h1>Set Margin Number</h1>
        <span>
          Current Fee Percentage: {currentMarginFee || user?.margin_fee}
        </span>
        <div className={classes.form}>
          <Form onSubmit={handleMarginSubmit} schema={schema} entries={entries}>
            <input
              name="margin_fee"
              id="clean"
              placeholder="Enter Here"
              type="number"
              step="any"
              value={marginFee}
              onChange={(event) => setMarginFee(() => event.target.value)}
              required
            />

            <div className={classes.formErrSection}>
              <span>{marginFormError}</span>
            </div>

            <Button loading={loading}>Submit</Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SetFees;
