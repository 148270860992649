import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import classes from 'styles/DepositeTicket.module.scss';
import axios from 'axios';
import { ROUTES } from 'config';
import { useAdmin } from 'context/admin';
import { useModal } from 'context/modal';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { color } from './ExtraDiv';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/arrow-down-black.svg';

interface main {
  funcrun: () => {};
}

const cryptoCurrenciesList = ['BTC', 'ETH', 'USDT'];

function DepositeTicket({ funcrun }: main) {
  const { headers } = useAdmin();
  const { closeModal } = useModal();

  const [receivedAmtCurrency, setReceivedAmtCurrency] = useState<string>('BTC');

  // initial value++++++++++++++++++++++
  const initialvalues = {
    transaction_hash: '',
    source_address: '',
    received_amt: ' ',
  };
  // schema or stucture ++++++++++++++++
  const schema = yup.object().shape({
    transaction_hash: yup.string().required('Hash String Is Required'),
    source_address: yup.string().required('Source Address Required'),
    received_amt: yup.number().required('Recived amount Required').moreThan(0),
  });

  const handleSubmit = async (value: any) => {
    try {
      const body = {
        transaction_hash: value.transaction_hash,
        source_address: value.source_address,
        received_amt: Number(value.received_amt),
        received_amt_curr: receivedAmtCurrency,
      };
      const { data } = await axios.post(ROUTES.Deposite_Creator, body, {
        headers,
      });
      if (data) {
        toast.success('Success!');
        closeModal();
        funcrun();
      }
      if (!data) {
        toast.warning('Please Provide Correct Data');
      }
    } catch (err) {
      toast.warning('Please Provide Correct Data');
      console.log(err);
    }
  };

  return (
    <div className={classes.Container}>
      <h1 className={classes.heading}>Create Deposit Request</h1>
      <Formik
        initialValues={initialvalues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className={classes.Forms}>
                <label>Transaction Hash</label>
                <Field
                  name="transaction_hash"
                  type="text"
                  placeholder="Transaction Hash"
                  className={classes.field}
                />
                <ErrorMessage
                  name="transaction_hash"
                  render={(msg) => <div style={color}>{msg}</div>}
                />
              </div>
              <div className={classes.Forms}>
                <label>Source Address</label>
                <Field
                  name="source_address"
                  type="text"
                  placeholder="Source Address"
                  className={classes.field}
                />
                <ErrorMessage
                  name="source_address"
                  render={(msg) => <div style={color}>{msg}</div>}
                />
              </div>
              <div className={classes.Forms}>
                <label> Amount</label>
                <Field
                  name="received_amt"
                  type="text"
                  placeholder="Received Amount"
                  className={classes.field}
                />
                <ErrorMessage
                  name="received_amt"
                  render={(msg) => <div style={color}>{msg}</div>}
                />
              </div>
              <div className={classes.Forms}>
                <label>Received Amount Currency</label>
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropDownfield}>
                      <span>{receivedAmtCurrency}</span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  onItemClick={(e) => setReceivedAmtCurrency(() => e.value)}
                >
                  {cryptoCurrenciesList.map((crypto: string) => (
                    <MenuItem
                      key={crypto}
                      value={crypto}
                      className={
                        receivedAmtCurrency === crypto
                          ? 'dropdown-item-selected'
                          : ''
                      }
                    >
                      {crypto}
                    </MenuItem>
                  ))}
                </Menu>

                <ErrorMessage
                  name="received_amt_curr"
                  render={(msg) => <div style={color}>{msg}</div>}
                />
              </div>
              <button>Submit</button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default DepositeTicket;
