import React, { useState, useEffect } from 'react';
import classes from '../../styles/EditUserBalance.module.scss';
import axios from 'axios';
import { ROUTES } from '../../config';
import { useAdmin } from 'context/admin';
// import { toast } from 'react-toastify';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

interface Props {
  user: any;
}

const LadminUserBalance = ({ user }: Props) => {
  const [currentFiatBalance, setCurrentFiatBalance] = useState<any>();

  const [currentCryptoBalance, setCurrentCryptoBalance] = useState<any>();

  const { headers } = useAdmin();

  // console.log({
  //   headers,
  //   user,
  // });

  // ? Method to fetch the user's fiat balances
  const fetchFiatBalance = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_FIAT_BALANCE_ADMIN(user?._id),
      {
        headers,
      },
    );

    if (data) {
      console.log({
        fiatBal: data?.data?.fiat_bal,
      });

      setCurrentFiatBalance(data?.data?.fiat_bal);
    }
  };

  // ? Method to fetch the user's crypto balances
  const fetchCryptoBalance = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_CRYPTO_BALANCE_ADMIN(user?._id),
      {
        headers,
      },
    );

    if (data) {
      console.log({
        cryptoBal: data?.data?.crypto_bal,
      });

      setCurrentCryptoBalance(data?.data?.crypto_bal);
    }
  };

  useEffect(() => {
    fetchFiatBalance();

    fetchCryptoBalance();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.marginContainer}>
        <h1>Edit FIAT Balance</h1>
        <div className={classes.balancesSection}>
          Current balance:
          <br />
          {/* // eslint-disable-next-line */}
          {currentFiatBalance
            ? currentFiatBalance.map((fiatData: any) => {
                if (fiatData?.fiat_curr !== 'AED') {
                  return `${fiatData?.fiat_curr}: ${CURRENCY_CONV.fiatAmtConv(
                    fiatData?.fiat_coins,
                  )} | `;
                }
                return null;
              })
            : ''}
        </div>
      </div>

      <div className={classes.verticalDivider} />

      <div className={classes.commissionsContainer}>
        <h1>Edit Crypto Balance</h1>

        <div className={classes.balancesSection}>
          Current balance:
          <br />
          {currentCryptoBalance
            ? currentCryptoBalance.map((cryptoData: any) => {
                return `${
                  cryptoData?.crypto_curr
                }: ${CURRENCY_CONV.cryptoAmtConv(cryptoData?.crypto_coins)} | `;
              })
            : ''}
        </div>

        {/* <div className={classes.form}>
                    <Form
                        onSubmit={() => checkUserVerificationStatus('CRYPTO')}
                        schema={schema}
                        entries={entries}
                    >
                        <div className={classes.formContainer}>
                            <input
                                name="comission_fee"
                                id="clean"
                                placeholder="New Crypto Balance"
                                type="number"
                                step="any"
                                value={cryptoData.balance}
                                onChange={(event) =>
                                    setCryptoData({
                                        ...cryptoData,
                                        balance: Number(event.target.value),
                                    })
                                }
                                required
                            />
                            <div className={classes.selectionDiv}>
                                <select
                                    onChange={(e) =>
                                        setCryptoData({
                                            ...cryptoData,
                                            currency: e.target.value,
                                        })
                                    }
                                    value={cryptoData.currency}
                                >
                                    {Object.keys(CRYPTO_CURR).map((curr: any) => (
                                        <option key={curr} value={curr}>
                                            {curr}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={classes.formErrSection}>
                            <span>{cryptoData.error}</span>
                        </div>

                        <Button loading={cryptoData.loading}>Submit</Button>
                    </Form>
                </div> */}
      </div>
    </div>
  );
};

export default LadminUserBalance;
