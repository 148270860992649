import axios from 'axios';
import { useUser } from 'context/user';
import { useEffect, useState } from 'react';
import { PaginateProps } from '../components/Paginate';

interface Props {
  route: string;
  params?: any;
  infinite?: boolean;
}

interface Options {
  reload: () => Promise<void>;
  loadMore: () => Promise<void>;
  loadPrev: () => Promise<void>;
  loadPage: () => Promise<void>;
  page: number;
  hasMore: boolean;
  total: number;
  lastPage: number;
  loading: boolean;
  paginateProps: PaginateProps;
}

interface Fetch {
  page?: number;
  append?: boolean;
}

interface Fetched<T> {
  list: T[];
  thisPage: number;
  total: number;
  hasMore: boolean;
}

export default function usePaginate<T>(
  { route, params = {}, infinite }: Props,
  deps: any[] = [],
  cb: (list: T[]) => T[] = (list) => list,
) {
  const { headers } = useUser();
  const [list, setList] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getList = async ({
    page: fetchPage = page + 1,
    append = false,
  }: Fetch = {}) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data }: { data: Fetched<T> } = await axios.get(route, {
        params: {
          sort: { createdAt: -1 },
          page: fetchPage,
          ...params,
        },
        headers,
      });
      const l = cb(data.list);
      setList((p) => (infinite && append ? [...p, ...l] : l));
      setPage(data.thisPage);
      setHasMore(data.hasMore);
      setTotal(data.total);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () =>
    hasMore ? getList({ page: page + 1, append: true }) : undefined;
  const reload = () => getList({ page: 1 });
  const loadPrev = page === 1 ? undefined : () => getList({ page: page - 1 });
  const loadPage = (page: number) => getList({ page });
  const lastPage = Math.ceil(total / 10);

  useEffect(() => {
    getList({ page: 1 });
    // eslint-disable-next-line
  }, [headers, ...deps]);

  return [
    list,
    {
      reload,
      loadMore,
      loadPrev,
      loadPage,
      page,
      hasMore,
      total,
      lastPage,
      loading,
      paginateProps: {
        currentPage: page,
        lastPage,
        next: loadMore,
        prev: loadPrev,
        jumpTo: loadPage,
      },
    },
  ] as [T[], Options];
}
