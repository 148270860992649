import React, { ReactNode } from 'react';
import { FormProp } from '../interfaces';
import InputField from './InputField';
import classes from '../styles/Form.module.scss';
import * as yup from 'yup';
import useYupValidationResolver from '../hooks/useYupValidationResolver';
import { Resolver, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  entries: FormProp[];
  schema?: yup.AnyObjectSchema;
  onSubmit: any;
  children?: ReactNode;
  defaultValues?: any;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  account?: any;
  color?: string;
  Bgcolor?: string;
  disabled?: boolean;
}

export default function Form({
  entries, // has all values of inputs
  schema, // for validation
  children,
  onSubmit,
  defaultValues,
  setLoading,
  account,
  color,
  Bgcolor,
  disabled,
}: Props) {
  const handleSubmit = async (data: any) => {
    setLoading?.(true);
    try {
      const isValid = await schema?.validate(data);
      if (!schema || isValid) {
        const { error, success } = (await onSubmit(data)) || {};
        if (error) toast.error(error);
        if (success) toast.success(success);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setLoading?.(false);
    }
  };
  const resolver = useYupValidationResolver(schema) as Resolver;
  const {
    register,
    handleSubmit: submitForm,
    formState: { errors },
    setValue,
  } = useForm({ resolver, defaultValues });

  return (
    <>
      <form
        className={classes.container}
        onSubmit={submitForm(handleSubmit, console.warn)}
      >
        <div className={classes.container}>
          {entries.map((entry, index) => (
            <div key={index.toString()}>
              <InputField
                setValue={setValue}
                errors={errors}
                register={register}
                entry={entry}
                color={color}
                Bgcolor={Bgcolor}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
        {children}
      </form>
    </>
  );
}
