import axios from 'axios';
import { useAdmin } from 'context/admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ROUTES } from '../config';
import classes from '../styles/BankDetails2.module.scss';
import BankDetailsCard from './BankDetailsCard';

interface Props {
  user: any;
}

export default function BankDetails({ user }: Props) {
  const [banks, setBanks] = useState([]);
  const { id } = useParams() as { id: string };
  const { headers } = useAdmin();

  const getBanks = async () => {
    const { data } = await axios.get(
      ROUTES.S_FETCH_USER_BANK_APPLICATIONS_ADMIN(id),
      { headers },
    );
    console.log(data);
    setBanks(data);
  };

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      {banks.map((bank: any, index) => (
        <div key={index.toString()}>
          <BankDetailsCard {...bank} ref_userID={user} />
          <hr />
        </div>
      ))}
    </div>
  );
}
