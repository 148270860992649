import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
// import { anyObject } from '../interfaces';
import axios from 'axios';
import { ROUTES } from '../config';
import { useUser } from '../context/user';
import { useModal } from '../context/modal';
import classes from 'styles/Verifyphone.module.scss';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { Link } from 'react-router-dom';
import { paradata } from './ExtraDiv';
interface Props {
  validation: any;
}

export default function VerifyPhoneOTP({ validation }: Props) {
  const { headers, user } = useUser();
  const [otpval, setotpval] = useState('');
  const [gacode, setGacode] = useState('');
  const [authentication, setAuthentication] = useState('0');
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [disableOtp, setDisableOtp] = useState(false);
  const { closeModal } = useModal();

  useEffect(() => {
    if (authentication === '2') {
      sendOtp();
    }
    // eslint-disable-next-line
  }, [authentication]);

  const sendOtp = async () => {
    try {
      const { data } = await axios.post(ROUTES.SEND_PHONE_OTP, {}, { headers });
      toast.success(data.message);
    } catch (error) {
      toast.error('There is some problem. Please try again later');
    }
  };

  const handleAuthenticationMode = async (event: any) => {
    setAuthentication(event.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        ROUTES.VALIDATE_OTP,
        { userOTP: otpval },
        {
          headers,
        },
      );
      console.log({ data });
      if (data.validated) {
        validation();
        closeModal();
        toast.success('Proceed to change email');
      }

      // return { success: data.message };
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  // google auth handler++++++++++++++++++++++++++++++++++++
  const handleGoogleAuthSubmit = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    try {
      const result = await axios.post(
        ROUTES.GACode_check,
        {
          ga_sSteps: gacode,
        },
        { headers },
      );
      const data = await result.data;

      if (data.validated === true) {
        toast.success('Google Auth. Success');
        validation();
        closeModal();
      }
    } catch (err: any) {
      toast.warning('Something went wrong, please try again.');
    }
  };

  const modalcss = {
    border: '0px solid transparent',
    borderRadius: '10px',
    width: '51px',
    height: '51px',
    background: '#F1F1F1',
    fontSize: '12px',
    color: 'black',
    fontWeight: '400',
    caretColor: 'blue',
  };

  const number = user.phone_no;

  return (
    <div>
      <label>Select Authentication Mode</label>
      <Menu
        menuButton={
          <MenuButton className={classes.dropDownfield}>
            <span>Select Authentication Mode</span>
            <img src={Arrowdown} alt="arrow-img" />
          </MenuButton>
        }
        transition
        menuClassName="drop-down"
        onItemClick={(e: any) => handleAuthenticationMode(e)}
      >
        {user.enabled_3fa && (
          <MenuItem value="1">Google Authenticator</MenuItem>
        )}
        <MenuItem value="2">Send OTP to Phone</MenuItem>
      </Menu>
      {authentication === '1' && (
        <div className={classes.forms}>
          <label htmlFor="otp">Enter Google Authenticator OTP</label>
          {/* <form onSubmit={handleSubmit}> */}
          <div className={classes.grid}>
            <div>
              <OtpInput
                value={gacode}
                onChange={setGacode}
                numInputs={6}
                inputStyle={modalcss}
                isDisabled={false}
                disabledStyle={{ opacity: '0.3' }}
                separator={<span>-</span>}
                isInputNum={true}
                isInputSecure={false}
              />
            </div>
            <div>
              <Link className={classes.reset} to="/">
                Need Help?
              </Link>
            </div>
          </div>
          <div className={classes.paraDiv}>
            {/* paragraph++++++++++++++ */}
            {paradata.map((data, index) => {
              return (
                <p className={classes.para2} key={index.toString()}>
                  Please open Google Authenticator on your registered device.
                  In-case you have forgot/lost access to these,
                  <Link to="/contact" className={classes.links}>
                    please contract encryptus support.
                  </Link>
                </p>
              );
            })}
          </div>
          <div className={classes.ResetDiv}>
            <button
              className={classes.reset2}
              // disabled={disableOtp}
              onClick={handleGoogleAuthSubmit}
            >
              Submit Request
            </button>
          </div>
          {/* </form> */}
        </div>
      )}
      {authentication === '2' && (
        <div className={classes.container}>
          <h2>Please enter your code sent to {number.slice(0, 4) + 'XXXXX'}</h2>
          <form onSubmit={handleSubmit}>
            <OtpInput
              value={otpval}
              onChange={setotpval}
              numInputs={6}
              inputStyle={modalcss}
              isDisabled={disableOtp}
              disabledStyle={{ opacity: '0.3' }}
              separator={<span>-</span>}
              isInputNum={true}
              isInputSecure={false}
            />

            <Button style={{ marginTop: '20px' }} loading={loading}>
              Verify
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
