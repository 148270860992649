import React, { useState } from 'react';
import { FormProp, ResetPasswordForm } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import { useUser } from '../context/user';
import { useModal } from '../context/modal';
import axios from 'axios';
import { ROUTES } from 'config';
import { toast } from 'react-toastify';
import Login from './Login';
import inputClass from 'styles/InputField.module.scss';
import { useAdmin } from 'context/admin';

interface Props {
  email: string;
  isAdmin?: boolean;
  selfChange?: boolean;
}

const entries: FormProp[] = [
  // {
  //   name: 'email',
  //   label: 'E-Mail',
  //   placeholder: 'Your E-Mail',
  //   type: 'email',
  // },
  {
    name: 'newPassword',
    label: 'New Password',
    placeholder: '',
    type: 'password',
  },
  {
    name: 'passwordConfirmation',
    label: 'Confirm Password',
    placeholder: '',
    type: 'password',
  },
];

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6)
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain More than 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
    ),
  passwordConfirmation: yup
    .string()
    .min(6)
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export default function ResetPassword({
  email,
  isAdmin,
  selfChange = false,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { headers } = useUser();
  const { headers: adminHeaders } = useAdmin();
  const { openModal, closeModal } = useModal();

  const handleSubmit = async (formData: ResetPasswordForm) => {
    console.log({
      formData,
      isAdmin,
    });

    try {
      let body: any = {
        newPass: formData.newPassword,
        confirmPass: formData.passwordConfirmation,
      };

      if (isAdmin) {
        body.adminEmail = email;
      } else body.userEmail = email;

      const axiosHeaders = isAdmin ? adminHeaders : headers;

      const ROUTE = isAdmin
        ? ROUTES.RESET_ADMIN_PASSWORD
        : ROUTES.RESET_USER_PASSWORD;

      const { data } = await axios.patch(ROUTE, body, axiosHeaders);

      console.log({
        data,
      });

      if (data && data?.status) {
        toast.success(data?.message || 'Password Reset Successful!');

        if (!isAdmin && !selfChange) openModal(Login);
        else closeModal();
      } else {
        toast.error('Error Resetting Password');
      }
    } catch (error: any) {
      console.log({
        error,
      });
      toast.error(error?.response?.data?.message);
    }
  };

  const styles = {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '50px',
    color: '#091A49',
  };

  return (
    <div>
      <h1 style={styles}>
        {isAdmin ? 'Change' : 'Forgot'} Password - Password Reset
      </h1>

      {/* EMAIL INPUT - DISABLED */}
      <div className={inputClass.container}>
        <label>E-Mail</label>
        <div className={inputClass.inputContainer}>
          <div className={inputClass.icon}></div>
          <input
            name="email"
            id="clean"
            placeholder="Your E-Mail"
            type="email"
            value={email}
            disabled
          />
          <div className={inputClass.tool}></div>
        </div>
      </div>

      <Form
        defaultValues={{ email }}
        entries={entries}
        schema={schema}
        onSubmit={handleSubmit}
        setLoading={setLoading}
      >
        <Button loading={loading} type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
