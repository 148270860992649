import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.png';
import classes from 'styles/Footer.module.scss';
import MediumImage from 'assets/FinalLandingPage/MediumIcon.png';
import TwitterImage from 'assets/FinalLandingPage/TwitterIcon.png';
import LinkedinImage from 'assets/FinalLandingPage/LinkedinIcon.png';

function Footer() {
  return (
    <>
      <div className={classes.container_fourteen}>
        <div className="flex lg:grid items-center justify-between grid-rows-6 grid-flow-row lg:grid-rows-2">
          <Link className="lg:col-span-2" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/termsCondition" className={classes.mainlink}>
            Terms &amp; Conditions
          </Link>
          <Link to="/privacy" className={classes.mainlink}>
            Privacy Policy
          </Link>
          <Link to="/useragreement" className={classes.mainlink}>
            User Agreement
          </Link>
          {/* <Link to="/cookiepolicy" className={classes.mainlink}>
            Cookie Policy
          </Link> */}
          <Link to="/riskdisclosure" className={classes.mainlink}>
            Risk  Disclosure
          </Link>
          <Link to="/publicdisclosure" className={classes.mainlink}>
            Public Disclosure
          </Link>
           <Link to="/complianthandling" className={classes.mainlink}>
            Compliants Handling
          </Link>
          <div className="flex gap-2 lg:col-span-2 lg:pt-5 justify-center">
            <a
              target="_blank"
              href="https://medium.com/@Encryptus"
              rel="noreferrer"
            >
              <img src={MediumImage} alt="MediumIcon"></img>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/EncryptusGlobal"
              rel="noreferrer"
            >
              <img src={TwitterImage} alt="TwitterIcon"></img>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/encryptus/?viewAsMember=true"
              rel="noreferrer"
            >
              <img src={LinkedinImage} alt="LinkedinIcon"></img>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
