import React, { useEffect, useState } from 'react';
import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/Permission.module.scss';
import { useModal } from 'context/modal';
import DraftPermissions from 'modals/DraftPermissions';
import axios from 'axios';
import { ADMIN_ACTIONS, ROUTES } from 'config';
import { useAdmin } from 'context/admin';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { DATE_CONV } from 'utils/DateConv';
import { toast } from 'react-toastify';
import BinIcon from 'assets/bin.svg';

export default function Permissions() {
  const { openModal } = useModal();
  const { headers } = useAdmin();
  const [collections, setCollections] = useState<object[]>([]);
  const [selection, setSelection] = useState<string>('');

  useEffect(() => {
    request(selection);
    // eslint-disable-next-line
  }, [selection]);

  const request = async (target?: any) => {
    // console.log(target);
    try {
      let current = target ? target : selection;
      if (current === 'all') {
        const { data }: any = await axios.get(ROUTES.All_PERMISSION, {
          headers,
        });
        setCollections(data.list);
      } else {
        const { data }: any = await axios.get(
          ROUTES.Selected_Permissions(selection),
          { headers },
        );
        setCollections(data.list);
      }
    } catch (err) {
      console.log('error');
    }
  };

  const deletePermissionHandler = async (values: any, action: any) => {
    try {
      toast.info('Deleting permission...', {
        toastId: 'deletePermission',
      });
      await axios.delete(ROUTES.DELETE_PERMISSION(values._id, action), {
        headers,
      });
      toast.dismiss('deletePermission');
      toast.success('Permission deleted successfully');
      request(selection);
    } catch (error) {
      toast.dismiss('deletePermission');
      toast.error('Error deleting permission');
    }
    // console.log(values, action);
  };

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, []);

  const rerender = () => {
    request();
  };

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.header}>
        <div className={classes.permission}>
          <Menu
            menuButton={
              <MenuButton className={classes.dropdownBtn}>
                <span>
                  {selection === ''
                    ? 'All Permissions'
                    : ADMIN_ACTIONS[selection as keyof typeof ADMIN_ACTIONS]}
                </span>
                <img src={Arrowdown} alt="arrow-img" width={10} />
              </MenuButton>
            }
            transition
            menuClassName={`drop-down ${classes.permissionDropdown} `}
            onItemClick={(e: any) => setSelection(e.value)}
          >
            <MenuItem
              value={''}
              key={'all'}
              className={selection === '' ? 'dropdown-item-selected' : ''}
            >
              All Permissions
            </MenuItem>

            {Object.keys(ADMIN_ACTIONS).map((data, i) => {
              return (
                <MenuItem
                  value={data}
                  key={i.toString()}
                  className={selection === data ? 'dropdown-item-selected' : ''}
                >
                  {ADMIN_ACTIONS[data as keyof typeof ADMIN_ACTIONS]}
                </MenuItem>
              );
            })}
          </Menu>

          <button
            onClick={() =>
              openModal(() => <DraftPermissions rerender={rerender} />)
            }
          >
            Create Permissions
          </button>
        </div>
      </div>
      <div className={classes.permission_table}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Action Allowed</th>
              <th>Roles Allowed</th>
            </tr>
          </thead>
          <tbody>
            {collections &&
              collections.map((values: any, i) => {
                const date = values.createdAt.slice(0, 10);
                return (
                  <>
                    <tr key={i.toString()}>
                      <td>{DATE_CONV.dateConvert(date)}</td>
                      <td>
                        {values.actions_allowed.map((action: any) => {
                          return (
                            <div className={classes.actionContainer}>
                              <div key={action}>
                                {
                                  ADMIN_ACTIONS[
                                    action as keyof typeof ADMIN_ACTIONS
                                  ]
                                }
                              </div>
                              <div
                                onClick={() =>
                                  deletePermissionHandler(values, action)
                                }
                              >
                                <img src={BinIcon} alt="Delete" width={25} />
                              </div>
                              <br />
                            </div>
                          );
                        })}
                      </td>
                      <td>{values.role_allowed}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </AdminScreen>
  );
}
