// ? Method that renders the round currency icon

import classes from 'styles/CurrencyIcon.module.scss';

const currencyIcon = (symbol: string | number) => {
  return (
    <div className={classes.currencyIcon}>
      <span>{symbol}</span>
    </div>
  );
};

export { currencyIcon };
