import React from 'react';
import classes from '../styles/BankDetails.module.scss';
import { useModal } from '../context/modal';
import AddNote from './AddNote';
import { DATE_CONV } from 'utils/DateConv';
import { copyText } from 'utils/CopyContent';

const BankDetail: React.FC<{
  data?: any;
  changeStatus: any;
  reject: any;
  index: number;
}> = ({ data, changeStatus, reject, index }) => {
  const { openModal, closeModal } = useModal();
  return (
    <div className={classes.container}>
      <h1>Bank Detail</h1>
      <section className={classes.bank}>
        <div className={classes.item}>
          <h6>Customer Email</h6>
          <p onDoubleClick={() => copyText(data?.tagger_email)}>
            {data?.tagger_email}
          </p>
        </div>
        <div className={classes.item}>
          <h6>Account Holder Name</h6>
          <p onDoubleClick={() => copyText(data?.accountHolderName)}>
            {data?.accountHolderName}
          </p>
        </div>
        <div className={classes.item2}>
          <h6>Account Holder Address</h6>
          <p onDoubleClick={() => copyText(data?.accountHolderAddress)}>
            {data?.accountHolderAddress}
          </p>
        </div>
        <div className={classes.item2}>
          <h6>Applied on</h6>
          <p
            onDoubleClick={() =>
              copyText(DATE_CONV.dateTimeConvert(data?.createdAt))
            }
          >
            {DATE_CONV.dateTimeConvert(data?.createdAt)}
          </p>
        </div>
        <div className={classes.item}>
          <h6>Beneficiary Bank Name</h6>
          <p onDoubleClick={() => copyText(data?.bankName)}>{data?.bankName}</p>
        </div>
        <div className={classes.item}>
          <h6>Beneficiary Bank Address</h6>
          <p onDoubleClick={() => copyText(data?.bankAddress)}>
            {data?.bankAddress}
          </p>
        </div>
        <div className={classes.item}>
          <h6>Country of Beneficiary Bank</h6>
          <p onDoubleClick={() => copyText(data?.bankCountry)}>
            {data?.bankCountry}
          </p>
        </div>
        <div className={classes.item}>
          <h6>IBAN/Account Number</h6>
          <p onDoubleClick={() => copyText(data?.accountNumber)}>
            {data?.accountNumber}
          </p>
        </div>
        <div className={classes.item}>
          <h6>BIC/Swift</h6>
          <p onDoubleClick={() => copyText(data?.bankIdentifierCode)}>
            {data?.bankIdentifierCode}
          </p>
        </div>
        <div className={classes.item}>
          <h6>Currency</h6>
          <p onDoubleClick={() => copyText(data?.currency)}>{data?.currency}</p>
        </div>
        <div className={classes.item2}>
          <h6>Status</h6>
          <p
            className={
              data?.status === 'approved'
                ? 'textStatusApproved'
                : data?.status === 'pending'
                ? 'textStatusPending'
                : 'textStatusRejected'
            }
            onDoubleClick={() => copyText(data?.status?.toUpperCase())}
          >
            {data?.status?.toUpperCase()}
          </p>
        </div>
      </section>
      {data?.status === 'approved' ? (
        <div className={classes.btnGrp}>
          <button
            className={classes.reject}
            onClick={() =>
              openModal(() => <AddNote reject={reject(data?._id, index)} />)
            }
          >
            Reject
          </button>
        </div>
      ) : (
        <div className={classes.btnGrp}>
          {data?.status === 'pending' ? (
            <>
              <button
                className={classes.approve}
                onClick={() => {
                  closeModal();
                  changeStatus(data?._id, 'approved', index);
                }}
              >
                Approve
              </button>
              <button
                className={classes.reject}
                onClick={() =>
                  openModal(() => <AddNote reject={reject(data?._id, index)} />)
                }
              >
                Reject
              </button>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default BankDetail;
