import { useCallback, useEffect, useState } from 'react';
import useQuery from '../hooks/useQuery';
import axios from 'axios';
import { ROUTES } from 'config';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export default function OldSession(props: any) {
  const token = useQuery('verifyToken');
  const isAdmin = useQuery('isAdmin');
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);
  console.log({ token });
  const verifyToken = useCallback(async () => {
    try {
      if (!token) return;
      const ROUTE =
        isAdmin === 'true'
          ? ROUTES.TERMINATE_ADMIN_OLD_SESSION
          : ROUTES.TERMINATE_USER_OLD_SESSION;
      const { data } = await axios.post(ROUTE, {
        emailToken: token,
      });
      console.log({ data });
      toast.success(data.message);
      if (data.status === 'success') setVerified(true);
    } catch (error: any) {
      console.log({ error });
      setError(error?.response?.data?.message);
    }
    // eslint-disable-next-line
  }, [token, setError]);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, [verifyToken]);

  useEffect(() => {
    if (!error) return;
    toast.error(error);
  }, [error]);

  if (verified) return <Redirect to="/" />;

  return (
    <div>
      <p style={{ margin: 'auto', textAlign: 'center', padding: 20 }}>
        {verified
          ? 'OLD SESSION TERMINATED'
          : error || 'TERMINATING OLD SESSION . . .'}
      </p>
    </div>
  );
}
