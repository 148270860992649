import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/AdminDashboard.module.scss';
import AdminDetailedDashboard from './DetailedDashboard';

export default function AdminDashboard() {
  return (
    <AdminScreen className={classes.container}>
      <AdminDetailedDashboard />
    </AdminScreen>
  );
}
