import classes from '../styles/SetFees.module.scss';
import { anyObject, FormProp } from '../interfaces';
import Button from '../components/Button';
import * as yup from 'yup';
import { useModal } from '../context/modal';
import Form from '../components/Form';

const entries: FormProp[] = [
  {
    name: 'accountHolderName',
    label: 'Account Holder Name',
    placeholder: '',
    type: 'checkbox',
  },
  {
    name: 'accountHolderAddress',
    label: 'Account Holder Address',
    placeholder: '',
    type: 'checkbox',
  },
  {
    name: 'iban_number',
    label: 'IBAN/Account Number',
    placeholder: '',
    type: 'checkbox',
  },
  {
    name: 'currency',
    label: 'Currency',
    placeholder: '',
    type: 'checkbox',
  },
  {
    name: 'bankIdentifierCode',
    label: 'Swift/BIC',
    placeholder: '',
    type: 'checkbox',
  },
  {
    name: 'note',
    label: 'Enter Rejection Note',
    placeholder: 'Enter Here',
    type: 'textarea',
  },
];

const schema = yup.object().shape({
  note: yup.string().required(),
});

interface Props {
  reject: (body: anyObject) => any;
}

const AddNote = ({ reject }: Props) => {
  const { closeModal } = useModal();
  const handleSubmit = async ({ note, ...rest }: anyObject) => {
    const body = {
      note,
      conflicting_keys: Object.entries(rest).reduce(
        (prev, [key, value]) => (value ? [...prev, key] : prev),
        [] as string[],
      ),
    };
    const data = await reject(body);
    closeModal();
    console.log({ body });
    return data;
  };
  return (
    <div className={classes.container}>
      <h1>Add Note</h1>
      <Form onSubmit={handleSubmit} schema={schema} entries={entries}>
        <div className={classes.action}>
          <Button>Submit</Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNote;
