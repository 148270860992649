import React from 'react';
import classes from 'styles/Fiattransaction.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';

interface Props {
  collections: object[] | any;
  type?: string;
}

function FiatTransaction(props: Props) {
  if (props.type === 'partners') {
    return (
      <>
        <h3 className={classes.heading}>Fiat Transaction Details</h3>
        <div className={classes.container}>
          <div>
            <h4>Transaction Reference ID</h4>
            <p onDoubleClick={() => copyText(props.collections?.unq_ref_id)}>
              {props.collections?.unq_ref_id}
            </p>
          </div>

          <div>
            <h4>Customer Email</h4>
            <p onDoubleClick={() => copyText(props.collections?.tagger_email)}>
              {props.collections?.tagger_email}
            </p>
          </div>

          {/* <div>
          <h4>Customer ID</h4>
          <p onDoubleClick={() => copyText(props.collections?.tagger_id)}>
            {props.collections?.tagger_id}
          </p>
        </div> */}

          <div>
            <h4>Transaction Status</h4>
            <p
              className={
                props.collections?.transc_status === 'approved'
                  ? 'textStatusApproved'
                  : props.collections?.transc_status === 'pending'
                  ? 'textStatusPending'
                  : 'textStatusRejected'
              }
              onDoubleClick={() =>
                copyText(props.collections?.transc_status.toUpperCase())
              }
            >
              {props.collections?.transc_status.toUpperCase()}
            </p>
          </div>

          <div>
            <h4>Approval</h4>
            <p
              className={
                props.collections?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusPending'
              }
              onDoubleClick={() =>
                copyText(
                  props.collections?.fin_approval_given === true
                    ? 'APPROVED'
                    : 'NOT APPROVED',
                )
              }
            >
              {props.collections?.fin_approval_given === true
                ? 'APPROVED'
                : 'NOT APPROVED'}
            </p>
          </div>

          {/* <div>
          <h4>Approval Count</h4>
          <p
            onDoubleClick={() =>
              copyText(
                props.collections?.need_approval_count === -1
                  ? 'Approval Count not set'
                  : props.collections?.need_approval_count,
              )
            }
          >
            {props.collections?.need_approval_count === -1
              ? 'Approval Count not set'
              : props.collections?.need_approval_count}
          </p>
        </div> */}

          <div>
            <h4>Deposited Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  `${CURRENCY_CONV.fiatAmtConv(props.collections?.depos_amt)} ${
                    props.collections?.depos_curr
                  }`,
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(props.collections?.depos_amt)}&nbsp;
              {props.collections?.depos_curr}
            </p>
          </div>

          <div>
            <h4>Flagged</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  !props.collections?.flagged === true ? 'CLEAN' : 'DIRTY',
                )
              }
            >
              {!props.collections?.flagged === true ? 'CLEAN' : 'DIRTY'}
            </p>
          </div>

          <div>
            <h4>Date</h4>
            <p
              onDoubleClick={() =>
                copyText(DATE_CONV.dateConvert(props.collections?.createdAt))
              }
            >
              {DATE_CONV.dateTimeConvert(props.collections?.createdAt)}
            </p>
          </div>
        </div>
      </>
    );
  }

  // For user section+++++++++++++++++++++++++
  return (
    <>
      <h3 className={classes.heading}>Fiat Transaction Details</h3>
      <div className={classes.container}>
        <div>
          <h4>Transaction Reference ID</h4>
          <p onDoubleClick={() => copyText(props.collections?.unq_ref_id)}>
            {props.collections?.unq_ref_id}
          </p>
        </div>

        <div>
          <h4>Customer Email</h4>
          <p onDoubleClick={() => copyText(props.collections?.tagger_email)}>
            {props.collections?.tagger_email}
          </p>
        </div>

        <div>
          <h4>Customer ID</h4>
          <p onDoubleClick={() => copyText(props.collections?.tagger_id)}>
            {props.collections?.tagger_id}
          </p>
        </div>

        <div>
          <h4>Logged IP</h4>
          <p
            onDoubleClick={() =>
              copyText(props.collections?.client_recorded_IP)
            }
          >
            {props.collections?.client_recorded_IP}
          </p>
        </div>

        <div>
          <h4>Transaction Status</h4>
          <p
            className={
              props.collections?.transc_status === 'approved'
                ? 'textStatusApproved'
                : props.collections?.transc_status === 'pending'
                ? 'textStatusPending'
                : 'textStatusRejected'
            }
            onDoubleClick={() =>
              copyText(props.collections?.transc_status.toUpperCase())
            }
          >
            {props.collections?.transc_status.toUpperCase()}
          </p>
        </div>

        <div>
          <h4>Approval</h4>
          <p
            className={
              props.collections?.fin_approval_given === true
                ? 'textStatusApproved'
                : 'textStatusPending'
            }
            onDoubleClick={() =>
              copyText(
                props.collections?.fin_approval_given === true
                  ? 'APPROVED'
                  : 'NOT APPROVED',
              )
            }
          >
            {props.collections?.fin_approval_given === true
              ? 'APPROVED'
              : 'NOT APPROVED'}
          </p>
        </div>

        <div>
          <h4>Approval Count</h4>
          <p
            onDoubleClick={() =>
              copyText(
                props.collections?.need_approval_count === -1
                  ? 'Approval Count not set'
                  : props.collections?.need_approval_count,
              )
            }
          >
            {props.collections?.need_approval_count === -1
              ? 'Approval Count not set'
              : props.collections?.need_approval_count}
          </p>
        </div>

        <div>
          <h4>Deposited Amount</h4>
          <p
            onDoubleClick={() =>
              copyText(
                `${CURRENCY_CONV.fiatAmtConv(props.collections?.depos_amt)} ${
                  props.collections?.depos_curr
                }`,
              )
            }
          >
            {CURRENCY_CONV.fiatAmtConv(props.collections?.depos_amt)}&nbsp;
            {props.collections?.depos_curr}
          </p>
        </div>

        <div>
          <h4>Flagged</h4>
          <p
            onDoubleClick={() =>
              copyText(!props.collections?.flagged === true ? 'CLEAN' : 'DIRTY')
            }
          >
            {!props.collections?.flagged === true ? 'CLEAN' : 'DIRTY'}
          </p>
        </div>

        <div>
          <h4>Date</h4>
          <p
            onDoubleClick={() =>
              copyText(DATE_CONV.dateConvert(props.collections?.createdAt))
            }
          >
            {DATE_CONV.dateTimeConvert(props.collections?.createdAt)}
          </p>
        </div>
      </div>
    </>
  );
}

export default FiatTransaction;
