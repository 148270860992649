import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import * as signalR from '@microsoft/signalr';
import { ANY, LPStreamRequest } from '../interfaces';
// import { useUser } from 'context/user';

export const CovarioContext = createContext({
  quote: ANY,
  changeRequest: async (data: LPStreamRequest, priceData: any) => ANY,
});

export const CovarioProvider: FC = ({ children }) => {
  const [connection, setConnection] = useState<signalR.HubConnection>();
  const [streamRequest, setStreamRequest] = useState<any>();
  const [streamQuote, setStreamQuote] = useState<any>();
  const [quote, setQuote] = useState<any>();
  const [locked, setLocked] = useState(false);
  const [PriceValues, setPriceValues] = useState<{ [key: string]: any }>({});

  const init = useCallback(async () => {
    // setup connection
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `https://${process.env.REACT_APP_COVARIO_BASE_URL}/api/signalr/otcHub`,
      )
      .build();
    setConnection(hubConnection);
    // eslint-disable-next-line
  }, []);

  const startup = useCallback(
    async (updatePrice = false) => {
      if (
        connection &&
        connection.state === signalR.HubConnectionState.Disconnected
      ) {
        // Start connection
        await connection.start();
        return connection
          .stream('StartQuote', streamRequest, PriceValues.accessToken)
          .subscribe({
            next: async (item) => {
              setStreamQuote(item);
              if (updatePrice) {
                quoteNormaliser(item);
                updatePrice = false;
              }
            },
            complete: () => {
              setStreamQuote(undefined);
            },
            error: () => {
              setStreamQuote(undefined);
            },
          });
      }
    },
    // eslint-disable-next-line
    [connection, streamRequest],
  );

  const shutdown = useCallback(async () => {
    if (connection) {
      return await connection.stop();
    }
  }, [connection]);

  const quoteNormaliser = (item: any) => {
    /*
     * Calculating user price by applying mergins
     * Buying or Selling Price is calculated for quantity of 1 only
     * Then for the given quantity, price is calculated in the buyCryptoFrom and sellCryptoForm files itself.
     */
    if (Object.keys(PriceValues).length !== 0 && item) {
      const buyingpriceFC: number = item['buy'];
      item['originalPrice'] = item['quantity'] * item['buy'];
      const addTF: number =
        (parseFloat(PriceValues.buisness_mp) / 100) * Math.ceil(buyingpriceFC);
      item['margins_applied'] = Number(addTF);
      const buyingpriceWM: number = Number(buyingpriceFC) + Number(addTF);
      const marginFee: number =
        (parseFloat(PriceValues.margin_fee) / 100) * buyingpriceWM;
      const buyingpriceWMTF: number = Number(buyingpriceWM) + Number(marginFee);
      item['buyingPriceForUser'] = buyingpriceWMTF.toFixed(4);
      item['transc_fee'] = PriceValues.buisness_mp;
      item['fees_charged'] = marginFee;
      // calculate the sellingPriceForUser at FE
      const sellingpriceFC: number = item['sell'];
      item['originSellPrice'] = item['quantity'] * item['sell'];
      const deductMP: number =
        (parseFloat(PriceValues.margin_fee) / 100) * Math.ceil(sellingpriceFC);
      const deductTF: number =
        (parseFloat(PriceValues.buisness_mp) / 100) * Math.ceil(sellingpriceFC);
      const totalDeduction = Number(deductMP.toFixed(4)) + Number(deductTF);
      item['sellingPriceForUser'] = (
        Number(sellingpriceFC) - Number(totalDeduction)
      ).toFixed(4);
      item['unit_buyPrice_cov'] = item['buy'];
      item['unit_sellPrice_cov'] = item['sell'];
      console.log('Covario data ', item);
      setQuote(item);
      setStreamQuote(item);
      return item;
    }
  };

  useEffect(() => {
    init();
    return () => {
      shutdown();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalID = setTimeout(() => {
      setLocked((prev) => !prev);
    }, 10000);
    return () => clearInterval(intervalID);
  }, [locked]);

  useEffect(() => {
    quoteNormaliser(streamQuote);
    // eslint-disable-next-line
  }, [locked]);

  useEffect(() => {
    if (
      connection &&
      connection.state !== signalR.HubConnectionState.Disconnected
    ) {
      shutdown().then(() => {
        startup(true);
      });
    } else {
      startup(true);
    }
    setLocked(false);
    // eslint-disable-next-line
  }, [streamRequest]);

  useEffect(() => {
    if (streamQuote && !locked) {
      quoteNormaliser(streamQuote);
    }
    // eslint-disable-next-line
  }, [locked]);

  const changeRequest = async (request: LPStreamRequest, priceData: any) => {
    setPriceValues(priceData);
    setStreamRequest(request);
  };

  return (
    <CovarioContext.Provider
      value={{
        quote,
        changeRequest,
      }}
    >
      {children}
    </CovarioContext.Provider>
  );
};

export const useCovario = () => useContext(CovarioContext);
