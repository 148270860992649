import React, { useState } from 'react';
import Button from '../components/Button';
import { anyObject } from '../interfaces';
import axios from 'axios';
import { ROUTES } from '../config';
import { useUser } from '../context/user';
import { useModal } from '../context/modal';
import classes from 'styles/Verifyphone.module.scss';
import OtpInput from 'react-otp-input';

export default function VerifyPhone() {
  const { headers, getUser, user } = useUser();
  const [otpval, setotpval] = useState('');
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [disableOtp, setDisableOtp] = useState(false);
  const { closeModal } = useModal();
  const handleSubmit = async (formData: anyObject) => {
    try {
      const { data } = await axios.post(
        ROUTES.SEND_SMS_OTP,
        { userOTP: otpval },
        {
          headers,
        },
      );
      await getUser();
      closeModal();
      return { success: data.message };
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  const modalcss = {
    border: '0px solid transparent',
    borderRadius: '10px',
    width: '51px',
    height: '51px',
    background: '#F1F1F1',
    fontSize: '12px',
    color: 'black',
    fontWeight: '400',
    caretColor: 'blue',
  };

  const number = user.phone_no;

  return (
    <div className={classes.container}>
      <h2>Please enter your code sent to {number.slice(0, 4) + 'XXXXX'}</h2>
      <form onSubmit={handleSubmit}>
        <OtpInput
          value={otpval}
          onChange={setotpval}
          numInputs={6}
          inputStyle={modalcss}
          isDisabled={disableOtp}
          disabledStyle={{ opacity: '0.3' }}
          separator={<span>-</span>}
          isInputNum={true}
          isInputSecure={false}
        />

        <Button style={{ marginTop: '20px' }} loading={loading}>
          Verify
        </Button>
      </form>
    </div>
  );
}
