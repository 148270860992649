import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/AddAdmin.module.scss';

import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useState } from 'react';
import axios from 'axios';
import { ROUTES, USER_ROLES } from 'config';
import { useAdmin } from 'context/admin';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import * as yup from 'yup';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';

interface Values {
  name: string;
  email: string;
  role: string;
}

export default function NewAdmin() {
  const [loading, setLoading] = useState(false);
  const [adminRole, setAdminRole] = useState('Finance');

  const { headers } = useAdmin();

  const handlesubmit = async (
    values: Values,
    { resetForm }: FormikHelpers<Values>,
  ) => {
    try {
      setLoading(true);
      const body = {
        admin_name: values.name,
        admin_email: values.email,
        admin_group: USER_ROLES[adminRole as keyof typeof USER_ROLES],
      };
      await axios.post(ROUTES.ADD_NEW_ADMIN, body, {
        headers,
      });
      setLoading(false);
      toast.success('Admin added Successfully');
      resetForm();
    } catch (err: any) {
      setLoading(false);
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  const initialValues = {
    name: '',
    email: '',
    role: 'Finance',
  };

  const schema = yup.object().shape({
    name: yup.string().required('Must give input'),
    email: yup.string().required('Must be a valid email'),
  });

  return (
    <AdminScreen className={classes.container}>
      <h2>Add New Admin</h2>
      <div className={classes.formContainer}>
        <Formik
          setLoading={setLoading}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handlesubmit}
        >
          <Form>
            <div className={classes.inputgroup}>
              <label>Name</label> <br />
              <Field type="text" name="name" className={classes.inputs} />{' '}
              <br />
              <ErrorMessage name="name" />
            </div>
            <div className={classes.inputgroup}>
              <label>Email</label> <br />
              <Field
                type="email"
                name="email"
                className={classes.inputs}
              />{' '}
              <br />
              <ErrorMessage name="email" />
            </div>
            <div className={classes.inputgroup}>
              <label>Group:</label> <br />
              <Menu
                menuButton={
                  <MenuButton className={classes.addAdminDropdownBtn}>
                    <span>{adminRole}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName={`drop-down ${classes.dropdownList}`}
                onItemClick={(e: any) => setAdminRole(e.value)}
              >
                {Object.keys(USER_ROLES).map((data, index) => {
                  return (
                    <MenuItem
                      value={data}
                      key={index}
                      className={
                        adminRole === data ? 'dropdown-item-selected' : ''
                      }
                    >
                      {data}
                    </MenuItem>
                  );
                })}
              </Menu>
              <br />
              <ErrorMessage name="role" />
            </div>
            <Button loading={loading} type="submit">
              Add Admin
            </Button>
          </Form>
        </Formik>
      </div>
    </AdminScreen>
  );
}
