import React from 'react';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { DATE_CONV } from 'utils/DateConv';
import CurrencyList from 'currency-list';
import classes from '../../styles/TransactionList.module.scss';

type TransactionItemProp = {
  _id: string;
  depos_curr: string;
  fiat_curr: string;
  transc_status: string;
  depos_amt: any;
  quot_amount: any;
  withdraw_amt: any;
  createdAt: any;
  withdraw_curr: any;
  transac_type: any;
};

function TransactionItem({
  _id,
  depos_curr,
  fiat_curr,
  quot_amount,
  transc_status,
  depos_amt,
  withdraw_amt,
  createdAt,
  withdraw_curr,
  transac_type,
}: TransactionItemProp) {
  return (
    <div className={classes.transactionItem}>
      <div className={classes.transactionDetail}>
        <div className={classes.date}>{DATE_CONV.dateConvert(createdAt)}</div>
        <div className={classes.transactionMessage}>
          {depos_curr || withdraw_curr || fiat_curr || 'USD'}
        </div>
      </div>
      <div className={classes.transactionStatus}>
        <div className={classes.amount}>
          {CurrencyList.get(depos_curr || fiat_curr || withdraw_curr)?.symbol}
          &nbsp;
          {CURRENCY_CONV.fiatAmtConv(depos_amt || quot_amount || withdraw_amt)}
        </div>
        <div
          className={classes.status}
          style={{
            color:
              transc_status.toUpperCase() === 'APPROVED'
                ? '#5F9816'
                : transc_status.toUpperCase() === 'PENDING'
                ? '#d68b00'
                : 'red',
          }}
        >
          {transc_status.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export default function FiatTransactionList({ fiatTransactions }: any) {
  console.log({
    fiatTransactions,
  });

  return (
    <div className={classes.transactionList}>
      {fiatTransactions.length > 0 ? (
        fiatTransactions?.map((item: any, i: any) => {
          return (
            <div key={i.toString()}>
              <TransactionItem {...item} />
              {fiatTransactions?.length - 1 !== i && (
                <div className={classes.gutters} key={i.toString()}>
                  <div className={classes.dashedLine}></div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className={classes.no_transaction}>
          <h3>No Transactions found!</h3>
        </div>
      )}
    </div>
  );
}
