import { useEffect, useState, memo } from 'react';
import classes from '../styles/ClientDashboard.module.scss';
import CurrencyDescription from 'components/CurrencyDescription';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import class1 from '../styles/BuyForm.module.scss';
import { useModal } from 'context/modal';
import { MainSteps } from 'modals/Stepbar';
import { DebounceInput } from 'react-debounce-input';
import Loader from 'react-loader-spinner';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { CURRENCY_CONV } from '../utils/CurrencyConv';
import CurrencyList from 'currency-list';
import { currencyIcon } from './CurrencyIcon';
import Switch from 'assets/switch.png';
import { useSHdigital } from 'context/SHdigital';
import { useFalconx } from 'context/falconx';

// ? List of all the currencies of the world
const currenciesList = ['USD', 'EUR', 'GBP'];

interface SellCryptoFormProps {
  coin: any;
}
class QuoteCurrency {
  public static QUOTE_CURR: string = 'USD';
  public static quantity: number = 1;
}

function setQuoteCurrency(quoteCurrency: string) {
  QuoteCurrency.QUOTE_CURR = quoteCurrency;
}
function setQuantity(quantity: number) {
  QuoteCurrency.quantity = quantity;
}
// function setQuantity2(quantity: number) {
//   QuoteCurrency.quantity = quantity;
// }

const SellCryptoForm = ({ coin }: SellCryptoFormProps) => {
  // ?States-----------------------------------------
  const [agreed, setAgreed] = useState(false);
  const history = useHistory();
  const { headers } = useUser();
  const [userBalance, setUserBalance] = useState<any>(0);
  const [quantity2, setQuantity2] = useState<any>(1);
  const { openModal } = useModal();
  const [validation, setValidation] = useState(false);
  const [bankData, setBankData] = useState<any>({});
  const [bankDetails, setBankDetails] = useState<any>([]);
  // const [quote, setQuote] = useState<any>('');
  // eslint-disable-next-line
  const [PriceValues, setPriceValues] = useState<{ [key: string]: any }>({});
  const [quoteRequest, setQuoteRequest] = useState<{ [key: string]: any }>({});
  // const [lastSubscription, setLastSubscription] =
  //   useState<ISubscription<any> | null>(null);
  const [counter, setCounter] = useState(0);
  // const [firstUpdate, setFirstUpdate] = useState(false);
  const [Price, setPrice] = useState<number>(0);
  const [unitCryptoPrice, setUnitCryptoPrice] = useState<number>(1);
  const [userUnitCryptoPrice, setUserUnitCryptoPrice] = useState<number>(1);
  const [toggler, setToggler] = useState<string>('sent');
  const [cryptovalue, setCryptovalue] = useState<number>(0);
  const [availableBalance, setAvailableBalance] = useState<any>(0);
  const { changeRequest, quote } = useSHdigital();
  const { changeRequest2, quote2 } = useFalconx();
  const [finalquote, setFinalQuote] = useState<any>();
  // ?state ends here

  // function sortingQuotes() {
  //   if (QuoteCurrency.QUOTE_CURR === 'EUR' || 'GBP') {
  //     // SHDIGITAL+++++++++++++
  //     finalquote = quote;
  //   }
  //   if (QuoteCurrency.QUOTE_CURR === 'USD') {
  //     // falconx+++++++++++++++++++
  //     finalquote = quote2;
  //   }
  // }

  useEffect(() => {
    if (
      QuoteCurrency.QUOTE_CURR === 'EUR' ||
      QuoteCurrency.QUOTE_CURR === 'GBP' ||
      quoteRequest.baseCurrency === 'USDC'
    ) {
      setFinalQuote(quote);
    }
    if (
      QuoteCurrency.QUOTE_CURR === 'USD' &&
      quoteRequest.baseCurrency !== 'USDC'
    ) {
      setFinalQuote(quote2);
    }
    // eslint-disable-next-line
  }, [quote, quote2]);

  // useEffect(() => {
  //   sortingQuotes();
  // }, [QuoteCurrency.QUOTE_CURR]);
  // balance update request++++++++++++++++++
  useEffect(() => {
    const funds = async () => {
      try {
        const body = {
          crypto_currency: coin.symbol,
        };
        const { data } = await axios.post(ROUTES.Crypto_Balance, body, {
          headers,
        });
        if (data.data.crypto_coins == null) {
          setAvailableBalance(CURRENCY_CONV.cryptoAmtConv(0));
          return setUserBalance(0);
        }
        setAvailableBalance(
          CURRENCY_CONV.cryptoAmtConv(data.data.crypto_coins),
        );
        return setUserBalance(data.data.crypto_coins);
      } catch (err) {
        // console.log(err);
      }
    };
    funds();
    // eslint-disable-next-line
  }, []);

  // for checking user balance
  // useEffect(() => {
  //   if (userBalance) {
  //     setTimeout(() => {
  //       if (userBalance < 0.0001) {
  //         toast.warning('Insufficient cryptocurrency balance');
  //         history.push('/dashboard');
  //       }
  //     }, 1500);
  //   }
  //   if (!userBalance) {
  //     return;
  //   }
  //   // eslint-disable-next-line
  // }, [userBalance]);

  const BankAddresData = async () => {
    try {
      const { data } = await axios.get(ROUTES.FETCH_USER_BANK_APPLICATIONS, {
        headers,
      });

      setBankDetails(data.list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    BankAddresData();
    // eslint-disable-next-line
  }, []);

  const UpdateValidation = () => {
    setValidation(true);
    streamQuote();
  };

  const formatIBAN = (data: any) => {
    return (
      Object.keys(data).length &&
      `${data.accountHolderName} - ${
        data.currency
      } - ****${data.iban_number.slice(-4)}`
    );
  };

  // qunantity input handler++++++++++++++++++++++++++++++++++
  const handleChange = (event: any) => {
    const now = new Date().getTime();
    let time = 0;
    if (now - time < 2000) {
      return;
    }
    time = now;
    return setQuantity(event.target.value);
  };

  const handleChange2 = async (event: any) => {
    const now = new Date().getTime();
    let time = 0;
    if (now - time < 2000) {
      return;
    }
    time = now;
    setQuantity2(event.target.value);
    if (Price === 0) {
      return;
    } else {
      setCryptovalue(event.target.value / unitCryptoPrice);
    }
  };

  // finding Bank SWIFT BIC from bank ac+ounts array
  const Swiftcode = bankDetails.find((applications: any) => {
    return applications.iban_number === bankData.iban_number;
  });

  // new updates +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // const baseUrl = process.env.COVARIO_BASE_URL;
  useEffect(() => {
    async function PriceUpdate() {
      try {
        const { data } = await axios.get(ROUTES.Price_Update_API, { headers });
        setPriceValues(data.sigr_payload);
      } catch (err) {
        console.log(err);
      }
    }
    PriceUpdate();
    // eslint-disable-next-line
  }, [QuoteCurrency.quantity]);

  useEffect(() => {
    if (coin) {
      setQuoteRequest({
        baseCurrency: coin.symbol,
        quoteCurrency: 'USD',
        // quantityCurrency: coin.symbol,
        quantity: Number(QuoteCurrency.quantity),
      });
    }
    // eslint-disable-next-line
  }, [PriceValues]);

  const streamQuote = async (updatePrice = false, unitPrice = false) => {
    // await connection.start();
    const value =
      toggler === 'sent' && !unitPrice
        ? QuoteCurrency.quantity
        : unitPrice
        ? 1
        : cryptovalue;
    const quoteRequestStream = {
      ...quoteRequest,
      quoteCurrency: QuoteCurrency.QUOTE_CURR,
      quantity: Number(value),
    };
    // @ts-ignore
    if (
      QuoteCurrency.QUOTE_CURR === 'EUR' ||
      QuoteCurrency.QUOTE_CURR === 'GBP' ||
      quoteRequest.baseCurrency === 'USDC'
    ) {
      // SHDIGITAL+++++++++++++
      const lpQuote = await changeRequest(quoteRequestStream, PriceValues);
      return lpQuote;
    } else if (QuoteCurrency.QUOTE_CURR === 'USD') {
      // falconx+++++++++++++++++++
      const lpQuote = await changeRequest2(quoteRequestStream, PriceValues);
      return lpQuote;
    }
    // const covarioData = await changeRequest(quoteRequestStream, PriceValues);
  };

  useEffect(() => {
    const updateQuote = async () => {
      await streamQuote();
    };
    updateQuote();
    // eslint-disable-next-line
  }, [quoteRequest]);

  // Code startinh Hrere++++++++++++++++++++++++++++++
  if (!coin?.name) {
    history.push('/dashboard');
  }

  // Method to handle the Sell form submision
  const handleFormSubmit = () => {
    if (!agreed) {
      return toast('Please agree to the Terms and Conditions.', {
        type: 'warning',
      });
    } else if (!Object.keys(bankData).length) {
      return toast.warn('Please select your IBAN');
    } else if (QuoteCurrency.quantity < 0) {
      return toast.warning('Please enter a positive value in Sent Amount');
    } else {
      // data passed here to next step with use location hook++++++++++++++++++++++++++
      if (validation) {
        history.push({
          pathname: '/sellcheckout',
          state: {
            // give:
            //   toggler === 'sent'
            //     ? (quote.sellingPriceForUser * quote.quantity).toFixed(2)
            //     : cryptovalue,
            // buyingPriceForUser
            // get: toggler === 'sent' ? QuoteCurrency.quantity : quantity2,
            toggler,
            cryptovalue,
            quantity2,
            PriceValues,
            quoteId: finalquote.quoteId,
            baseCurrency: coin.symbol.toUpperCase(),
            quoteCurrency: QuoteCurrency.QUOTE_CURR,
            quantity: Number(QuoteCurrency.quantity),
            // originalPrice: Number(quote.originalPrice),
            // originalSellPrice: Number(quote.originSellPrice),
            // displayedPrice: Number(
            //   (quote.sellingPriceForUser * quote.quantity).toFixed(2),
            // ),
            // unit_buyPrice_cov: Number(quote.unit_buyPrice_cov),
            // unit_sellPrice_cov: Number(quote.unit_sellPrice_cov),
            maxSlippage: 5,
            // side: 'Sell',
            clientReference: finalquote.clientReference,
            userbankAccNo: bankData.iban_number,
            userSwiftBIC: Swiftcode ? Swiftcode.bankIdentifierCode : null,
            cointype: coin?.symbol,
            // commisions_applied: Number(quote.commisions_applied),
            // margins_applied: Number(quote.margins_applied),
            // transc_fee: Number(quote.transc_fee),
            // fees_charged: Number(quote.fees_charged),
            // revenue: Number(
            //   (quote.originSellPrice * quote.quantity) -
            //   quote.sellingPriceForUser * quote.quantity,
            // ),
          },
        });
      } else {
        openModal(() => <MainSteps validation={UpdateValidation} />);
      }
    }
  };
  // * End of handleFormSubmit()

  const calculateUserUnitCryptoPrice = async (newQuote: any) => {
    setUserUnitCryptoPrice(newQuote.sellingPriceForUser);
  };

  useEffect(() => {
    if (finalquote !== undefined) {
      calculateUserUnitCryptoPrice(finalquote);
      setPrice(
        finalquote?.sellingPriceForUser
          ? finalquote.sellingPriceForUser * finalquote.quantity
          : 0,
      );
      setUnitCryptoPrice(finalquote.sellingPriceForUser);
      setCryptovalue(quantity2 / finalquote.sellingPriceForUser);
    }
    // eslint-disable-next-line
  }, [finalquote]);

  useEffect(() => {
    if (counter > 9 && finalquote !== undefined) {
      calculateUserUnitCryptoPrice(finalquote);
      // setPrice(quote.sellingPriceForUser * quote.quantity || 0);
      streamQuote();
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        return prev > 9 ? 0 : prev + 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
      QuoteCurrency.QUOTE_CURR = 'USD';
      QuoteCurrency.quantity = 1;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.exchange}>
      <div className={classes.exchangeTitle}>
        Sell {coin?.name}
        <button
          onClick={() => history.push('/dashboard')}
          className={classes.exchangeButton}
        >
          Select Other Cryptocurrency
        </button>
      </div>
      {toggler === 'sent' ? (
        <div style={{ paddingTop: '8px' }} className={classes.boxExchange}>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Sent Amount</label>
            </div>
            {/* input1+++++++++++++++++++++++++++++++++  */}
            <div className={class1.outline_div}>
              <DebounceInput
                minLength={1}
                debounceTimeout={500}
                className={class1.main_input}
                type="number"
                value={QuoteCurrency.quantity}
                onChange={(e) => {
                  handleChange(e);
                }}
                min={0}
              />

              <span>{coin?.name}</span>
            </div>
          </div>
          <button
            onClick={() => setToggler('received')}
            className={classes.icon_btn}
          >
            <img src={Switch} alt="switch" height={45} width={45} />
          </button>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Received Amount</label>
            </div>
            {/* input2 +++++++++++++++++++++++++++ */}
            <div className={class1.outline_div}>
              {finalquote === undefined ? (
                <div className={class1.loaderSection}>
                  <Loader
                    type="ThreeDots"
                    color={'#E8CB69'}
                    height={10}
                    width={225}
                  />
                </div>
              ) : (
                <input
                  className={class1.main_input}
                  type="text"
                  value={
                    finalquote && finalquote.sellingPriceForUser
                      ? Price.toFixed(2)
                      : 0
                  }
                  onChange={() => {}}
                />
              )}
              <Menu
                menuButton={
                  <MenuButton className={class1.currencyDropBtn}>
                    <span>{QuoteCurrency.QUOTE_CURR}</span>
                    <img src={Arrowdown} alt="arrow-img" />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                align={'end'}
              >
                {currenciesList.map((currency, idx) => (
                  <MenuItem
                    key={idx}
                    className={`dropdown-item${
                      QuoteCurrency.QUOTE_CURR === currency ? '-selected' : ''
                    }`}
                    onClick={async () => {
                      setQuoteCurrency(currency);
                      await streamQuote(true);
                    }}
                  >
                    {currency}{' '}
                    {currencyIcon(CurrencyList.get(currency)?.symbol)}
                  </MenuItem>
                ))}{' '}
              </Menu>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Fiat edit amount section here+++++++++++++++++++++++++++++++ */}
          <div style={{ paddingTop: '8px' }} className={classes.boxExchange}>
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={class1.mainlabel}>Received Amount</label>
              </div>
              {/* input1+++++++++++++++++++++++++++++++++  */}
              <div className={class1.outline_div}>
                <DebounceInput
                  minLength={1}
                  debounceTimeout={500}
                  className={class1.main_input}
                  type="number"
                  value={quantity2}
                  onChange={async (e) => {
                    await handleChange2(e);
                    streamQuote(true);
                  }}
                  min={0}
                />
                <Menu
                  menuButton={
                    <MenuButton className={class1.currencyDropBtn}>
                      <span>{QuoteCurrency.QUOTE_CURR}</span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  align={'end'}
                >
                  {currenciesList.map((currency, idx) => (
                    <MenuItem
                      key={idx}
                      className={`dropdown-item${
                        QuoteCurrency.QUOTE_CURR === currency ? '-selected' : ''
                      }`}
                      onClick={async () => {
                        setQuoteCurrency(currency);
                        await streamQuote(true);
                      }}
                    >
                      {currency}{' '}
                      {currencyIcon(CurrencyList.get(currency)?.symbol)}
                    </MenuItem>
                  ))}{' '}
                </Menu>
              </div>
            </div>
            <button
              onClick={async () => {
                setToggler('sent');
                setPrice(0);
              }}
              className={classes.icon_btn}
            >
              <img src={Switch} alt="switch" height={45} width={45} />
            </button>
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={class1.mainlabel}>Sent Amount</label>
              </div>
              {/* input2 +++++++++++++++++++++++++++ */}
              <div className={class1.outline_div}>
                {finalquote === '' ? (
                  <div className={class1.loaderSection}>
                    <Loader
                      type="ThreeDots"
                      color={'#E8CB69'}
                      height={10}
                      width={225}
                    />
                  </div>
                ) : (
                  <input
                    className={class1.main_input}
                    type="text"
                    value={
                      finalquote && finalquote.sellingPriceForUser
                        ? cryptovalue.toFixed(5)
                        : 0
                    }
                    onChange={() => {}}
                  />
                )}
                <span>{coin?.name}</span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Balance dIV++++++++++++++++++++++ */}
      <div className={class1.balanceDiv}>
        Available balance :{' '}
        <span
          style={{
            fontWeight: 900,
          }}
        >
          {availableBalance ? availableBalance : 0} {coin?.name}
        </span>
      </div>
      <div className={classes.currencyDescContainer}>
        <CurrencyDescription
          icon={coin?.icon}
          title={coin?.name}
          symbol={coin?.symbol}
          price={
            finalquote && finalquote.sellingPriceForUser
              ? (`
          ${CurrencyList.get(QuoteCurrency.QUOTE_CURR)?.symbol}
          ${userUnitCryptoPrice}` as string)
              : '0'
          }
          message={'Last updated ' + counter + ' seconds ago'}
        />
      </div>
      {/* Bank Selection step++++++++++++++++++++++++++++++ */}
      <div className={classes.address}>
        <div className={classes.bars}>
          <div className={classes.inputs}>
            {/* select input +++++++++++++++++++++++++++ */}
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Receive At</label>
            </div>
            <div>
              <Menu
                menuButton={
                  <MenuButton className={class1.dropBtn}>
                    <span>{formatIBAN(bankData) || 'Select Your IBAN'}</span>
                    <img src={Arrowdown} alt="arrow-img" />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                // onItemClick={(e: any) => setBankData(e.value)}
              >
                {bankDetails &&
                  // eslint-disable-next-line
                  bankDetails.map((options: any) => {
                    if (options?.status === 'approved') {
                      return (
                        <MenuItem
                          value={formatIBAN(options)}
                          className={
                            bankData.iban_number === options.iban_number
                              ? 'dropdown-item-selected'
                              : ''
                          }
                          onClick={() => setBankData(options)}
                        >
                          {formatIBAN(options)}
                        </MenuItem>
                      );
                    }
                  })}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.agree}>
        <input
          checked={agreed}
          onChange={(e) => setAgreed(e.currentTarget.checked)}
          type="checkbox"
          name="agree"
          id="clean"
        />
        <p>
          I have read and accepted the{' '}
          <a href="/termsCondition" target="_blank">
            {' '}
            Terms and Conditions{' '}
          </a>{' '}
          of Encryptus.
        </p>
      </div>
      {/* button divsion+++++++++++++++ */}
      <div className={class1.btn_div}>
        <span className="errorText">
          {QuoteCurrency?.quantity > userBalance || cryptovalue > userBalance
            ? 'Sent Amount exceed user balance!'
            : ' '}
        </span>
        <button
          className={class1.refresh_btn}
          onClick={async () => {
            handleFormSubmit();
          }}
          disabled={
            (QuoteCurrency.quantity > parseFloat(userBalance) &&
              toggler === 'sent') ||
            cryptovalue > parseFloat(userBalance)
              ? true
              : false
          }
          style={{
            pointerEvents:
              (QuoteCurrency.quantity > parseFloat(userBalance) &&
                toggler === 'sent') ||
              cryptovalue > parseFloat(userBalance)
                ? 'none'
                : 'auto',
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default memo(SellCryptoForm);
