import axios from 'axios';
import KycDetails from 'components/KycDetails';
import { useAdmin } from 'context/admin';
import SetFees from 'modals/SetFees';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AdminScreen from '../../components/AdminScreen';
import AdminWallets from '../../components/AdminWallets';
import BankDetails from '../../components/BankDetails';
import NoDp from '../../components/NoDp';
import Tabs from '../../components/Tabs';
import { ROUTES } from '../../config';
import classes from '../../styles/User.module.scss';
import Arrow from 'assets/Navbar svg/Arrow';
import { Link } from 'react-router-dom';
import EditUserBalance from 'modals/EditUserBalance';
import LoginHistory from 'components/LoginHistory';

export default function User() {
  const [user, setUser] = useState<any>({});
  const { id } = useParams() as { id: string };
  const { headers } = useAdmin();

  const getUser = async () => {
    console.log(headers);
    const { data } = await axios.get(ROUTES.USERS, {
      headers,
      params: { filters: { _id: id } },
    });
    setUser(data.list[0]);
    console.log(data.list);
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  console.log(user);

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.details}>
        <Link to="/admin/users">
          <Arrow />
        </Link>
        <NoDp />
        <h1>{user.name}</h1>
      </div>
      <Tabs
        tabs={[
          {
            title: 'Bank Details',
            Component: () => <BankDetails user={user} />,
          },
          {
            title: 'Wallets',
            Component: () => <AdminWallets />,
          },
          {
            title: 'User Details',
            Component: () => <KycDetails user={user} />,
          },
          {
            title: 'Set Fees & Margins',
            Component: () => <SetFees user={user} />,
          },
          {
            title: 'Edit User Balance',
            Component: () => <EditUserBalance user={user} />,
          },
          {
            title: 'Login History',
            Component: () => <LoginHistory user={user} />,
          },
        ]}
      />
    </AdminScreen>
  );
}
