import { useState } from 'react';

export default function useFilters() {
  const [filters, setFilters] = useState<object>({});
  const updateFilters = (key: string, value: string) =>
    setFilters((p: any) => ({
      ...p,
      [key]: value === p[key] ? undefined : value,
    }));

  return { filters, updateFilters };
}
