import React, { useState } from 'react';
import classes from 'styles/ModalStep2.module.scss';
import OtpInput from 'react-otp-input';
import { useUser } from 'context/user';
import { modalcss } from '../ExtraDiv';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useModal } from 'context/modal';

interface Qrcode {
  qrcode: string;
}

function ModalStep2({ qrcode }: Qrcode) {
  const [gacode, setGacode] = useState('');
  const { headers, user } = useUser();
  const history = useHistory();
  const { closeModal } = useModal();
  // handle submit 2 ++++++++++++++++++++++++++++++++++++++
  const handleSubmit2 = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // // opt try catch+++++++++++++++++++++
    try {
      // make api call to enable 3fa for user
      const { data } = await axios.post(
        ROUTES.ENABLE_3FA,
        {
          token: gacode,
        },
        {
          headers,
        },
      );
      closeModal();

      toast.success(data.message);

      if (user?.associated_banks.length === 0) {
        history.push('/bank_accounts');
      } else {
        history.push('/dashboard');
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
      return closeModal();
    }
  };
  return (
    <div className={classes.ParentContainer}>
      <h1>Step 2 : Setup 3FA</h1>
      <div className={classes.Qrcode}>
        <img src={qrcode} alt="barcode" width={200} height={200} />
        <div className={classes.forms}>
          <form onSubmit={handleSubmit2}>
            <label htmlFor="otp">Enter Google Authenticator OTP</label>
            <div className={classes.grid}>
              <div>
                <OtpInput
                  value={gacode}
                  onChange={setGacode}
                  numInputs={6}
                  inputStyle={modalcss}
                  disabledStyle={{ opacity: '0.3' }}
                  separator={<span>-</span>}
                  isInputNum={true}
                  isInputSecure={false}
                />
              </div>
              <div>
                <Link className={classes.reset} to="/">
                  Need Help?
                </Link>
              </div>
            </div>
            <p>
              In-case you have forgot/lost access to these,{' '}
              <Link to="/" className={classes.smtext}>
                please contract encryptus support.
              </Link>
            </p>
            <div className={classes.btndiv}>
              <button className={classes.verifybtn}>
                Verify Code and Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalStep2;
