import React from 'react';
import classes from '../styles/BankDetails.module.scss';
import { useModal } from '../context/modal';

const KYCDetail: React.FC<{
  data?: any;
  approve: any;
  reject: any;
  index: number;
}> = ({ data, approve, reject, index }) => {
  const { closeModal } = useModal();
  return (
    <div className={classes.container}>
      <h1>KYC Detail</h1>
      <div className={classes.bank}>
        <div className={classes.section}>
          <div>
            <h6>Name</h6>
            <p>{data?.user?.name}</p>
          </div>
          <div>
            <h6>Email</h6>
            <p>{data?.user?.email}</p>
          </div>
        </div>
        <div className={classes.section}>
          <div>
            <h6>Applied on</h6>
            <p>{new Date(data?.createdAt).toDateString()}</p>
          </div>
          <div>
            <h6>Status</h6>
            <p>{data?.status}</p>
          </div>
        </div>
        <div className={classes.section}>
          <div>
            <h6>Contact</h6>
            <p>{data?.user?.phone_no}</p>
          </div>
          <div>
            <h6>Role</h6>
            <p>{data?.user?.role}</p>
          </div>
        </div>
        <div className={classes.section}>
          <div>
            <h6>Transaction Fee</h6>
            <p>{data?.user?.transc_fee}</p>
          </div>
          <div>
            <h6>Application ID</h6>
            <p>{data?._id}</p>
          </div>
        </div>
        <div className={classes.section}>
          <h6>KYC Documents</h6>
          <p>
            {data?.kycDocuments.length > 0 ? (
              <ol>
                {data?.kycDocuments.map((doc: any, i: number) => (
                  <li key={i.toString()}>{doc}</li>
                ))}
              </ol>
            ) : (
              'documents not Uploaded'
            )}
          </p>
        </div>
      </div>
      <div className={classes.btnGrp}>
        <button
          className={classes.approve}
          onClick={() => {
            closeModal();
            approve(data?._id, index);
          }}
        >
          Approve
        </button>
        <button
          className={classes.reject}
          onClick={() => {
            closeModal();
            reject(data?._id, index);
          }}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default KYCDetail;
