import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classes from '../styles/LatestTransactions.module.scss';
import Card from './Card';
import CryptoTransactionList from './common/CryptoTransactionList';
import FiatTransactionList from './common/FiatTransactionList';
import Loader from 'react-loader-spinner';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { btcYellow, usdIcon, walletYellow } from 'utils/DropdownAssets';
import AllTransactionList from './common/AllTransactionList';

export default function LatestTransactions() {
  const { headers } = useUser();

  // ? STATE
  // eslint-disable-next-line
  const [toggleTo, setToggleTo] = useState('Crypto'); // * Fiat || Crypto
  // eslint-disable-next-line
  const [alltransaction, setAlltransaction] = useState([]);
  const [fiatTransactions, setFiatTransactions] = useState([]);
  const [cryptoBuyTransactions, setCryptoBuyTransactions] = useState([]);
  const [cryptoDepositTransactions, setCryptoDepositTransactions] = useState(
    [],
  );
  const [cryptoSellTransactions, setCryptoSellTransactions] = useState([]);
  const [cryptoWithdrawTransactions, setCryptoWithdrawTransactions] = useState(
    [],
  );
  // eslint-disable-next-line
  const [FiatWithdrawTransactions, setFiatWithdrawTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const [selection, setSelection] = useState('All'); // ? Fiat || Buy || Deposit

  // ? Method to fetch the Latest Transactions from API server
  const fetchLatestTransactions = async () => {
    try {
      const { data } = await axios.get(ROUTES.USER_TRANSACTION, { headers });

      setFiatTransactions(data?.fiat_deposit_transc_list?.list.slice(0, 5));
      setCryptoBuyTransactions(data?.crypto_buy_transc_list?.list.slice(0, 5));
      setCryptoDepositTransactions(
        data?.crypto_deposit_transc_list?.list.slice(0, 5),
      );
      setCryptoSellTransactions(data?.crypto_sell_transc_list?.list);
      setCryptoWithdrawTransactions(data?.crypto_withdraw_transc_list?.list);
      setFiatWithdrawTransactions(data?.fiat_withdraw_transc_list?.list);
      setAlltransaction(data?.all_transactions_list);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  // * End of fetchLatestTransactions()

  // ? Method to conditionally render the Title of the dropdown
  const renderDropdownTitle = () => {
    switch (selection) {
      case 'All':
        return 'All';

      case 'Fiat':
        return 'Fiat Deposit';

      case 'Fiat_Withdraw':
        return 'Fiat Withdraw';

      case 'Buy':
        return 'Crypto Buy';

      case 'Deposit':
        return 'Crypto Deposit';

      case 'Sell':
        return 'Crypto Sell';

      case 'Withdraw':
        return 'Crypto Withdraw';
    }
  };
  // * End of renderDropdownTitle();

  // ? USE EFFECT HOOK
  useEffect(() => {
    fetchLatestTransactions();
    // eslint-disable-next-line
  }, [headers]);

  const renderTransactionsList = () => {
    // ? Method to conditionally pass the crypto data according to the selection
    const cryptoListData = () => {
      switch (selection) {
        case 'Buy':
          return cryptoBuyTransactions;

        case 'Deposit':
          return cryptoDepositTransactions;

        case 'Sell':
          return cryptoSellTransactions;

        case 'Withdraw':
          return cryptoWithdrawTransactions;
      }
    };
    // * End of cryptoListData();

    if (loading) {
      return (
        <div className="loaderSection">
          <Loader type="ThreeDots" color={'#E8CB69'} height={15} />
        </div>
      );
    } else {
      if (selection === 'All') {
        return (
          <>
            <AllTransactionList
              selection={selection}
              transactions={alltransaction.slice(0, 5)}
            />
          </>
        );
      }
      if (selection === 'Fiat') {
        return (
          <FiatTransactionList
            fiatTransactions={fiatTransactions.slice(0, 5)}
          />
        );
      } else if (selection === 'Fiat_Withdraw')
        return (
          <FiatTransactionList
            fiatTransactions={FiatWithdrawTransactions.slice(0, 5)}
          />
        );
      else {
        return (
          <CryptoTransactionList
            cryptoTransactions={cryptoListData()}
            selection={selection}
          />
        );
      }
    }
  };

  return (
    <Card className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>Latest Transactions</h3>
        <div className={classes.selectionDiv}>
          <Menu
            menuButton={
              <MenuButton className={classes.dropBtn}>
                <span>{renderDropdownTitle()}</span>
                <img src={Arrowdown} alt="arrow-img" />
              </MenuButton>
            }
            transition
            menuClassName="drop-down"
            onItemClick={(e) => setSelection(() => e.value)}
          >
            <MenuItem
              value="Fiat"
              className={selection === 'Fiat' ? 'dropdown-item-selected' : ''}
            >
              Fiat Deposit {usdIcon(25, 25)}
            </MenuItem>
            <MenuItem
              value="Fiat_Withdraw"
              className={
                selection === 'Fiat_Withdraw' ? 'dropdown-item-selected' : ''
              }
            >
              Fiat Withdraw {usdIcon(25, 25)}
            </MenuItem>
            <MenuItem
              value="Buy"
              className={selection === 'Buy' ? 'dropdown-item-selected' : ''}
            >
              Crypto Buy {btcYellow(25, 25)}
            </MenuItem>
            <MenuItem
              value="Deposit"
              className={
                selection === 'Deposit' ? 'dropdown-item-selected' : ''
              }
            >
              Crypto Deposit {walletYellow(25, 25)}
            </MenuItem>
            <MenuItem
              value="Sell"
              className={selection === 'Sell' ? 'dropdown-item-selected' : ''}
            >
              Crypto Sell {walletYellow(25, 25)}
            </MenuItem>
            <MenuItem
              value="Withdraw"
              className={
                selection === 'Withdraw' ? 'dropdown-item-selected' : ''
              }
            >
              Crypto Withdraw {walletYellow(25, 25)}
            </MenuItem>
            <MenuItem
              value="All"
              className={selection === 'All' ? 'dropdown-item-selected' : ''}
            >
              All Transactions
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className={classes.body}>
        {/* <TransactionList 
          toggleTo={ toggleTo }
        /> */}
        {renderTransactionsList()}
      </div>
      <div className={classes.btndiv}>
        <button onClick={() => history.push('/usertransactions')}>
          See All Transactions
        </button>
      </div>
    </Card>
  );
}
