import { useEffect } from 'react';
import classes from 'styles/Depositemain.module.scss';
import Banklogo from 'assets/Group 75.svg';
import Bitcoinlogo from 'assets/bank logo 2.svg';
import Nextbtn from 'assets/nextarrow.svg';
import { NavLink } from 'react-router-dom';
import { useModal } from 'context/modal';
import SelectCurrency from './SelectCurrency';
import { useUser } from 'context/user';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import WithdrawCrypto from './WithdrawCrypto';
import axios from 'axios';
import { ROUTES } from 'config';

const data = [
  {
    imgurl: Banklogo,
    heading: 'Bank Transfer',
    para: '1-2 Business Days',
    link: SelectCurrency,
  },
  { imgurl: Bitcoinlogo, heading: 'Cryptocurrency', para: '', link: '/' },
];

function Withdrawmain() {
  const { user, headers } = useUser();
  const { openModal, closeModal } = useModal();
  const history = useHistory();

  // ? Method to open the corresponding modal based on the user choice
  const handleMethodSelection = (option: string) => {
    if (user?.enabled_3fa === false) {
      closeModal();

      history.push('settings_user');

      toast.warn('Please enable MFA to proceed with Withdraw!');
    } else {
      if (option === 'Bank Transfer') {
        openModal(() => <SelectCurrency isWithdraw />);
      } else {
        openModal(WithdrawCrypto);
      }
    }
  };

  // ? Method to fetch all of the user's bank applications
  const fetchUserBankApplications = async () => {
    try {
      const { data } = await axios.get(ROUTES.FETCH_USER_BANK_APPLICATIONS, {
        headers,
      });

      console.log({
        banksData: data,
      });

      if (data && data?.count <= 0) {
        toast.warn('Cannot Withdraw | No Bank Account found for this account');

        closeModal();
      }
    } catch (err) {
      console.log(err);

      toast.warn(
        'Unable to process your withdraw request. Please try again later!',
      );

      closeModal();
    }
  };
  // * End of fetchUserBankApplications();

  useEffect(() => {
    fetchUserBankApplications();

    // eslint-disable-next-line
  });

  return (
    <>
      <div className={classes.container}>
        <h2>Withdraw</h2>
        <div className={classes.innerContainer}>
          {data.map((data) => {
            return (
              <>
                <div
                  className={classes.options}
                  onClick={() => handleMethodSelection(data.heading)}
                >
                  <img src={data.imgurl} alt="logo" />
                  <h4>{data.heading}</h4>
                  <p>{data.para}</p>
                  <NavLink to="/">
                    <span>
                      <img src={Nextbtn} alt="arrowbtns" />
                    </span>
                  </NavLink>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Withdrawmain;
