import React, { useState } from 'react';
import classes from 'styles/Depositefiat.module.scss';
import Danger from 'assets/danger.png';
import axios from 'axios';
import { ROUTES } from '../config';
import { useUser } from 'context/user';
import { toast } from 'react-toastify';
import { useModal } from 'context/modal';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

// eslint-disable-next-line
interface Head {
  currency: string;
  symbol: string;
  depositAmount: number;
  uuid: string;
}

const bankDetails: any = {
  USD: {
    accountNumber: '202378829620',
    swift: 'CHFGUS44021',
    bankName: 'Choice Financial Group',
    bankAddress: '4501 23rd Avenue S Fargo, ND 58104 USA.',
    ABARoutingNumber: '091311229',
    Recipient: 'Encryptus DMCC',
    RecipientAddress:
      'Jumeirah Street, F34, DMCC Business Centre, Level No 13, AG Tower, Dubai, ARE',
  },
  EUR: {
    iban: 'GB07CLJU04130729904977',
    sortCode: '04 13 07',
    accountNumber: '2990 4977',
    swift: 'CLJUGB21',
    bankName: 'Clear Junction Limited',
    bankAddress:
      '4th Floor Imperial House, 15 Kingsway, London, United Kingdom, WC2B 6UN',
    Recipient: 'Encryptus Europe UAB',
    RecipientAddress: 'Eisiskis Sodu 18-oji g. 11, Vilnius, Lithuania',
  },
  GBP: {
    iban: 'GB07CLJU04130729904977',
    sortCode: '04 13 07',
    accountNumber: '2990 4977',
    swift: 'CLJUGB21',
    bankName: 'Clear Junction Limited',
    bankAddress:
      '4th Floor Imperial House, 15 Kingsway, London, United Kingdom, WC2B 6UN',
    Recipient: 'Encryptus Europe UAB',
    RecipientAddress: 'Eisiskis Sodu 18-oji g. 11, Vilnius, Lithuania',
  },
};

function Depositefiat({ currency, symbol, depositAmount, uuid }: Head) {
  console.log({ currency, symbol, depositAmount, uuid });

  const [referenceCheckbox, setReferenceCheckbox] = useState<boolean>(false);
  const [referenceCheckbox2, setReferenceCheckbox2] = useState<boolean>(false);

  const { user, headers } = useUser();
  const { closeModal } = useModal();
  const data = {
    tagger_email: user.email,
    depos_amt: depositAmount,
    unq_ref_id: uuid,
    depos_curr: symbol,
  };
  const handleSubmit = async () => {
    if (!referenceCheckbox) {
      toast.warn('Please check the checkbox to proceed with the deposit!');

      return;
    }

    try {
      const result = await axios.post(ROUTES.Deposite_request, data, {
        headers,
      });
      console.log(result);

      toast.success(
        `${result?.data?.message} | Your deposit will be approved within 48 hours`,
      );
      closeModal();
      setTimeout(() => {
        return window.location.reload();
      }, 5000);
    } catch (err: any) {
      console.log(err);
      toast.warning(err?.response?.data?.message);
    }
  };

  const renderCopyBtn = (data: any) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="20px"
        height="20px"
        viewBox="0 0 24.000000 24.000000"
        preserveAspectRatio="xMidYMid meet"
        className={classes.copyBtn}
        onClick={() => {
          navigator.clipboard.writeText(data);
          toast.success('Copied To Clipboard');
        }}
      >
        <g
          transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path d="M20 140 c0 -47 4 -80 10 -80 6 0 10 30 10 70 l0 70 70 0 c40 0 70 4 70 10 0 6 -33 10 -80 10 l-80 0 0 -80z" />
          <path d="M60 100 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z m140 0 l0 -60 -60 0 -60 0 0 60 0 60 60 0 60 0 0 -60z" />
        </g>
      </svg>
    );
  };

  return (
    <div className={classes.Container}>
      <h2>Deposit {symbol}</h2>
      <div className={classes.division}>
        <div className={classes.innerdiv}>
          <h3>No Third Party Payments</h3>
          <img src={Danger} alt="dangericon" />
        </div>
        <p>Third Party Payments are not supported.</p>
      </div>
      <section className={classes.Payinfo}>
        <h4>Deposit To This Account:</h4>
        <table>
          <tbody>
            <tr>
              <td>Recipient</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.Recipient}
                {renderCopyBtn(bankDetails[symbol]?.Recipient)}
              </td>
            </tr>
            <tr>
              <td>Recipient Address</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.RecipientAddress}
                {renderCopyBtn(bankDetails[symbol]?.RecipientAddress)}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Account Number</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.accountNumber}
                {renderCopyBtn(bankDetails[symbol]?.accountNumber)}
              </td>
            </tr>
            {bankDetails[symbol]?.iban ? (
              <tr>
                <td>Beneficiary IBAN</td>
                <td className={classes.values}>
                  {bankDetails[symbol]?.iban}
                  {renderCopyBtn(bankDetails[symbol]?.iban)}
                </td>
              </tr>
            ) : null}
            {bankDetails[symbol]?.sortCode ? (
              <tr>
                <td>Sort Code</td>
                <td className={classes.values}>
                  {bankDetails[symbol]?.sortCode}
                  {renderCopyBtn(bankDetails[symbol]?.sortCode)}
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Swift</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.swift}
                {renderCopyBtn(bankDetails[symbol]?.swift)}
              </td>
            </tr>
            <tr>
              <td>Currency</td>
              <td className={classes.values}>
                {symbol}
                {renderCopyBtn(currency)}
              </td>
            </tr>
            <tr>
              <td>Amount</td>
              <td className={classes.values}>
                {CURRENCY_CONV.fiatAmtConv(depositAmount)}
                {renderCopyBtn(CURRENCY_CONV.fiatAmtConv(depositAmount))}
              </td>
            </tr>
            <tr>
              <td>Payment Reference ID</td>
              <td className={classes.values}>
                {uuid}
                {renderCopyBtn(uuid)}
              </td>
            </tr>
            <tr>
              <td>Bank Name</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.bankName}
                {renderCopyBtn(bankDetails[symbol]?.bankName)}
              </td>
            </tr>
            <tr>
              <td>Bank Address</td>
              <td className={classes.values}>
                {bankDetails[symbol]?.bankAddress}
                {renderCopyBtn(bankDetails[symbol]?.bankAddress)}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <label className={classes.checkboxSection}>
        <input
          type="checkbox"
          required
          checked={referenceCheckbox}
          onChange={() => setReferenceCheckbox(() => !referenceCheckbox)}
        />
        <span>
          Please add the above payment reference number with your bank wire
          reference / additional information
        </span>
      </label>
      <label className={classes.checkboxSection}>
        <input
          type="checkbox"
          required
          checked={referenceCheckbox2}
          onChange={() => setReferenceCheckbox2(() => !referenceCheckbox2)}
        />
        <span>
          I confirm that this is not a third party bank account and as a user I
          have full control over this bank account.
        </span>
      </label>

      <div className={classes.btndiv}>
        <button onClick={handleSubmit}>I Made A Deposit</button>
      </div>
    </div>
  );
}

export default Depositefiat;
