import axios from 'axios';
import { ROUTES } from 'config';
import { useAdmin } from 'context/admin';
import { useModal } from 'context/modal';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import classes from '../styles/Taezor.module.scss';

function WhitelistSelection() {
  const [value, setValue] = useState('');
  const { headers } = useAdmin();
  const { closeModal } = useModal();
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  const handleSubmit = async () => {
    try {
      const body = {
        walletAddress: value,
      };
      const { data } = await axios.patch(ROUTES.WhiteList, body, {
        headers,
      });
      if (data) {
        toast.success('Success !');
        closeModal();
      }
    } catch (err: any) {
      toast.warning(err.response.data.message);
    }
  };
  return (
    <div className={classes.Container}>
      <h1>WhiteList Wallet</h1>
      <div className={classes.inputSection}>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <input
            type="text"
            placeholder="Please Enter Wallet Address Here"
            value={value}
            onChange={handleChange}
            min="20"
            className={classes.input}
          />
          <button>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default WhitelistSelection;
