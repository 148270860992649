import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/UserAgreement.scss';
import React, { useEffect } from 'react';
import UTable from '../components/table/UaggrementTable';

export const UserAgreementPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <div className="main_containter">
        <div className="container">
          <div className="inner_div">
            <h1>
              User <span style={{ color: '#fff' }}> Agreement </span>
            </h1>
          </div>
        </div>
        <div className="container_first">
          
          <p>This Client Agreement (“Agreement”) is made by and between ENCRYPTUS DMCC, a company incorporated under the laws
            of United Arab Emirates, having its office at DMCC Business Centre, Unit F34, AG Tower, Level 13, JLT (referred
            to as the “Company” or “Encryptus”) and you, our Client, with your details and address being as submitted to us
            by you in the EDD/onboarding form.</p> <br/>

          <p>Throughout this Agreement, the terms “we”, “us”, and “our” refer to the Company, together with its employees,
            consultants, directors, successors, subsidiaries, affiliates, payer correspondents, and assignees. The terms
            “you” and “your” and “Client” refer to the Client to whom the Services shall be rendered.</p> <br/>

          <p>The parties are individually referred to as “Party”, and collectively, “Parties”.</p> <br/>

          <p>BY CLICKING “I AGREE”, YOU AGREE THAT (A) YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT AND THAT YOU HAVE
            READ AND UNDERSTAND FULLY THE TERMS AND CONDITIONS OF THIS AGREEMENT; AND (B) YOU SHALL BE BOUND BY THIS AGREEMENT
            AND TO COMPLY WITH THE LAWS, REGULATIONS, AND RULES OF ANY APPLICABLE GOVERNMENTAL OR REGULATORY AUTHORITY.</p> <br/>

          <p>BY CLICKING ON “I AGREE”, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, AS MAY BE AMENDED
            FROM TIME TO TIME. PLEASE READ THIS AGREEMENT CAREFULLY AND MAKE SURE YOU UNDERSTAND IT FULLY BEFORE USING [THE
            PLATFORM AND OUR SERVICE].</p> <br/>

          <p>BY CLICKING ON “I AGREE”, YOU ACKNOWLEDGE THAT YOU HAVE READ AND YOU UNDERSTAND AND ACKNOWLEDGE THE TERMS OF OUR
            PRIVACY POLICY.</p> <br/>

          <p>BY CLICKING ON “I AGREE”, YOU ACKNOWLEDGE THAT VIRTUAL ASSETS ARE PRONE TO CERTAIN RISKS. THE VALUE OF VIRTUAL
            ASSETS MAY INCREASE OR DECREASE, CAUSING SUBSTANTIAL RISK THAT YOU MAY LOSE MONEY WHILE BUYING, SELLING, HOLDING,
            OR INVESTING IN VIRTUAL ASSETS. YOU ACKNOWLEDGE THAT YOU HAVE READ OUR RISK DISCLOSURE STATEMENT BEFORE ENTERING
            INTO THIS AGREEMENT.</p> <br/>

          <p>BY CLICKING ON “I AGREE” BELOW, YOU AGREE AND ACKNOWLEDGE THAT THIS AGREEMENT AND THE FOLLOWING DOCUMENTS (AS MAY
            BE AMENDED FROM TIME TO TIME AND PUBLISHED ON OUR PLATFORM), ARE INCORPORATED BY REFERENCE INTO THIS AGREEMENT
            AND FORM PART OF YOUR CONTRACTUAL RELATIONSHIP WITH US:</p> <br/>

          <ol>
            <li>PRIVACY POLICY;</li>
            <li>RISK DISCLOSURE STATEMENT;</li>
            <li>PUBLIC DISCLOSURES.</li>
          </ol> <br/>

          <p>BY REQUESTING FOR OUR SERVICES, YOU ARE CONSENTING TO THE TERMS AND CONDITIONS OF ALL THE ABOVE MENTIONED DOCUMENTS
            WHICH FORM PART OF THIS AGREEMENT.</p> <br/>
        </div>
        <img src={UserAgreementTwo} alt="FooterImage" />
        <div className="container_second">
          <ol className="ordered_list">
            <h1>1.2. INTERPRETATION</h1>

            <p>1.1.1. In this Agreement, the following terms and phrases have the meaning assigned herein below:</p>

            <UTable/>

            <p>1.2.1. The Client agrees and acknowledge that this Agreement and the following documents (as may be amended from time to time and published on the Platform), are incorporated by reference to this Agreement and form part of the Client’s contractual relationship with Encryptus:</p>

            <p>(a) Platform’s Terms of Use;<br />
              (b) Privacy Policy;<br />
              (c) Risk Disclosure Statement;<br />
              (d) Public Disclosures;</p>

            <p>By applying for the Services, the Client has consented to the terms and conditions of all the above-mentioned documents which form part of this Agreement. In the event that the Client is accepted by Encryptus as a customer, the Parties shall be bound by these terms and conditions.</p>

            <p>1.2.2. This Agreement includes all amendments, additions, and variations thereto agreed between the Parties.<br />
              1.2.3. Any reference to:</p>

            <p>1.2.3.1. “clauses” and “schedules” are to the clauses of, and the schedules to, this Agreement (unless the context otherwise requires);<br />
              1.2.3.2. “person” shall include an individual, corporation, company, partnership, firm, trustee, trust, executor, administrator or other legal personal representative, unincorporated association, joint venture, syndicate or other business enterprise, any governmental, administrative or regulatory authority or agency (notwithstanding that "person" may be sometimes used herein in conjunction with some of such words), and their respective successors, legal personal representatives and assigns, as the case may be, and pronouns shall have a similarly extended meaning; and<br />
              1.2.3.3. "written" and "in writing" include any means of visible reproduction (including, for the avoidance of doubt, by way of electronic mail.</p>

            <p>1.2.4. Unless the context otherwise requires, words importing the singular shall include the plural and vice versa and words importing a specific gender shall include the other genders (male, female or neuter).<br />
              1.2.5. The Schedules form part of this Agreement and have the same force and effect as if expressly set out in the body of this Agreement.<br />
              1.2.6. All transactions for the sale and purchase of Virtual Assets are entered into by the Parties in reliance on the fact that the Accepted Order and this Agreement together form part of a single agreement between the Parties.<br />
              1.2.7. The word “includes” shall be construed as “without limitation”.<br />
              1.2.8. This Agreement is effective from the date mentioned in the introductory paragraphs of this Agreement.<br />
              1.2.9. Reference to any statute, ordinance or other law includes all regulations and other instruments and all consolidations, amendments, re-enactments or replacements for the time being in force.<br />
              1.2.10. All headings, bold typing and italics (if any) have been inserted for convenience of reference only and do not define limit or affect the meaning or interpretation of the terms of this Agreement.</p> <br /> <br />

          

            <h2>3. SERVICES</h2>

            <p>3.1 For the purpose of this Agreement, Encryptus shall exclusively offer Services pertaining to the following Virtual Assets: Bitcoin, Ethereum, USDC and USDT.</p>

            <p>3.2 The Company proposes to provide the following services to its clients:</p>

            <p>(a) arranging orders for the purchase and sale of Virtual Assets between two entities or persons;<br />
              (b) soliciting or accepting orders for Virtual Assets and accepting fiat currency, or other Virtual Assets;<br />
              (c) facilitating the matching of transactions in Virtual Assets between buyers and sellers.</p>

            <p>(collectively referred to as the "Services”).</p>

            <p>3.3 The Company provides execution-only services. To elaborate further, neither the Company, nor any of its representatives, will:</p>

            <p>(a) provide the Client with investment advice or recommendation;<br />
              (b) offer any opinion regarding the suitability of any Virtual Assets, financial products, order, transaction, or investment strategy;<br />
              (c) monitor Client account or investments or the appropriateness thereof;<br />
              (d) alert Client to any recommended change to Client’s investments, accounts, or services; or<br />
              (e) provide legal, tax, or accounting advise.</p>

            <p>3.4 Nothing on the Platform is or shall be deemed a recommendation or solicitation:</p>

            <p>(a) To buy, sell or store Virtual Assets; or<br />
              (b) the manner in which those products are bought, procured, sold or stored.</p>

            <p>3.5 The Client will not seek, accept or rely on any advice from the Company or its Representatives, nor will any communication between Company (or its Representatives) and the Client (or its representatives) be construed as such.</p>

            <p>3.6 The Company reserves the right to add, modify, amend or otherwise change a service, in whole or in part, and if it does so, Encryptus will provide the Client adequate notice of such change.</p>

            <p>3.7 Through the Platform we will facilitate transactions on Virtual Assets, listed on the Platform at www.encryptus.io . If, due to any factors including a “fork”, or other changes, any previously supported virtual asset is no longer supported, Encryptus will assess the impact of such change as soon as possible and notify the Client of all measures adopted by us to reduce any losses.</p>

            <p>3.8 Throughout the provision of the Services, Client Virtual Assets will remain securely stored and in control of Encryptus in the Client Asset Wallet provided by our trusted custody service provider, ‘Fireblocks’. Encryptus will always maintain control of the private keys for the Client Asset Wallet. The Client Virtual Assets will leave the control of the Encryptus during the following scenarios:</p>

            <p>(a) Buy Order – When the Relevant Virtual Assets will be transferred back to the whitelisted Client Wallet in the event of Buy Order; and<br />
              (b) Sell Order – When the Relevant Virtual Assets will be transferred to the execution partner for the completion of the Services in the event of Sell Order.</p> <br /> <br />

            <h2>4. ACCOUNT CREATION</h2>

            <p>4.1 In order to receive Services from the Company, the Client is required to register an Account. For the purposes of opening an Account, Encryptus will require that the Client to complete Encryptus’ [Account Opening Application Form] and submit such supporting documentation, as may be deemed necessary.</p> <br /> <br />

            <p>4.2 The Client acknowledges that the Company shall accept the Client as its customer only after the Client satisfactorily clears all the verifications required by the Company, including any required under the AML/CFT Laws. In addition to the initial verifications undertaken by the Company, the Client may be subject to periodic due diligence and verification by the Company to ensure its ongoing compliance with any AML/CFT Laws. The Client shall immediately inform the Company if there are any changes in the information or documentation provided by the Client to the Company.</p> <br /><br />

            <p>4.3 The Company may, at any time, refuse, restrict or limit the service made available to the Client or decide to terminate this Agreement and close an Account, in the event the Client is found to have provided incorrect, incomplete, inaccurate, or false information to the Company. Furthermore, if, at any time, the Client refuses to provide such required information, the Company reserves the right to:</p>

            <p>(i) refuse opening an Account;<br />
              (ii) close an existing Account; or<br />
              (iii) otherwise limit or restrict the access to the Account.</p> <br /><br />

            <p>4.4 UPON COMPLETION OF THE ONBOARDING, COMPANY WILL REGISTER THE ACCOUNT FOR YOU. THIS AGREEMENT SHALL BE EFFECTIVE FROM THE DATE ON WHICH THE COMPANY HAS COMPLETED ONBOARDING YOU AS A CLIENT AND YOUR ACCOUNT IS REGISTERED.</p>
            <p>4.5 The Client represents and warrants that:<br />
              (a) the Company has not previously been suspended or terminated, the Client’s account on the Platform or otherwise terminated the Client’s account for any default by the Client;<br />
              (b) the Client has had the opportunity to seek legal, accounting, taxation, or other professional advice regarding investing in Virtual Assets;<br />
              (c) neither the Client, nor its beneficial owners, directors or key personnel are located in, or a resident of any state, country, territory, or other jurisdiction where use of Services may be restricted or prohibited under law;<br />
              (d) neither the Client, nor its beneficial owners, directors or key personnel have been named on any sanctions list or are otherwise subject to any sanctions;<br />
              (e) neither the Client, nor any of its beneficial owners or officers have been convicted of an offence involving any crime or financial impropriety; and<br />
              (f) has the full power and authority to enter into this Agreement and in doing so will not violate any other agreement to which the Client is a party.</p> <br /><br />

            <p>4.6 The Client warrants that the Client shall:<br />
              (a) not use the Platform or the Services for any purpose other than for which they are intended, or<br />
              (b) not use the Platform or the Services in breach of any Applicable Laws and regulations or in violation of this Agreement; and<br />
              (c) ensure that it remains compliant with the Company’s eligibility criteria, as notified from time to time.</p>

            <p>4.7 When using the Account, the Client must:<br /> <br />
              (a) take all reasonable steps to keep Account log-in details safe at all times and never disclose the password and log-in credentials to anyone;<br />
              (b) inform the Company, if the Client has had any indication or suspicion that the log-in details, password or other security features relating to Account are lost, stolen, misappropriated, used without authorization or otherwise compromised and wherever possible immediately change the password of the Account; and<br />
              (c) change the password, on a regular basis and particularly when the Client suspects that a third party has become aware of the Client’s log-in details.</p> <br />

            <p> 4.8 Access to the Platform is provided on “as-is” basis. The Company make no warranties, express or implied representations or guarantees as to the merchantability and/or fitness for any particular purpose or otherwise with respect to the Platform, their content, any documentation. The Client may face technical difficulties including failures, delays, or malfunction, which may cause Orders not to be transmitted, received or executed as a result of such disruption, failure or malfunction, software erosion or hardware damage, which could be the result of hardware, software or communication link inadequacies or other causes. The Company shall not be liable to the Client for any losses arising from any such Orders not being transmitted, received or executed.</p> <br />
            <p>4.9 Except where the loss is incurred due to the default of the Company, neither the Company nor any of its representatives shall be liable for any loss that might occur as a result of or arising out of using, accessing, installing, maintaining, modifying, de-activating, or attempting to access either the Platform
            </p> <br />

            <h2>5. CLIENT CLASSIFICATION</h2>

            <p>5.1 During the client onboarding process, the Company will undertake to classify each of its clients as a ‘Retail Investor’, ‘Qualified Investor’ or ‘Institutional Investor’, in accordance with VARA rulebooks. Under the Company’s VARA license, the Company is authorised to provide Services to [Retail Investor, Qualified Investor and Institutional Investor].</p>

            <p>5.2 Based on the particulars and information supplied to the Company by the Client, the Client has been classified as either an Institutional Investor, a Qualified Investor or a Retail Investor.</p>

            <p>5.3 The Client agrees that:</p>

            <p>(a) the Client shall promptly notify the Company of any changes that may affect the Client’s classification as provided under Clause 5.2 herein above.<br />
              (b) the Company may, at its option, seek any information from the Client to reconfirm that the Client’s classification has not changed.</p>

            <p>5.4 The Client acknowledges and accepts that the Company is authorized to contact any source of information, or any person or entity nominated by the Client as a reference in order to verify the accuracy and correctness of the particulars and information provided by the Client to the Company.</p>

            <h2>6. PLACING AN ORDER, EXECUTION AND SETTLEMENT</h2>

            <p>6.1 Once the Client has been sufficiently verified by the Company, and an Account is registered, the Client after whitelisting the Client Wallet and Client Bank Account with the Company can avail the Services and place Orders. An Order may be placed in any of the following manners: (i) ‘Market Order’ ; or (ii) a ‘Limit Order’.</p>

            <p>6.2 The Client may place the Order either through the Platform, communication channels or through any of the following agreed communication channels:<br />
              i.e telegram, encryptus email, slack</p>

            <p>If the Company receives any Order through the agreed communication channels and from an Account registered as being held by the Client, the Company shall be authorized to rely on such Orders. In such instances, the Company will not be required to make any further inquiry as to the genuineness, authority or identity of the person giving or purporting to give such Orders. If the Client has contacted the Company through the agreed communication channel, an assigned trader representing the Company shall establish a private instant messaging channel with Client and receive Orders and provide quotes to the Client.</p>

            <p>6.3 Market Order. A Market Order will be placed in accordance with the below:</p>

            <p>(a) The Client will place a Market Order either by: (i) clicking on the buy/sell link on the Platform; or (ii) by messaging on contacting the Company through the approved communication channel.<br />
              (b) When placing a Market Order, the Client may choose a Buy Order or Sell Order along with the type of Virtual Assets the Client proposes to transact with.<br />
              KL Note: Please elaborate on who will be a part of the chat window, and who will be the authorized trader engaging with the clients. We will see what information to retain / divulge.<br />
              the trader, the customer, and the account manager and a member of the finance team<br />
              (c) Upon receipt of an Order, the Company shall provide the Client with a quote for the Order. The rate quoted by the Company will be valid only for Sixty (60) seconds, which shall be calculated from the nearest whole minute that appears in the instant messaging conversation and the Company’s server logs.<br />
              (d) Once, the Company provides a quote to the Client, the Client may confirm the Order as follows:<br />
              i. If the Order has been placed through the Platform, the Client shall accept the quote by clicking on confirm link;<br />
              ii. If the quote has been received through the approved communication channel, the Client may confirm the quote through the communication channel by typing the words ‘OKAY,CONFIRMED in the instant messaging conversation.
              The Client may negotiate the price until the price and amount of Virtual Assets to be traded is agreed and accepted by Encryptus. Upon confirmation of the Order by the Company, the Company may proceed with the acceptance and execution of the Order.<br />
              (e) The execution and settlement of an Accepted Order will be in accordance with this Agreement.</p>

            <p>6.4 Limit Order. A Limit Order will be placed in accordance with the below:</p>

            <p>(a) In a Limit Order, the Client shall set the details of the specific price at which an identified amount of Virtual Assets may be bought or sold.<br />
              (b) When placing a Limit Order through the communication channel, the Client shall place this request by letting the trader know his/her expected rate for execution of trade. When communicating through the agreed communication channel, the Client shall expressly identify that the Client is placing a Limit Order.<br />
              (c) As and when the Company is able to execute the Order at the rate requested by the Client, the Company may proceed with the execution of the Order.</p>

            <p>6.5 The Accepted Order shall particularize by stating:</p>

            <p>(a) The type of Virtual Assets to be sold or bought and the total amount (i.e. number of units) of the Virtual Assets being bought or sold;<br />
              (b) Whether the Virtual Asset is to be purchased or sold by the Client;<br />
              (c) The price per unit of the Virtual Assets;<br />
              (d) The Total Purchase Price or Total Sale Price, as applicable.</p>

            <p>6.6 The Parties agree that upon acceptance of an Order by Encryptus, all the terms and conditions in this Agreement shall be incorporated and made a part of the Accepted Order. In the event of any inconsistency between the provision of this Agreement and the terms contained in an Accepted Order, the Accepted Order shall prevail to the extent of such inconsistency.</p>

            <p>6.7 Any Order may be rejected for any reason by Encryptus without reason.</p>

            <p>6.8 The Parties agree that, once agreed, the Relevant Price shall not be affected by any further price movement in the market.</p>

            <p>6.9 In the event of a Buy Order for Relevant Virtual Assets:</p>

            <p>(a) The Client must have sent sufficient fiat currency from their whitelisted Client Bank Account to Encryptus’ Client Money Account, as applicable, in accordance with the Accepted Order;<br />
              (b) The total Relevant Virtual Assets will be transferred to the whitelisted Client Wallet on or before the Settlement Date.</p>

            <p>6.10 In the event of a Sell Order for Relevant Virtual Assets:</p>

            <p>(a) The Client must have sent sufficient Relevant Virtual Assets sent to Encryptus’ Client Asset Wallet from their whitelisted Client Wallet in accordance with the Accepted Order;<br />
              (b) The Total Sale Price will be transferred to the whitelisted Client Bank Account by electronic transfer on the Settlement Date</p>

            <p>6.11 Encryptus may, subject to Applicable Laws, refuse to execute any Order from the Client. In addition, Encryptus may refuse any Orders previously given by the Client, provided that Encryptus has not acted upon the Orders, for any reason whatsoever including, without limitation, any manifest error or any abusive market practices or strategies indulged by the Client. If the Client requests for a cancellation of an Order, the Company shall only be able to do so if it has not already acted on such Order.</p>

            <p>6.12 Orders may be executed by Encryptus or passed to any affiliate, third party broker or agent for execution. The Company may arrange for a transaction to be executed, either in whole or in part. The Company shall take all sufficient steps to execute each Order promptly, in a best effort basis. However, in accepting an Order, the Company does not represent or warrant that it will be possible to execute such Order or that the execution will be possible according to the Client’s instructions.</p>

            <p>6.13 Once an Accepted Order is executed, the Client shall be bound by such transaction and such Order cannot be cancelled / revoked.</p>

            <p>6.14 All completed Orders are settled within a maximum of three (3) hours from the acceptance of the Order, by debiting and crediting the relevant balances of the Client Money and Client Virtual Asset held with the Company. The Client will receive a notification of the completed transactions via the Platform [and/or via email or any other agreed means of communication]. All completed transactions will be reflected on the Account, within one (1) day of completion of the Transaction.</p>

            <p>6.15 Notwithstanding the above, Encryptus may, to be in compliance with Applicable Laws or due to external market conditions:</p>

            <p>(a) Set a different Settlement Date on the notification of Accepted Order;<br />
              (b) Defer the Settlement Date by a written notification to the Client; and<br />
              (c) Impose any restrictions and/or conditions on any Accepted Order and not to effect the completion of any Order until such restrictions are released or conditions are fulfilled.</p>

            <h2>7. CLIENT MONEY</h2>

            <p>7.1 The Company will promptly place any Client Money it receives from the Client Bank Account (refer to Clause 7.5), in the course of performance of the Services into the Client Money Account. The Company shall open and maintain the Client Money Account with licensed banks. The Company shall exercise due skill, care and diligence in the selection and appointment and periodic review of the bank.</p>

            <p>7.2. The Company confirms that the Client Money shall be a common pooled account wherein the funds from multiple clients will be received. However, the Client Money shall be maintained segregated from the Company’s own funds and monies.</p>

            <p>7.3. The Company does not provide any deposit services and hence the Client Money held in the Client Money Account shall not attract any interest or any protection.</p>

            <p>7.4. The Company may request the Client to provide documentation confirming the source of funds deposited into the Client Money Account. The Company shall have the right to reject a deposit of the Client if the Company is not duly satisfied as to the legality of the source of funds. The Client shall bear all expenses and charges including any bank charges and currency conversion charges arising from the deposit of monies in the Client Money Account.</p>

            <p>7.5. The Company shall allow deposit and withdrawal of funds only from a Client Bank Account, that have been reviewed, vetted and ‘white-listed’ by the Company. The Company will not accept third party or anonymous deposits in the Client Money Account, nor will it permit any withdrawals to any other third party or anonymous account.</p>

            <p>7.6. The Company shall, subject to any compliance required under AML/CFT Laws, transfer the funds to the Client Bank Account.</p>

            <p>7.7. Subject to Clause 18 of this Agreement, the Client agrees that the Company is authorised to transfer out any funds held in the Client Money Account, if such funds are payments due and payable to the Company whether as Charges or other miscellaneous costs, expenses and reimbursements for a transaction.</p>

            <h2>8. CLIENT VIRTUAL ASSETS</h2>

            <p>8.1. The Company will promptly place any Client Virtual Assets it receives from the whitelisted Client Wallet (refer to Clause 8.5), in the course of performance of the Services into the Client Asset Wallet. The Company confirms that the Client Asset Wallet is a Virtual Asset wallet, which is opened by the by the Company solely for the Client. Therefore, the Client Asset Wallet will not contain the Virtual Assets belonging to the Company or any other Client of the Company. The Client agrees and acknowledges that, although the Client Asset Wallets are opened specifically for the Client, the Company will hold the private keys for the Client Asset Wallets.</p><br /><br />

            <p>8.2. The Company does not provide any deposit services and hence the Client Virtual Asset held in the Client Asset Wallet shall not attract any interest or any protection.</p><br /><br />

            <p>8.3. The Company may request the Client to provide documentation confirming the source of Virtual Assets or the funds used to procure the Virtual Assets deposited into the Client Asset Wallet opened for you. The Company shall have the right to reject a deposit if the Company is not duly satisfied as to the legality of the source of funds used to procure the Virtual Assets or the Virtual Assets. The Client shall bear all expenses and charges arising from the deposit (or a rejection of a deposit) of any Virtual Asset into the Client Asset Wallet.</p><br /><br />

            <p>8.4. The Company shall allow deposit and withdrawal of Virtual Assets only from a Client Wallet, that have been reviewed, vetted and ‘white-listed’ by the Company. The Company will not accept third party or anonymous payments in the Client Asset Wallet, nor will it permit any withdrawals to any other third party or anonymous account.</p><br /><br />

            <p>8.5. The Company shall, subject to any compliance required under AML/CFT Laws, transfer the funds to the Client Wallet.</p><br /><br />

            <p>8.6. Subject to Clause 18 of this Agreement, the Client agrees that the Company is authorised to transfer out any Virtual Assets held in the Client Asset Wallet, if such Virtual Assets are payments due and payable to the Company whether as charges or other miscellaneous costs, expenses and reimbursements for a transaction.</p><br /><br />

            <h2>9. FEES AND CHARGES</h2>

            <p>9.1. Details of the fees and charges payable to the Company for rendering the Services to the Client are as listed within Schedule of Charges (included under as Schedule I to this Agreement)</p><br /><br />

            <p>9.2. The Client bears sole responsibility for any taxes as a result of the matters and transactions that are the subject of this Agreement, Accepted Orders, and any future acquisition, ownership, use, sale or other disposition of any Virtual Assets pursuant to any Accepted Orders held by or on behalf of the Client. The Client agrees to indemnify, defend and hold Encryptus and any of its affiliates, employees, officers or agents (including developers, auditors, contractors or founders) harmless on an after-tax basis for any claim, liability, assessment or penalty with respect to any taxes associated with or arising from any Accepted Order.</p><br /><br />

            <p>9.3. The Parties agree that the Company may collect its charges in fiat currency or Virtual Assets. Prior to transferring the fiat amounts or Virtual Assets into the Client Bank Account or Client Wallet, the Company shall be authorised to deduct its charges.</p><br /><br />

            <h2>10. REPRESENTATIONS AND WARRANTIES</h2>

            <p>10.1. The Client hereby represents, warrants and covenants to Encryptus, as of the date hereof and during the term of this Agreement that:</p><br /><br />

            <p>(a) all statements and information provided by the Client are true, complete and correct and that no material fact has been willfully withheld;</p><br /><br />

            <p>(b) in case of the Client is an entity, it is duly organized and validly existing and in good standing under the laws of its place of incorporation, and no proceedings have been commenced or are pending for the bankruptcy, winding up, liquidation or reorganization of the Client and the Client is not insolvent;</p><br /><br />

            <p>(c) in case of the Client is an individual, he/she has reached the age of 18 and not under any undischarged bankruptcy order in any jurisdiction;</p><br /><br />

            <p>(b) the Client has full power and authority to execute and deliver and perform all of its obligations under this Agreement and any other agreements to be executed by it hereunder and to consummate the transactions contemplated hereby and thereby;</p><br /><br />

            <p>(e) all actions, conditions and things required to be taken, fulfilled and done (including the obtaining of any necessary consents) in order to: (i) enable the Client lawfully to enter into, exercise its rights and perform or comply with its obligations under, this Agreement and any other agreements to be executed by it hereunder and to consummate the transactions contemplated hereby and thereby; and (ii) ensure that those obligations are legally binding and enforceable have been taken, fulfilled and done;</p><br /><br />

            <p>(f) this Agreement, the Accepted Orders and all other agreements and instruments of the Client shall hereby be legal, valid and binding agreement of the Client, enforceable against the Client in accordance with their terms;</p><br /><br />

            <p>(g) the Client has fully complied with all Applicable Laws or regulations of all relevant jurisdictions in connection with the source of funds or Virtual Assets used or to be used for the settlement of any Accepted Order, and the Client will not, by reason of acceptance and receipt of such proceeds, be in breach of any applicable law or regulation of any relevant jurisdiction, including but not limited to AML/CFT Laws;</p><br /><br />

            <p>(h) the Client has noted the client classification assigned to it and will inform the Company if there are any changes that may impact this classification;</p><br /><br />

            <p>(i) the Client has read these terms and conditions and all disclosures (including all risk disclosures) and it is aware of and understand the risks inherent in making investments into Virtual Assets including any financial risks;</p><br /><br />

            <p>(j) no event of default or any event which may become (with the passage of time, the giving of notice, the making of any determination or any combination of the above) an event of default has occurred and is continuing with respect to the Client;</p><br /><br />

            <p>(k) any information which the Client provides or has provided to the Company with respect to its financial position or other matters is accurate and not misleading in any material respect;</p><br /><br />

            <p>(l) neither the Client and in case the Client is a corporate entity, any of the Client’s shareholders, ultimate beneficial owners, directors or key management personnel have been convicted of any crimes (in any jurisdiction) and there are no criminal lawsuits or other litigation, actions, administrative or other proceedings or governmental investigations of a criminal nature pending or threatened against or relating to the Client or its properties or business;</p><br /><br />

            <p>(m) neither the execution and delivery of this Agreement, nor the consummation of the transactions contemplated hereby, does or will violate any statute, regulation, rule, judgment, order, decree, ruling, charge or other restriction of any government, governmental agency, or court to which the Client is subject or conflict with, violate or constitute a default under any agreement, debt or other instrument to which the Client is a party;</p><br /><br />

            <p>(n) all transactions contemplated under Accepted Orders are for the Client’s own benefit and the Client is acting as principal and not as trustee or otherwise on behalf of any other person;</p><br /><br />

            <p>(o) the Client has not been the subject of any civil, criminal, arbitration, administrative or other proceeding or government investigations involving a default on its part, and to the best of the Client’s knowledge, no fact or circumstance exists which might give rise to such proceedings or investigations;</p><br /><br />

            <p>(p) with respect to any Virtual Assets to be sold or transferred by the Client to Encryptus under any Accepted Order, the Client is the lawful owner of such Virtual Assets with good and marketable title thereto, and the Client has the absolute right to sell, assign, convey, transfer and deliver such digital asset and such digital asset are free and clear of any and all Encumbrances; and</p><br /><br />

            <p>(q) the Client is the legal and beneficial owner of the Account(s), and the Account(s) is owned and operated solely for the benefit of the Client, and no person, other than the Client, has any right, title or interest in the Account(s).</p><br /><br />

            <p>10.2. As on the date of this Agreement, Encryptus hereby represents and warrants to the Client that:</p><br /><br />

            <p>(a) It is duly organized and validly existing and in good standing under the laws of its place of incorporation;</p><br /><br />

            <p>(b) It has full power and authority to execute and deliver and perform all of its obligations under this Agreement and any other agreements to be executed by it hereunder and to consummate the transactions contemplated hereby and thereby;</p><br /><br />

            <p>(c) The execution, delivery and performance of this Agreement by Encryptus will not conflict with its constitution or any law, order, judgment, decree, rule or regulation of any court, arbitral, tribunal or government agency, or any agreement, instrument or indenture to which Encryptus or any of its related corporations is a party or by which Encryptus is bound;</p><br /><br />

            <p>(d) No proceedings have been commenced or are pending for the bankruptcy, winding up, liquidation or reorganization of Encryptus and Encryptus is not insolvent;</p><br /><br />

            <p>(e) Encryptus has not been the subject of any civil, criminal, arbitration, administrative or other proceeding or government investigations involving a default on its part, and to the best of Encryptus’ knowledge, no fact or circumstance exists which might give rise to such proceedings or investigations.</p><br /><br />



            <h2>11. OTHER DISCLAIMERS</h2>

            <p>11.1. The Client agrees, understands and acknowledges that:</p><br /><br />

            <p>(a) Encryptus reserves the right to reject or delay executing any Accepted Order if any such sale order is, in its sole determination, unclear, conflicting, incorrect, incomplete, in an unapproved format, unauthorised, fraudulent or not otherwise authentic, or in breach of (or may breach) any of the security procedures of Encryptus and/or any applicable law or regulation;</p><br /><br />

            <p>(b) Encryptus shall only transfer Virtual Assets to the Client Wallet that has been verified and whitelisted by Encryptus, and Encryptus has no responsibility to ensure that the Client Wallet is compatible with the Virtual Assets;</p><br /><br />

            <p>(c) The Client shall be responsible to the security of the Client Wallet, Client Bank Account and the Account;</p><br /><br />

            <p>(d) Encryptus’ responsibility shall be deemed fulfilled when Encryptus notifies the Client that the Virtual Assets or fiat currency (as the case may be) has sent to the whitelisted Client Wallet or the Client Bank Account in accordance with the Accepted Order;</p><br /><br />

            <p>(e) Encryptus shall not be responsible if the Client provides any wrong wallet address or bank account number to Encryptus;</p><br /><br />

            <p>(f) Encryptus shall not be responsible in case of any technical error on the blockchain network when Encryptus transfers digital asset to the Client Wallet;</p><br /><br />

            <p>(g) Encryptus shall not be responsible in any event if the Client Bank Account has refused any transfer from Encryptus or the Client Bank Account is frozen;</p><br /><br />

            <p>(h) the Virtual Asset to be delivered to the Client Wallet is at the sole risks of the Client and no representation or warranty is made in respect of the digital asset networks or the Virtual Assets;</p><br /><br />

            <p>(i) the purchase or sale of Virtual Assets involve significant risks, all of which the Client fully and completely acknowledges and assumes, including, but not limited to, the risk that the sale or purchase of digital asset may decrease in value over time and/or lose all monetary value;</p><br /><br />

            <p>(j) all risk of unauthorised instructions, forgery, fraud, misunderstandings, errors and operation failure shall lie solely with the Client;</p><br /><br />

            <p>(k)Client is solely responsible for any decision to enter into a transaction subject to this Agreement, including the evaluation of any and all risks related to any such transaction and in entering into any such Order and Accepted Order, the Client has not relied on any statement or other representation of Encryptus other than as expressly set forth herein.</p><br /><br />

            <h2>12. CLIENT COMPLAINTS</h2>



            <p>12.1. Complaints about the Services or the Platform will be dealt with in accordance with the complaints handling procedure. The Client can obtain further detailed information on its rights by contacting the Company at the following contact details: hello@encryptus.io</p><br /><br />

            <h2>13. EXCLUSIONS AND LIMITATIONS</h2>

            <p>13.1. General exclusion: Neither the Company or their Representatives shall be liable for any losses, damages, costs or expenses, whether arising out of negligence, breach of contract, misrepresentation or otherwise, incurred or suffered by the Client under this Agreement (including any transaction or where the Company has declined to enter into a proposed transaction) unless such loss is a reasonably foreseeable consequence or arises directly from the Company or our personnel’s respective gross negligence, wilful default or fraud. In no circumstance shall the Company’s liability include losses suffered by the Client or any third party for any special damage, or loss of profits or loss of goodwill or loss of business opportunity arising under or in connection with this Agreement, whether arising out of negligence, breach of contract, misrepresentation or otherwise.</p><br /><br />

            <p>13.2. Encryptus’ total aggregate liability, arising out of or in connection with the performance or contemplated performance of an Accepted Order shall be limited to the Total Price or the Total Sale Price.</p><br /><br />

            <p>13.3. Changes in the market: Without limitation, the Company does not accept any liability by reason of any delay or change in market conditions before any particular transaction is executed.</p><br /><br />

            <h2>14. INDEMNITY</h2>



            <p>14.1. The Client shall keep the Company and their Representatives indemnified at all times from all actions, proceedings, claims, losses, damages, costs, interest (both before and after judgment) and expenses which may be brought against or suffered or incurred by the Company and their Representatives, directly or indirectly arising out of resulting from:</p><br /><br />

            <p>(a) Any information provided by the Client to the Company to facilitate the provision of Services;</p><br /><br />

            <p>(b) Anything done or omitted to be done for the purpose of carrying out any transaction for the Client or providing any Service to the Client or otherwise acting on the Client’s instructions under this Agreement;</p><br /><br />

            <p>(c) Any breach of the Client’s representation and warranties;</p><br /><br />

            <p>(d) Any breach of the Client’s obligations under the Agreement; and/or</p><br /><br />

            <p>(e) Any gross negligence or wilful misconduct by the Client.</p><br /><br />



            <h2>15. TERMINATION</h2>

            <p>15.1. Termination by Client. The Client may terminate this Agreement for any reason by giving the Company at least 30 calendar days’ notice.</p><br /><br />

            <p>15.2. Termination by Company for Cause. This Agreement may be immediately terminated, in whole or in part, by notice given to the Client by the Company in case of any of the following circumstances:</p><br /><br />

            <p>(a) if the Client are in breach of the terms of this Agreement or any other instruction or communication provided to the Client by the Company;</p><br /><br />

            <p>(b) if the Client fail to pay the charges or any other fees, costs, expenses, reimbursements or other monies due to Company at the time at which such monies become due and payable;</p><br /><br />

            <p>(c) if Company receives written confirmation of the Client’s dissolution or winding up or the Client becomes bankrupt, insolvent or is subject to any analogous insolvency procedure in any relevant jurisdiction;</p><br /><br />

            <p>(d) if the Client is unable to pay its debts as they become due, or make a general assignment or composition with or for the benefit of creditors, or become the subject of insolvency, bankruptcy or similar proceedings, or a petition is presented for the Client’s winding- up or liquidation or an administrator or liquidator is appointed over all or substantially all of the Client’s assets;</p><br /><br />

            <p>(e) if Company receives an order from a court or other regulatory authority to terminate this Agreement;</p><br /><br />

            <p>(f) if the Client is dissolved, or, if the Client’s capacity or existence is dependent upon a record in a formal register, such registration is removed or ends, or any procedure is commenced seeking or proposing the Client’s dissolution, removal from such a register or ending of such registration;</p><br /><br />

            <p>(g) if the Company believes that funds or Virtual Assets used by the Client to make payment to the Company or to make an investment have been obtained through unlawful means or that such funds are being used to effect suspicious transactions or if the Company believes that the Client are otherwise in violation of any AML/CFT Laws;</p><br /><br />

            <p>(h) if the Client does not, upon request by Company, provide the Company with any information or documents relating to the Client and/or the Account;</p><br /><br />

            <p>(i) if the Company deems that the continuing to provide Services to the Client poses a reputational, regulatory, operational, or any other risk to Company;</p><br /><br />

            <p>(j) if the Company believes that the Client may be in breach of any Applicable Laws;</p><br /><br />

            <p>(k) if the Client have been found to have provided incorrect, incomplete, inaccurate or false information to the Company;</p><br /><br />

            <p>(l) the Client has seriously or persistently breached this Agreement or the Company has reason to believe that the Client has used, or intends to use the Platform or the Account for fraudulent or other unlawful purposes;</p><br /><br />

            <p>(m) if, despite reasonable attempts by the Company to communicate with the Client using the Client’s registered email address or any other contact details on record, the Company has been unsuccessful.</p><br /><br />

            <p>15.3. Termination by Company for Cause. The Company may terminate this Agreement, in whole or in part, for any reason by giving The Client at least 30 calendar days’ notice.</p><br /><br />

            <p>15.4. Consequences of Termination.</p><br /><br />

            <p>(a) Following termination of this Agreement, Company shall determine whether to complete any outstanding transactions or obligations. For the avoidance of doubt, the Client agrees that the Client shall be responsible for the payment of any costs, fees, charges, expenses, levies, taxes duties and reimbursements incurred by Company, in relation to any such outstanding transactions at the time of termination. Any outstanding fees, costs and expenses incurred by the Company at the time of termination or resulting from such termination shall become due and payable by the Client immediately upon termination.</p><br /><br />

            <p>(b) Any pending Orders at the time of the termination of this Agreement shall be cancelled automatically without the need for any further notice to the Client. After deductions of any amounts or charges owed to us, the Company shall transfer any fiat currency or Virtual Assets of the Client in the Company’s control to the Client designated bank account and virtual asset wallet respectively.</p><br /><br />

            <p>(c) The Client understands and agrees that the date of refund to the Client by the Company (if applicable) may be different from the date the Account is closed.</p><br /><br />

            <p>(d) Clauses 14, 15, 16, 17, 22.9, 22.12 and 22.13 shall survive termination of this Agreement.</p><br /><br />

            <h2>16. CONFIDENTIALITY</h2>

            <p>16.1. The Client undertakes that it shall (except with the prior written consent of the other Party) treat as
              strictly confidential and not disclose any Confidential Information received or obtained as a result of entering
              into this Agreement (or any agreement entered into, pursuant to or in connection with this Agreement).</p><br /><br />

            <p>16.2. The confidentiality obligations shall not apply to any information as follows: (a) which is in, or becomes
              available in, the public domain without breach of this clause; (b) which is required to be disclosed pursuant to
              any Applicable Laws, provided that the disclosing Party shall, as far as legally permissible and to the extent
              practicable, inform the other Party in advance of the disclosure to be made, to provide the other Party with the
              reasonable opportunity to contest such disclosure and to consult with the other Party as to the form, timing,
              content and manner of such disclosure, and the disclosing Party shall take into account the reasonable comments
              of the other Party when making such disclosure; and (c) which is disclosed by a Party to its related
              corporations, shareholders, affiliates or any of its respective (including prospective) officers, directors,
              employees, bankers, financiers, financial advisers, consultants and legal or other advisers on a need-to-know
              basis and solely for the purpose of the subject matter of this Agreement, and provided that such disclosure is on
              the basis that such recipients of the information agree to comply with this clause.</p><br /><br />

            <h2>17. OWNERSHIP AND INTELLECTUAL PROPERTY</h2>

            <p>17.1 Client agrees and acknowledges that Encryptus is the owner and/or authorised user of all trademarks, service
              marks, design marks, patents, copyrights, database rights and all other intellectual property appearing on or
              contained within the Platform, unless otherwise indicated. All information, text, material, graphics, software
              and advertisements on the Platform are the copyright of Encryptus, its suppliers and licensors, unless
              expressly indicated otherwise by Encryptus.</p><br /><br />

            <p>17.2 Except as provided in this Agreement, the use of the Platform does not grant to the Client, any right, title,
              interest or license to any such intellectual property accessed by the Client hereof. The Client unconditionally
              agrees that Client is not authorised to use any of the trademarks belonging to the Company without Company’s
              prior express written consent.</p><br /><br />

            <h2>18. SET-OFF</h2>

            <p>18.1 Save as otherwise set out herein or otherwise expressly agreed, payments or transfers to be made by the Client
              to Encryptus under an Accepted Order shall not be subject to any set off or netting against any other amounts due
              from Encryptus to the Client. Each payment of any amount owing to Encryptus hereunder shall be for the full
              amount due, without reduction, withholding or offset for any reason (including, without limitation, any exchange
              charges, bank transfer charges, any other fees, other than pursuant to any applicable withholding tax
              obligations).</p><br /><br />

            <p>18.2 The Company may, at any time and without prior notice to the Client, set off any liability or debt the Company
              owes to the Client against any liability the Client owes to the Company, irrespective of the currency or its
              denomination.</p><br /><br />

            <p>18.3 Any exercise by the Company of its rights under this clause shall be without prejudice to any other rights or
              remedies available to the Company hereunder or at law.</p><br /><br />

            <p>18.4 The Client agrees that this Agreement is intended to be and constitute a “Netting Agreement” and each
              transaction executed by the Company, in furtherance to an Order is intended to be and constitutes a “Qualified
              Financial Contract”, as each term is defined in UAE Federal Decree-Law No. 10/2018 On Netting (the “Netting
              Law”). The Client agrees that it shall not take any steps or bring any proceedings in any forum to dispute or
              contest or otherwise seek to challenge the validity or enforceability of this Agreement or any trading
              transaction on the basis that this Agreement or any such transaction(s) do not constitute or are not part of a
              Netting Agreement or collectively or individually are not in compliance with or within the scope of the Netting
              Law.</p><br /><br />

            <h2>19. PROHIBITED USE OF THE PLATFORM AND OUR SERVICES</h2>

            <p>19.1. Client agrees that Client shall not exploit the Platform in any unauthorized way whatsoever, including but
              not limited to trespass, or materially burden the network capacity of Encryptus and Client shall not use the
              Platform for any illegal or immoral purpose, or in violation of any Applicable Laws, such as laws governing
              intellectual property and other proprietary rights, data protection and privacy, anti-money laundering and
              currency control. Client acknowledges that Client is fully responsible for satisfying any legal and regulatory
              requirements applicable in countries and territories in which Client operates.</p><br /><br />

            <p>19.2. Encryptus reserves the right to apply any limits on the use of the Platform as it deems fit, for example, by
              limiting the available features, or number of supported users and/or transactions. We further reserve the right to
              publish such limitations, or to amend such limitations at will, in each case in our sole discretion.</p><br /><br />

            <p>19.3. Client may not use the Account to engage in the following categories of activities (“Prohibited Uses”). By
              opening an Account, Client confirms that Client will not use the Account for any of the Prohibited Uses, which
              shall include, but not be limited to:</p><br /><br />

            <p>(a) Unlawful Activities: Activities which would violate, or assist in violation of, any law, statute, ordinance,
              or regulation, sanctions programs administered in any countries, including but not limited to the persons or
              countries listed by the United Arab Emirates Financial Intelligence Unit (FIU), or which would involve proceeds of
              any unlawful activity; publish, distribute or disseminate any unlawful material or information;</p><br /><br />

            <p>(b) Abusive Activities: Actions which impose an unreasonable or disproportionately large load on Encryptus’
              infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or information;
              transmit or upload any material to the Platform that contains viruses, Trojan horses, worms, or any other harmful
              or deleterious programs; attempt to gain unauthorized access to the Platform , other user accounts, computer
              systems or networks connected to the Platform, through password mining or any other means; use Account
              information of another user on the Platform to access or use the Platform, except in the case of specific
              merchants and/or applications which are specifically authorized by such user to access such user's Account and
              information; or transfer your Account access or rights to a third party, unless by operation of law or with the
              express written consent of Encryptus;</p><br /><br />

            <p>(c) Abuse Other Users: Interfere with another user’s access to or use of any the Services to defame, abuse,
              extort, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to,
              privacy rights, publicity and intellectual property rights) of other users; incite, threaten, facilitate, promote,
              or encourage violent acts against other users; harvest or otherwise collect information from the Platform about
              other users, including without limitation, any personal identifiable data, without proper consent;</p><br /><br />

            <p>(d) Fraud: Activities which operates to defraud Encryptus or any other person; provide any false, inaccurate, or
              misleading information to Encryptus, gambling, lotteries; bidding fee auctions; sports forecasting or odds making,
              fantasy sports leagues with cash prizes, internet gaming, contests, sweepstakes, games of chance and infringement
              of any intellectual property rights of any entity or user, engage in transactions involving items that infringe or
              violate any copyright, trademark, right of publicity or privacy or any other proprietary right under any
              applicable law, including but not limited to sales, distribution, or access to counterfeit music, movies, software,
              or other licensed materials without the appropriate authorization from the rights holder, use of Encryptus’
              intellectual property, name, or logo, including use of Encryptus trade or service marks, without express consent
              from Encryptus, or in a manner that otherwise harms Encryptus or the Encryptus brand, or perform any action that
              implies an untrue endorsement by or affiliation with Encryptus; and</p><br /><br />

            <p>(e) Intellectual Property Infringement: Engage in any transactions involving items that infringe or violate any
              copyright, trademark, right of publicity or privacy or any other proprietary right under any Applicable Law,
              including but not limited to, sales, distribution, or access to counterfeit music, movies, software, or other
              licensed materials without the appropriate authorization from the rights holder, use of Encryptus intellectual
              property, name, or logo, including use of Encryptus trade or service marks, without express consent from Encryptus
              or in a manner that otherwise harms Encryptus or the Encryptus brand; or perform any action that implies an untrue
              endorsement by or affiliation with Encryptus.</p><br /><br />

            <h2>20. SIGNATURES</h2>

            <p>20.1. You agree that the Company may elect to rely on a signature that is transmitted, recorded or stored by any
              electronic, optical or similar means (including telecopy, imaging, photocopying, electronic mail, electronic data
              interchange, telegram or telex) as if it were executed physically with wet signatures. Such signatures may create
              legally binding contract under the UAE laws.</p><br /><br />

            <h2>21. NOTICES</h2>

            <p>21.1. All communications between the Parties shall be via email; provided however any Order placing through the
              communication channels listed in Clause 6.2 shall be valid communication and notice.</p><br /><br />

            <p>22.2. If the Company sends an email to the email address on record for the Client’s Account, the Client agrees and
              understands that this constitutes notice from the Company to the Client. If the Client emails the Company at
              hello@encryptus.io , this constitutes notice from the Client to the Company.</p><br /><br />

            <p>22.3. For all notices made by email, the date of receipt is considered to be the date of transmission.</p><br /><br />

            <h2>22. MISCELLANEOUS</h2>

            <p>22.1. Costs: Each Party will pay their own fees and expenses (including legal expenses) related to or arising out
              of any transactions under this Agreement.</p><br /><br />

            <p>22.2. Illegality: The illegality, invalidity or unenforceability of any provision of this Agreement under the law
              of any jurisdiction shall not affect its legality, validity or enforceability under the law of any other
              jurisdiction nor the legality, validity or enforceability of any other provision.</p><br /><br />

            <p>22.3. No Third Party Rights: A person who is not a party to this Agreement shall have no right under any law or
              contract to enforce or enjoy the benefit of any term of this Agreement.</p><br /><br />

            <p>22.4. Further Assurance: Each Party shall do and execute or procure to be done and executed all such further acts,
              deeds, things and documents as may be necessary to give effect to the terms of this Agreement.</p><br /><br />

            <p>22.5. Entire Agreement: This Agreement and any Accepted Sales Order together shall constitute the entire agreement
              and understanding among the Parties relating to the subject matter contemplated herein, and supersede any
              previous or contemporaneous written or oral agreements or representations (whether written or oral) affecting
              the subject matter. Each Party acknowledges that it has not entered into this Agreement in reliance upon any
              representation, warranty or undertaking of any other Party which is not set out or referred to in this Agreement.
              Nothing in this Clause shall however operate to limit or exclude liability for fraud.</p><br /><br />

            <p>22.6. Amendments: The Company may change or modify this Agreement, including fees and charges under this Agreement,
              by providing the Client a prior notice of at least 30 calendar days. The Company will, use its best efforts, to
              explain the reason for the change or amendment. If the Client does not wish to continue this Agreement because of
              a change or amendment being proposed, the Client has the right to terminate this Agreement immediately and
              without charge by giving the Company written notice before the change comes into effect. If the Client fails
              cancel during the notice period specified herein, the Client shall be deemed to have accepted the changes and
              amendments.</p><br /><br />

            <p>22.7. Independent Advice: The Client confirms that it has received independent legal and financial advice
              relating to all the matters provided for in this Agreement, including the provisions of this Clause, and agrees,
              having considered the terms of this Clause and this Agreement as a whole, that the provisions of this Clause are
              fair and reasonable.</p><br /><br />

            <p>22.8. Counterparts: This Agreement may be executed in any number of counterparts, each of which when executed and
              delivered shall be an original, but all of which when taken together shall constitute a single instrument.</p><br /><br />

            <p>22.9. No Waiver: Failure by any Party to exercise or enforce any rights available to that Party or the giving of
              any forbearance, delay or indulgence by any Party shall not be construed as a waiver of that Party’s rights under
              this Agreement. No provision of this Agreement shall be waived unless such waiver is made in writing and signed by
              an authorised representative of the Party against which enforcement of the waiver is sought. A waiver shall not
              constitute a consent to, or waiver of any subsequent breach of any provision of the same or different nature.</p><br /><br />

            <p>22.10. Personal Information: Full details on how the Company uses any personal information collected from the
              Client is available in the Privacy Policy on our Platform <a
                href="https://encryptus.io/legal/privacy">https://encryptus.io/legal/privacy</a>. Changes may be made to that
              Privacy Policy, where permitted or required by applicable law.</p><br /><br />

            <p>22.11. Third Party Website: Encryptus does not warrant that it possesses any ability to prevent or mitigate
              cyberattacks and modifications on the blockchain networks, and Encryptus does not guarantee any insurance to the
              Client from any cyberattacks.</p><br /><br />

            <p>22.12. Force majeure: Encryptus shall not be held responsible for any non-performance or delay of any of its
              obligations that is caused by circumstances beyond its control which includes, inter alia, acts of God, terrorism
              or the threat of terrorism or cyberterrorism, sabotage, equipment failures, issuance or operation of any adverse
              governmental law or regulation, or any other emergency tha<br /><br />t prevents Encryptus from operating normally. All such
              events are ‘force majeure events’ under this Agreement. The Company shall use all reasonable endeavours to bring
              the force majeure event to a close or to find a solution by which the Agreement may be performed despite the
              continuance of a force majeure circumstance and/or shall take all reasonable steps to resume performance as soon
              as is reasonably possible following the cessation of a force majeure event. In any event the Company shall not be
              liable to the Client for any delayed, partial or non-performance of the Company’s obligations hereunder by reason
              of force majeure.</p><br /><br />

            <p>22.13. Governing Law: This Agreement shall be governed by, and construed in accordance with, the laws of United
              Arab Emirates, without giving effect to the principles of conflicts of law thereof.</p><br /><br />

            <p>22.14. Jurisdiction: The Client agrees that the Dubai Courts (excluding the DIFC courts) shall have exclusive
              jurisdiction in relation to any legal action or proceedings arising out of or in connection with this Agreement
              and waive any objection to proceedings in such courts on the grounds that the proceedings have been brought in
              the wrong or inconvenient forum.</p><br /><br />


          </ol>
        </div >
      </div >
      <br />
      <Footer />
    </>
  );
};


{/* <h2>2. REGULATORY STATUS</h2>
<p>2.1 The Company is licensed by VARA under license number ……………………………. and is authorised to provide ‘Broker-Dealer Services’ within the Emirate of Dubai, as defined in the VARA regulations and rulebooks.</p> <br /> <br /> */}
