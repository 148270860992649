import { useEffect, useState } from 'react';
import classes from '../../../styles/Transactions.module.scss';
import { useModal } from 'context/modal';
import { ROUTES } from 'config';
import DepositeTicket from 'modals/DepositeTicket';
import useFilters from '../../../hooks/useFilters';
// import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { DATE_CONV } from 'utils/DateConv';
import { copyText } from 'utils/CopyContent';
import AdminScreen from 'components/AdminScreen';
import useAdminPaginate from 'hooks/useAdminPaginate';
// import { useAdmin } from 'context/admin';
import MobileToupsDetails from 'modals/MobileToupsDetails';
import exportFromJSON from 'export-from-json';

// const filterList: FilterListItem[] = [
//   {
//     field: 'createdAt',
//     label: 'Created',
//     options: [
//       { value: '7', label: 'Last 7 days' },
//       { value: '15', label: 'Last 15 days' },
//       { value: '30', label: 'Last 30 days' },
//       { value: '90', label: 'Last 90 days' },
//     ],
//   },
// ];

// This is deposit transaction for crypto and fiat+++++++
export default function PGiftcards() {
  const { openModal } = useModal();
  // const { headers } = useAdmin();
  // States?
  const [Change, setChange] = useState('giftcard');
  // const { search } = useSearch();
  const { filters } = useFilters();

  // const [status, setStatus] = useState('all');

  // ? Search Bar Values

  // const [cryptoDepositSearchField, setCryptoDepositSearchField] =
  //   useState<string>('transaction_hash');
  // eslint-disable-next-line
  const [cryptoDepositSearchValue, setCryptoDepositSearchValue] =
    useState<string>('');
  // eslint-disable-next-line
  const [cryptoBuySearchValue, setCryptoBuySearchValue] = useState<string>('');
  // const [cryptoBuySearchField, setCryptoBuySearchField] =
  //   useState<string>('transaction_hash');
  // eslint-disable-next-line
  const [fiatSearchField, setFiatSearchField] =
    useState<string>('tagger_email');
  // eslint-disable-next-line
  const [fiatSearchValue, setFiatSearchValue] = useState<string>('');
  // const [checked, setChecked] = useState<boolean>(false)

  const [collections, { reload, paginateProps }] = useAdminPaginate<any>(
    {
      route: ROUTES.Admin_Giftcards,
      // params: {
      //   filters: { tagger_email: fiatSearchValue, ...filters },
      // },
    },
    // [search, filters],
  );
  const [collections2, { reload: reload2, paginateProps: paginateProps2 }] =
    useAdminPaginate<any>(
      {
        route: ROUTES.Admin_Topups,
        // params: {
        //   filters: { [cryptoBuySearchField]: cryptoBuySearchValue, ...filters },
        // },
      },
      // [search, filters],
    );

  // console.log(collections2);

  // console.log({ collections2 });

  // selection for filter switch
  // const changeSelections = (status: string) => {
  //   updateFilters?.('transc_status', status === 'all' ? '' : status);

  //   setStatus(status);
  // };

  // switch for chnaging fiat to crytop++++++++++++++
  const ChangeManager = async () => {
    console.log('CHANGE MANAGER');

    await resetAllSearchFields();

    if (Change === 'giftcard') {
      setChange('mobileTopUp');
      reload2();
    }

    if (Change === 'mobileTopUp') {
      setChange('giftcard');
      reload();
    }
  };

  useEffect(() => {
    if (Change === 'giftcard') reload();
    else if (Change === 'mobileTopUp') reload2();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    reload();
    reload2();
    // eslint-disable-next-line
  }, []);

  // ? SEARCH MODULE

  // ? Method to reset all search fields
  const resetAllSearchFields = () => {
    setFiatSearchValue(() => '');
    setFiatSearchField(() => 'tagger_email');

    setCryptoBuySearchValue(() => '');
    setCryptoDepositSearchValue(() => '');
  };
  // * End of resetAllSearchFields();

  // ? Method to Search Fiat Tickets
  const handleFiatSearch = async (event: any) => {
    event.preventDefault();
    reload();
  };
  // * End of handleFiatSearch()

  // // ? Method to Search Crypto Buy Tickets
  // const handleCryptoBuySearch = async (event: any) => {
  //   event.preventDefault();
  //   reload2();
  // };
  // * End of handleCryptoBuySearch()

  // ? Method to Search Crypto Deposit Tickets
  const handleCryptoDepositSearch = async (event: any) => {
    event.preventDefault();
    reload2();
  };
  // * End of handleCryptoDepositSearch()

  // const handlevalue = async (id: string, type: string) => {
  //   try {
  //     const body = {
  //       transac_type: type,
  //     };
  //     const data = await axios.patch(ROUTES.Processing_Route(id), body, {
  //       headers,
  //     });
  //     if (data) {
  //       toast.success('Success');
  //       reload();
  //       reload2();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.warning('Not Set To Processing');
  //   }
  // };

  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  const renderSearchBar = () => {
    // ? FIAT
    if (Change === 'giftcard') {
      return (
        <form className={classes.userSearchForm} onSubmit={handleFiatSearch}>
          <h4>Giftcard Transactions</h4>

          {/* <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                placeholder="Search Email"
                value={fiatSearchValue}
                onChange={(event) => setFiatSearchValue(event?.target.value)}
              />

              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderFiatDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setFiatSearchField(e.value)}
              >
                <MenuItem
                  value="tagger_email"
                  className={
                    fiatSearchField === 'tagger_email'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  OrderId
                </MenuItem>
              </Menu>
            </div>
            <Button type="submit">Search</Button>

            <Button onClick={resetAllSearchFields}>Reset</Button>
          </div> */}
        </form>
      );
    } else {
      return (
        <form
          className={classes.userSearchForm}
          onSubmit={handleCryptoDepositSearch}
        >
          <h4>Mobile Topup</h4>
          {/* <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                value={cryptoDepositSearchValue}
                onChange={(event) =>
                  setCryptoDepositSearchValue(event?.target.value)
                }
                placeholder={
                  cryptoDepositSearchField === 'transaction_hash'
                    ? 'Search Txn Hash'
                    : cryptoDepositSearchField === 'email'
                    ? 'source_address'
                    : 'Search Address'
                }
              />

              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderCryptoDepositDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setCryptoDepositSearchField(e.value)}
              >
                <MenuItem
                  value="source_address"
                  className={
                    fiatSearchField === 'source_address'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  OrderId
                </MenuItem>
              </Menu>
            </div>

            <Button type="submit">Search</Button>

            <Button onClick={resetAllSearchFields}>Reset</Button>
          </div> */}
        </form>
      );
    }
  };

  return (
    <AdminScreen className={classes.container}>
      {/* <div className={classes.searchBarSection}></div> */}
      <div className={classes.header}>
        {renderSearchBar()}
        {/* <Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        /> */}
        {/* Button to Switch Fiat to Crypto +++++++++++++++++++ */}

        <button onClick={ChangeManager}>
          Switch to{' '}
          {Change === 'giftcard'
            ? 'Topup Transactions'
            : 'GiftCards Transactions'}
        </button>

        <button
          onClick={() => {
            Change === 'giftcard'
              ? exportToExcel(collections, 'Giftcards', 'csv')
              : exportToExcel(collections2, 'Mobile Topups', 'csv');
          }}
        >
          Export
        </button>

        {/* Despoite Ticker CreatinG Button++++++++++++ */}
        {Change === 'Buy' ? (
          <button
            onClick={() =>
              openModal(() => <DepositeTicket funcrun={reload2} />)
            }
          >
            Create Deposit Request
          </button>
        ) : null}

        {/* <img src={Filtericon} alt="filtericon" /> */}
      </div>

      <div className={classes.transaction_module}>
        {
          // ? FIAT TRANSACTIONS TABLE
          Change === 'giftcard' ? (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order ID</th>
                  <th>Status</th>
                  <th>GC Total Amount</th>
                  <th>GC Currency</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {collections &&
                  collections.map((values: any) => {
                    return (
                      <>
                        <tr key={values.transc_status}>
                          <td
                            onDoubleClick={() =>
                              copyText(DATE_CONV.dateConvert(values?.createdAt))
                            }
                          >
                            {DATE_CONV.dateConvert(values?.createdAt)}
                          </td>
                          <td onDoubleClick={() => copyText(values._id)}>
                            {values.encryptus_order_id}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(values?.gc_response.gc_delivery_status)
                            }
                          >
                            {values?.gc_response.gc_delivery_status.toUpperCase()}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(values?.gc_response.gc_total_amount)
                            }
                          >
                            {values?.gc_response?.gc_total_amount}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(values.gc_response?.gc_total_amount)
                            }
                          >
                            {values?.gc_response?.gc_currency_code}
                          </td>
                          <td>
                            <button
                              className={classes.DetailsBTN}
                              onClick={() =>
                                openModal(() => (
                                  <MobileToupsDetails
                                    collections={values}
                                    type="GC"
                                  />
                                ))
                              }
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div>
              {/* // ? Mobile Topup Transaction TABLE */}
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Order Id</th>
                    <th>Currency Amount</th>
                    <th>Local Currency</th>
                    <th>Transaction Id</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {/* CRypto deposit starts here */}
                  {collections2 &&
                    collections2.map((values: any) => {
                      return (
                        <tr key={values?.transc_status}>
                          <td
                            onDoubleClick={() =>
                              copyText(DATE_CONV.dateConvert(values?.createdAt))
                            }
                          >
                            {DATE_CONV.dateConvert(values?.createdAt)}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(values?.encryptus_order_id)
                            }
                          >
                            {values?.encryptus_order_id}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(
                                values?.mobile_topup_response
                                  .mt_localCurrencyAmount,
                              )
                            }
                          >
                            {
                              values?.mobile_topup_response
                                .mt_localCurrencyAmount
                            }
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(
                                values?.mobile_topup_response.mt_localCurrency,
                              )
                            }
                            className={classes.taggeremail}
                          >
                            {values?.mobile_topup_response.mt_localCurrency}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(
                                values?.mobile_topup_response.mt_transactionId,
                              )
                            }
                            className={classes.taggeremail}
                          >
                            {values?.mobile_topup_response.mt_transactionId}
                          </td>
                          <td>
                            <button
                              className={classes.view_btn}
                              onClick={() =>
                                openModal(() => (
                                  <MobileToupsDetails
                                    collections={values}
                                    type="MT"
                                  />
                                ))
                              }
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )
        }
      </div>
      {Change === 'giftcard' && <Paginate {...paginateProps} />}
      {Change !== 'giftcard' && <Paginate {...paginateProps2} />}
    </AdminScreen>
  );
}
