import '../styles/NewHomePage.scss';
import { useModal } from 'context/modal';
import AdminLogin from '../modals/Adminlogin';
import { useHistory } from 'react-router-dom';

export const NewHomePage = ({
  loginType = 'ADMIN',
}: {
  loginType?: string;
}) => {
  const { openModal } = useModal();
  const history = useHistory();
  return (
    <>
      <main>
        <div className="landing_screen">
          <h1 className="first_text">Welcome To Encryptus</h1>
          <p className="first_text2">
            {' '}
            {loginType === 'ADMIN' ? 'Admin Login' : 'Partner Login'}
          </p>
          <section className="Btn_div">
            <button
              className="become_client"
              onClick={() =>
                openModal(loginType === 'ADMIN' ? AdminLogin : AdminLogin)
              }
            >
              {loginType === 'ADMIN' ? 'Admin Login' : ''}
            </button>
            <button className="become_client" onClick={() => history.push('/')}>
              Go to User Section
            </button>
          </section>
        </div>
        {/* <img src={fading_image} alt="background_logo" className="fading_logo" /> */}
      </main>
    </>
  );
};
