import React, { useEffect, useState } from 'react';
import classes from '../../styles/KycIdVerification.module.scss';
import { useUser } from '../../context/user';
import Backdrop from 'assets/backdrop.png';
import SumsubWebSdk from '@sumsub/websdk-react';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

export default function Verifyid() {
  const { headers } = useUser();
  const history = useHistory();

  const [sumSubAccessToken, setSumSubAccessToken] = useState('');

  // ? Method to generate Access Token for SumSub session
  let generateAccessToken = async () => {
    try {
      const { data } = await axios.get(ROUTES.GENERATE_AT_SSB, {
        headers,
      });

      console.log({
        SSB: data,
      });

      if (data && data?.sumsub_CAT) {
        setSumSubAccessToken(data?.sumsub_CAT);
      } else {
        toast.error('Error Please try again later');
      }
    } catch (err) {
      console.log(err);
    }
  };
  // * End of generateAccessToken()

  // ? Method to handle the response from SumSub
  const sumSubResponseHandler = async (data: any, payload: any) => {
    console.log({
      message: 'SUMSUB RESPONSE HANDLER',
      data,
      payload,
    });

    if (
      payload?.reviewResult?.reviewAnswer === 'GREEN' &&
      payload?.reviewStatus === 'completed'
    ) {
      let body = payload;

      const { data } = await axios.patch(ROUTES.UPDATE_KYC_LOGS, body, {
        headers,
      });

      if (data && data?.status) {
        console.log({
          data,
          message: 'KYC LOG UPDATED',
        });

        toast.success('Welcome To Encryptus, You can Explore the App Now.');
        history.push('/dashboard');
      } else {
        console.log({
          data,
          message: 'KYC LOG NOT UPDATED',
        });
      }
    }
  };
  // * End of sumSubResponseHandler()

  // ? RENDERING METHODS

  // ? Method to render the SumSub Component
  const renderSumSubComponent = () => {
    return (
      <SumsubWebSdk
        accessToken={sumSubAccessToken}
        expirationHandler={() => Promise.resolve(sumSubAccessToken)}
        onMessage={sumSubResponseHandler}
        onError={(data) => console.log('onError', data)}
      />
    );
  };
  // * End of renderSumSubComponent()

  // ? Method to conditionally render the page content
  const renderPageContent = () => {
    if (sumSubAccessToken !== '') {
      return renderSumSubComponent();
    } else {
      return (
        <div className={classes.loaderSection}>
          <Loader type="ThreeDots" color={'#E8CB69'} height={15} />
        </div>
      );
    }
  };
  // * End of renderPageContent()

  // ? UseEffect Hook
  useEffect(() => {
    generateAccessToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        // height: sumSubAccessToken ? '' : '89.2vh',
        background: sumSubAccessToken ? 'white' : '#091A49',
      }}
    >
      {/* backdrop +++++++++++++++++++++++++++++++++ */}
      <div className={classes.backdrop}>
        <img src={Backdrop} alt="backdrop"></img>
      </div>

      <div
        className={classes.sumSubSection}
        // style={{
        //   // background: sumSubAccessToken ? 'white' : '$blue',
        // }}
      >
        {renderPageContent()}
      </div>
    </div>
  );
}
