import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/UserAgreement.scss';
import React, { useEffect } from 'react';

import ATable from '../components/table/AssetTable'

export const PublicDisclosurePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <div className="main_containter">
                <div className="container">
                    <div className="inner_div">
                        <h1>
                            Public <span style={{ color: '#fff' }}> Disclosure </span>
                        </h1>
                    </div>
                </div>
                <div className="container_first">
                    <p className="first_para">
                        Last revised: 15-January-2024
                    </p><br /><br />
                    <p className="second_para">
                        These Public Disclosures are reviewed annually, unless there is a change in applicable regulations or Encryptus’ business that requires implementation of an earlier update.
                        <br />

                    </p><br /><br />

                </div>
                <img src={UserAgreementTwo} alt="FooterImage" />
                <div className="container_second">
                    <ol className="ordered_list">


                        <h2><strong>1. SERVICES</strong></h2>
                        <p>Encryptus operates as a broker-dealer in relation to virtual assets and facilitates over-the-counter trading in virtual assets where professional and institutional investors are able to exchange fiat-virtual asset and vice versa. <br />
                            The list of virtual assets and products offered by Encryptus is constantly evolving and our website will be updated as we add Virtual Assets to our offering. Currently Encryptus trades in the following virtual assets:</p><br /> <br />
                        <ATable />

                        <p>Encryptus will provide in a prominent manner on its platform, information pertaining to virtual assets it trades in. This includes:</p>
                        <p> a. Name and symbol; <br />
                            b. date of issuance; <br />
                            c. market capitalization and fully diluted value; <br />
                            d. circulating supply including as a percentage of maximum total supply (if applicable); <br />
                            e. whether the virtual asset has been subject to an independent smart contract audit and date of the most recent audit; <br />
                            f. largest reduction in price from high to low stated as both an absolute amount and a percentage change, including when it occurred; and <br />
                            g. information that can be found via publicly available data sources such as CoinMarketCap.
                        </p> <br /> <br />


                        <h2><strong>2. PRICING:</strong></h2>
                        <p>Prices are determined by Encryptus, taking into account, the following:</p>
                        <p>a. External price sources from liquidity providers;</p>
                        <p>b. The current risk position of the firm; and</p>
                        <p>c. Per-client configurations, defined by Encryptus or, in some cases, at the request of the client.</p><br /><br />

                        <h2><strong>3. EXECUTION</strong></h2>
                        <p>Client orders will always be executed by Encryptus on execution venues which Encryptus chooses. Encryptus will deal with the client as a principal, and act as the counterparty to each trade. A copy of Encryptus’ order execution policy can be requested by emailing hello@encryptus.io.</p>
                        <p>In the event Encryptus routes twenty percent or more of its orders to any one liquidity source, the identity of such sources will be provided here:</p>
                        <p>a. Falcon X Limited</p>
                        <p>Encryptus does not introduce its clients to any other entities. Encryptus does not refer or introduce clients to other entities, including but not limited to, other VASPs.</p><br /><br />

                        <h2><strong>4. HOLDING AND CONTROLLING CLIENT MONEY</strong></h2>
                        <p>In the process of providing its services, Encryptus will hold or control Client Money. The Client Money will be held in a separate, dedicated Client Money Account, purely for such purpose (Client Account), with a designated Licensed Financial Institution, that are well versed with handling of Client Money. <br />
                            Encryptus has put in place internal systems to ensure that Client Money is identifiable and secure at all times and that its own proprietary funds will not be commingled with the Client Money.
                            Encryptus maintains systems and controls for identifying money which is not to be part of a Client Account and for transferring it without delay and Encryptus maintains adequate records of all payments of Client Money received by it.</p><br /><br />

                        <h2><strong>5. HOLDING AND CONTROLLING CLIENT ASSETS</strong></h2>
                        <p>In the process of providing its services, Encryptus will hold and control Client Assets on an incidental basis. It is clarified that Encryptus will not act as a virtual assets custodian i.e., at no point will Encryptus provide only custody services to the Client. In the process of handling Client Assets, Encryptus uses third-party service providers, such as Fireblocks which provides wallet services to Encryptus that enable Encryptus to hold and monitor Client Assets</p>
                        <p>ith respect to key arrangements, it is clarified that Encryptus may hold private keys to wallets in which Client Assets are held. Encryptus selects custody service providers only after having performed a solid due diligence. Custody arrangements entail an analysis on the regulatory status of the service provider, well-defined and structured internal approvals processes, as well as the adoption of strong risk management practices. Encryptus intends to appoint only entities ensuring asset segregation or equivalent safeguards for long-term custody purposes.</p><br /><br />

                        <h2><strong>6. PRIVACY</strong></h2>
                        <p>Encryptus appreciates you trusting us with your personal data. We are committed to protecting your privacy. For more details, you can access our Privacy Policy <a href="https://encryptus.io/legal/privacy">here</a>, and the Client Agreement <a href="https://encryptus.io/useragreement">here</a>.</p><br /><br />

                        <h2><strong>7. COMPLAINTS AND WHISTLEBLOWING</strong></h2>
                        <p>Encryptus is committed to promptly, fairly, consistently and efficiently resolving all complaints received by clients and/or other stakeholders. Encryptus views complaints as an opportunity to complete the loop, and continually improve the quality of its offerings. To that end, Encryptus has formulated and implemented comprehensive complaints handling procedures. If you seek to make any complaints, you can write to us at hello@encryptus.io.</p>
                        <p>Encryptus is committed to the highest standards of integrity, accountability, and transparency. Encryptus seeks to be responsive to all employees and stakeholders, be seen as a trusted organization that treats its employees with respect, demonstrate commitment to its own policies and applicable laws and regulations, and strengthen the “speak up” culture. An important aspect of this is a mechanism to enable voicing of concerns in a responsible and effective manner. If you are an employee of Encryptus or a stakeholder and  seek to raise any concerns, you can write to us at hello@encryptus.io anonymously. You can also write to hello@encryptus.io for a copy of our whistleblowing policy.</p><br /><br />

                        <h2><strong>8. RISK</strong></h2>
                        <p>Virtual Assets transactions are subject to several risks, including but not limited to market risks, credit risks, liquidity risks, and technology risks. Please read our detailed Risk Disclosure Statement <a href="https://encryptus.io/riskdisclosure">here</a>.</p><br /><br />

                        <h2><strong>9. CONFLICT OF INTEREST</strong></h2>
                        <p>Encryptus is committed to conducting its business according to the principle that it must manage conflicts of interest appropriately. At present there are no actual, identified conflicts of interest arising out of Encryptus’s Broker Dealer Services. Encryptus has, and will continue to identify potential conflicts of interest arising out of its outsourcing arrangements with group entities. Encryptus has put in place internal mechanisms and guardrails to ensure that no actual conflicts arise.</p><br /><br />

                        <h2><strong>10. ANTI BRIBERY AND CORRUPTION</strong></h2>
                        <p>It is the policy of Encryptus to conduct all its business in an honest and ethical manner. Company has a zero-tolerance approach to Bribery and Corruption and are committed to acting professionally, fairly and with integrity in all our business dealings and relationships, and for implementing and enforcing effective systems to counter Bribery.</p><br /><br />

                        <h2><strong>11. PAST CONVICTIONS</strong></h2>
                        <p>Encryptus and any member[s] of its Senior Management or Board have not faced any past convictions or prosecutions of, whether before the courts of the UAE or the courts of another jurisdiction.</p><br /><br />

                        <h2><strong>12. VIRTUAL ASSETS LISTING</strong></h2>
                        <p>Encryptus takes reasonable steps, including but not limited to, conducting relevant due diligence to ensure that all Virtual Assets meet the standards set forth hereinunder, prior to, and at all times, during which it provides the Services and/or is engaged in any other virtual asset activities in relation to such Virtual Assets.</p><br/>
                        <p>Encryptus will consider, amongst others, the following criteria in relation to all supported Virtual Assets:</p>
                        <ol>
                            <li>
                                <p>a. its market capitalisation, fully diluted value and liquidity, and whether such metrics have trended downwards over time;</p>
                            </li>
                            <li>
                                <p>b. its design, features and use cases, whether or not intended by the Issuer (as defined under the VARA Regulations) or relevant developers;</p>
                            </li>
                            <li>
                                <p>c. whether there are features which may materially affect Encryptus’ compliance with applicable laws including the VARA Regulations, AML/CFT, sanctions, securities, and intellectual property;</p>
                            </li>
                            <li>
                                <p>d. regulatory treatment by VARA and other appropriate authorities (including authorities outside of the Emirate of Dubai), in particular whether the issuance of the virtual asset has received any regulatory approvals;</p>
                            </li>
                            <li>
                                <p>e. whether a virtual asset is prohibited by VARA or any other appropriate authorities (both inside, or outside the UAE) in jurisdictions where Encryptus will provide ‘broker-dealer’ services, or equivalent activities, in relation to such virtual asset;</p>
                            </li>
                            <li>
                                <p>f. the security and immutability of the underlying DLT protocol;</p>
                            </li>
                            <li>
                                <p>g. its future development (e.g. “roadmap”) as communicated by the Issuer and/or relevant developers;</p>
                            </li>
                            <li>
                                <p>h. whether it may be susceptible to price manipulation for any reason and relevant mitigations that will be implemented by Encryptus;</p>
                            </li>
                            <li>
                                <p>i. whether potential or actual conflicts of interest that may arise, should Encryptus provide any Services in relation to the virtual asset and relevant mitigations;</p>
                            </li>
                            <li>
                                <p>j. the background of its Issuer (as defined under the VARA Regulations) including, but not limited to, relevant experience in the virtual asset sector and whether it has been subject to any investigations or claims in relation to fraud or deceit;</p>
                            </li>
                            <li>
                                <p>k. if the virtual asset represents rights to any other assets, the enforceability of such rights; and</p>
                            </li>
                            <li>
                                <p>l. sufficient assets are available to satisfy any obligation with respect to any of the Services.</p>
                            </li>
                        </ol>

                        <p>
                            Encryptus will ensure that the terms and conditions in relation to a Virtual Asset, reflect, to the extent possible, the operation of any existing underlying physical market and avoid adverse impacts to such market. <br/> <br/>  

                            Encryptus will review all terms and conditions in relation to a supported Virtual Asset on a periodic basis for appropriate correlation with any physical market, to ensure that such terms and conditions conform to standards and practices in that physical market. <br/> <br/>

                            Encryptus will regularly, and at a minimum, annually, assess that a Virtual Asset, continues to meet and comply with these Listing Standards. In this respect, Encryptus will ensure that (a) in the event of any major global market development, (b) other external factors which impact the ability of a Virtual Asset to comply with these Listing Standards, or (c) upon the publication of a notice by VARA in relation to a Virtual Asset’s ability to comply with these Listing Standards; immediately review the relevant Virtual Asset and reconfirm its suitability for being offered as part of its Services. <br/> <br/>

                            Encryptus reserves the right to set conditions in relation to a Virtual Asset that may be suspended, including where a Virtual Asset no longer meets these Listing Standards. Encryptus shall have and implement all necessary operational procedures and controls in the event such conditions are met. <br/> <br/>

                            Encryptus will notify VARA as soon as possible, after becoming aware that a Virtual Asset no longer meets these Listing Standards, and it will take such steps as VARA may direct, in order to minimise any adverse impact on any of Encryptus’ clients as a result. <br/> <br/>

                            Encryptus shall diligently and on a best-effort basis, maintain their books and records in their original form or native format, including those recorded on distributed ledgers, where appropriate. All records shall be retained for a minimum of eight (8) years and shall be available to inspection by VARA upon request. <br/> <br/>

                            Encryptus will ensure that these Listing Standards are made available on its website. In the event these Listing Standards are amended and/or updated, such amendments and/or updates will reflect on the website accordingly, immediately thereafter <br/> <br/>
                        </p>

                        <h2><strong>13. RESTRICTED JURISDICTIONS</strong></h2>
                        <p>Encryptus considers following list as Restricted Jurisdictions: Abkhazia, Afghanistan, Angola, Armenia, Belarus, Burma (Myanmar), Burundi, Canada, Central African Republic, Congo, Cuba, Ethiopia, Guinea-Bissau, Iran, Iraq, Ivory Coast, Japan, Lebanon, Liberia, Libya, Mali, Nicaragua, North Korea, Northern Cyprus, Russia, Sahrawi Arab Democratic, Somalia Republic, South Ossetia, South Sudan, Sudan, Syria, Ukraine, United Kingdom, United States of America, Venezuela, Yemen. This is applicable for customers whose residence address is in the United States, holding US passport or green card, with nationality as United  States or who was born in the United States) or any jurisdictions as may be designated by Encryptus from time to time.</p>

                    </ol>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};
