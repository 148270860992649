import io from 'socket.io-client';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { ANY, LPStreamRequest } from 'interfaces';
import { useUser } from './user';

export const FalconxContext = createContext({
  quote2: ANY,
  changeRequest2: async (data: LPStreamRequest, priceData: any) => ANY,
});

export const FalconxProvider: FC = ({ children }: any) => {
  const { user } = useUser();
  const [streamRequest, setStreamRequest] = useState<any>();
  const [streamQuote, setStreamQuote] = useState<any>();
  const [quote, setQuote] = useState<any>();
  const [PriceValues, setPriceValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (
      streamRequest &&
      streamRequest.quoteCurrency &&
      streamRequest.baseCurrency
    ) {
      connection();
    }
  }, [streamRequest]);

  const connection = () => {
    const socket = io.connect(
      `${process.env.REACT_APP_SERVER_HOST as string}`,
      {
        allowEIO3: false,
      },
    );

    socket.emit('streaming', streamRequest, user?.email);

    socket.on('streaming', (quotes: any) => {
      setQuote(quotes.data);
      setStreamQuote(quotes.data);
      return quotes;
    });

    socket.on('connect_error', (message: any) => {

      console.log(`Cannot connect to the server. Error: ${message}`);

      socket.close();
    });
  };

  const changeRequest2 = async (request: LPStreamRequest, priceData: any) => {
    setPriceValues(priceData);
    setStreamRequest(request);
  };

  return (
    <FalconxContext.Provider
      value={{
        quote2: quote,
        changeRequest2,
      }}
    >
      {children}
    </FalconxContext.Provider>
  );
};

export const useFalconx = () => useContext(FalconxContext);
