import React from 'react';
import classes from 'styles/Fiattransaction.module.scss';
import { copyText } from 'utils/CopyContent';

interface Props {
  collections: object[] | any;
  type: string;
}

function MobileToupsDetails(props: Props) {
  if (props.type === 'MT') {
    return (
      <>
        <h3 className={classes.heading}>Topup Information</h3>
        <div className={classes.container}>
          <div>
            <h4>Encryptus Reference ID</h4>
            <p
              onDoubleClick={() =>
                copyText(props.collections.encryptus_order_id)
              }
            >
              {props.collections?.encryptus_order_id}
            </p>
          </div>

          <div>
            <h4>Crypto Charged Quantity</h4>
            <p
              onDoubleClick={() =>
                copyText(props?.collections.crypto_charged_quantity)
              }
            >
              {props?.collections.crypto_charged_quantity}
            </p>
          </div>

          <div>
            <h4>Mobile Number</h4>
            <p onDoubleClick={() => copyText(props.collections?.mobile_number)}>
              {props.collections.mobile_number}
            </p>
          </div>

          <div>
            <h4>MT Face Value</h4>
            <p
              onDoubleClick={() =>
                copyText(props?.collections.mobile_topup_response?.mt_faceValue)
              }
            >
              {props?.collections?.mobile_topup_response?.mt_faceValue}
            </p>
          </div>

          <div>
            <h4>MT Fees</h4>
            <p
              onDoubleClick={() =>
                copyText(props?.collections.mobile_topup_response?.mt_fees)
              }
            >
              {props?.collections.mobile_topup_response?.mt_fees}
            </p>
          </div>

          <div>
            <h4>MT Transaction Id</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  props?.collections.mobile_topup_response?.mt_operatorTranscId,
                )
              }
            >
              {props?.collections.mobile_topup_response?.mt_operatorTranscId}
            </p>
          </div>

          <div>
            <h4>Partner User Id</h4>
            <p
              onDoubleClick={() => copyText(props?.collections.partner_userID)}
            >
              {props?.collections.partner_userID}
            </p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <h3 className={classes.heading}>GiftCards Information</h3>
      <div className={classes.container}>
        <div>
          <h4>Encryptus Reference ID</h4>
          <p
            onDoubleClick={() => copyText(props.collections.encryptus_order_id)}
          >
            {props.collections?.encryptus_order_id}
          </p>
        </div>

        <div>
          <h4>Status</h4>
          <p
            onDoubleClick={() =>
              copyText(props?.collections.gc_response.gc_delivery_status)
            }
          >
            {props?.collections.gc_response.gc_delivery_status.toUpperCase()}
          </p>
        </div>

        <div>
          <h4>Total Amount</h4>
          <p onDoubleClick={() => copyText(props.collections?.tagger_id)}>
            {props.collections.gc_response?.gc_total_amount}
          </p>
        </div>

        <div>
          <h4>Amopunt Charged</h4>
          <p
            onDoubleClick={() =>
              copyText(props?.collections.gc_response?.gc_amount_charged)
            }
          >
            {props?.collections.gc_response?.gc_amount_charged}
          </p>
        </div>

        <div>
          <h4>GC Country</h4>
          <p
            onDoubleClick={() =>
              copyText(props?.collections.gc_response?.gc_country)
            }
          >
            {props?.collections.gc_response?.gc_country}
          </p>
        </div>

        <div>
          <h4>GC Order Id</h4>
          <p
            onDoubleClick={() =>
              copyText(props?.collections.gc_response?.gc_order_id)
            }
          >
            {props?.collections.gc_response?.gc_order_id}
          </p>
        </div>

        <div>
          <h4>Partner User Id</h4>
          <p onDoubleClick={() => copyText(props?.collections.partner_userID)}>
            {props?.collections.partner_userID}
          </p>
        </div>
      </div>
    </>
  );
}

export default MobileToupsDetails;
