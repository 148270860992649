import AdminScreen from '../../components/AdminScreen';
import Button from '../../components/Button';
import { ROUTES } from '../../config';
import classes from '../../styles/Users.module.scss';
import { Link } from 'react-router-dom';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { FilterListItem } from '../../interfaces';
import useFilters from '../../hooks/useFilters';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { useState } from 'react';
import Filters from 'components/Filters';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';

const filterList: FilterListItem[] = [
  {
    field: 'kycStatus',
    label: 'KYC',
    options: [
      { value: true, label: 'Approved' },
      { value: false, label: 'Not Applied' },
    ],
  },
];

const submitHandler = () => {
  console.log('View Details button');
};

export default function Luser() {
  const { filters, updateFilters } = useFilters();
  const [searchValue, setSearchValue] = useState<String>('');
  const [searchField, setSearchField] = useState<any>('name'); // ? name || phone_no || email

  const [searchValueSubmitted, setSearchValueSubmitted] = useState<String>('');
  const [users, { paginateProps, loading }] = useAdminPaginate<any>(
    {
      route: ROUTES.L_USERS,
      params: {
        filters: {
          role: 'fintech',
          [searchField]: searchValueSubmitted,
          ...filters,
        },
      },
    },
    [searchValueSubmitted, filters],
  );

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    setSearchValueSubmitted(() => searchValue);
  };
  console.log({ users });

  // ? Method to render the dropdown title
  const renderDropdownTitle = () => {
    switch (searchField) {
      case 'name':
        return 'Name';

      case 'phone_no':
        return 'Phone No';

      case 'email':
        return 'Email';
    }
  };
  // * End of renderDropdownTitle();
  if (!users) {
    return (
      <h1>You Don't have acccess to See Users, Please Contact SuperAdmin</h1>
    );
  }

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.usersTitleSection}>
        <h3>Users</h3>
        <Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        />

        <form className={classes.userSearchForm} onSubmit={handleSearchSubmit}>
          <div className={classes.searchInputSection}>
            <input
              type="text"
              placeholder={
                searchField === 'name'
                  ? 'Search Name'
                  : searchField === 'email'
                  ? 'Search Email'
                  : 'Ph: 91 12345-67890'
              }
              onChange={(event) => setSearchValue(event?.target.value)}
            />
            <Menu
              menuButton={
                <MenuButton className={classes.dropdownBtn}>
                  <span>{renderDropdownTitle()}</span>
                  <img src={arrowDownBlack} alt="arrow-img" width={10} />
                </MenuButton>
              }
              transition
              menuClassName="drop-down"
              onItemClick={(e: any) => setSearchField(e.value)}
            >
              <MenuItem
                value="name"
                className={
                  searchField === 'name' ? 'dropdown-item-selected' : ''
                }
              >
                Name
              </MenuItem>
              <MenuItem
                value="phone_no"
                className={
                  searchField === 'phone_no' ? 'dropdown-item-selected' : ''
                }
              >
                Phone No.
              </MenuItem>
              <MenuItem
                value="email"
                className={
                  searchField === 'email' ? 'dropdown-item-selected' : ''
                }
              >
                Email
              </MenuItem>
            </Menu>
          </div>
          <Button loading={loading} type="submit">
            Search
          </Button>
        </form>
      </div>
      {users ? (
        <Table loading={loading} height="75%">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Transaction Fee</th>
              <th>KYC</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(
              ({
                application_id,
                email,
                kycStatus,
                name,
                phone_no,
                _id,
                transc_fee,
              }) => (
                <tr key={name}>
                  <td onDoubleClick={() => copyText(name)}>{name}</td>
                  <td onDoubleClick={() => copyText(email)}>{email}</td>
                  <td onDoubleClick={() => copyText(`${transc_fee}%`)}>
                    {transc_fee}%
                  </td>
                  <td
                    onDoubleClick={() =>
                      copyText(
                        kycStatus
                          ? 'Approved'
                          : !application_id
                          ? 'Not Applied'
                          : 'Rejected',
                      )
                    }
                    className={[
                      classes[
                        kycStatus
                          ? 'approved'
                          : !application_id
                          ? 'notApplied'
                          : 'rejected'
                      ],
                      classes.status,
                    ].join(' ')}
                  >
                    <p>
                      {kycStatus
                        ? 'Approved'
                        : !application_id
                        ? 'Not Applied'
                        : 'Rejected'}
                    </p>
                  </td>
                  <td>
                    <Link to={`/admin/lusersData/${_id}`}>
                      <Button
                        onClick={submitHandler}
                        size="s"
                        invert
                        className={classes.setFeesBtn}
                      >
                        View Details
                      </Button>
                    </Link>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      ) : (
        <h1>You Don't have Access to Users Please Contact SuperAdmin</h1>
      )}
      <div className={classes.foot}>
        <Paginate {...paginateProps} />
      </div>
    </AdminScreen>
  );
}
