import React, { useState } from 'react';
import Form from '../components/Form';
import classes from '../styles/Login.module.scss';
import { FormProp } from '../interfaces';
// import { ReactComponent as emailIcon } from '../assets/email.svg';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
// import { useUser } from '../context/user';
import { toast } from 'react-toastify';

interface Props {
  entries: FormProp[];
  title: string;
  onSubmit: (email: any) => any;
}

const schema = yup.object().shape({
  email: yup.string().email(),
  phone_no: yup
    .string()
    .min(8, 'Phone Number minimum length needs to be 8')
    .max(22, 'Phone Number maximum length is 22'),
});

export default function ChangeUserData({ entries, title, onSubmit }: Props) {
  // const { headers } = useUser();
  const { closeModal } = useModal();

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (formData: any) => {
    try {
      const key = Object.keys(formData);
      console.log({ key });
      let body = {};
      if (key[0] === 'phone_no') {
        body = {
          phone_no: formData.phone_no,
        };
      } else {
        body = {
          email: formData.email,
        };
      }

      onSubmit(body);
      closeModal();
    } catch (error: any) {
      console.log({ error });

      toast.error(error?.response?.data?.message || error?.response?.data);
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>{title}</h1>
      <Form
        setLoading={setLoading}
        onSubmit={handleSubmit}
        schema={schema}
        entries={entries}
      >
        <div className={classes.action}>
          <div />
          <Button loading={loading} type="submit">
            Submit
          </Button>
          <div />
        </div>
      </Form>
    </div>
  );
}
