// ? UTILS FILE TO CONVERT FIAT & CRYPTO CURRENCIES TO APPROPRIATE FORMAT

export const CURRENCY_CONV = {
  // ? Method to convert the Provided Crypto amount to appropriate format
  // * Required Format: 10.1234567.. -> 10.1234
  cryptoAmtConv: (cryptoAmt: any) => {
    let cryptoFloat = parseFloat(cryptoAmt);

    return cryptoFloat.toLocaleString(undefined, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  },

  // ? Method to convert the Provided Fiat amount to appropriate format
  // * Required Fomat: 12345.1234567.. -> 12,345.12
  fiatAmtConv: (fiatAmt: any) => {
    let fiatFloat = parseFloat(fiatAmt);

    return fiatFloat.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
};
