import React, { useState } from 'react';
import classes from 'styles/Depositcrypto.module.scss';
import ETH from 'assets/eth.png';
import bitcoin from '../assets/btc.png';
import usdt from '../assets/usdt.png';
import maticcoin from '../assets/cryptocurrencies/maticcoin.svg';
import usdc from '../assets/usdc.png';

import Cryptobar from './Cryptobar';
import { useModal } from 'context/modal';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import loader from '../assets/RollingLoader.gif';
import { toast } from 'react-toastify';

const crypto = [
  {
    logo: bitcoin,
    name: 'Bitcoin',
    symbol: 'btc',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: ETH,
    name: 'Ethereum',
    symbol: 'eth',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: usdt,
    name: 'Tether',
    symbol: 'usdt',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: usdc,
    name: 'USDC',
    symbol: 'usdc',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: maticcoin,
    name: 'MATIC',
    symbol: 'matic',
    rate: '$400',
    change: '+17.5%',
  },
];

function DepositCrypto() {
  const { headers } = useUser();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const handleApiRequest = (name: string, ct: string) => {
    axios
      .post(ROUTES.GENERATE_WALLET_ADDRESS, { cointype: ct }, { headers })
      .then((res) => {
        setIsLoading(false);
        openModal(() => <Cryptobar name={name} address={res.data.address} />);
        toast('Wallet address generated succesfully!', { type: 'success' });
      })
      .catch((e) => {
        setIsLoading(false);
        toast('There is some error!', { type: 'error' });
        console.error(e);
      });
  };
  return (
    <div className={classes.container}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img width={100} src={loader} alt="loader" />
          <div
            style={{
              paddingTop: '2rem',
              textAlign: 'center',
            }}
            className={classes.name}
          >
            Generating wallet address, please wait....
          </div>
        </div>
      ) : (
        <>
          <h1>Deposit</h1>
          <div className={classes.grid_div}>
            {crypto.map((values) => {
              return (
                <section
                  className={classes.Innerdiv}
                  key={values.name}
                  onClick={() => {
                    setIsLoading(true);
                    handleApiRequest(values.name, values.symbol);
                  }}
                >
                  <div className={classes.Imgsection}>
                    <div className={classes.logo}>
                      <img src={values.logo} alt={values.name} />
                    </div>
                    <div className={classes.name}>
                      <h3>{values.name}</h3>
                      <p>{values.symbol.toUpperCase()}</p>
                    </div>
                  </div>
                </section>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default DepositCrypto;
