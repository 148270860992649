import { useModal } from 'context/modal';
import React, { useEffect, useRef } from 'react';
import classes from 'styles/Cryptobar.module.scss';
import CryptoDepositAmount from './CryptoDepositeAmount';
const QRCode = require('qrcode');

interface Props {
  name: string;
  address: string;
}

const renderCopyBtn = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="20px"
      height="20px"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
      className={classes.copyBtn}
      onClick={() => {
        navigator.clipboard.writeText(data);
      }}
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M20 140 c0 -47 4 -80 10 -80 6 0 10 30 10 70 l0 70 70 0 c40 0 70 4 70 10 0 6 -33 10 -80 10 l-80 0 0 -80z" />
        <path d="M60 100 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z m140 0 l0 -60 -60 0 -60 0 0 60 0 60 60 0 60 0 0 -60z" />
      </g>
    </svg>
  );
};

function Cryptobar({ name, address }: Props) {
  const { openModal } = useModal();
  const canvas = useRef(null);
  useEffect(() => {
    QRCode.toCanvas(canvas.current, address, function (error: Error) {
      if (error) console.error(error);
      console.log('success!');
    });
  }, [address]);
  return (
    <div className={classes.Container}>
      <h1>Deposit {name}</h1>
      <canvas ref={canvas}></canvas>
      <h3>
        {address}
        <span>{renderCopyBtn(address)}</span>
      </h3>
      <h5>
        {name === 'Ethereum' || name === 'Bitcoin'
          ? '(NATIVE Token)'
          : '(ERC20 Token)'}
      </h5>
      <button
        onClick={() =>
          openModal(() => <CryptoDepositAmount name={name} address={address} />)
        }
      >
        Made A Deposit
      </button>
      <p>
        Send only {name} to this address. Sending any other token to this
        address will result in permanent loss of funds
      </p>
    </div>
  );
}

export default Cryptobar;
