import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClientScreen from '../../components/ClientScreen';
import classes from '../../styles/Checkout.module.scss';
import btn from '../../styles/ClientDashboard.module.scss';
import Loader from 'react-loader-spinner';
import Footer from 'components/common/Footer';
import { useSHdigital } from 'context/SHdigital';
import { useFalconx } from 'context/falconx';

export default function Checkout() {
  const [count, setCount] = useState(20);
  const [timedOut, setTimedOut] = useState(false);
  const [flag, setFlag] = useState(0);
  const history = useHistory();
  const location: any = useLocation();
  const [quntity, setQuantity] = useState<any>('');
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const { changeRequest, quote } = useSHdigital();
  const { changeRequest2, quote2 } = useFalconx();
  const [finalquote, setFinalQuote] = useState<any>();

  // eslint-disable-next-line
  const [datamain, setData] = useState<any>({});
  // const [loading, setLoading] = useState(false);
  const { headers } = useUser();
  const [txnLock, setTxnLock] = useState(false);

  useEffect(() => {
    let c = 20;
    let timerId = setInterval(() => {
      c -= 1;
      setCount(c);
      if (c === 0) {
        toast('Session timed out!', { type: 'error' });
        setTimedOut(true);
        setCount(20);
        clearInterval(timerId);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clearInterval(timerId);
    };
  }, [flag]);

  useEffect(() => {
    updateQuote();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (quote && (location.state.quoteCurrency !== 'USD' || location.state.baseCurrency === 'USDC')) {
      setFinalQuote(quote);
      setAmount(
        location.state.toggler === 'crypto'
          ? (quote.buyingPriceForUser * quote.quantity).toFixed(2)
          : location.state.quantity2,
      );
    }
    if (quote2 && (location.state.quoteCurrency === 'USD' || location.state.baseCurrency === 'USDC')) {
      setFinalQuote(quote2);
      setAmount(
        location.state.toggler === 'crypto'
          ? (quote2.buyingPriceForUser * quote2.quantity).toFixed(2)
          : location.state.quantity2,
      );
    }
  }, [location.state.quoteCurrency, quote, quote2]);

  const updateQuote = async () => {
    const value =
      location.state.toggler === 'crypto'
        ? location.state.quantity
        : location.state.cryptovalue;
    const quoteRequestStream = {
      baseCurrency: location.state.baseCurrency,
      quoteCurrency: location.state.quoteCurrency,
      quantity: Number(value),
    };
    if (location.state.quoteCurrency !== 'USD' || location.state.baseCurrency === 'USDC') {
      // @ts-ignore
      await changeRequest(quoteRequestStream, location.state.PriceValues);
    } else {
      await changeRequest2(quoteRequestStream, location.state.PriceValues);
    }
    setQuantity(
      location.state.toggler === 'crypto'
        ? location.state.quantity
        : location.state.cryptovalue.toFixed(5),
    );
  };

  const handleSubmit = async (quantity: number) => {
    try {
      const body = {
        baseCurrency: String(location.state.cointype.toUpperCase()),
        quoteCurrency: location.state.quoteCurrency,
        quantity: Number(quantity),
        maxSlippage: 4,
      };
      const { data } = await axios.post(ROUTES.Crypto_Fetch, body, {
        headers,
      });
      setData(data.currQuoteInfo);
      setAmount(data.currQuoteInfo.buyingPriceForUser);
      toast.success('Price Updated');
    } catch (err) {
      console.log(err);
    }
  };

  const handleRequest = async () => {
    setLoading(true);

    try {
      const body = {
        quoteId: location.state.quoteId,
        baseCurrency: location.state.baseCurrency,
        quoteCurrency: location.state.quoteCurrency,
        quantity: parseFloat(quntity),
        originalPrice: Number(finalquote.originalPrice * finalquote.quantity),
        displayedPrice: Number(
          (finalquote.buyingPriceForUser * finalquote.quantity).toFixed(2),
        ),
        unit_buyPrice_lp: Number(finalquote.unit_buyPrice_lp),
        unit_sellPrice_lp: Number(finalquote.unit_sellPrice_lp),
        maxSlippage: location.state.maxSlippage,
        side: 'Buy',
        clientReference: location.state.clientReference,
        walletAddress: location.state.walletAddress,
        associated_network:
          location.state.associated_network === 'tether'
            ? 'ethereum'
            : location.state.associated_network,
        cointype: location.state.cointype,
        commisions_applied: Number(finalquote.commisions_applied),
        margins_applied: Number(finalquote.margins_applied),
        transc_fee: Number(finalquote.transc_fee),
        fees_charged: Number(finalquote.fees_charged),
        revenue: Number(
          finalquote.buyingPriceForUser * finalquote.quantity -
          finalquote.originalPrice * finalquote.quantity,
        ),
      };
      if (location.state.quoteCurrency === 'USD' && location.state.baseCurrency !== 'USDC') {
        const { data } = await axios.post(ROUTES.Falconx_Place_Order, body, {
          headers,
        });
        if (data) {
          toast.success('Order Placed!');
          history.push({
            pathname: `/orders/${location.state.cointype}`,
          });
        }
      } else if (
        location.statue.quoteCurrency === 'EUR' ||
        location.statue.quoteCurrency === 'GBP' || location.state.baseCurrency === 'USDC'
      ) {
        const { data } = await axios.post(ROUTES.SHD_Order_Place_Api, body, {
          headers,
        });
        if (data) {
          toast.success('Order Placed!');
          history.push({
            pathname: `/orders/${location.state.cointype}`,
          });
        }
      }
      // if (data) {
      //   toast.success('Order Placed!');
      //   history.push({
      //     pathname: `/orders/${location.state.cointype}`,
      //   });
      // }
    } catch (err: any) {
      if (err.response.status === 500) {
        toast.warning(
          'Failed to place order at the moment...Please try again later!',
        );
        history.push('/dashboard');
      } else {
        toast.error(err.response?.data?.message);
        console.log(err);
        history.push('/dashboard');
      }
    }

    setLoading(false);
  };

  return (
    <>
      <ClientScreen title="Check Out">
        <div className={classes.Outline_Div}>
          <div className={classes.Inner_DIV}>
            {/* Value to Give+++++++++++++++++++ */}
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={classes.label_main}>You Give</label>
                <label className={classes.label_parent}>
                  {amount} {location.state.quoteCurrency}
                </label>
              </div>
            </div>
            {/* Value to Get++++++++++++++++++++ */}
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={classes.label_main}>You Get</label>
                <label className={classes.label_parent}>
                  {quntity}{' '}
                  {(location.state.cointype as string).toLocaleUpperCase()}
                </label>
              </div>
            </div>
          </div>
          {/* Address++++++++++++++++++++++ */}
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={classes.label_main}>Payout Address</label>
              <label className={classes.label_parent}>
                <br />
                {location.state.walletAddress}
              </label>
            </div>
          </div>
        </div>
        {/* New Button Divion++++++++++++++++++++++++ */}
        <div className={classes.btn_divs}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
            className={btn.button}
          >
            <button
              onClick={async () => {
                if (!txnLock) {
                  setTxnLock(true);
                  if (timedOut) {
                    await handleSubmit(quntity);
                    setFlag(flag + 1);
                    setTimedOut(false);
                    setTxnLock(false);
                  } else {
                    await handleRequest();
                    setFlag(flag + 1);
                  }
                }
              }}
              className={btn.buttonTransact}
              disabled={loading}
            >
              {loading ? (
                <Loader type="ThreeDots" color={'#FFFFFF'} height={15} />
              ) : timedOut ? (
                'Try Again!'
              ) : (
                `Confirm & Pay ${' '}(${count})`
              )}
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
            className={btn.button}
          >
            <button
              className={btn.buttonTransact}
              onClick={() => history.goBack()}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ClientScreen>
      <Footer />
    </>
  );
}
