import React from 'react';
import classes from '../../styles/SetFees.module.scss';
// import { useAdmin } from 'context/admin';

interface Props {
  user: any;
}

const Lfees = ({ user }: Props) => {
  // const { headers } = useAdmin();

  // console.log({
  //   headers,
  //   user,
  // });

  return (
    <div className={classes.container}>
      <div className={classes.marginContainer}>
        <h1>Set Fee Percentage</h1>
        <span>Current Fee Percentage: {user?.transc_fee}</span>
      </div>

      <div className={classes.verticalDivider} />

      <div className={classes.commissionsContainer}>
        <h1>Set Margin Number</h1>
        <span>Current Fee Percentage: {user?.margin_fee}</span>
      </div>
    </div>
  );
};

export default Lfees;
