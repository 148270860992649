import React, { useState } from 'react';
import Form from '../components/Form';
import classes from '../styles/Login.module.scss';
import { FormProp, LoginForm } from '../interfaces';
import { ReactComponent as emailIcon } from '../assets/email.svg';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import Login from './Login';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from '../context/user';
import { toast } from 'react-toastify';
import ForgotPasswordOTP from './ForgotPasswordOTP';

const entries: FormProp[] = [
  {
    name: 'email',
    label: 'E-Mail',
    icon: emailIcon,
    placeholder: '',
    type: 'email',
  },
];

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function ForgotPassword() {
  const { headers } = useUser();

  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();

  const handleSubmit = async (formData: LoginForm) => {
    try {
      let body = {
        userEmail: formData.email,
      };

      const { data } = await axios.post(ROUTES.FORGOT_PASSWORD, body, {
        headers,
      });

      console.log({
        responseData: data,
      });

      if (data && data?.status === 'success') {
        toast.success(data.message);

        openModal(() => <ForgotPasswordOTP email={formData.email} />);
      } else {
        console.log(data);

        toast.error(data.message);
      }
    } catch (error: any) {
      console.log({ error });

      toast.error(error?.response?.data?.message || error?.response?.data);
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Forgot Password</h1>
      <Form
        setLoading={setLoading}
        onSubmit={handleSubmit}
        schema={schema}
        entries={entries}
      >
        <div className={classes.action}>
          <div />
          <Button loading={loading} type="submit">
            Submit
          </Button>
          <div />
        </div>
        <p className={classes.linkText}>
          Back to <span onClick={() => openModal(Login)}>Login / Register</span>
        </p>
      </Form>
    </div>
  );
}
