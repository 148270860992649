import React from 'react';
import { ReactComponent as SVG } from '../assets/nodp.svg';
import { HTMLELEMENT } from '../interfaces';

export default function NoDp({ style, ...props }: HTMLELEMENT<HTMLDivElement>) {
  return (
    <div
      {...props}
      style={{
        background: '#E3E3E3',
        height: 52,
        width: 52,
        display: 'grid',
        placeItems: 'center',
        borderRadius: 999,
        ...style,
      }}
    >
      <SVG />
    </div>
  );
}
