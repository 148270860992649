import axios, { AxiosRequestHeaders } from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from '../config';
import { ANY, LoginForm, OTPForm } from '../interfaces';

const AdminContext = createContext({
  admin: ANY,
  adminlogin: async (
    data: LoginForm,
    ifLogged?: () => any,
    ifOTP?: (email: string) => any,
  ) => ANY,
  sendAdminOTP: async (formData: OTPForm, cb?: () => any) => ANY,
  logout: async () => ANY,
  headers: ANY,
  getAdmin: ANY,
  adminToken: ANY,
});

export const AdminProvider: FC = ({ children }) => {
  const history = useHistory();
  const [admin, setAdmin] = useState<any>();
  const [adminToken, setAdminToken] = useState<string | null>();
  const [headers, setHeaders] = useState<AxiosRequestHeaders>();

  useEffect(() => {
    setAdminToken(localStorage.getItem('admintoken'));
  }, []);

  useEffect(() => {
    setHeaders({ authorization: `Bearer ${adminToken}` });
  }, [adminToken]);

  useEffect(() => {
    if (
      headers &&
      headers.authorization.split(' ')[1] &&
      headers.authorization.split(' ')[1].length > 20
    ) {
      getAdmin();
    }
    // eslint-disable-next-line
  }, [headers]);

  // login function ++++++++++++++++++++++++++++++++++++++++++++++++

  const adminlogin = async (
    { email, password }: LoginForm,
    ifLogged?: () => any,
    ifOTP?: (email: string) => any,
  ) => {
    try {
      axios.defaults.withCredentials = true;
      const { data }: any = await axios.post(ROUTES.ADMIN_LOGIN, {
        email,
        password,
      });
      const { OTPSent, accessToken } = data;
      if (accessToken) {
        localStorage.setItem('admintoken', accessToken);
        setAdminToken(accessToken);
        ifLogged?.();
        history.push('/admin/dashboard');
        return { success: `Logged In!` };
      }
      if (OTPSent) {
        ifOTP?.(email);
        return { success: `OTP sent to ${email}` };
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  const sendAdminOTP = async (formData: OTPForm, cb?: () => any) => {
    try {
      const { data }: any = await axios.post(ROUTES.ADMIN_OTP, formData);
      if (data.accessToken) {
        localStorage.setItem('admintoken', data.accessToken);
        setAdminToken(data.accessToken);
        cb?.();

        history.push('/admin/dashboard');

        return { success: `Logged In!` };
      }
    } catch (error: any) {
      console.log({ error });
      return { error: error?.response?.data?.message };
    }
  };

  // admin data +++++++++++++++++++++++++++++++++++++++++
  const getAdmin = async () => {
    try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(ROUTES.Admin_data, { headers });
      console.log(data);
      if (!data) return;
      setAdmin(data);
      history.push('/admin/dashboard');
    } catch (error: any) {
      setAdmin(null);
      if (error?.response?.status === 401)
        console.log('problem coming get admin');
    }
  };

  // Logout ++++++++++++++++++++++++++++++++++++++++++++

  // eslint-disable-next-line
  const logout = async () => {
    localStorage.removeItem('admintoken');
    setAdminToken(undefined);
    try {
      axios.defaults.withCredentials = true;
      const { data }: any = await axios.post(
        ROUTES.Admin_logout,
        { email: admin.email },
        { headers },
      );
      console.log({ data });
      if (data.loggedOut) {
        toast.success('Logged Out');
        history.push('/');
      }
    } catch (error: any) {
      toast.warn('Please Enter Valid OTP');
      return { error: error?.response?.data?.message };
    }
  };

  return (
    <AdminContext.Provider
      value={{
        adminlogin,
        sendAdminOTP,
        getAdmin,
        admin,
        logout,
        headers,
        adminToken,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
