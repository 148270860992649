import Footer from 'components/common/Footer';
import React from 'react';
// import { useLocation } from 'react-router-dom';
import ClientScreen from '../../components/ClientScreen';
import Submitted from '../../components/Submitted';
// import useQuery from "hooks/useQuery";

export default function OrderSubmitted() {
  // const loaction = useLocation();
  return (
    <>
      <ClientScreen title="Check Out">
        <Submitted />
      </ClientScreen>
      <Footer />
    </>
  );
}
