import React, { useState } from 'react';
import { FormProp } from '../interfaces';
import * as yup from 'yup';
import Form from '../components/Form';
import Button from '../components/Button';
import classes from '../styles/SetTransactionLimit.module.scss';
import axios from 'axios';
import { ROUTES } from '../config';
import { toast } from 'react-toastify';
import { useModal } from '../context/modal';
import { useAdmin } from 'context/admin';

const entries: FormProp[] = [
  // {
  //   name: 'max_limit',
  //   label: 'Enter Maximum Limit',
  //   placeholder: 'Type Here',
  //   type: 'number',
  // },
  // {
  //   name: 'min_limit',
  //   label: 'Enter Minimum Limit',
  //   placeholder: 'Type Here',
  //   type: 'number',
  // },
];

const schema = yup.object().shape({
  // max_limit: yup.number().typeError('You must specify a number'),
  // min_limit: yup
  //   .number()
  //   .typeError('You must specify a number')
  //   .when('max', (max: number, schema: any) =>
  //     !!max
  //       ? schema.lessThan(max, 'Minimum shuould be lesser than maximum')
  //       : schema,
  //   ),
});

interface Props {
  max_limit: number;
  min_limit: number;
  symbol: string;
  isGlobalActive: boolean;
  reload: () => any;
}

export default function SetTransactionLimit({
  max_limit,
  min_limit,
  symbol,
  isGlobalActive,
  reload,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [maxLimit, setMaxLimit] = useState<any>();
  const [minLimit, setMinLimit] = useState<any>();

  const { headers } = useAdmin();
  const { closeModal } = useModal();

  const handleSubmit = async (formData: any) => {
    console.log({ maxLimit, minLimit });

    if (parseFloat(minLimit) < 0)
      return toast.error('Minimum limit cannot be negative');
    if (parseFloat(maxLimit) < 0)
      return toast.error('Maximum limit cannot be negative');
    if (parseFloat(minLimit) >= parseFloat(maxLimit))
      return toast.error('Minimum limit should be lesser than maximum limit');

    try {
      const payload = {
        max_limit: parseFloat(maxLimit),
        min_limit: parseFloat(minLimit),
        isGlobalActive,
      };

      const { data } = await axios.patch(
        ROUTES.UPDATE_COIN_GLOBAL(symbol),
        payload,
        { headers },
      );

      console.log({ data });

      if (data.status !== 'success') return;

      toast.success(`Transaction limit of ${symbol} changed!`);
      closeModal();
      await reload();
    } catch (err: any) {
      toast.error('Error setting Transaction Limit!');
    }
  };

  return (
    <div className={classes.container}>
      <h1>Set Transastion Limit For Bitcoin(BTC)</h1>
      <Form
        setLoading={setLoading}
        defaultValues={{ max_limit, min_limit }}
        entries={entries}
        onSubmit={handleSubmit}
        schema={schema}
      >
        <div className={classes.formInner}>
          <div className="InputField_container__1isqO">
            <label>Enter Maximum Limit</label>
            <div className="InputField_inputContainer__K4R_P ">
              <div className="InputField_icon__3VC9J"></div>
              <input
                name="max_limit"
                id="clean"
                placeholder="Type Here"
                type="number"
                step="any"
                value={maxLimit}
                onChange={(event) => setMaxLimit(event.target.value)}
              />
              <div className="InputField_tool__VEz1F"></div>
            </div>
          </div>
        </div>

        <div className={classes.formInner}>
          <div className="InputField_container__1isqO">
            <label>Enter Minimum Limit</label>
            <div className="InputField_inputContainer__K4R_P ">
              <div className="InputField_icon__3VC9J"></div>
              <input
                name="max_limit"
                id="clean"
                placeholder="Type Here"
                type="number"
                step="any"
                value={minLimit}
                onChange={(event) => setMinLimit(event.target.value)}
              />
              <div className="InputField_tool__VEz1F"></div>
            </div>
          </div>
        </div>

        <Button loading={loading}>Submit</Button>
      </Form>
    </div>
  );
}
