import { NewHomePage } from './NewHomePage';

function AdminLogin() {
  return (
    <main>
      <NewHomePage />
    </main>
  );
}

export default AdminLogin;
