import { useEffect, useState, memo } from 'react';
import classes from '../styles/ClientDashboard.module.scss';
import CurrencyDescription from 'components/CurrencyDescription';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import class1 from '../styles/BuyForm.module.scss';
import usePaginate from 'hooks/usePaginate';
import { useModal } from 'context/modal';
import { MainSteps } from 'modals/Stepbar';
import Checkout from 'pages/client/Checkout';
import // HubConnectionBuilder,
// HubConnectionState,
// ISubscription,
'@microsoft/signalr';
import { DebounceInput } from 'react-debounce-input';
import Loader from 'react-loader-spinner';
import '../utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import CurrencyList from 'currency-list';
import { currencyIcon } from './CurrencyIcon';
import { validateAddress } from 'utils/Validate';
import Switch from 'assets/switch.png';
import { useSHdigital } from 'context/SHdigital';
import { useFalconx } from 'context/falconx';

// ? List of all the currencies of the world
const currenciesList = ['USD', 'EUR', 'GBP'];

interface BuyCryptoFormProps {
  coin: any;
}

class QuoteCurrency {
  public static QUOTE_CURR: string = 'USD';
  public static quantity: number = 1;
}

function setQuoteCurrency(quoteCurrency: string) {
  QuoteCurrency.QUOTE_CURR = quoteCurrency;
}
function setQuantity(quantity: number) {
  QuoteCurrency.quantity = quantity;
}

const BuyCryptoForm = ({ coin }: BuyCryptoFormProps) => {
  // ?States-----------------------------------------
  const [agreed, setAgreed] = useState(false);
  const history = useHistory();
  const { headers } = useUser();
  const [quantity2, setQuantity2] = useState<any>(1);
  const [cryptovalue, setCryptovalue] = useState<number>(0);
  const [userBalance, setUserBalance] = useState<any>();
  const { openModal } = useModal();
  const [validation, setValidation] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [wallets] = usePaginate<any>({ route: ROUTES.FETCH_USER_WALLETS });
  const [Price, setPrice] = useState<number>(0);
  // eslint-disable-next-line
  const [PriceValues, setPriceValues] = useState<{ [key: string]: any }>({});
  const [quoteRequest, setQuoteRequest] = useState<{ [key: string]: any }>({});
  // const [lastSubscription, setLastSubscription] =
  //   useState<ISubscription<any> | null>(null);
  const [counter, setCounter] = useState(0);
  // const [firstUpdate, setFirstUpdate] = useState(false);
  const [unitCryptoPrice, setUnitCryptoPrice] = useState<number>(1);
  const [userUnitCryptoPrice, setUserUnitCryptoPrice] = useState<number>(1);
  const [toggler, setToggler] = useState<string>('crypto');
  // eslint-disable-next-line
  const [availableBalance, setAvailableBalance] = useState<any>(0);
  const { changeRequest, quote } = useSHdigital();
  const { changeRequest2, quote2 } = useFalconx();
  const [finalquote, setFinalQuote] = useState<any>();
  // state ends here+++++++++++

  // function sortingQuotes() {
  //   if (QuoteCurrency.QUOTE_CURR === 'EUR' || 'GBP') {
  //     // SHDIGITAL+++++++++++++
  //     console.log('sh');
  //     return setFinalQuote(quote);
  //   }
  //   if (QuoteCurrency.QUOTE_CURR === 'USD') {
  //     // falconx+++++++++++++++++++
  //     console.log('falconx');
  //     return setFinalQuote(quote2);
  //   }
  // }
  useEffect(() => {
    if (
      QuoteCurrency.QUOTE_CURR === 'EUR' ||
      QuoteCurrency.QUOTE_CURR === 'GBP' ||
      quoteRequest.baseCurrency === 'USDC'
    ) {
      setFinalQuote(quote);
    }
    if (
      QuoteCurrency.QUOTE_CURR === 'USD' &&
      quoteRequest.baseCurrency !== 'USDC'
    ) {
      setFinalQuote(quote2);
    }
    // eslint-disable-next-line
  }, [quote, quote2, QuoteCurrency.QUOTE_CURR]);

  // useEffect(() => {
  //   sortingQuotes();
  //   console.log(finalquote, quote2, QuoteCurrency.QUOTE_CURR);
  // }, [QuoteCurrency.QUOTE_CURR]);

  // balance update request++++++++++++++++++
  useEffect(() => {
    const funds = async () => {
      try {
        const { data } = await axios.get(ROUTES.Fiat_Balance, { headers });
        if (data.data.fiat_bal == null) {
          setAvailableBalance(CURRENCY_CONV.fiatAmtConv(0));
          return setUserBalance(0);
        }

        let fiatBalance = 0;

        data.data.fiat_bal.map((fiatData: any) => {
          return (fiatBalance += fiatData?.fiat_coins);
        });

        if (fiatBalance < 0.0001) {
          toast.warning('Insufficient Fiat balance');

          history.push('/dashboard');
        }

        setUserBalance(data.data.fiat_bal);
      } catch (err) {
        // console.log(err);
      }
    };
    funds();
    // eslint-disable-next-line
  }, [QuoteCurrency.QUOTE_CURR]);

  const UpdateValidation = () => {
    setValidation(true);
    streamQuote();
  };

  // qunantity input handler++++++++++++++++++++++++++++++++++
  const handleChange = async (event: any) => {
    return setQuantity(event.target.value);
  };

  const handleChange2 = async (event: any) => {
    const now = new Date().getTime();
    let time = 0;
    if (now - time < 2000) {
      return;
    }
    time = now;
    // setPrice(0);
    setQuantity2(event.target.value);
    if (Price === 0) {
      return;
    } else {
      setCryptovalue(event.target.value / unitCryptoPrice);
    }
  };

  // Code startinh Hrere++++++++++++++++++++++++++++++
  if (!coin?.name) {
    history.push('/dashboard');
  }
  // new updates +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // const baseUrl = process.env.COVARIO_BASE_URL;
  useEffect(() => {
    async function PriceUpdate() {
      try {
        const { data } = await axios.get(ROUTES.Price_Update_API, { headers });
        setPriceValues(data.sigr_payload);
      } catch (err) {
        console.log(err);
      }
    }
    PriceUpdate();
    // eslint-disable-next-line
  }, [QuoteCurrency.quantity]);

  useEffect(() => {
    if (coin) {
      setQuoteRequest({
        baseCurrency: coin.symbol,
        quoteCurrency: QuoteCurrency.QUOTE_CURR,
        quantity: Number(QuoteCurrency.quantity),
      });
    }
    // eslint-disable-next-line
  }, [PriceValues, QuoteCurrency.QUOTE_CURR]);

  const streamQuote = async (updatePrice = false) => {
    const value = toggler === 'crypto' ? QuoteCurrency.quantity : cryptovalue;
    const quoteRequestStream = {
      ...quoteRequest,
      quoteCurrency: QuoteCurrency.QUOTE_CURR,
      quantity: Number(value),
    };
    if (
      QuoteCurrency.QUOTE_CURR === 'EUR' ||
      QuoteCurrency.QUOTE_CURR === 'GBP' ||
      quoteRequest.baseCurrency === 'USDC'
    ) {
      // SHDIGITAL+++++++++++++
      const lpQuote = await changeRequest(quoteRequestStream, PriceValues);
      return lpQuote;
    } else {
      // falconx+++++++++++++++++++
      const lpQuote = await changeRequest2(quoteRequestStream, PriceValues);
      return lpQuote;
    }
    // @ts-ignore
    // const lpQuote = await changeRequest(quoteRequestStream, PriceValues);
    // return lpQuote;
  };

  useEffect(() => {
    const updateQuote = async () => {
      await streamQuote();
    };
    updateQuote();
    // eslint-disable-next-line
  }, [quoteRequest]);

  useEffect(() => {
    let flag = false;

    if (wallets && wallets.length > 0) {
      // eslint-disable-next-line
      wallets.map((wallet) => {
        if (coin.name.toLowerCase() === wallet.coin_type.toLowerCase()) {
          flag = true;
        }
      });

      if (!flag) {
        history.push('/wallets');

        toast.warn(
          `Please add a ${coin?.name} Wallet before proceeding to Purchase`,
        );
      }
    }

    if (counter > 9 && finalquote) {
      calculateUserUnitCryptoPrice(finalquote);
      // setPrice(quote?.buyingPriceForUser * quote.quantity ?? 0);
      streamQuote();
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        return prev > 9 ? 0 : prev + 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
      QuoteCurrency.QUOTE_CURR = 'USD';
      QuoteCurrency.quantity = 1;
    };
    // eslint-disable-next-line
  }, []);

  // ? Method to handle the Buy form submission
  const handleFormSubmit = () => {
    if (!agreed) {
      return toast('Please agree to the Terms and Conditions', {
        type: 'warning',
      });
    } else if (walletAddress === '') {
      return toast.warn(`Please select your ${coin?.name} Receiving Address!`);
    } else if (
      !validateAddress({ address: walletAddress, addressType: coin?.name })
    ) {
      return toast.warn('Invalid Wallet Address');
    } else if (QuoteCurrency.quantity < 0) {
      return toast.warn(`Please enter a positive value in Received Amount`);
    } else {
      // data passed here to next step with use location hook++++++++++++++++++++++++++
      if (validation && finalquote) {
        history.push({
          pathname: '/checkout',
          state: {
            // give:
            //   toggler === 'crypto'
            //     ? (quote.buyingPriceForUser * quote.quantity).toFixed(2)
            //     : quantity2,
            // buyingPriceForUser
            toggler,
            quantity2,
            cryptovalue,
            PriceValues,
            // get:
            //   toggler === 'crypto'
            //     ? QuoteCurrency.quantity
            //     : cryptovalue.toFixed(5),
            quoteId: finalquote.quoteId,
            baseCurrency: coin.symbol.toUpperCase(),
            quoteCurrency: QuoteCurrency.QUOTE_CURR,
            quantity: Number(QuoteCurrency.quantity),
            // originalPrice: Number(quote.originalPrice),
            // displayedPrice: Number(
            //   (quote.buyingPriceForUser * quote.quantity).toFixed(2),
            // ),
            // unit_buyPrice_lp: Number(quote.unit_buyPrice_lp),
            // unit_sellPrice_lp: Number(quote.unit_sellPrice_lp),
            maxSlippage: 4,
            // side: 'Buy',
            clientReference: finalquote.clientReference,
            walletAddress: walletAddress,
            associated_network: coin?.name.toLowerCase(),
            cointype: coin?.symbol.toLowerCase(),
            // commisions_applied: Number(quote.commisions_applied),
            // margins_applied: Number(quote.margins_applied),
            // transc_fee: Number(quote.transc_fee),
            // fees_charged: Number(quote.fees_charged),
            // revenue: Number(
            //   quote.buyingPriceForUser * quote.quantity - (quote.originalPrice * quote.quantity),
            // ),
          },
        });
        <Checkout></Checkout>;
      } else {
        openModal(() => <MainSteps validation={UpdateValidation} />);
      }
    }
  };
  // * End of handleFormSubmit();

  const calculateUserUnitCryptoPrice = (newQuote: any) => {
    setUserUnitCryptoPrice(newQuote.buyingPriceForUser);
  };

  useEffect(() => {
    if (finalquote !== undefined) {
      calculateUserUnitCryptoPrice(finalquote);
      setPrice(
        finalquote?.buyingPriceForUser
          ? finalquote.buyingPriceForUser * finalquote.quantity
          : 0,
      );
      setUnitCryptoPrice(finalquote.buyingPriceForUser);
      setCryptovalue(quantity2 / finalquote.buyingPriceForUser);
    }
    // eslint-disable-next-line
  }, [finalquote]);

  return (
    <div className={classes.exchange}>
      <div className={classes.exchangeTitle}>
        Buy {coin?.name}
        <button
          onClick={() => history.push('/dashboard')}
          className={classes.exchangeButton}
        >
          Select Other Cryptocurrency
        </button>
      </div>
      {toggler === 'crypto' ? (
        <div style={{ paddingTop: '8px' }} className={classes.boxExchange}>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Received Amount</label>
            </div>
            {/* input1+++++++++++++++++++++++++++++++++  */}
            <div className={class1.outline_div}>
              <DebounceInput
                minLength={1}
                debounceTimeout={500}
                className={class1.main_input}
                type="number"
                value={QuoteCurrency.quantity}
                onChange={(e) => {
                  handleChange(e);
                }}
                min={0}
              />
              <span>{coin?.name}</span>
            </div>
          </div>
          <button
            onClick={() => setToggler('fiat')}
            className={classes.icon_btn}
          >
            <img src={Switch} alt="switch" height={45} width={45} />
          </button>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Sent Amount</label>
            </div>
            {/* input2 +++++++++++++++++++++++++++ */}
            <div className={class1.outline_div}>
              {finalquote === '' ? (
                <div className={class1.loaderSection}>
                  <Loader
                    type="ThreeDots"
                    color={'#E8CB69'}
                    height={10}
                    width={225}
                  />
                </div>
              ) : (
                <>
                  <input
                    className={class1.main_input}
                    type="text"
                    value={
                      finalquote && finalquote.buyingPriceForUser
                        ? Price.toFixed(2)
                        : 0
                    }
                    onChange={() => {}}
                  />
                </>
              )}

              <Menu
                menuButton={
                  <MenuButton className={class1.currencyDropBtn}>
                    <span>{QuoteCurrency.QUOTE_CURR}</span>
                    <img src={Arrowdown} alt="arrow-img" />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                align={'end'}
              >
                {currenciesList.map((currency) => (
                  <MenuItem
                    className={`dropdown-item${
                      QuoteCurrency.QUOTE_CURR === currency ? '-selected' : ''
                    }`}
                    onClick={async () => {
                      setQuoteCurrency(currency);
                      await streamQuote(true);
                    }}
                  >
                    {currency}{' '}
                    {currencyIcon(CurrencyList.get(currency)?.symbol)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: '8px' }} className={classes.boxExchange}>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Sent Amount</label>
            </div>
            {/* input1+++++++++++++++++++++++++++++++++  */}
            <div className={class1.outline_div}>
              <DebounceInput
                minLength={1}
                debounceTimeout={500}
                className={class1.main_input}
                type="number"
                value={quantity2}
                onChange={async (e) => {
                  await handleChange2(e);
                  streamQuote(true);
                }}
                min={0}
              />
              <Menu
                menuButton={
                  <MenuButton className={class1.currencyDropBtn}>
                    <span>{QuoteCurrency.QUOTE_CURR}</span>
                    <img src={Arrowdown} alt="arrow-img" />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                align={'end'}
              >
                {currenciesList.map((currency) => (
                  <MenuItem
                    className={`dropdown-item${
                      QuoteCurrency.QUOTE_CURR === currency ? '-selected' : ''
                    }`}
                    onClick={async () => {
                      setQuoteCurrency(currency);
                      await streamQuote(true);
                    }}
                  >
                    {currency}{' '}
                    {currencyIcon(CurrencyList.get(currency)?.symbol)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          <button
            onClick={() => {
              setToggler('crypto');
              setPrice(0);
              // connection.stop();
              // connection.onclose(() => {
              //   streamQuote(true);
              // });
            }}
            className={classes.icon_btn}
          >
            <img src={Switch} alt="switch" height={45} width={45} />
          </button>
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={class1.mainlabel}>Received Amount</label>
            </div>
            {/* input2 +++++++++++++++++++++++++++ */}
            <div className={class1.outline_div}>
              {finalquote === '' ? (
                <div className={class1.loaderSection}>
                  <Loader
                    type="ThreeDots"
                    color={'#E8CB69'}
                    height={10}
                    width={225}
                  />
                </div>
              ) : (
                <>
                  <input
                    className={class1.main_input}
                    type="text"
                    value={
                      finalquote && finalquote.buyingPriceForUser
                        ? cryptovalue.toFixed(5)
                        : 0
                    }
                    onChange={() => {}}
                  />
                </>
              )}

              <span>{coin?.name}</span>
            </div>
          </div>
        </div>
      )}

      {/* Balance dIV++++++++++++++++++++++ */}
      <div className={class1.balanceDiv}>
        Available Balance :{' '}
        <span
          style={{
            fontWeight: 900,
          }}
        >
          {userBalance?.map((fiatBalance: any, index: number) => {
            return `${fiatBalance?.fiat_coins} ${fiatBalance?.fiat_curr} ${
              userBalance?.length !== index + 1 ? ` | ` : ''
            } `;
          })}
        </span>
      </div>
      <div className={classes.currencyDescContainer}>
        <CurrencyDescription
          icon={coin?.icon}
          title={coin?.name}
          symbol={coin?.symbol}
          price={
            finalquote && finalquote.buyingPriceForUser
              ? (`
              ${CurrencyList.get(QuoteCurrency.QUOTE_CURR)?.symbol}
              ${userUnitCryptoPrice}` as string)
              : '0'
          }
          message={'Last updated ' + counter + ' seconds ago'}
        />
      </div>
      <div className={classes.address}>
        <div className={classes.bars}>
          <div className={classes.inputs}>
            {/* select input +++++++++++++++++++++++++++ */}
            <div className={classes.labels}>
              <label className={class1.mainlabel}>
                Your {coin?.name} Receiving Address
              </label>
            </div>
            <div>
              {wallets && wallets.length === 0 ? (
                <div className={class1.outline_div}>
                  <input
                    className={class1.main_input2}
                    type="text"
                    placeholder="Enter Wallet Address Here"
                    value={walletAddress}
                    onChange={(e: any) => setWalletAddress(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <Menu
                    menuButton={
                      <MenuButton className={class1.dropBtn}>
                        <span>{walletAddress || 'Select Wallet'}</span>
                        <img src={Arrowdown} alt="arrow-img" />
                      </MenuButton>
                    }
                    transition
                    menuClassName="drop-down"
                    onItemClick={(e: any) => setWalletAddress(e.value)}
                  >
                    {
                      // eslint-disable-next-line
                      wallets.map((options, i) => {
                        if (
                          coin?.name?.toLowerCase() ===
                          options?.coin_type?.toLowerCase()
                        ) {
                          return (
                            <MenuItem
                              key={i}
                              value={options.walletAddress}
                              className={
                                walletAddress === options.walletAddress
                                  ? 'dropdown-item-selected'
                                  : ''
                              }
                            >
                              {`${
                                options.walletAddress.substr(0, 6) +
                                '...' +
                                options.walletAddress.substr(-6)
                              } ${options?.inputLabel ? '-' : ''} ${
                                options?.inputLabel ? options?.inputLabel : ''
                              }`}
                            </MenuItem>
                          );
                        } else {
                          <MenuItem>
                            Please Add Wallet to Access This Feature
                          </MenuItem>;
                        }
                      })
                    }
                  </Menu>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.agree}>
        <input
          checked={agreed}
          onChange={(e) => setAgreed(e.currentTarget.checked)}
          type="checkbox"
          name="agree"
          id="clean"
        />
        <p>
          I have read and accepted the{' '}
          <a href="/termsCondition" target="_blank">
            {' '}
            Terms and Conditions{' '}
          </a>{' '}
          of Encryptus.
        </p>
      </div>
      {/* button divsion+++++++++++++++ */}
      <div className={class1.btn_div}>
        <button className={class1.refresh_btn} onClick={handleFormSubmit}>
          Next
        </button>
      </div>
    </div>
  );
};

export default memo(BuyCryptoForm);
