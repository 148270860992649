import React from 'react';
import classes from '../styles/SellTransactionDetailsModal.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';

function SellTransactionDetails({ transactionData }: any) {
  console.log({
    transactionData,
  });

  return (
    <div className={classes.detailsContainer}>
      <h1>Crypto Sell Details</h1>
      <section className={classes.box}>
        <>
          <div>
            <h4>Transaction Reference ID</h4>
            <p
              onDoubleClick={() => copyText(transactionData?._id)}
              style={{
                paddingRight: '30px',
              }}
            >
              {transactionData?._id}
            </p>
          </div>
          <div>
            <h4>Customer Email</h4>
            <p onDoubleClick={() => copyText(transactionData?.tagger_email)}>
              {transactionData?.tagger_email}
            </p>
          </div>
          <div>
            <h4>Customer ID</h4>
            <p onDoubleClick={() => copyText(transactionData?.tagger_id)}>
              {transactionData?.tagger_id}
            </p>
          </div>
          <div>
            <h4>Logged IP</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.client_recorded_IP)
              }
            >
              {transactionData?.client_recorded_IP}
            </p>
          </div>
          <div>
            <h4>Transaction Status</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.transc_status?.toUpperCase())
              }
              className={
                transactionData?.transc_status === 'approved'
                  ? 'textStatusApproved'
                  : transactionData?.transc_status === 'pending'
                    ? 'textStatusPending'
                    : 'textStatusRejected'
              }
            >
              {transactionData?.transc_status?.toUpperCase()}
            </p>
          </div>
          <div>
            <h4>Approval Given</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.fin_approval_given
                    ?.toString()
                    ?.toUpperCase(),
                )
              }
              className={
                transactionData?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.fin_approval_given?.toString()?.toUpperCase()}
            </p>
          </div>
          <div>
            <h4>Approval Count</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.need_approval_count === -1
                    ? 'Not Set'
                    : transactionData?.need_approval_count,
                )
              }
            >
              {transactionData?.need_approval_count === -1
                ? 'Not Set'
                : transactionData?.need_approval_count}
            </p>
          </div>
          <div>
            <h4>Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  `${transactionData?.lp_order_response?.lp_executedQuantity} ${transactionData?.lp_order_response?.lp_baseCurrency}`,
                )
              }
            >
              {transactionData?.lp_order_response?.lp_executedQuantity}&nbsp;
              {transactionData?.lp_order_response?.lp_baseCurrency}
            </p>
          </div>
          <div>
            <h4>Liquidity Provider Original Price</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  `${CURRENCY_CONV.fiatAmtConv(
                    transactionData?.lp_original_price,
                  )} ${transactionData?.lp_order_response?.lp_quoteCurrency}`,
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.lp_original_price)}
              &nbsp;{transactionData?.lp_order_response?.lp_quoteCurrency}
            </p>
          </div>
          <div>
            <h4>User Selling Price</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  `${CURRENCY_CONV.fiatAmtConv(
                    transactionData?.user_selling_price,
                  )} ${transactionData?.lp_order_response?.lp_quoteCurrency}`,
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.user_selling_price)}
              &nbsp;{transactionData?.lp_order_response?.lp_quoteCurrency}
            </p>
          </div>
          <div>
            <h4>Profit Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  `${CURRENCY_CONV.fiatAmtConv(transactionData?.profit_amt)} ${transactionData?.profit_curr
                  }`,
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.profit_amt)}&nbsp;
              {transactionData?.profit_curr}
            </p>
          </div>
          <div>
            <h4>User IBAN</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.userbankAccNo)}
              style={{
                paddingRight: '30px',
              }}
            >
              {transactionData?.userbankAccNo}
            </p>
          </div>
          <div>
            <h4>User SWIFT/BIC</h4>
            <p onDoubleClick={() => copyText(transactionData?.userSwiftBIC)}>
              {transactionData?.userSwiftBIC}
            </p>
          </div>
          <div>
            <h4>Flagged</h4>
            <p
              onDoubleClick={() =>
                copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
              }
              className={
                transactionData?.flagged === false
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
            </p>
          </div>
          <div>
            <h4>Transaction Date - Time</h4>
            <p
              onDoubleClick={() =>
                copyText(DATE_CONV.dateTimeConvert(transactionData.createdAt))
              }
            >
              {DATE_CONV.dateTimeConvert(transactionData.createdAt)}
            </p>
          </div>
        </>
      </section>
    </div>
  );
}

export default SellTransactionDetails;
