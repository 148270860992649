import { useEffect, useState } from 'react';
import classes from 'styles/Depositcrypto.module.scss';
import ETH from 'assets/eth.png';
import bitcoin from '../assets/btc.png';
import usdt from '../assets/usdt.png';
import Matic from '../assets/matic.png';
import USDC from '../assets/usdc.png';
import WithdrawAmount from './WithdrawAmount';
import { useModal } from 'context/modal';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { toast } from 'react-toastify';
import { customAlphabet } from 'nanoid';

const crypto = [
  {
    logo: bitcoin,
    name: 'Bitcoin',
    symbol: 'BTC',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: ETH,
    name: 'Ethereum',
    symbol: 'ETH',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: usdt,
    name: 'Tether',
    symbol: 'USDT',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: USDC,
    name: 'USDC',
    symbol: 'USDC',
    rate: '$400',
    change: '+17.5%',
  },
  {
    logo: Matic,
    name: 'Matic',
    symbol: 'Matic',
    rate: '$400',
    change: '+17.5%',
  },
];

function WithdrawCrypto() {
  const { headers, user } = useUser();
  const { openModal } = useModal();

  const [cryptoBalance, setCryptoBalance] = useState<any>();

  const handleWithdraw = (name: string, symbol: string) => {
    const nanoid = customAlphabet('123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);
    let uuid = nanoid();

    // eslint-disable-next-line
    if (cryptoBalance[symbol] == 0) {
      return toast.warn(
        `Your ${symbol} balance is 0. Please select different crypto.`,
      );
    }
    const wallets = user.associated_wallets.filter((wallet: any) => {
      return (wallet?.coin_type as string).toLowerCase() === name.toLowerCase();
    });
    if (wallets.length === 0) {
      return toast.warn(`Please add ${name} wallet.`);
    }
    openModal(() => (
      <WithdrawAmount
        type="crypto"
        currency={name}
        symbol={symbol}
        uuid={uuid}
        wallets={wallets}
      />
    ));
  };

  // ? Method to fetch the CRYPTO  balance of the user
  const fetchUserCryptoBalance = async () => {
    let balanceObj: any = {};

    try {
      const { data: cryptoUSDTBalanceData } = await axios.post(
        ROUTES.Crypto_Balance,
        {
          crypto_currency: 'USDT',
        },
        { headers },
      );

      if (cryptoUSDTBalanceData?.data?.crypto_coins == null) {
        balanceObj.USDT = '0.0000';
      } else {
        balanceObj.USDT = CURRENCY_CONV.cryptoAmtConv(
          cryptoUSDTBalanceData.data.crypto_coins,
        );
      }

      const { data: cryptoETHBalanceData } = await axios.post(
        ROUTES.Crypto_Balance,
        {
          crypto_currency: 'ETH',
        },
        { headers },
      );

      if (cryptoETHBalanceData?.data?.crypto_coins == null) {
        balanceObj.ETH = '0.0000';
      } else {
        balanceObj.ETH = CURRENCY_CONV.cryptoAmtConv(
          cryptoETHBalanceData.data.crypto_coins,
        );
      }

      const { data: cryptoBTCBalanceData } = await axios.post(
        ROUTES.Crypto_Balance,
        {
          crypto_currency: 'BTC',
        },
        { headers },
      );
      if (cryptoBTCBalanceData?.data?.crypto_coins == null) {
        balanceObj.BTC = '0.0000';
      } else {
        balanceObj.BTC = CURRENCY_CONV.cryptoAmtConv(
          cryptoBTCBalanceData.data.crypto_coins,
        );
      }

      console.log({
        balanceObj,
      });

      setCryptoBalance(balanceObj);
    } catch (err) {
      console.log(err);
    }
  };
  // * End of fetchUserCryptoBalance();

  useEffect(() => {
    fetchUserCryptoBalance();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <>
        <h1>Withdraw</h1>
        <div className={classes.grid_div}>
          {crypto.map((values) => {
            return (
              <section
                className={classes.Innerdiv}
                key={values.name}
                onClick={() => {
                  //setIsLoading(true);
                  handleWithdraw(values.name, values.symbol);
                }}
              >
                <div className={classes.Imgsection}>
                  <div className={classes.logo}>
                    <img src={values.logo} alt={values.name} />
                  </div>
                  <div className={classes.name}>
                    <h3>{values.name}</h3>
                    <p>{values.symbol.toUpperCase()}</p>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </>
    </div>
  );
}

export default WithdrawCrypto;
