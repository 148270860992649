import React, { useEffect, useState } from 'react';
import AdminScreen from 'components/AdminScreen';
import classes from 'styles/Transactions.module.scss';
import Check from 'assets/ticklogo.png';
import Reject from 'assets/rejectlogo.svg';
import { useModal } from 'context/modal';
import { useAdmin } from 'context/admin';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import CryptoApproval from 'modals/CryptoApproval';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import SellTransactionDetails from 'modals/SellTransactionDetails';
import useAdminPaginate from 'hooks/useAdminPaginate';
import useFilters from 'hooks/useFilters';
import Paginate from './Paginate';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Button from './Button';

export default function SellTransactions() {
  const { openModal } = useModal();
  const { headers } = useAdmin();
  const { filters, updateFilters } = useFilters();
  const [emailSearch, setEmailSearch] = useState('');
  const [emailSearchField, setEmailSearchField] =
    useState<string>('tagger_email');
  const [collections, { reload, paginateProps }] = useAdminPaginate<any>(
    {
      route: ROUTES.Sell_Crypto,
      params: {
        filters: { tagger_email: emailSearch, ...filters },
      },
    },
    [filters],
  );
  const [status, setStatus] = useState('all');

  const approval = async (id: string, bool: boolean) => {
    try {
      const body = {
        final_approval: bool,
      };
      await axios.patch(ROUTES.SAdmin_SEllTransaction(id), body, {
        headers,
      });
      toast.success('Completed Successfully');
      reload();
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };
  // fiat render function++++++++++++++++
  const rerender = () => {
    reload();
  };

  // ????????????selection for filter switch ???????????????
  const changeSelections = (status: any) => {
    updateFilters?.('transc_status', status === 'all' ? '' : status);

    setStatus(status);
  };

  const renderStatusDropdownTitle = () => {
    switch (status) {
      case 'all':
        return 'All Transactions';

      case 'pending':
        return 'Pending';

      case 'approved':
        return 'Approved';

      case 'rejected':
        return 'Approved';

      case 'processing':
        return 'Processing';

      default:
        return 'All Transactions';
    }
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    reload();
    // eslint-disable-next-line
  }, []);

  const handlevalue = async (id: string, type: string) => {
    try {
      const body = {
        transac_type: type,
      };
      const data = await axios.patch(ROUTES.Processing_Route(id), body, {
        headers,
      });
      if (data) {
        toast.success('Success');
        reload();
      }
    } catch (err) {
      console.log(err);
      toast.warning('Not Set To Processing');
    }
  };

  // ? Methods to render the dropdown title
  const renderSearchDropdown = () => {
    switch (emailSearchField) {
      case 'tagger_email':
        return 'Email';
    }
  };

  // ? Method to Search Fiat Tickets
  const handleSearch = async (event: any) => {
    event.preventDefault();
    reload();
  };

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.header}>
        <h1 className={classes.sellHeading}>Crypto Sell Transaction</h1>

        <form className={classes.userSearchForm} onSubmit={handleSearch}>
          <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                placeholder="Search Email"
                value={emailSearch}
                onChange={(event) => setEmailSearch(event?.target.value)}
              />

              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderSearchDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setEmailSearchField(e.value)}
              >
                <MenuItem
                  value="tagger_email"
                  className={
                    emailSearchField === 'tagger_email'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Email
                </MenuItem>
              </Menu>
            </div>
            <Button type="submit">Search</Button>
          </div>
        </form>
        <div>
          <Menu
            menuButton={
              <MenuButton className={classes.statusDropdown}>
                <span>{renderStatusDropdownTitle()}</span>
                <img src={Arrowdown} alt="arrow-img" width={10} />
              </MenuButton>
            }
            transition
            menuClassName="drop-down"
            onItemClick={(e: any) => changeSelections(e.value)}
          >
            <MenuItem
              value="all"
              className={status === 'all' ? 'dropdown-item-selected' : ''}
            >
              All Transactions
            </MenuItem>
            <MenuItem
              value="pending"
              className={status === 'pending' ? 'dropdown-item-selected' : ''}
            >
              Pending
            </MenuItem>
            <MenuItem
              value="approved"
              className={status === 'approved' ? 'dropdown-item-selected' : ''}
            >
              Approved
            </MenuItem>
            <MenuItem
              value="rejected"
              className={status === 'rejected' ? 'dropdown-item-selected' : ''}
            >
              Rejected
            </MenuItem>
            <MenuItem
              value="processing"
              className={
                status === 'processing' ? 'dropdown-item-selected' : ''
              }
            >
              Processing
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className={classes.transaction_module2}>
        <table>
          <thead>
            <tr>
              <th style={{ width: '90px' }}>Dates</th>
              <th>Ticket ID</th>
              <th>Quantity</th>
              <th>User Selling Price</th>
              <th>Customer Email</th>
              <th>Details</th>
              <th>Transaction Status</th>
              <th>Process Txn</th>
            </tr>
          </thead>
          <tbody>
            {collections.map((values: any) => {
              //hash+++
              return (
                <>
                  <tr key={values.transc_status}>
                    <td
                      style={{ width: '90px !important' }}
                      onDoubleClick={() =>
                        copyText(DATE_CONV.dateConvert(values?.createdAt))
                      }
                    >
                      {DATE_CONV.dateConvert(values?.createdAt)}
                    </td>
                    <td onDoubleClick={() => copyText(values?._id)}>
                      {values?._id}
                    </td>
                    <td
                      onDoubleClick={() =>
                        copyText(
                          `${values?.lp_order_response?.lp_executedQuantity} ${values?.lp_order_response?.lp_baseCurrency}`,
                        )
                      }
                    >
                      {values?.lp_order_response?.lp_executedQuantity}&nbsp;
                      {values?.lp_order_response?.lp_baseCurrency}
                    </td>
                    <td
                      onDoubleClick={() =>
                        copyText(
                          `${CURRENCY_CONV.fiatAmtConv(
                            values?.user_selling_price,
                          )} ${values?.lp_order_response?.lp_quoteCurrency?.toUpperCase()}`,
                        )
                      }
                    >
                      {CURRENCY_CONV.fiatAmtConv(values?.user_selling_price)}
                      &nbsp;
                      {values?.lp_order_response?.lp_quoteCurrency?.toUpperCase()}
                    </td>
                    <td onDoubleClick={() => copyText(values?.tagger_email)}>
                      {values?.tagger_email}
                    </td>
                    <td>
                      <button
                        className={classes.DetailsBTN}
                        onClick={() =>
                          openModal(() => (
                            <SellTransactionDetails transactionData={values} />
                          ))
                        }
                      >
                        View Details
                      </button>
                    </td>
                    <td>
                      {values.transc_status === 'pending' ||
                      values.transc_status === 'processing' ? (
                        <div className={classes.btnDiv}>
                          <button
                            className={classes.btnapproval}
                            onClick={() =>
                              openModal(() => (
                                <CryptoApproval
                                  id={values._id}
                                  render={rerender}
                                />
                              ))
                            }
                          >
                            Set Approval Count
                          </button>
                          <button
                            className={classes.approvals}
                            onClick={() => approval(values._id, true)}
                          >
                            <img src={Check} alt="check" />
                          </button>
                          <button
                            className={classes.approvals}
                            onClick={() => approval(values._id, false)}
                          >
                            <img src={Reject} alt="reject" />
                          </button>
                        </div>
                      ) : (
                        <>
                          <h4 className={classes.title_success}>Processed</h4>
                        </>
                      )}
                    </td>
                    <td>
                      {values.transc_status === 'pending' ? (
                        <button
                          onClick={() =>
                            handlevalue(values._id, 'cryptorelease')
                          }
                          className={classes.ProcessBTN}
                        >
                          Under Process
                        </button>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <Paginate {...paginateProps} />
    </AdminScreen>
  );
}
