import React, { FC, useState } from 'react';
import classes from '../styles/Tabs.module.scss';

interface Tab {
  title: string;
  Component: FC;
}

interface Props {
  tabs: Tab[];
}

export default function Tabs({ tabs }: Props) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const { Component } = tabs[currentIdx];
  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        {tabs.map(({ title }, idx) => (
          <p
            onClick={() => setCurrentIdx(idx)}
            className={idx === currentIdx ? classes.active : ''}
            key={title}
          >
            {title}
          </p>
        ))}
      </div>
      <div className={classes.component}>
        <Component />
      </div>
    </div>
  );
}
