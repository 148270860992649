import React, { useEffect, useState } from 'react';
import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/Transactions.module.scss';
import Check from 'assets/ticklogo.png';
import Reject from 'assets/rejectlogo.svg';
import { useModal } from 'context/modal';
import { useAdmin } from 'context/admin';
import { API_HOST, ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import CryptoApprovalmodal from 'modals/CryptoApprovalmodal';
import CryptoDetails from 'modals/CryptoDetails';
import Switch from 'react-switch';
import Ethmodal from 'modals/Ethmodal';
import Button from 'components/Button';
import Filters from '../../components/Filters';
import { FilterListItem } from '../../interfaces';
import useFilters from '../../hooks/useFilters';
import { useSearch } from '../../context/search';
import useAdminPaginate from 'hooks/useAdminPaginate';
import Paginate from '../../components/Paginate';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
import exportFromJSON from 'export-from-json';

const filterList: FilterListItem[] = [
  {
    field: 'createdAt',
    label: 'Created',
    options: [
      { value: '7', label: 'Last 7 days' },
      { value: '15', label: 'Last 15 days' },
      { value: '30', label: 'Last 30 days' },
      { value: '90', label: 'Last 90 days' },
    ],
  },
];

export default function BuyTransactions() {
  const { openModal } = useModal();
  const { headers } = useAdmin();
  // States?
  // eslint-disable-next-line
  const [Change, setChange] = useState('Crypto');
  // eslint-disable-next-line
  const [Change2, setChange2] = useState('Buy');
  const { search } = useSearch();
  const { filters, updateFilters } = useFilters();

  const [status, setStatus] = useState('all');

  // ? Search Bar Values

  const [cryptoDepositSearchField, setCryptoDepositSearchField] =
    useState<string>('transaction_hash');
  const [cryptoDepositSearchValue, setCryptoDepositSearchValue] =
    useState<string>('');
  const [cryptoBuySearchValue, setCryptoBuySearchValue] = useState<string>('');
  const [cryptoBuySearchField, setCryptoBuySearchField] =
    useState<string>('tagger_email');
  const [fiatSearchField, setFiatSearchField] =
    useState<string>('tagger_email');
  const [fiatSearchValue, setFiatSearchValue] = useState<string>('');

  const [collections2, { reload: reload2, paginateProps: paginateProps2 }] =
    useAdminPaginate<any>(
      {
        route: ROUTES.CRYPTO_BUY_REPORT_TICKETS(),
        params: {
          filters: { [cryptoBuySearchField]: cryptoBuySearchValue, ...filters },
        },
      },
      [search, filters],
    );

  // Crypto Approval++++++++++++++++++++++++++++++++++
  const CryptoApproval = async (id: string, bool: boolean) => {
    try {
      const body = {
        final_approval: bool,
      };
      await axios.patch(ROUTES.Crypto_approval(id), body, {
        headers,
      });
      toast.success('Completed Successfully');
      reload2();
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  const Cryptorerender = () => {
    reload2();
  };

  // ????????????selection for filter switch ???????????????
  const changeSelections = (status: string) => {
    updateFilters?.('transc_status', status === 'all' ? '' : status);

    setStatus(status);
  };

  useEffect(() => {
    if (Change === 'Crypto' && Change2 === 'Buy') reload2();
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    reload2();
    // eslint-disable-next-line
  }, []);

  const ViewDetails = (transactionData: any) => {
    openModal(() => <CryptoDetails transactionData={transactionData} />);
  };

  const handleChecked = async (id: string, value: boolean) => {
    if (!value) {
      try {
        const body = {
          updateWS: true,
        };
        const { data } = await axios.patch(
          `${API_HOST}/encryptus/admin/update/cgticket/mlWalletStatus/${id}`,
          body,
          { headers },
        );
        reload2();
        toast.success('Liminal Wallet Status Updated To True');
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
    if (value) {
      try {
        const body = {
          updateWS: false,
        };
        const { data } = await axios.patch(ROUTES.Wallet_status(id), body, {
          headers,
        });
        reload2();
        toast.success('Liminal Wallet Status Updated To False');
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ? SEARCH MODULE

  // ? Method to reset all search fields
  const resetAllSearchFields = () => {
    setFiatSearchValue(() => '');
    setFiatSearchField(() => 'tagger_email');

    setCryptoBuySearchValue(() => '');
    setCryptoDepositSearchValue(() => '');
  };
  // * End of resetAllSearchFields();

  // ? Method to Search Fiat Tickets
  const handleFiatSearch = async (event: any) => {
    event.preventDefault();
  };
  // * End of handleFiatSearch()

  // ? Method to Search Crypto Buy Tickets
  const handleCryptoBuySearch = async (event: any) => {
    event.preventDefault();
    reload2();
  };
  // * End of handleCryptoBuySearch()

  // ? Method to Search Crypto Deposit Tickets
  const handleCryptoDepositSearch = async (event: any) => {
    event.preventDefault();
  };
  // * End of handleCryptoDepositSearch()

  // ? Methods to render the dropdown title
  const renderFiatDropdown = () => {
    switch (fiatSearchField) {
      case 'name':
        return 'Name';

      case 'phone_no':
        return 'Phone No';

      case 'tagger_email':
        return 'Email';
    }
  };

  const renderCryptoBuyDropdown = () => {
    switch (cryptoBuySearchField) {
      case 'tagger_email':
        return 'Email';

      case 'tagger_id':
        return 'Customer ID';

      case 'user_destination_walletAddress':
        return 'Wallet Address';
    }
  };

  const renderCryptoDepositDropdown = () => {
    switch (cryptoDepositSearchField) {
      case 'transaction_hash':
        return 'Transaction Hash';

      case 'source_address':
        return 'Source Address';
    }
  };

  const renderStatusDropdownTitle = () => {
    switch (status) {
      case 'all':
        return 'All Transactions';

      case 'pending':
        return 'Pending';

      case 'approved':
        return 'Approved';

      case 'rejected':
        return 'Rejected';

      case 'processing':
        return 'Processing';

      default:
        return 'All Transactions';
    }
  };
  // * End of renderDropdownTitle methods;

  const handlevalue = async (id: string, type: string) => {
    try {
      const body = {
        transac_type: type,
      };
      const data = await axios.patch(ROUTES.Processing_Route(id), body, {
        headers,
      });
      if (data) {
        toast.success('Success');

        reload2();
      }
    } catch (err) {
      console.log(err);
      toast.warning('Not Set To Processing');
    }
  };

  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  const renderSearchBar = () => {
    // ? FIAT
    if (Change === 'Fiat') {
      return (
        <form className={classes.userSearchForm} onSubmit={handleFiatSearch}>
          <h4>Fiat Transactions</h4>

          <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                placeholder="Search Email"
                value={fiatSearchValue}
                onChange={(event) => setFiatSearchValue(event?.target.value)}
              />

              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderFiatDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setFiatSearchField(e.value)}
              >
                <MenuItem
                  value="tagger_email"
                  className={
                    fiatSearchField === 'tagger_email'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Email
                </MenuItem>
              </Menu>
            </div>
            <Button type="submit">Search</Button>

            <Button onClick={resetAllSearchFields}>Reset</Button>
          </div>
        </form>
      );
    } else {
      // ? CRYPTO BUY
      if (Change2 === 'Buy') {
        return (
          <form
            className={classes.userSearchForm}
            onSubmit={handleCryptoBuySearch}
          >
            <h4>Crypto Buy</h4>

            <div className={classes.searchBarSection}>
              <div className={classes.searchInputSection}>
                <input
                  type="text"
                  value={cryptoBuySearchValue}
                  onChange={(event) =>
                    setCryptoBuySearchValue(() => event.target.value)
                  }
                  placeholder={
                    cryptoBuySearchField === 'tagger_email'
                      ? 'Search Email'
                      : cryptoBuySearchField === 'tagger_id'
                      ? 'Search ID'
                      : 'Search Wallet Address'
                  }
                />
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropdownBtn}>
                      <span>{renderCryptoBuyDropdown()}</span>
                      <img src={arrowDownBlack} alt="arrow-img" width={10} />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  onItemClick={(e: any) => setCryptoBuySearchField(e.value)}
                >
                  <MenuItem
                    value="tagger_email"
                    className={
                      fiatSearchField === 'tagger_email'
                        ? 'dropdown-item-selected'
                        : ''
                    }
                  >
                    Email
                  </MenuItem>
                  <MenuItem
                    value="tagger_id"
                    className={
                      fiatSearchField === 'tagger_id'
                        ? 'dropdown-item-selected'
                        : ''
                    }
                  >
                    Customer ID
                  </MenuItem>
                  <MenuItem
                    value="user_destination_walletAddress"
                    className={
                      fiatSearchField === 'user_destination_walletAddress'
                        ? 'dropdown-item-selected'
                        : ''
                    }
                  >
                    Wallet Address
                  </MenuItem>
                </Menu>
              </div>
              <Button type="submit">Search</Button>

              <Button onClick={resetAllSearchFields}>Reset</Button>
            </div>
          </form>
        );
      } else {
        // ? CRYPTO DEPOSIT
        return (
          <form
            className={classes.userSearchForm}
            onSubmit={handleCryptoDepositSearch}
          >
            <h4>Crypto Deposit</h4>

            <div className={classes.searchBarSection}>
              <div className={classes.searchInputSection}>
                <input
                  type="text"
                  value={cryptoDepositSearchValue}
                  onChange={(event) =>
                    setCryptoDepositSearchValue(event?.target.value)
                  }
                  placeholder={
                    cryptoDepositSearchField === 'transaction_hash'
                      ? 'Search Txn Hash'
                      : cryptoDepositSearchField === 'email'
                      ? 'source_address'
                      : 'Search Address'
                  }
                />

                <Menu
                  menuButton={
                    <MenuButton className={classes.dropdownBtn}>
                      <span>{renderCryptoDepositDropdown()}</span>
                      <img src={arrowDownBlack} alt="arrow-img" width={10} />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  onItemClick={(e: any) => setCryptoDepositSearchField(e.value)}
                >
                  <MenuItem
                    value="transaction_hash"
                    className={
                      fiatSearchField === 'transaction_hash'
                        ? 'dropdown-item-selected'
                        : ''
                    }
                  >
                    Transaction Hash
                  </MenuItem>
                  <MenuItem
                    value="source_address"
                    className={
                      fiatSearchField === 'source_address'
                        ? 'dropdown-item-selected'
                        : ''
                    }
                  >
                    Source Address
                  </MenuItem>
                </Menu>
              </div>

              <Button type="submit">Search</Button>

              <Button onClick={resetAllSearchFields}>Reset</Button>
            </div>
          </form>
        );
      }
    }
  };

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.searchBarSection}>{renderSearchBar()}</div>
      <div className={classes.header}>
        {
          <Menu
            menuButton={
              <MenuButton className={classes.statusDropdown}>
                <span>{renderStatusDropdownTitle()}</span>
                <img src={Arrowdown} alt="arrow-img" width={10} />
              </MenuButton>
            }
            transition
            menuClassName="drop-down"
            onItemClick={(e: any) => changeSelections(e.value)}
          >
            <MenuItem
              value="all"
              className={status === 'all' ? 'dropdown-item-selected' : ''}
            >
              All Transactions
            </MenuItem>
            <MenuItem
              value="pending"
              className={status === 'pending' ? 'dropdown-item-selected' : ''}
            >
              Pending
            </MenuItem>
            <MenuItem
              value="approved"
              className={status === 'approved' ? 'dropdown-item-selected' : ''}
            >
              Approved
            </MenuItem>
            <MenuItem
              value="rejected"
              className={status === 'rejected' ? 'dropdown-item-selected' : ''}
            >
              Rejected
            </MenuItem>
            <MenuItem
              value="processing"
              className={
                status === 'processing' ? 'dropdown-item-selected' : ''
              }
            >
              Processing
            </MenuItem>
          </Menu>
        }

        <button
          onClick={() => {
            exportToExcel(collections2, 'Buy Transactions', 'csv');
          }}
        >
          Export
        </button>

        <Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        />
      </div>
      {/* // ? CRYPTO BUY TABLE */}
      <div className={classes.transaction_module}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Customer Email</th>
              <th>Profit Amount</th>
              <th>Crypto Asset</th>
              <th>Details</th>
              <th>Transaction Status</th>
              <th>Liminal wallet fund</th>
              <th>Process Txn</th>
            </tr>
          </thead>
          <tbody>
            {collections2.map((values: any) => {
              return (
                <>
                  <tr key={values.transc_status}>
                    <td
                      onDoubleClick={() =>
                        copyText(DATE_CONV.dateConvert(values?.createdAt))
                      }
                    >
                      {DATE_CONV.dateConvert(values?.createdAt)}
                    </td>

                    <td onDoubleClick={() => copyText(values.tagger_email)}>
                      <p>{values.tagger_email}</p>
                    </td>

                    <td
                      onDoubleClick={() =>
                        copyText(
                          CURRENCY_CONV.fiatAmtConv(values.profit_amt) + ' USD',
                        )
                      }
                    >
                      {CURRENCY_CONV.fiatAmtConv(values.profit_amt)}{' '}
                      {values.profit_curr}
                    </td>
                    <td
                      className={classes.tagger_id}
                      onDoubleClick={() =>
                        copyText(
                          `${values?.lp_order_response?.lp_executedQuantity} ${values?.lp_order_response?.lp_baseCurrency}`,
                        )
                      }
                    >
                      {values?.lp_order_response?.lp_executedQuantity}{' '}
                      {values?.lp_order_response?.lp_baseCurrency}
                    </td>

                    <td>
                      <button
                        className={classes.view_btn}
                        onClick={() => ViewDetails(values)}
                      >
                        View Details
                      </button>
                    </td>

                    <td>
                      {values.transc_status === 'pending' ||
                      values.transc_status === 'processing' ? (
                        <div className={classes.btnDiv}>
                          <button
                            className={classes.btnapproval}
                            onClick={() =>
                              openModal(() => (
                                <CryptoApprovalmodal
                                  id={values._id}
                                  Cryptorender={Cryptorerender}
                                  flow="Buy"
                                />
                              ))
                            }
                          >
                            Set Approval Count
                          </button>
                          {values?.lp_order_response?.lp_baseCurrency ===
                          'ETH' ? (
                            <button
                              className={classes.approvals}
                              onClick={() =>
                                openModal(() => (
                                  <Ethmodal
                                    request={reload2}
                                    id={values._id}
                                    userDestinationWalletAddress={
                                      values?.user_destination_walletAddress
                                    }
                                    coinQty={
                                      values?.lp_order_response
                                        .lp_executedQuantity
                                    }
                                  />
                                ))
                              }
                            >
                              <img src={Check} alt="check" />
                            </button>
                          ) : (
                            <button
                              className={classes.approvals}
                              onClick={() => CryptoApproval(values._id, true)}
                            >
                              <img src={Check} alt="check" />
                            </button>
                          )}
                          <button
                            className={classes.approvals}
                            onClick={() => CryptoApproval(values._id, false)}
                          >
                            <img src={Reject} alt="reject" />
                          </button>
                        </div>
                      ) : (
                        <>
                          <h4 className={classes.title_success}>Processed</h4>
                        </>
                      )}
                    </td>
                    <td>
                      {values?.lp_order_response?.lp_baseCurrency === 'ETH' ? (
                        <p>-</p>
                      ) : (
                        <Switch
                          onChange={() =>
                            handleChecked(values._id, values.mlimWalletFunded)
                          }
                          checked={values.mlimWalletFunded}
                          onColor="#E8CB69"
                        />
                      )}

                      {/* +++++++++++++ */}
                    </td>
                    <td>
                      {values?.transc_status === 'pending' ? (
                        <button
                          onClick={() => handlevalue(values._id, 'cryptograb')}
                          className={classes.ProcessBTN}
                        >
                          Under Process
                        </button>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {Change !== 'Fiat' && Change2 === 'Buy' && (
        <Paginate {...paginateProps2} />
      )}
    </AdminScreen>
  );
}
