import axios from 'axios';
import { useUser } from 'context/user';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from '../../config';
import useQuery from '../../hooks/useQuery';

export default function VerifyEmail() {
  const token = useQuery('verifyToken');
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);
  // console.log({ token });
  const { headers } = useUser();
  const verifyToken = useCallback(async () => {
    try {
      if (!token) return;
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(
        ROUTES.VERIFY_EMAIL,
        {
          emailToken: token,
        },
        { headers },
      );
      // console.log({ data });
      toast.success(data.message);
      if (data.status === 'success') setVerified(true);
    } catch (error: any) {
      console.log({ error });
      setError(error?.response?.data?.message);
    }
    // eslint-disable-next-line
  }, [token, setError]);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, [verifyToken]);

  useEffect(() => {
    if (!error) return;
    toast.error(error);
  }, [error]);

  if (verified) return <Redirect to="/" />;

  return (
    <div>
      <p style={{ margin: 'auto', textAlign: 'center', padding: 20 }}>
        {verified ? 'VERIFIED' : error || 'VERIFYING EMAIL . . .'}
      </p>
    </div>
  );
}
