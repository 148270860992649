import React from 'react';
import classes from '../styles/CryptoDetails.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { Link } from 'react-router-dom';
// import { useModal } from 'context/modal';

type Props = {
  transactionData: object[] | any;
  type?: string;
};

function CryptoDepositDetailsModal({ transactionData, type }: Props) {
  console.log({
    transactionData,
  });

  const url = (tagger_id: string) => {
    // closeModal();
    return `/admin/user/${tagger_id}`;
  };

  if (type === 'partners') {
    return (
      <div className={classes.detailsContainer}>
        <h1>Crypto Deposit Details</h1>
        <section className={classes.box}>
          <>
            <div>
              <h4>Tagger Email</h4>
              <p onDoubleClick={() => copyText(transactionData?.tagger_email)}>
                {transactionData?.tagger_email}
              </p>
            </div>

            <div>
              <h4>Transaction Status</h4>
              <p
                onDoubleClick={() =>
                  copyText(transactionData?.transc_status?.toUpperCase())
                }
                className={
                  transactionData?.transc_status === 'approved'
                    ? 'textStatusApproved'
                    : transactionData?.transc_status === 'pending'
                    ? 'textStatusPending'
                    : 'textStatusRejected'
                }
              >
                {transactionData?.transc_status?.toUpperCase()}
              </p>
            </div>

            {/* <div>
            <h4>Approval Given</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.fin_approval_given
                    ?.toString()
                    ?.toUpperCase(),
                )
              }
              className={
                transactionData?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.fin_approval_given?.toString()?.toUpperCase()}
            </p>
          </div> */}

            {/* <div>
            <h4>Approval Count</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.need_approval_count === -1
                    ? 'Not Set'
                    : transactionData?.need_approval_count,
                )
              }
            >
              {transactionData?.need_approval_count === -1
                ? 'Not Set'
                : transactionData?.need_approval_count}
            </p>
          </div> */}

            <div>
              <h4>Amount</h4>
              <p
                onDoubleClick={() =>
                  copyText(
                    CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt),
                  )
                }
              >
                {CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt)}
              </p>
            </div>

            <div>
              <h4>Crypto Asset</h4>
              <p
                onDoubleClick={() =>
                  copyText(transactionData?.received_amt_curr)
                }
              >
                {transactionData?.received_amt_curr}
              </p>
            </div>

            <div>
              <h4>Source Address</h4>
              <p
                onDoubleClick={() => copyText(transactionData?.source_address)}
                style={{
                  paddingRight: '30px',
                }}
              >
                {transactionData?.source_address}
              </p>
            </div>

            <div
              style={{
                paddingRight: '10px',
              }}
            >
              <h4>Transaction Hash</h4>
              <p
                onDoubleClick={() =>
                  copyText(transactionData?.transaction_hash)
                }
              >
                {transactionData?.transaction_hash}
              </p>
            </div>

            <div>
              <h4>Flagged</h4>
              <p
                onDoubleClick={() =>
                  copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
                }
                className={
                  transactionData?.flagged === false
                    ? 'textStatusApproved'
                    : 'textStatusRejected'
                }
              >
                {!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
              </p>
            </div>

            <div>
              <h4>Transaction Date - Time</h4>
              <p
                onDoubleClick={() =>
                  copyText(DATE_CONV.dateTimeConvert(transactionData.createdAt))
                }
              >
                {DATE_CONV.dateTimeConvert(transactionData.createdAt)}
              </p>
            </div>
          </>
        </section>
      </div>
    );
  }

  // const { closeModal } = useModal();

  return (
    <div className={classes.detailsContainer}>
      <h1>Crypto Deposit Details</h1>
      <section className={classes.box}>
        <>
          <div>
            <h4>Transaction Reference ID</h4>
            <p
              onDoubleClick={() => copyText(transactionData?._id)}
              style={{
                paddingRight: '30px',
              }}
            >
              {transactionData?._id}
            </p>
          </div>

          <div>
            <h4>Customer ID</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.tagger_id)}
              className={classes.customer_id}
            >
              <Link to={url(transactionData?.tagger_id)}>
                {transactionData?.tagger_id}
              </Link>
            </p>
          </div>

          <div>
            <h4>Tagger Email</h4>
            <p onDoubleClick={() => copyText(transactionData?.tagger_email)}>
              {transactionData?.tagger_email}
            </p>
          </div>

          <div>
            <h4>Logged IP</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.client_recorded_IP)
              }
            >
              {transactionData?.client_recorded_IP}
            </p>
          </div>

          <div>
            <h4>Transaction Status</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.transc_status?.toUpperCase())
              }
              className={
                transactionData?.transc_status === 'approved'
                  ? 'textStatusApproved'
                  : transactionData?.transc_status === 'pending'
                  ? 'textStatusPending'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.transc_status?.toUpperCase()}
            </p>
          </div>

          <div>
            <h4>Approval Given</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.fin_approval_given
                    ?.toString()
                    ?.toUpperCase(),
                )
              }
              className={
                transactionData?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.fin_approval_given?.toString()?.toUpperCase()}
            </p>
          </div>

          <div>
            <h4>Approval Count</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.need_approval_count === -1
                    ? 'Not Set'
                    : transactionData?.need_approval_count,
                )
              }
            >
              {transactionData?.need_approval_count === -1
                ? 'Not Set'
                : transactionData?.need_approval_count}
            </p>
          </div>

          <div>
            <h4>Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt),
                )
              }
            >
              {CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt)}
            </p>
          </div>

          <div>
            <h4>Crypto Asset</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.received_amt_curr)}
            >
              {transactionData?.received_amt_curr}
            </p>
          </div>

          <div>
            <h4>Source Address</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.source_address)}
              style={{
                paddingRight: '30px',
              }}
            >
              {transactionData?.source_address}
            </p>
          </div>

          <div
            style={{
              paddingRight: '10px',
            }}
          >
            <h4>Transaction Hash</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.transaction_hash)}
            >
              {transactionData?.transaction_hash}
            </p>
          </div>

          <div>
            <h4>Flagged</h4>
            <p
              onDoubleClick={() =>
                copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
              }
              className={
                transactionData?.flagged === false
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
            </p>
          </div>

          <div>
            <h4>Transaction Date - Time</h4>
            <p
              onDoubleClick={() =>
                copyText(DATE_CONV.dateTimeConvert(transactionData.createdAt))
              }
            >
              {DATE_CONV.dateTimeConvert(transactionData.createdAt)}
            </p>
          </div>
        </>
      </section>
    </div>
  );
}

export default CryptoDepositDetailsModal;
