import { createContext, FC, useContext, useState } from 'react';
import Modal from '../components/Modal';

type ComponentType = FC | undefined;
interface ComponentState {
  Component: ComponentType;
}
interface Props {
  Component: ComponentType;
  openModal: (modal: ComponentType) => void;
  closeModal: () => void;
}

const ModalContext = createContext<Props>({
  Component: undefined,
  openModal: (modal) => null,
  closeModal: () => null,
});

export const ModalProvider: FC = ({ children }) => {
  const [ModalComponent, setModalComponent] = useState<ComponentState>({
    Component: undefined,
  });
  return (
    <ModalContext.Provider
      value={{
        Component: ModalComponent.Component,
        openModal: (Component) => setModalComponent({ Component }),
        closeModal: () => setModalComponent({ Component: undefined }),
      }}
    >
      <>
        <Modal />
        {children}
      </>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
