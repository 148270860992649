import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import classes from '../../styles/AdminDetailedDashboard.module.scss';
import Search from 'assets/search.svg';
import { kmFormatter } from 'utils/format';
import { ROUTES } from 'config';
import axios from 'axios';
import exportFromJSON from 'export-from-json';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useAdmin } from 'context/admin';
import DoughnutChart from 'components/charts/DoughnutChart';
import Piechart from 'components/charts/PieChartComponent';
import TreeMap from 'components/charts/TreeMap';
import Arrowdown from 'assets/Arrow - Down 4.png';
import Button from 'components/Button';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

export default function AdminDetailedDashboard() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterRange, setFilterRange] = useState<string>('');
  const [totalFiatDeposits, setTotalFiatDeposits] = useState([]);
  // eslint-disable-next-line
  const [totalFiatWithdraw, setTotalFiatWithdraw] = useState<any>([]);
  const [usersDetails, setUsersDetails] = useState({
    totalActiveUsers: 0,
    totalCompleteOnboardings: 0,
    totalIncompleteOnboardings: 0,
  });
  const [volumes, setVolumes] = useState({
    averageVolume: 0,
    totalBuyVolume: 0,
    totalSellVolume: 0,
  });
  const [levelTypeVolume, setLevelTypeVolume] = useState([]);
  const [cryptoDetails, setCryptoDetails] = useState([]);
  const [sortBy, setSortBy] = useState<string>('');
  const [exportItem, setExportItem] = useState<string>(
    'crypto_deposit_withdraw',
  );
  const [selectedFiatCurrency, setSelectedFiatCurrency] =
    useState<string>('USD');
  const [fiatChartData, setFiatChartData] = useState<any>([]);
  // eslint-disable-next-line
  const [exportCGVolumeData, setExportCGVolumeData] = useState([]);
  const [cryptoGrabPairs, setCryptoGrabPairs] = useState([]);
  const [cryptoSellPairs, setCryptoSellPairs] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const { headers } = useAdmin();
  const range = {
    // Today: [moment(), moment()],
    // Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    // 'Last 14 Days': [moment().subtract(13, 'days'), moment()],
    // 'Last 28 Days': [moment().subtract(27, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last 90 Days': [moment().subtract(89, 'days'), moment()],
    // 'This Week (starts Sunday)': [
    //   moment().startOf('week'),
    //   moment().endOf('week'),
    // ],
    // 'This Week to date (starts Sunday)': [moment().startOf('week'), moment()],
    // 'Last Week': [
    //   moment().subtract(1, 'weeks').startOf('week'),
    //   moment().subtract(1, 'weeks').endOf('week'),
    // ],
    // 'This Week (starts Monday)': [
    //   moment(moment()).isoWeekday(1),
    //   moment(moment()).isoWeekday(7),
    // ],
    // 'This Week to Date (starts Monday)': [
    //   moment(moment()).isoWeekday(1),
    //   moment(),
    // ],
    // 'Last Week (starts Monday)': [
    //   moment(moment()).startOf('week').isoWeekday(1),
    //   moment(moment()).subtract(1, 'week').endOf('week').isoWeekday(7),
    // ],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'This Month to Date': [moment().startOf('month'), moment()],
    // 'Last Month': [
    //   moment().subtract(1, 'month').startOf('month'),
    //   moment().subtract(1, 'month').endOf('month'),
    // ],
    // 'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
    // 'This Quarter to Date': [moment().startOf('quarter'), moment()],
    // 'Last Quarter': [
    //   moment().subtract(1, 'quarter').startOf('quarter'),
    //   moment().subtract(1, 'quarter').endOf('quarter'),
    // ],
    // 'Last Year': [
    //   moment().subtract(1, 'year').startOf('year'),
    //   moment().subtract(1, 'year').endOf('year'),
    // ],
    // 'This Year': [moment().startOf('year'), moment().endOf('year')],
    // 'This Year to Date': [moment().startOf('year'), moment()],
  };

  useEffect(() => {
    fetchReport();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFiatChartData(selectedFiatCurrency);
    // eslint-disable-next-line
  }, [totalFiatDeposits, totalFiatWithdraw]);

  const fetchReport = async () => {
    const { data } = await axios.get(ROUTES.DETAILED_REPORT(), {
      headers,
    });
    setTotalFiatDeposits(data.totalFiatDeposit);
    setTotalFiatWithdraw(data.totalFiatWithdraw);
    setUsersDetails(data.usersDetails);
    setVolumes(data.volumes);
    setCryptoDetails(data.cryptoDetails);
    setExportCGVolumeData(data.exportCGVolumeData);
    setCryptoGrabPairs(data.cryptoGrabPairs);
    setCryptoSellPairs(data.cryptoSellPairs);
    setLevelTypeVolume(data.volumeData);
    setTotalRevenue(data.totalRevenue);
  };

  const fetchReportFiltered = async (startDate: any, endDate: any) => {
    const { data } = await axios.get(
      ROUTES.DETAILED_REPORT_FILTERED(startDate, endDate),
      {
        headers,
      },
    );
    setTotalFiatDeposits(data.totalFiatDeposit);
    setTotalFiatWithdraw(data.totalFiatWithdraw);
    setUsersDetails(data.usersDetails);
    setVolumes(data.volumes);
    setCryptoDetails(data.cryptoDetails);
    setExportCGVolumeData(data.exportCGVolumeData);
    setCryptoGrabPairs(data.cryptoGrabPairs);
    setCryptoSellPairs(data.cryptoSellPairs);
    handleFiatChartData(selectedFiatCurrency);
  };

  const handleEvent = async (event: any, picker: any) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    setFilterRange(picker.chosenLabel);
    fetchReportFiltered(
      picker.startDate._d.toISOString(),
      picker.endDate._d.toISOString(),
    );
  };

  const handleFiatChartData = (currency: string) => {
    let totalDeposit = 0,
      totalWithdraw = 0;
    totalFiatDeposits?.forEach((deposit: any) => {
      if (currency === deposit.name) totalDeposit = deposit.totalFiatDeposit;
    });

    totalFiatWithdraw?.forEach((withdraw: any) => {
      if (currency === withdraw.name)
        totalWithdraw = withdraw.totalFiatWithdrawal;
    });

    let data = [
      { name: currency, value: totalDeposit, type: 'Deposit' },
      { name: currency, value: totalWithdraw, type: 'Withdraw' },
    ];

    setFiatChartData(data);
  };

  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  const handleExportVolume = async (fileName: any, exportType: any) => {
    if (filterRange === '') {
      const { data } = await axios.get(ROUTES.EXPORT_CRYPTO_VOLUME(), {
        headers,
      });
      exportToExcel(data.allVolumes, fileName, exportType);
    } else {
      const { data } = await axios.get(
        ROUTES.EXPORT_CRYPTO_VOLUME_FILTERED(fromDate, toDate),
        {
          headers,
        },
      );
      exportToExcel(data.allVolumes, fileName, exportType);
    }
  };

  const sortCryptoDetails = useCallback(
    (field: any) => {
      cryptoDetails?.sort((a: any, b: any) =>
        sortBy === field
          ? a[field] > b[field]
            ? -1
            : 1
          : a[field] > b[field]
          ? 1
          : -1,
      );
      setSortBy(field);
    },
    [cryptoDetails, sortBy],
  );

  // ? Method to rendert the title in the dropdown
  const renderExportDropdown = () => {
    switch (exportItem) {
      case 'crypto_deposit_withdraw':
        return 'Crypto Deposit and Withdraw';
      case 'crypto_volume':
        return 'Crypto Volume';
      default:
        return 'Crypto Deposit and Withdraw';
    }
  };

  const fetchFiatWithdrawData = (name: string) => {
    let data = totalFiatWithdraw.find(
      (fiatWithdrawData: any) => fiatWithdrawData.name === name,
    );

    return data?.totalFiatWithdrawal ? data?.totalFiatWithdrawal : 0;
  };

  return (
    <div className={classes.container}>
      <div className={classes.dropdownContainer}>
        <Menu
          menuButton={
            <MenuButton className={classes.statusDropdown}>
              <span>{renderExportDropdown()}</span>
              <img src={Arrowdown} alt="arrow-img" width={10} />
            </MenuButton>
          }
          transition
          menuClassName="drop-down"
          onItemClick={(e: any) => setExportItem(e.value)}
        >
          <MenuItem
            value="crypto_deposit_withdraw"
            className={
              exportItem === 'crypto_deposit_withdraw'
                ? 'dropdown-item-selected'
                : ''
            }
          >
            Crypto Deposit and Withdraw
          </MenuItem>
          <MenuItem
            value="crypto_volume"
            className={
              exportItem === 'crypto_volume' ? 'dropdown-item-selected' : ''
            }
          >
            Crypto Volume
          </MenuItem>
        </Menu>
        <Button
          onClick={() =>
            exportItem === 'crypto_deposit_withdraw'
              ? exportToExcel(cryptoDetails, 'Crypto Report', 'csv')
              : handleExportVolume('Report', 'csv')
          }
          // style={{ padding: '17px' }}
        >
          Export
        </Button>
      </div>
      {/* --------First row--------- */}
      <div className={classes.rows}>
        {/* <div className={classes.imageLogo}>
          <img src={logo} alt="logo"></img>
        </div> */}
        <div className={classes.headerText}>
          {filterRange ? filterRange : 'All'}
        </div>
        <div className={classes.datePicker}>
          <DateRangePicker
            initialSettings={{
              ranges: range,
              alwaysShowCalendars: false,
              linkedCalendars: false,
            }}
            onApply={handleEvent}
          >
            {!filterRange ? (
              <button>Selected Date Range</button>
            ) : (
              <button>
                {moment(fromDate).format('LL')} to {moment(toDate).format('LL')}
              </button>
            )}
          </DateRangePicker>
        </div>
      </div>
      {/* --------Second row-------- */}
      <div className={classes.rows}>
        <div className={classes.totalTraded}>
          <div>
            <div>Total traded volume</div>
            <div>${volumes.totalSellVolume + volumes.totalBuyVolume}</div>
          </div>
          <div className={classes.pieChartContainer}>
            <div>
              <Piechart data={levelTypeVolume} />
            </div>
            <div>
              <p>
                <p className={classes.individual}></p>individual
              </p>
              <p>
                <p className={classes.institution}></p>institution
              </p>
            </div>
          </div>
        </div>
        <div className={classes.middleRow}>
          <div>
            <div>Total revenue</div>
            <div>
              ${kmFormatter(Number(CURRENCY_CONV.fiatAmtConv(totalRevenue)))}
            </div>
          </div>
          <div>
            <div>Average order volume</div>
            <div>{volumes.averageVolume}</div>
          </div>
          <div>
            <div>Active Clients</div>
            <div>{usersDetails.totalActiveUsers}</div>
          </div>
        </div>
        <div className={classes.noBackground}>
          <div className={classes.headerText}>Most traded pairs</div>
          <div className={classes.mostTradedChart}>
            <div>All</div>
            <div className={classes.buySellContainer}>
              <div>
                <div className={classes.header}>buy</div>
                <TreeMap data={cryptoGrabPairs} />
              </div>
              <div>
                <div className={classes.header}>sell</div>
                <TreeMap data={cryptoSellPairs} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------Third row-------- */}
      <div className={classes.rows}>
        <div className={classes.headerText}>
          Total Fiat deposits and withdrawals
        </div>
        <div className={classes.headerText}>
          Total cryptocurrencies deposits and withdrawals
        </div>
      </div>
      {/* --------Fourth row------- */}
      <div className={classes.rows}>
        <div className={classes.headerContainer}>
          <div>
            <div className={classes.fiatCurrency}>
              <div>
                <div>FIAT Currency</div>
              </div>
              <div>Total USD</div>
            </div>
            <div className={classes.search}>
              <div>
                <img src={Search} alt="search-img" />
              </div>
              <div>
                <input type="text" placeholder="Type to search"></input>
              </div>
            </div>
            <div className={classes.fiatCurrenciesContainer}>
              <div className={classes.fiatCurrencies}>
                <div>
                  <div className={classes.currency}>
                    <div>Currency</div>
                  </div>
                  {/* eslint-disable-next-line */}
                  <div>Withdraw</div>
                  <div>Deposit</div>
                </div>
              </div>
              {totalFiatDeposits &&
                totalFiatDeposits.map(({ name, totalFiatDeposit }, index) => (
                  <div className={classes.fiatCurrencies}>
                    <div key={index.toString()}>
                      <div className={classes.currency}>
                        <div>
                          <input
                            type="checkbox"
                            onChange={() => {
                              setSelectedFiatCurrency(name);
                              handleFiatChartData(name);
                            }}
                            checked={
                              selectedFiatCurrency === name ? true : false
                            }
                          ></input>
                        </div>
                        <div>{name}</div>
                      </div>
                      {/* eslint-disable-next-line */}
                      <div>{kmFormatter(fetchFiatWithdrawData(name))}</div>
                      <div>{kmFormatter(totalFiatDeposit)}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            {/* <div>Total FIAT deposits and withdrawals</div>
            <div>$840,003,104.11</div> */}
            {fiatChartData &&
              fiatChartData.map((data: any, index: string) => {
                return (
                  <div key={index}>
                    Total {data.name} {data.type} {data.value}
                  </div>
                );
              })}
          </div>
          <div className={classes.doughnutChartContainer}>
            <div>
              <DoughnutChart data={fiatChartData} />
            </div>
            <div>
              <p>
                <p className={classes.withdrawal}></p>withdrawal
              </p>
              <p>
                <p className={classes.deposit}></p>deposit
              </p>
            </div>
          </div>
        </div>
        <div className={classes.middleRow}>
          <div>
            <div>Total clients onboarded</div>
            <div>{usersDetails.totalCompleteOnboardings}</div>
          </div>
          <div>
            <div>Total incomplete onboardings</div>
            <div>{usersDetails.totalIncompleteOnboardings}</div>
          </div>
        </div>
        <div className={classes.table}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th onClick={() => sortCryptoDetails('_id')}>Crypto</th>
                <th onClick={() => sortCryptoDetails('type')}>Type</th>
                <th onClick={() => sortCryptoDetails('quantity')}>Quantity</th>
                <th>USD Value</th>
              </tr>
            </thead>
            <tbody>
              {cryptoDetails &&
                cryptoDetails.map(({ _id, type, quantity, value }, index) => (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>{_id}</td>
                    <td>{type}</td>
                    <td>{quantity}</td>
                    <td>$0{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
