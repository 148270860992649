import React from 'react';

//@ts-ignore
export default function CustomTable() {
    const tableStyles = {
        // borderCollapse: 'collapse',
        width: '80%',
        margin: '20px auto',
    };

    const thStyles = {
        backgroundColor: '#091A49', // Updated color
        color: 'white',
        padding: '10px',
        border: '2px solid #091A49', // Updated color
    };

    const tdStyles = {
        padding: '8px',
        color: 'black',
        border: '2px solid #091A49', // Updated color
    };

    const columns = [
        {
            term: 'Accepted Order',
            meaning: 'Means a Buy Order or Sell Order placed by the Client that has been validly accepted by Encryptus.',
        },
        {
            term: 'AML/CFT Laws',
            meaning: 'Means anti-money laundering and combatting terrorism financing laws.',
        },
        {
            term: 'Applicable Laws',
            meaning: 'Means, with respect to any person, any and all applicable treaties, statutes, legislations, laws, regulations, ordinances, codes, rules, rulings, judgments, orders, awards, or any form of decisions, determinations or requirements of or made or issued by, any governmental, statutory, regulatory or supervisory bodies (including without limitation, any relevant stock exchange or securities council) or any court or tribunal with competent jurisdiction, whether in the United Arab Emirates or elsewhere, as amended, modified or replaced from time to time, and to which such person is subject.',
        },
        {
            term: 'Account',
            meaning: 'Means an account on the Platform in the name of the Client, through which the Client may avail the Services.',
        },
        {
            term: 'Business Day',
            meaning: 'Means a day (other than a Saturday, Sunday or public holiday) on which commercial banks are open for business in the United Arab Emirates.',
        },
        {
            term: 'Buy Order',
            meaning: 'Means an Order to buy Virtual Assets in exchange for fiat currency or another Virtual Assets.',
        },
        {
            term: 'Client Bank Account',
            meaning: 'Means the bank account is held and controlled by the Client.',
        },
        {
            term: 'Client Money',
            meaning: 'Means money or any fiat currency which the Company holds and controls on behalf of a Client, in the course of providing the Services.',
        },
        {
            term: 'Client Money Account',
            meaning: 'Means a bank account which is maintained by the Company to hold Client Money and which is designated as ‘Client Account’ in its title and is held with [insert the bank and their details].',
        },
        {
            term: 'Client Wallet',
            meaning: 'Means the applicable digital wallet held and controlled by the Client.',
        },
        {
            term: 'Client Virtual Assets',
            meaning: 'Means the Virtual Assets which the Company holds and controls on behalf of a Client, in the course of providing the Services.',
        },
        {
            term: 'Client Asset Wallet',
            meaning: 'Means the virtual asset wallet which is maintained by the Company to hold Client Virtual Assets and which is designated as ‘Client Virtual Asset Wallet’ in its title and is held with Fireblocks.',
        },
        {
            term: 'Confidential Information',
            meaning: 'Means any information which is proprietary and confidential to either Party including but not limited to the terms and conditions of this Agreement, each Order, each Accepted Order, information concerning or relating in any way whatsoever to the Encryptus’ investments or other arrangements, principals, any of the trade secrets or confidential operations, processes or inventions carried on or used by Encryptus, any information concerning the organisation, business, finances, transactions, investments or affairs of Encryptus, Encryptus’ dealings, secret or confidential information which relates to its business or any of its investee companies’ transactions or affairs, financial statements or information, any information therein in respect of trade secrets and information and material which is either marked confidential or is by its nature intended to be exclusively for the knowledge of the recipient alone.',
        },
        {
            term: 'Encumbrance',
            meaning: 'Means any mortgage, assignment of receivables, debenture, lien, hypothecation, charge, pledge, title retention, right to acquire, security interest, option, pre-emptive or other similar right, right of first refusal, restriction, third-party right or interest, any other encumbrance, condition or security interest whatsoever or any other type of preferential arrangement (including without limitation, a title transferor retention arrangement) having similar effect.',
        },
        {
            term: 'Order',
            meaning: 'Means a Buy Order or Sell Order.',
        },
        {
            term: 'Platform',
            meaning: 'Means a digital interface, encompassing websites, applications, or software, through which Services are conducted or facilitated, and can be located at www.encryptus.io.',
        },
        {
            term: 'Relevant Virtual Assets',
            meaning: 'Means the Virtual Assets to be purchased by a Client in case of a Buy Order or the Virtual Assets to be sold by a Client in case of Sell Order.',
        },
        {
            term: 'Relevant Price',
            meaning: 'Means, in an Accepted Order, the final price agreed by the Parties.',
        },
        {
            term: 'Representatives',
            meaning: 'Means, in relation to a Party, its advisers, agents, employees, officers or other representatives.',
        },
        {
            term: 'Sell Order',
            meaning: 'Means an Order to sell Virtual Assets in exchange for fiat currency or another Virtual Assets.',
        },
        {
            term: 'Services',
            meaning: 'Means the services mentioned in Clause 3.1 hereof.',
        },
        {
            term: 'Settlement Date',
            meaning: 'Refers to the date Encryptus transfers any Virtual Assets or fiat currency to the Client for the purpose of fulfilling the Accepted Order, which shall be no later than 24 hours from the date on which the Order was accepted by Encryptus.',
        },
        {
            term: 'Total Purchase Price',
            meaning: 'Means the total price in fiat currency or Virtual Assets to be paid by the Client in case of a Buy Order.',
        },
        {
            term: 'Total Sale Price',
            meaning: 'Means the total price to be received by the Client, in fiat currency or Virtual Assets, in case of a Sell Order.',
        },
        {
            term: 'VARA',
            meaning: 'Means the Dubai Virtual Assets Regulatory Authority.',
        },
        {
            term: 'Virtual Assets',
            meaning: 'Means a digital representation of value that may be digitally traded, transferred, or used as an exchange or payment tool, or for investment purposes. This includes Virtual Tokens, and any digital representation of any other value as determined by VARA.',
        },
        {
            term: 'Virtual Tokens',
            meaning: 'Means a digital representation of a set of rights that can be digitally offered and traded through a Virtual Asset Platform.',
        },
    ];

    return (
        <table style={tableStyles}>
            <thead>
                <tr>
                    <th style={thStyles}>Terms</th>
                    <th style={thStyles}>Meaning</th>
                </tr>
            </thead>
            <tbody>
                {columns.map((column, index) => (
                    <tr key={index}>
                        <td style={tdStyles}><strong>{column.term}</strong></td>
                        <td style={tdStyles}>{column.meaning}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

