import axios from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROUTES } from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { ANY, LoginForm, OTPForm, RegisterForm } from '../interfaces';

const UserContext = createContext({
  user: ANY,
  token: ANY,
  login: async (
    data: LoginForm,
    ifLogged?: () => any,
    ifOTP?: (email: string) => any,
  ) => ANY,
  register: async (data: RegisterForm, cb?: () => any) => ANY,
  logout: async () => ANY,
  sendOTP: async (formData: OTPForm, cb?: () => any) => ANY,
  headers: ANY,
  getUser: async () => ANY,
});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [token, setToken] = useLocalStorage<string | null>('token', null);
  const history = useHistory();
  const headers = { authorization: `Bearer ${token}` };
  const TOKEN_ROTATION_DURATION = 2700000;

  const getUser = async () => {
    if (!token) return setUser(null);
    try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(ROUTES.WHO_AM_I, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (!data) return;
      setUser(data);
      localStorage.setItem('userEmail', data?.email);
    } catch (error: any) {
      setUser(null);
      if (
        error?.response?.status === 401 &&
        history.location.pathname !== '/verifiedEmail'
      )
        history.push('/');
    }
  };

  // ? Method that is used to request a Refresh Token from the backend and set it in LocalStorage
  const setRefreshToken = async () => {
    try {
      let email = localStorage.getItem('userEmail');

      const { data }: any = await axios.post(
        ROUTES.REFRESH_TOKEN,
        { email },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      // ? Set the newly generated Refresh Token in LocalStorage
      setToken(data.accessToken);
    } catch (error) {
      logout();
    }
  };
  // * End of setRefreshToken();

  useEffect(() => {
    getUser();

    // ? Checks if Access Token is set
    // ? # If true, setRefreshToken() is called every 'TOKEN_ROTATION_DURATION' seconds
    if (token) {
      const interval = setInterval(() => {
        setRefreshToken();
      }, TOKEN_ROTATION_DURATION);

      return () => clearInterval(interval); // ? Clearning the Interval to prevent Memory Leaks
    }

    // eslint-disable-next-line
  }, [token]);

  const login = async (
    { email, password }: LoginForm,
    ifLogged?: () => any,
    ifOTP?: (email: string) => any,
  ) => {
    try {
      axios.defaults.withCredentials = true;
      const { data }: any = await axios.post(ROUTES.LOGIN, {
        email,
        password,
      });
      console.log({ data });
      const { OTPsent, accessToken } = data;
      if (accessToken) {
        setToken(accessToken);
        ifLogged?.();
        history.push('/dashboard');
        return { success: `Logged In!` };
      }
      if (OTPsent) {
        ifOTP?.(email);
        return { success: `OTP sent to ${email}` };
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  const register = async (formData: RegisterForm, cb?: () => any) => {
    try {
      const { data }: any = await axios.post(ROUTES.REGISTER, {
        ...formData,
        phone_no: formData.phone_no,
        name: formData.firstName.trim() + ' ' + formData.lastName.trim(),
        firstName: undefined,
        lastName: undefined,
        acceptance: true,
        tac_doc_version: 1,
      });

      if (data.accessToken) {
        cb?.();
        setToken(data.accessToken);
        history.push('/dashboard');
        return { success: `Welcome to Encryptus, ${formData.firstName}!` };
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  const logout = async () => {
    setToken(null);
    localStorage.removeItem('userEmail');
    try {
      axios.defaults.withCredentials = true;
      const { data }: any = await axios.post(
        ROUTES.LOGOUT,
        { email: user.email },
        {
          headers,
        },
      );
      if (data.loggedOut) {
        history.push('/');
        toast.success('Logged Out');
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message };
    }
  };

  const sendOTP = async (formData: OTPForm, cb?: () => any) => {
    try {
      const { data }: any = await axios.post(ROUTES.OTP, formData);
      if (data.accessToken) {
        setToken(data.accessToken);
        cb?.();
        history.push('/dashboard');

        return { success: `Logged In!` };
      }
    } catch (error: any) {
      console.log({ error });
      toast.warn('Please Enter Valid OTP');
      return { error: error?.response?.data?.message };
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        login,
        register,
        logout,
        headers,
        sendOTP,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
