import React from 'react';
import { useModal } from '../context/modal';
import classes from '../styles/Modal.module.scss';
import { ReactComponent as X } from '../assets/X.svg';

export default function Modal() {
  const { Component, closeModal } = useModal();
  return Component ? (
    <>
      <div className={classes.backdrop} onClick={closeModal}></div>
      <div className={classes.container}>
        <div className={classes.innercontainer}>
          <button onClick={closeModal} className={classes.close}>
            <X />
          </button>
          <div className={classes.component}>
            <Component />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
