import { anyObject } from '../interfaces';
import * as yup from 'yup';

export const modalcss = {
  border: '0px solid transparent',
  borderRadius: '10px',
  width: '51px',
  height: '51px',
  background: '#F1F1F1',
  fontSize: '12px',
  color: 'black',
  fontWeight: '400',
  caretColor: 'blue',
};

export interface handle {
  handler: () => void;
  nextbtn: () => void;
}

export interface Props {
  submit?: (body: anyObject) => any;
  walletData?: any;
  validation?: () => void;
}
export interface walletdata {
  walletdata: object;
  validate?: any;
}

export const paradata = [
  {
    para: `You will recieve a OTP on this email address or your registered google authenticator device. 
        In-case you have forgot/lost access to these,`,
    Linktext: 'contact encryptus support.',
  },
];

// Schema 1 fro password++++++++++++++++++++
export const schema = yup.object().shape({
  p_fSteps: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number',
    ),
});

export const color = {
  color: 'red',
};
