import React, { useEffect, useState } from 'react';
import AdminScreen from 'components/AdminScreen';
import classes from 'styles/Transactions.module.scss';
import Check from 'assets/ticklogo.png';
import Reject from 'assets/rejectlogo.svg';
import { useModal } from 'context/modal';
import { useAdmin } from 'context/admin';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import Filters from 'components/Filters';
import { FilterListItem } from '../../../interfaces';
import useFilters from 'hooks/useFilters';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import FiatWithdrawDetails from 'modals/FiatWithdrawDetails';
import CryptoWithdrawDetails from 'modals/CryptoWithdrawDetails';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { useSearch } from 'context/search';
import Paginate from 'components/Paginate';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import arrowDownBlack from 'assets/arrow-down-black.svg';
import Arrowdown from 'assets/Arrow - Down 4.png';
import exportFromJSON from 'export-from-json';

const filterList: FilterListItem[] = [
  {
    field: 'createdAt',
    label: 'Created',
    options: [
      { value: '7', label: 'Last 7 days' },
      { value: '15', label: 'Last 15 days' },
      { value: '30', label: 'Last 30 days' },
    ],
  },
];

export default function PartnerWithdrawTransactions() {
  const { openModal } = useModal();
  const { headers } = useAdmin();
  const { search } = useSearch();
  // States?
  const [Change, setChange] = useState('Fiat');

  // ? Search Bar Values
  const [fiatSearchValue, setFiatSearchValue] = useState<string>('');
  const [fiatSearchField, setFiatSearchField] =
    useState<string>('tagger_email');

  const [cryptoBuySearchValue, setCryptoBuySearchValue] = useState<string>('');
  const [cryptoBuySearchField, setCryptoBuySearchField] =
    useState<string>('tagger_email');

  const [status, setStatus] = useState<string>('all');

  const { filters, updateFilters } = useFilters();

  const [collections, { reload, paginateProps }] = useAdminPaginate<any>(
    {
      route: ROUTES.ADMIN_PARTNERS_FIAT_Withdrawls,
      params: {
        filters: { tagger_email: fiatSearchValue, ...filters },
      },
    },
    [search, filters],
  );
  const [collections2, { reload: reload2, paginateProps: paginateProps2 }] =
    useAdminPaginate<any>(
      {
        route: ROUTES.ADMIN_PARTNERS_CRYPTO_WIthdrawls,
        params: {
          filters: { [cryptoBuySearchField]: cryptoBuySearchValue, ...filters },
        },
      },
      [search, filters],
    );

  // console.log(collections, collections2);

  const approval = async (id: string, bool: boolean) => {
    try {
      const body = {
        final_approval: bool,
      };
      await axios.patch(ROUTES.Admin_Partners_Approval_FWTickets(id), body, {
        headers,
      });
      toast.success('Completed Successfully');
      reload();
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  // Crypto Approval++++++++++++++++++++++++++++++++++
  const CryptoApproval = async (id: string, bool: boolean) => {
    try {
      const body = {
        final_approval: bool,
      };

      await axios.patch(ROUTES.Admin_Partners_Approval_CWTickets(id), body, {
        headers,
      });
      toast.success('Completed Successfully');
      reload2();
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  // ????????????selection for filter switch ???????????????
  const changeSelections = (status: any) => {
    updateFilters?.('transc_status', status === 'all' ? '' : status);

    setStatus(status);
  };

  // ????????????switch?????????????????????????????
  // switch for chnaging fiat to crytop++++++++++++++
  const ChangeManager = async () => {
    console.log('CHANGE MANAGER');

    await resetAllSearchFields();

    if (Change === 'Fiat') {
      setChange('Crypto');
      reload2();
    }

    if (Change === 'Crypto') {
      setChange('Fiat');
      reload();
    }
  };

  useEffect(() => {
    if (Change === 'Fiat') reload();
    else reload2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    reload();
    reload2();
    // eslint-disable-next-line
  }, []);

  // ? Method to reset all search fields
  const resetAllSearchFields = () => {
    setFiatSearchValue(() => '');
    setFiatSearchField(() => 'tagger_email');

    setCryptoBuySearchValue(() => '');
  };
  // * End of resetAllSearchFields();

  // ? Method to Search Fiat Tickets
  const handleFiatSearch = async (event: any) => {
    event.preventDefault();
    reload();
  };
  // * End of handleFiatSearch()

  // ? Method to Search Crypto Tickets
  const handleCryptoSearch = async (event: any) => {
    event.preventDefault();
    reload2();
  };
  // * End of handleCryptoSearch()
  // const handlevalue = async (id: string, type: string) => {
  //   try {
  //     const body = {
  //       transac_type: type,
  //     };
  //     const data = await axios.patch(ROUTES.Processing_Route(id), body, {
  //       headers,
  //     });
  //     if (data) {
  //       toast.success('Success');
  //       reload();
  //       reload2();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.warning('Not Set To Processing');
  //   }
  // };

  // * END OF SEARCH MODULE

  const renderSearchBar = () => {
    // ? FIAT
    if (Change === 'Fiat') {
      return (
        <form className={classes.userSearchForm} onSubmit={handleFiatSearch}>
          <h4>Fiat Transactions</h4>

          <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                placeholder="Search Email"
                value={fiatSearchValue}
                onChange={(event) => setFiatSearchValue(event?.target.value)}
              />
              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderFiatDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setFiatSearchField(e.value)}
              >
                <MenuItem
                  value="tagger_email"
                  className={
                    fiatSearchField === 'tagger_email'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Email
                </MenuItem>
              </Menu>
            </div>
            <Button type="submit">Search</Button>

            <Button onClick={resetAllSearchFields}>Reset</Button>
          </div>
        </form>
      );
    } else {
      // ? CRYPTO
      return (
        <form className={classes.userSearchForm} onSubmit={handleCryptoSearch}>
          <h4>Crypto Transactions</h4>

          <div className={classes.searchBarSection}>
            <div className={classes.searchInputSection}>
              <input
                type="text"
                value={cryptoBuySearchValue}
                onChange={(event) =>
                  setCryptoBuySearchValue(() => event.target.value)
                }
                placeholder={
                  cryptoBuySearchField === 'tagger_email'
                    ? 'Search Email'
                    : cryptoBuySearchField === 'tagger_id'
                    ? 'Search ID'
                    : 'Search Wallet Address'
                }
              />
              <Menu
                menuButton={
                  <MenuButton className={classes.dropdownBtn}>
                    <span>{renderCryptoBuyDropdown()}</span>
                    <img src={arrowDownBlack} alt="arrow-img" width={10} />
                  </MenuButton>
                }
                transition
                menuClassName="drop-down"
                onItemClick={(e: any) => setCryptoBuySearchField(e.value)}
              >
                <MenuItem
                  value="tagger_email"
                  className={
                    fiatSearchField === 'tagger_email'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Email
                </MenuItem>
                <MenuItem
                  value="tagger_id"
                  className={
                    fiatSearchField === 'tagger_id'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Customer ID
                </MenuItem>
                <MenuItem
                  value="user_destination_walletAddress"
                  className={
                    fiatSearchField === 'user_destination_walletAddress'
                      ? 'dropdown-item-selected'
                      : ''
                  }
                >
                  Wallet Address
                </MenuItem>
              </Menu>
            </div>
            <Button type="submit">Search</Button>

            <Button onClick={resetAllSearchFields}>Reset</Button>
          </div>
        </form>
      );
    }
  };

  // ? Method to rendert the title in the dropdown
  const renderFiatDropdown = () => {
    switch (fiatSearchField) {
      case 'name':
        return 'Name';

      case 'phone_no':
        return 'Phone No';

      case 'tagger_email':
        return 'Email';
    }
  };

  const renderCryptoBuyDropdown = () => {
    switch (cryptoBuySearchField) {
      case 'tagger_email':
        return 'Email';

      case 'tagger_id':
        return 'Customer ID';

      case 'user_destination_walletAddress':
        return 'Wallet Address';
    }
  };

  const renderStatusDropdownTitle = () => {
    switch (status) {
      case 'all':
        return 'All Transactions';

      case 'pending':
        return 'Pending';

      case 'approved':
        return 'Approved';

      case 'rejected':
        return 'Rejected';

      case 'processing':
        return 'Processing';

      default:
        return 'All Transactions';
    }
  };
  // * End of renderDropdownTitle methods
  const exportToExcel = (data: any, fileName: any, exportType: any) => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.header}>
        {
          <Menu
            menuButton={
              <MenuButton className={classes.statusDropdown}>
                <span>{renderStatusDropdownTitle()}</span>
                <img src={Arrowdown} alt="arrow-img" width={10} />
              </MenuButton>
            }
            transition
            menuClassName="drop-down"
            onItemClick={(e: any) => changeSelections(e.value)}
          >
            <MenuItem
              value="all"
              className={status === 'all' ? 'dropdown-item-selected' : ''}
            >
              All Transactions
            </MenuItem>
            <MenuItem
              value="pending"
              className={status === 'pending' ? 'dropdown-item-selected' : ''}
            >
              Pending
            </MenuItem>
            <MenuItem
              value="approved"
              className={status === 'approved' ? 'dropdown-item-selected' : ''}
            >
              Approved
            </MenuItem>
            <MenuItem
              value="rejected"
              className={status === 'rejected' ? 'dropdown-item-selected' : ''}
            >
              Rejected
            </MenuItem>
            {/* <MenuItem
              value="processing"
              className={
                status === 'processing' ? 'dropdown-item-selected' : ''
              }
            >
              Processing
            </MenuItem> */}
          </Menu>
        }
        <button
          style={{ padding: '17px' }}
          onClick={() => {
            Change === 'Fiat'
              ? exportToExcel(collections, 'Partners Fiat Withdraw', 'csv')
              : exportToExcel(collections2, 'Partners Crypto Withdraw', 'csv');
          }}
        >
          Export
        </button>

        <Filters
          filters={filters}
          updateFilters={updateFilters}
          filterList={filterList}
        />
        {/* Button to Switch Fiat to Crypto +++++++++++++++++++ */}

        <button onClick={ChangeManager}>
          Switch to{' '}
          {Change === 'Fiat' ? 'Crypto Transaction' : 'Fiat Transactions'}
        </button>
      </div>

      <div className={classes.searchBarSection}>{renderSearchBar()}</div>

      <div className={classes.transaction_module}>
        {Change === 'Fiat' ? (
          // ? FIAT WITHDRAW TABLE
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction Reference ID</th>
                <th>Withdraw Amount</th>
                <th>Status</th>
                <th>Customer Email</th>
                <th>Details</th>
                <th>Actions</th>
                {/* <th>Process Txn</th> */}
              </tr>
            </thead>
            <tbody>
              {collections &&
                collections.map((values: any) => {
                  return (
                    <>
                      <tr key={values?.transc_status}>
                        <td
                          onDoubleClick={() =>
                            copyText(DATE_CONV.dateConvert(values?.createdAt))
                          }
                        >
                          {DATE_CONV.dateConvert(values?.createdAt)}
                        </td>
                        <td onDoubleClick={() => copyText(values?.unq_ref_id)}>
                          {values?.unq_ref_id}
                        </td>
                        <td
                          onDoubleClick={() =>
                            copyText(
                              `${CURRENCY_CONV.fiatAmtConv(
                                values?.withdraw_amt,
                              )} ${
                                values?.fiat_curr?.toUpperCase() ||
                                values?.withdraw_curr?.toUpperCase()
                              }`,
                            )
                          }
                        >
                          {CURRENCY_CONV.fiatAmtConv(values?.withdraw_amt)}
                          &nbsp;
                          {values?.fiat_curr?.toUpperCase() ||
                            values?.withdraw_curr?.toUpperCase()}
                        </td>
                        <td
                          onDoubleClick={() =>
                            copyText(values?.transc_status?.toUpperCase())
                          }
                          className={
                            values?.transc_status === 'approved'
                              ? 'textStatusApproved'
                              : values?.transc_status === 'pending' ||
                                values?.transc_status === 'processing'
                              ? 'textStatusPending'
                              : 'textStatusRejected'
                          }
                        >
                          {values?.transc_status?.toUpperCase()}
                        </td>
                        <td
                          onDoubleClick={() => copyText(values.tagger_email)}
                          className={classes.taggeremail}
                        >
                          {values.tagger_email}
                        </td>
                        <td>
                          <button
                            className={classes.DetailsBTN}
                            onClick={() =>
                              openModal(() => (
                                <FiatWithdrawDetails
                                  transactionData={values}
                                  type="partners"
                                />
                              ))
                            }
                          >
                            View Details
                          </button>
                        </td>
                        <td>
                          {values?.transc_status === 'pending' ||
                          values?.transc_status === 'processing' ? (
                            <div className={classes.btnDiv}>
                              {/* <button
                                className={classes.btnapproval}
                                onClick={() =>
                                  openModal(() => (
                                    <Approvalmodal
                                      id={values._id}
                                      render={rerender}
                                      type="withdraw"
                                      currency={values.withdraw_curr}
                                    />
                                  ))
                                }
                              >
                                Set Approval Count
                              </button> */}
                              <button
                                className={classes.approvals}
                                onClick={() => approval(values._id, true)}
                              >
                                <img src={Check} alt="check" />
                              </button>
                              <button
                                className={classes.approvals}
                                onClick={() => approval(values._id, false)}
                              >
                                <img src={Reject} alt="reject" />
                              </button>
                            </div>
                          ) : (
                            'Processed'
                          )}
                        </td>
                        {/* <td>
                          {values?.transc_status === 'pending' ? (
                            <button
                              onClick={() =>
                                handlevalue(values._id, 'fiatwithdraw')
                              }
                              className={classes.ProcessBTN}
                            >
                              Under Process
                            </button>
                          ) : (
                            '-'
                          )}
                        </td> */}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        ) : (
          // ? CRYPTO WITHDRAW TABLE
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>
                    Transaction
                    <br />
                    Reference ID
                  </th>
                  <th>Withdraw Amount</th>
                  <th>Status</th>
                  <th>Customer Email</th>
                  <th>Details</th>
                  <th>Actions</th>
                  {/* <th>Process Txn</th> */}
                </tr>
              </thead>

              <tbody>
                {collections2 &&
                  collections2.map((values: any) => {
                    return (
                      <>
                        <tr key={values.transc_status}>
                          <td
                            onDoubleClick={() =>
                              copyText(DATE_CONV.dateConvert(values?.createdAt))
                            }
                          >
                            {DATE_CONV.dateConvert(values?.createdAt)}
                          </td>
                          <td
                            onDoubleClick={() => copyText(values?.unq_ref_id)}
                          >
                            {values?.unq_ref_id}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(
                                `${CURRENCY_CONV.cryptoAmtConv(
                                  values?.withdraw_amt,
                                )} ${values?.withdraw_curr?.toUpperCase()}`,
                              )
                            }
                          >
                            {CURRENCY_CONV.cryptoAmtConv(values?.withdraw_amt)}
                            &nbsp;
                            {values?.withdraw_curr?.toUpperCase()}
                          </td>
                          <td
                            onDoubleClick={() =>
                              copyText(values?.transc_status?.toUpperCase())
                            }
                            className={
                              values?.transc_status === 'approved'
                                ? 'textStatusApproved'
                                : values?.transc_status === 'pending' ||
                                  values?.transc_status === 'processing'
                                ? 'textStatusPending'
                                : 'textStatusRejected'
                            }
                          >
                            {values?.transc_status?.toUpperCase()}
                          </td>
                          <td
                            className={classes.taggeremail}
                            onDoubleClick={() => copyText(values.tagger_email)}
                          >
                            {values.tagger_email}
                          </td>
                          <td>
                            <button
                              className={classes.DetailsBTN}
                              onClick={() =>
                                openModal(() => (
                                  <CryptoWithdrawDetails
                                    transactionData={values}
                                    type="partners"
                                  />
                                ))
                              }
                            >
                              View Details
                            </button>
                          </td>
                          <td>
                            {values.transc_status === 'pending' ||
                            values.transc_status === 'processing' ? (
                              <div className={classes.btnDiv}>
                                {/* <button
                                  className={classes.btnapproval}
                                  onClick={() =>
                                    openModal(() => (
                                      <Approvalmodal
                                        id={values._id}
                                        render={rerender}
                                        type="withdraw"
                                        currency={values.withdraw_curr}
                                      />
                                    ))
                                  }
                                >
                                  Set Approval Count
                                </button> */}
                                <button
                                  className={classes.approvals}
                                  onClick={() =>
                                    CryptoApproval(values._id, true)
                                  }
                                >
                                  <img src={Check} alt="check" />
                                </button>
                                <button
                                  className={classes.approvals}
                                  onClick={() =>
                                    CryptoApproval(values._id, false)
                                  }
                                >
                                  <img src={Reject} alt="reject" />
                                </button>
                              </div>
                            ) : (
                              <>
                                <h4 className={classes.title_success}>
                                  Processed
                                </h4>
                              </>
                            )}
                          </td>
                          {/* <td>
                            {values?.transc_status === 'pending' ? (
                              <button
                                onClick={() =>
                                  handlevalue(values._id, 'cryptowithdraw')
                                }
                                className={classes.ProcessBTN}
                              >
                                Under Process
                              </button>
                            ) : (
                              '-'
                            )}
                          </td> */}
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {Change === 'Fiat' && <Paginate {...paginateProps} />}
      {Change === 'Crypto' && <Paginate {...paginateProps2} />}
    </AdminScreen>
  );
}
