import axios from 'axios';
import { useAdmin } from 'context/admin';
import { toast } from 'react-toastify';
import { ROUTES } from '../config';
import { useModal } from '../context/modal';
import { Coin } from '../interfaces';
import SetTransactionLimit from '../modals/SetTransactionLimit';
import classes from '../styles/CurrencySetting.module.scss';
import Card from './Card';

interface Props extends Coin {
  reload: () => any;
}

export default function CurrencySetting({
  icon,
  name,
  symbol,
  isGlobalActive,
  max_limit,
  min_limit,
  reload,
}: Props) {
  const { openModal } = useModal();
  const { headers } = useAdmin();

  const changeActive = async (active: boolean) => {
    const { data } = await axios.patch(
      ROUTES.UPDATE_COIN_GLOBAL(symbol),
      {
        isGlobalActive: active,
      },
      { headers },
    );
    console.log(active);
    console.log(data);

    if (data?.status === 'success') {
      toast.success(`Now users can${active ? '' : 'not'} buy ${symbol}`);
    }
    await reload();
  };

  return (
    <Card variant={2} className={classes.container}>
      <div className={classes.top}>
        <div className={classes.details}>
          <img src={icon} alt={`${name} icon`} />
          <div className={classes.text}>
            <h2>{name}</h2>
            <h3>{symbol}</h3>
          </div>
        </div>
        <p
          onClick={() =>
            openModal(() => (
              <SetTransactionLimit
                max_limit={max_limit}
                min_limit={min_limit}
                symbol={symbol}
                isGlobalActive={isGlobalActive}
                reload={reload}
              />
            ))
          }
        >
          Set Transaction Limit
        </p>
      </div>
      <div className={classes.bottom}>
        <p>Allow User to Buy & Sell</p>
        <div
          onClick={() => changeActive(!isGlobalActive)}
          className={`${classes.switch} ${
            isGlobalActive ? classes.active : ''
          }`}
        >
          <div className={classes.outer}>
            <div className={classes.dot}></div>
          </div>
        </div>
      </div>
    </Card>
  );
}
