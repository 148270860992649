import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAdmin } from 'context/admin';
import classes from '../styles/WithdrawDetailsModal.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { ROUTES } from '../config';

type Props = {
  transactionData: object[] | any;
  type?: string;
};

function FiatWithdrawDetails({ transactionData, type }: Props) {
  const { headers } = useAdmin();
  const [banks, setBanks] = useState([]);
  const [balance, setBalance] = useState(0);

  console.log({
    transactionData,
  });

  const getBanks = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_BANK_APPLICATIONS_ADMIN(
        transactionData.destination_address,
      ),
      { headers },
    );

    console.log({
      bankData: data,
    });

    setBanks(data[0]);
  };

  const userFiatBalance = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_FIAT_BALANCE_ADMIN(transactionData.tagger_id),
    );
    console.log({ data });
    // data.data.fiat_bal.map((bal: any) => {

    // })
    const coin = data.data.fiat_bal.find(
      (coinBal: any) => coinBal.fiat_curr === transactionData.withdraw_curr,
    );
    console.log('Coin ', coin);
    setBalance(coin.fiat_coins);
  };

  useEffect(() => {
    getBanks();
    userFiatBalance();
    // eslint-disable-next-line
  }, []);

  if (type === 'partners') {
    return (
      <div className={classes.detailsContainer}>
        <h1>Fiat Withdraw Details</h1>
        <section className={classes.box}>
          <>
            <div>
              <h4>Transaction Reference ID</h4>
              <p
                onDoubleClick={() => copyText(transactionData?.unq_ref_id)}
                style={{
                  paddingRight: '30px',
                }}
              >
                {transactionData?.unq_ref_id}
              </p>
            </div>
            <div>
              <h4>Customer Email</h4>
              <p onDoubleClick={() => copyText(transactionData?.tagger_email)}>
                {transactionData?.tagger_email}
              </p>
            </div>
            <div>
              <h4>Transaction Status</h4>
              <p
                onDoubleClick={() =>
                  copyText(transactionData?.transc_status?.toUpperCase())
                }
                className={
                  transactionData?.transc_status === 'approved'
                    ? 'textStatusApproved'
                    : transactionData?.transc_status === 'pending'
                    ? 'textStatusPending'
                    : 'textStatusRejected'
                }
              >
                {transactionData?.transc_status?.toUpperCase()}
              </p>
            </div>
            {/* <div>
            <h4>Quoted Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount)}
            </p>
          </div> */}
            <div>
              <h4>Withdraw Amount</h4>
              <p
                onDoubleClick={() =>
                  copyText(
                    CURRENCY_CONV.fiatAmtConv(transactionData?.withdraw_amt),
                  )
                }
              >
                {CURRENCY_CONV.fiatAmtConv(transactionData?.withdraw_amt)}
              </p>
            </div>
            <div>
              <h4>Currency</h4>
              <p
                onDoubleClick={() =>
                  copyText(
                    transactionData?.fiat_curr ||
                      transactionData?.withdraw_curr?.toUpperCase(),
                  )
                }
              >
                {transactionData?.fiat_curr ||
                  transactionData?.withdraw_curr?.toUpperCase()}
              </p>
            </div>
            <div>
              <h4>Flagged</h4>
              <p
                onDoubleClick={() =>
                  copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
                }
                className={
                  !transactionData?.flagged === true
                    ? 'textStatusApproved'
                    : 'textStatusRejected'
                }
              >
                {!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
              </p>
            </div>
            <div>
              <h4>Transaction Date - Time</h4>
              <p
                onDoubleClick={() =>
                  copyText(DATE_CONV.dateTimeConvert(transactionData.createdAt))
                }
              >
                {DATE_CONV.dateTimeConvert(transactionData.createdAt)}
              </p>
            </div>
          </>
        </section>
      </div>
    );
  }

  return (
    <div className={classes.detailsContainer}>
      <h1>Fiat Withdraw Details</h1>
      <section className={classes.box}>
        <>
          <div>
            <h4>Transaction Reference ID</h4>
            <p
              onDoubleClick={() => copyText(transactionData?.unq_ref_id)}
              style={{
                paddingRight: '30px',
              }}
            >
              {transactionData?.unq_ref_id}
            </p>
          </div>
          <div>
            <h4>Customer Email</h4>
            <p onDoubleClick={() => copyText(transactionData?.tagger_email)}>
              {transactionData?.tagger_email}
            </p>
          </div>
          <div>
            <h4>Logged IP</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.client_recorded_IP)
              }
            >
              {transactionData?.client_recorded_IP}
            </p>
          </div>
          {/* <div>
            <h4>Customer ID</h4>
            <p onDoubleClick={() => copyText(transactionData?.tagger_id)}>
              {transactionData?.tagger_id}
            </p>
          </div> */}
          <div>
            <h4>Transaction Status</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.transc_status?.toUpperCase())
              }
              className={
                transactionData?.transc_status === 'approved'
                  ? 'textStatusApproved'
                  : transactionData?.transc_status === 'pending'
                  ? 'textStatusPending'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.transc_status?.toUpperCase()}
            </p>
          </div>
          <div>
            <h4>Approval Given</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.fin_approval_given
                    ?.toString()
                    ?.toUpperCase(),
                )
              }
              className={
                transactionData?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {transactionData?.fin_approval_given?.toString()?.toUpperCase()}
            </p>
          </div>
          <div>
            <h4>Approval Count</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.need_approval_count === -1
                    ? 'Not Set'
                    : transactionData?.need_approval_count,
                )
              }
            >
              {transactionData?.need_approval_count === -1
                ? 'Not Set'
                : transactionData?.need_approval_count}
            </p>
          </div>
          {/* <div>
            <h4>Quoted Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.quot_amount)}
            </p>
          </div> */}
          <div>
            <h4>Withdraw Amount</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData?.withdraw_amt),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData?.withdraw_amt)}
            </p>
          </div>
          <div>
            <h4>Currency</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData?.fiat_curr ||
                    transactionData?.withdraw_curr?.toUpperCase(),
                )
              }
            >
              {transactionData?.fiat_curr ||
                transactionData?.withdraw_curr?.toUpperCase()}
            </p>
          </div>
          <div>
            <h4>Flagged</h4>
            <p
              onDoubleClick={() =>
                copyText(!transactionData?.flagged ? 'CLEAN' : 'DIRTY')
              }
              className={
                !transactionData?.flagged === true
                  ? 'textStatusApproved'
                  : 'textStatusRejected'
              }
            >
              {!transactionData?.flagged ? 'CLEAN' : 'DIRTY'}
            </p>
          </div>
          <div>
            <h4>Bank Name</h4>
            {/* @ts-ignore */}
            <p>{banks?.bankName}</p>
          </div>
          <div>
            <h4>IBAN</h4>
            {/* @ts-ignore */}
            <p>{banks?.iban_number}</p>
          </div>
          <div>
            <h4>Bank Identifier Code</h4>
            {/* @ts-ignore */}
            <p>{banks?.bankIdentifierCode}</p>
          </div>
          <div>
            <div>
              <h4>Account Holder Name</h4>
              {/* @ts-ignore */}
              <p>{banks?.accountHolderName}</p>
            </div>
          </div>
          <div>
            <h4>Initial User {transactionData.withdraw_curr} balance</h4>
            {/* @ts-ignore */}
            <p>{balance + transactionData?.withdraw_amt}</p>
          </div>
          <div>
            <h4>Transaction Date - Time</h4>
            <p
              onDoubleClick={() =>
                copyText(DATE_CONV.dateTimeConvert(transactionData.createdAt))
              }
            >
              {DATE_CONV.dateTimeConvert(transactionData.createdAt)}
            </p>
          </div>
        </>
      </section>
    </div>
  );
}

export default FiatWithdrawDetails;
