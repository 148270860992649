import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classes from 'styles/Approvalmodal.module.scss';
import * as yup from 'yup';
import { useAdmin } from 'context/admin';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_HOST, FIAT_CURR } from 'config';
import { useModal } from 'context/modal';

interface Id {
  id: string;
  render: () => void;
  type: string;
  currency: string;
}

function Approvalmodal({ id, render, type, currency }: Id) {
  const { headers } = useAdmin();
  const { closeModal } = useModal();
  console.log({ id, type, currency });
  const handlesubmit = async (values: any) => {
    try {
      const body = {
        approval_needed: parseInt(values.approval),
        transferred_amount: Number(values.amount),
        currency,
      };
      if (type === 'deposit') {
        await axios.patch(
          `${API_HOST}/encryptus/sadmin/rsigs/update/fdTicket/${id}`,
          body,
          {
            headers,
          },
        );
      } else {
        if (Object.values(FIAT_CURR).some((x) => x === currency)) {
          await axios.patch(
            `${API_HOST}/encryptus/sadmin/rsigs/update/fwTicket/${id}`,
            body,
            {
              headers,
            },
          );
        } else {
          await axios.patch(
            `${API_HOST}/encryptus/sadmin/rsigs/update/cwTicket/${id}`,
            body,
            {
              headers,
            },
          );
        }
      }
      toast.success('Completed Successfully');
      closeModal();
      render();
      console.log('render again');
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  const initialValues = {
    approval: '',
    amount: '',
    currency: currency,
  };

  const schema = yup.object().shape({
    approval: yup.number().required('Must give input').min(0),
    amount: yup.number().required('Must give input').min(0),
    currency: yup.string().required('Must give input'),
  });

  const currencies = [currency];

  return (
    <div className={classes.Container}>
      <h2>Set Approval count</h2>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handlesubmit}
        >
          <Form>
            <div className={classes.inputgroup}>
              <label>Approval Count</label>
              <Field type="text" name="approval" className={classes.inputs} />
              <ErrorMessage name="approval" />
            </div>
            <div className={classes.inputgroup}>
              <label>Transferred Amount</label>
              <Field type="text" name="amount" className={classes.inputs} />
              <ErrorMessage name="amount" />
            </div>
            <div className={classes.inputgroup}>
              <label>Currency</label>
              <Field
                as="select"
                name="currency"
                className={classes.inputs}
                disabled
              >
                {currencies.map((data) => {
                  return (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage name="currency" />
            </div>
            <div className={classes.btndiv}>
              <button>Submit</button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Approvalmodal;
