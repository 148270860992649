import { Formik, Form } from 'formik';
import classes from '../styles/Approvalmodal.module.scss';
import * as yup from 'yup';
import axios from 'axios';
import { useAdmin } from 'context/admin';
import { ADMIN_ACTIONS, ROUTES, ADMIN_ROLES } from 'config';
import { toast } from 'react-toastify';
import { useModal } from 'context/modal';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useState } from 'react';

interface Rerender {
  rerender: () => void;
}

function DraftPermissions({ rerender }: Rerender) {
  const { headers } = useAdmin();
  const { closeModal } = useModal();

  const [roles, setRoles] = useState<Set<string>>(new Set<string>());

  const [actions, setActions] = useState<Set<string>>(new Set<string>());

  const [validationError, setValidationError] = useState({
    role: false,
    actions: false,
  });

  const handlesubmit = async () => {
    try {
      if (!roles.size || !actions.size) {
        setValidationError({
          role: !roles.size,
          actions: !actions.size,
        });
        return;
      }
      const body = {
        actions_allowed: Array.from(actions),
        role_allowed: Array.from(roles),
      };
      const result = await axios.post(ROUTES.Admin_Permissions, body, {
        headers,
      });
      toast.success('Added Successfully');
      closeModal();
      rerender();
      console.log(result);
    } catch (err: any) {
      toast.warning(err?.response?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  const initialValues = {
    role: 'Fintech',
    actions: '',
  };

  const schema = yup.object().shape({
    role: yup.string().required('Please select a role'),
    actions: yup.string().required('Please select an action'),
  });

  const updateRolesList = (role: string) => {
    const localRoles = new Set(roles);
    if (localRoles.has(role)) localRoles.delete(role);
    else {
      localRoles.add(role);
      setValidationError({
        ...validationError,
        role: false,
      });
    }
    setRoles(localRoles);
  };

  const updateActionsList = (action: string) => {
    const localActions = new Set(actions);
    if (localActions.has(action)) localActions.delete(action);
    else {
      localActions.add(action);
      setValidationError({
        ...validationError,
        actions: false,
      });
    }
    setActions(localActions);
  };

  return (
    <div className={classes.Container}>
      <h2>Draft Permissions</h2>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handlesubmit}
        >
          <Form>
            <div className={classes.inputgroup}>
              <label>Role Allowed</label> <br />
              <Menu
                menuClassName="drop-down"
                onItemClick={(event) => (event.keepOpen = true)}
                menuButton={
                  <MenuButton
                    className={classes.menuInputs}
                    placeholder="Select a role"
                  >
                    {roles.size === 0
                      ? 'Select a role'
                      : Array.from(roles.values()).map((data, idx) => {
                          return (
                            <span key={idx}>
                              {data[0].toUpperCase() + data.slice(1)}
                              {idx < roles.size - 1 && ', '}
                            </span>
                          );
                        })}
                  </MenuButton>
                }
              >
                {Object.keys(ADMIN_ROLES).map((key, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      className={classes.menuOptions}
                      onClick={(e) => {
                        updateRolesList(e.value);
                      }}
                      checked={roles.has(
                        ADMIN_ROLES[key as keyof typeof ADMIN_ROLES],
                      )}
                      type="checkbox"
                      value={ADMIN_ROLES[key as keyof typeof ADMIN_ROLES]}
                    >
                      <span>{key}</span>
                    </MenuItem>
                  );
                })}
              </Menu>
              {validationError.role && 'Please select a role'}
              <br />
            </div>
            <div className={classes.inputgroup}>
              <label>Actions Allowed</label> <br />
              <Menu
                menuClassName={`drop-down ${classes.permissionsDropdown}`}
                onItemClick={(event) => (event.keepOpen = true)}
                menuButton={
                  <MenuButton
                    className={classes.menuInputs}
                    placeholder="Select an action"
                  >
                    {actions.size === 0
                      ? 'Select an action'
                      : Array.from(actions.values()).map((data, idx) => {
                          return (
                            <span key={idx}>
                              {data}
                              {idx < actions.size - 1 && ', '}
                            </span>
                          );
                        })}
                  </MenuButton>
                }
              >
                {Object.keys(ADMIN_ACTIONS).map((key, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      className={classes.menuOptions}
                      onClick={() => {
                        updateActionsList(key);
                      }}
                      checked={actions.has(key)}
                      type="checkbox"
                      value={ADMIN_ACTIONS[key as keyof typeof ADMIN_ACTIONS]}
                    >
                      <span>
                        {ADMIN_ACTIONS[key as keyof typeof ADMIN_ACTIONS]}
                      </span>
                    </MenuItem>
                  );
                })}
              </Menu>
              <br />
              {validationError.actions && 'Please select an action'}
            </div>
            <div className={classes.btndiv}>
              <button onClick={handlesubmit}>Submit</button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default DraftPermissions;
