import React from 'react';
import classes from '../styles/CurrencyDescription.module.scss';

interface CurrencyProps {
  icon: string;
  title?: string;
  symbol?: string;
  time?: string;
  rate?: string;
  price?: string;
  message?: string;
}

const CurrencyDescription = ({
  icon,
  title,
  symbol,
  rate,
  price,
  message,
}: CurrencyProps) => {
  return (
    <div className={classes.currency}>
      <div className={classes.desc1}>
        <img src={icon} alt={title + ' icon'} />
        <p className={classes.title}>
          {title}
          <span className={classes.symbol}>{symbol}</span>
        </p>
      </div>
      <div className="desc2">
        <p className={classes.rate}>
          {rate}
          <span className={classes.price}>{price}</span>
        </p>
        <p className={classes.message}>{message}</p>
        <p className={classes.fees}>including fees and taxes</p>
      </div>
    </div>
  );
};

export default CurrencyDescription;
