import { useState } from 'react';
import { useModal } from 'context/modal';
import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/ChangePasswordAdmin.module.scss';
import loginClasses from '../../styles/Login.module.scss';

import { ROUTES } from 'config';

import { useAdmin } from 'context/admin';
import axios from 'axios';
import { toast } from 'react-toastify';
import ForgotPasswordOTP from 'modals/ForgotPasswordOTP';
import Button from 'components/Button';

export default function ChangePassword() {
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { admin, headers } = useAdmin();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let body = {
        adminEmail: admin.email,
      };

      const { data } = await axios.post(ROUTES.ADMIN_CHANGE_PASSWORD, body, {
        headers,
      });

      console.log({
        responseData: data,
      });

      if (data && data?.status === 'success') {
        setLoading(false);
        toast.success(data.message);

        openModal(() => <ForgotPasswordOTP isAdmin email={admin.email} />);
      } else {
        console.log(data);
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error: any) {
      console.log({ error });
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.response?.data);
    }
  };

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.heading}>Change Password</div>
      <div className={classes.subheading}>Email: {admin.email}</div>
      <div className={loginClasses.action}>
        <Button loading={loading} onClick={handleSubmit} type="submit">
          Send OTP
        </Button>
      </div>
    </AdminScreen>
  );
}
