
import React from 'react';

import classes from "../../styles/AdminHeader.module.scss";

function Setting() {
    return <div>
        <svg className={ classes.headerIcon } width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.7421 10.1648L26.9122 8.72465C26.2101 7.50608 24.6542 7.08571 23.434 7.7849V7.7849C22.8531 8.12707 22.16 8.22415 21.5075 8.05473C20.855 7.88531 20.2967 7.46331 19.9557 6.88178C19.7363 6.51215 19.6184 6.09114 19.614 5.66134V5.66134C19.6338 4.97224 19.3738 4.30449 18.8933 3.81018C18.4128 3.31586 17.7526 3.0371 17.0633 3.03738H15.3913C14.7159 3.03738 14.0683 3.3065 13.5919 3.78521C13.1155 4.26392 12.8495 4.91274 12.8527 5.58811V5.58811C12.8327 6.98252 11.6966 8.10236 10.302 8.10222C9.8722 8.09776 9.45119 7.97987 9.08156 7.7605V7.7605C7.86133 7.0613 6.3054 7.48168 5.6033 8.70024L4.71238 10.1648C4.01113 11.3818 4.42579 12.9368 5.63992 13.643V13.643C6.42912 14.0987 6.91529 14.9407 6.91529 15.852C6.91529 16.7633 6.42912 17.6054 5.63992 18.061V18.061C4.42733 18.7625 4.01222 20.3137 4.71238 21.5271V21.5271L5.55449 22.9794C5.88345 23.573 6.43539 24.011 7.08818 24.1965C7.74096 24.3821 8.44077 24.2998 9.03275 23.968V23.968C9.61469 23.6284 10.3082 23.5354 10.959 23.7095C11.6099 23.8837 12.1642 24.3107 12.4988 24.8955C12.7182 25.2651 12.8361 25.6861 12.8405 26.116V26.116C12.8405 27.5247 13.9825 28.6667 15.3913 28.6667H17.0633C18.4672 28.6667 19.6073 27.5321 19.614 26.1282V26.1282C19.6107 25.4507 19.8784 24.8 20.3575 24.3209C20.8365 23.8419 21.4872 23.5742 22.1647 23.5774C22.5935 23.5889 23.0128 23.7063 23.3852 23.9192V23.9192C24.6022 24.6204 26.1571 24.2057 26.8634 22.9916V22.9916L27.7421 21.5271C28.0823 20.9433 28.1756 20.248 28.0015 19.5951C27.8274 18.9423 27.4003 18.3858 26.8146 18.0488V18.0488C26.2289 17.7119 25.8018 17.1554 25.6277 16.5025C25.4536 15.8497 25.5469 15.1544 25.8871 14.5706C26.1082 14.1844 26.4284 13.8642 26.8146 13.643V13.643C28.0214 12.9371 28.4351 11.3913 27.7421 10.177V10.177V10.1648Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <ellipse cx="16.2334" cy="15.852" rx="3.51487" ry="3.51487" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>;
}

export default Setting;
