import React, { useEffect, useState } from 'react';
import classes from 'styles/Selectcurrency.module.scss';
import Euro from 'assets/Pounds.svg';
import Dollor from 'assets/Dollor.svg';
import Pounds from 'assets/Euro.svg';
import Nextbtn from 'assets/nextarrow.svg';
// import AED from 'assets/aed.svg';
import { NavLink } from 'react-router-dom';
import { useModal } from 'context/modal';
import DepositAmount from './DepositAmount';
import WithdrawAmount from './WithdrawAmount';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import { toast } from 'react-toastify';
import { customAlphabet } from 'nanoid';

const data = [
  {
    imgurl: Euro,
    heading: 'Euro',
    para: '(EUR)',
    link: '/',
    symbol: 'EUR',
  },
  {
    imgurl: Pounds,
    heading: 'British Pounds',
    para: '(GBP)',
    link: '/',
    symbol: 'GBP',
  },
  // {
  //   imgurl: AED,
  //   heading: 'UAE Dirham',
  //   para: '(AED)',
  //   link: '/',
  //   symbol: 'AED',
  // },
  {
    imgurl: Dollor,
    heading: 'U.S Dollars',
    para: '(USD/Others)',
    link: '/',
    symbol: 'USD',
  },
];

interface SelectCurrencyProps {
  isWithdraw?: boolean;
}

function SelectCurrency({ isWithdraw }: SelectCurrencyProps) {
  const { openModal } = useModal();
  const { headers, user } = useUser();

  const [fiatBalance, setFiatBalance] = useState<any>([]);

  // ? Method to generate UUID and open the `Deposite Amount` modal
  const openDepositAmtModal = (heading: any, symbol: string) => {
    const nanoid = customAlphabet('123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);
    let uuid = nanoid();

    console.log({
      uuid,
      symbol,
    });
    if (isWithdraw) {
      const symbolBalance = fiatBalance.find(
        (f: any) => f.fiat_curr === symbol,
      );

      let bankAccounts: any;

      if (symbol !== 'USD') {
        bankAccounts = user.associated_banks.filter((bankAccount: any) => {
          return (
            (bankAccount?.currency as string).toLowerCase() ===
            symbol.toLowerCase()
          );
        });
      } else {
        bankAccounts = user.associated_banks;
      }

      if (symbol !== 'USD' && bankAccounts.length === 0) {
        return toast.warn(`Please add a bank supporting ${heading}.`);
      }

      if (symbolBalance?.fiat_coins === 0) {
        toast.warn(
          `Your ${symbol} balance is 0. Please select different currency.`,
        );
      } else {
        return openModal(() => (
          <WithdrawAmount
            currency={heading}
            symbol={symbol}
            uuid={uuid}
            type="fiat"
            bankAccounts={bankAccounts}
          />
        ));
      }
    } else {
      return openModal(() => (
        <DepositAmount currency={heading} symbol={symbol} uuid={uuid} />
      ));
    }
    // isWithdraw
  };
  // * End of openDepositModal()

  // ? Method to fetch the FIAT balance of the user
  const fetchUserFiatBalance = async () => {
    try {
      const { data }: any = await axios.get(ROUTES.Fiat_Balance, { headers });

      setFiatBalance(data?.data?.fiat_bal);

      console.log({
        data: data?.data?.fiat_bal,
      });
    } catch (err) {
      console.log(err);
    }
  };
  // * End of fetchUserFiatBalance();

  useEffect(() => {
    if (isWithdraw) {
      fetchUserFiatBalance();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <h2>Select Currency</h2>
      <div className={classes.innerContainer}>
        {data.map((data) => {
          return (
            <>
              <div
                className={classes.options}
                onClick={() => openDepositAmtModal(data?.heading, data?.symbol)}
              >
                <img src={data.imgurl} alt="logo" />
                <h4>{data.heading}</h4>
                <p>{data.para}</p>
                <NavLink to="/">
                  <span>
                    <img src={Nextbtn} alt="arrow" />
                  </span>
                </NavLink>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SelectCurrency;
