import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import React, { useEffect, useState } from 'react';
import classes from 'styles/UserTransaction.module.scss';
import Loader from 'react-loader-spinner';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';

function UserTransaction() {
  const { headers } = useUser();
  const [typeTransactions, setTypeTransactions] = useState<string>('All');
  const [alltransaction, setAlltransaction] = useState([]);
  const [fiatTransactions, setFiatTransactions] = useState([]);
  const [cryptoBuyTransactions, setCryptoBuyTransactions] = useState([]);
  const [cryptoDepositTransactions, setCryptoDepositTransactions] = useState(
    [],
  );
  const [cryptoSellTransactions, setCryptoSellTransactions] = useState([]);
  const [cryptoWithdrawTransactions, setCryptoWithdrawTransactions] = useState(
    [],
  );
  const [FiatWithdrawTransactions, setFiatWithdrawTransactions] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchLatestTransactions = async () => {
    try {
      const { data } = await axios.get(ROUTES.USER_TRANSACTION, { headers });
      console.log({ data });

      setFiatTransactions(data?.fiat_deposit_transc_list?.list);
      setCryptoBuyTransactions(data?.crypto_buy_transc_list?.list);
      setCryptoDepositTransactions(data?.crypto_deposit_transc_list?.list);
      setCryptoSellTransactions(data?.crypto_sell_transc_list?.list);
      setCryptoWithdrawTransactions(data?.crypto_withdraw_transc_list?.list);
      setFiatWithdrawTransactions(data?.fiat_withdraw_transc_list?.list);
      setAlltransaction(data?.all_transactions_list);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // ? Method to conditionally render the amount
  const renderAmount = (transactionData: any) => {
    switch (transactionData?.transac_type) {
      case 'fiatdeposit':
        return CURRENCY_CONV.fiatAmtConv(transactionData?.depos_amt);

      case 'fiatwithdraw':
        return CURRENCY_CONV.fiatAmtConv(transactionData?.withdraw_amt);

      case 'cryptograb':
        return CURRENCY_CONV.fiatAmtConv(
          transactionData?.lp_order_response?.lp_executedQuantity,
        );

      case 'cryptodeposit':
        return CURRENCY_CONV.cryptoAmtConv(transactionData?.received_amt);

      case 'cryptorelease':
        return CURRENCY_CONV.cryptoAmtConv(
          transactionData?.lp_order_response?.lp_executedQuantity,
        );

      case 'cryptowithdraw':
        return CURRENCY_CONV.cryptoAmtConv(transactionData?.withdraw_amt);

      default:
        return '';
    }
  };
  // * End of renderAmount();

  // ? Method to conditionally render the currency
  const renderCurrency = (transactionData: any) => {
    switch (transactionData?.transac_type) {
      case 'fiatdeposit':
        return transactionData?.depos_curr;

      case 'fiatwithdraw':
        return 'USD';

      case 'cryptograb':
        return transactionData?.lp_order_response?.lp_baseCurrency;

      case 'cryptodeposit':
        return transactionData?.received_amt_curr;

      case 'cryptorelease':
        return transactionData?.lp_order_response?.lp_baseCurrency;

      case 'cryptowithdraw':
        return transactionData?.withdraw_curr;

      default:
        return '';
    }
  };
  // * End of renderCurrency();

  // ? Method to conditionally render the type of trasaction
  const renderTransactionType = (transactionType: string) => {
    switch (transactionType) {
      case 'fiatdeposit':
        return 'Fiat Deposit';

      case 'fiatwithdraw':
        return 'Fiat Withdraw';

      case 'cryptograb':
        return 'Crypto Buy';

      case 'cryptodeposit':
        return 'Crypto Deposit';

      case 'cryptorelease':
        return 'Crypto Sell';

      case 'cryptowithdraw':
        return 'Crypto Withdraw';

      default:
        return '';
    }
  };
  // * End of renderTransactionType();

  const renderTransactions = () => {
    if (loading) {
      return (
        <div className={classes.loaderSection}>
          <Loader type="ThreeDots" color={'#E8CB69'} height={15} />
        </div>
      );
    } else {
      return (
        <table>
          {typeTransactions === 'Fiat' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {fiatTransactions.length > 0 &&
                  fiatTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>{CURRENCY_CONV.fiatAmtConv(value.depos_amt)}</td>
                        <td>{value.depos_curr}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'CryptoBuy' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cryptoBuyTransactions.length > 0 &&
                  cryptoBuyTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>
                          {CURRENCY_CONV.fiatAmtConv(value.user_buying_price)}
                        </td>
                        <td>{value.lp_order_response.lp_baseCurrency}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'CryptoDeposit' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cryptoDepositTransactions.length > 0 &&
                  cryptoDepositTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>
                          {CURRENCY_CONV.cryptoAmtConv(value.received_amt)}
                        </td>
                        <td>{value.received_amt_curr}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'All' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {alltransaction?.length > 0 ? (
                  alltransaction.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateTimeConvert(value?.createdAt)}</td>
                        <td>{renderAmount(value)}</td>
                        <td>{renderCurrency(value)}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status}</td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <div className={classes.LodingContainer}>
                      <h3>Nothing to Show...</h3>
                    </div>
                  </>
                )}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'CryptoSell' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cryptoSellTransactions.length > 0 &&
                  cryptoSellTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>{renderAmount(value)}</td>
                        <td>{renderCurrency(value)}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'CryptoWithdraw' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount Withdrawn</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cryptoWithdrawTransactions.length > 0 &&
                  cryptoWithdrawTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>
                          {CURRENCY_CONV.cryptoAmtConv(value.withdraw_amt)}
                        </td>
                        <td>{value.withdraw_curr}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
          {typeTransactions === 'FiatWithdraw' ? (
            <>
              <thead>
                <tr>
                  <th>Date - Time</th>
                  <th>Amount Withdrawn</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {FiatWithdrawTransactions.length > 0 &&
                  FiatWithdrawTransactions.map((value: any) => {
                    return (
                      <tr>
                        <td>{DATE_CONV.dateConvert(value?.createdAt)}</td>
                        <td>{CURRENCY_CONV.fiatAmtConv(value.withdraw_amt)}</td>
                        <td>{renderCurrency(value)}</td>
                        <td>{renderTransactionType(value?.transac_type)}</td>
                        <td>{value.transc_status.toUpperCase()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          ) : null}
        </table>
      );
    }
  };

  // ? Method to conditionally render the Title of the page and the dropdown btn
  const renderTitle = () => {
    switch (typeTransactions) {
      case 'All':
        return 'All Transactions';

      case 'Fiat':
        return 'Fiat Deposit Transactions';

      case 'FiatWithdraw':
        return 'Fiat Withdraw Transactions';

      case 'CryptoBuy':
        return 'Crypto Buy Transactions';

      case 'CryptoDeposit':
        return 'Crypto Deposit Transactions';

      case 'CryptoSell':
        return 'Crypto Sell Transactions';

      case 'CryptoWithdraw':
        return 'Crypto Withdraw Transactions';
    }
  };
  // * End of renderTitle();

  useEffect(() => {
    fetchLatestTransactions();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.Container}>
      <h1>All Transactions</h1>

      <section>
        <div className={classes.header}>
          <h1>{renderTitle()}</h1>

          <Menu
            menuButton={
              <MenuButton className={classes.dropBtn}>
                <span>{renderTitle()}</span>
                <img src={Arrowdown} alt="arrow-img" />
              </MenuButton>
            }
            transition
            menuClassName="drop-down"
            onItemClick={(e) => setTypeTransactions(() => e.value)}
          >
            <MenuItem
              value="All"
              className={
                typeTransactions === 'All' ? 'dropdown-item-selected' : ''
              }
            >
              All Transactions
            </MenuItem>
            <SubMenu label="Fiat Transactions" menuClassName="drop-down">
              <MenuItem
                value="Fiat"
                className={
                  typeTransactions === 'Fiat' ? 'dropdown-item-selected' : ''
                }
              >
                Fiat Deposit Transactions
              </MenuItem>
              <MenuItem
                value="FiatWithdraw"
                className={
                  typeTransactions === 'FiatWithdraw'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Fiat Withdraw Transactions
              </MenuItem>
            </SubMenu>
            <SubMenu label="Crypto Transactions" menuClassName="drop-down">
              <MenuItem
                value="CryptoBuy"
                className={
                  typeTransactions === 'CryptoBuy'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Crypto Buy Transactions
              </MenuItem>
              <MenuItem
                value="CryptoDeposit"
                className={
                  typeTransactions === 'CryptoDeposit'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Crypto Deposit Transactions
              </MenuItem>
              <MenuItem
                value="CryptoSell"
                className={
                  typeTransactions === 'CryptoSell'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Crypto Sell Transactions
              </MenuItem>
              <MenuItem
                value="CryptoWithdraw"
                className={
                  typeTransactions === 'CryptoWithdraw'
                    ? 'dropdown-item-selected'
                    : ''
                }
              >
                Crypto Withdraw Transactions
              </MenuItem>
            </SubMenu>
          </Menu>
        </div>

        {renderTransactions()}
      </section>
    </div>
  );
}

export default UserTransaction;
