import React, { useEffect, useState } from 'react';
import ClientScreen from '../../components/ClientScreen';
import classes from '../../styles/ClientDashboard.module.scss';
import CoinCard from '../../components/CoinCard';
import useExchange from '../../hooks/useExchange';
import useQuery from '../../hooks/useQuery';
import { Currency, CurrencyType, TransactionType } from '../../interfaces';
import BuyCryptoForm from '../../components/buyCryptoForm';
import SellCryptoForm from '../../components/sellCryptoForm';
import Footer from 'components/common/Footer';
import { useModal } from 'context/modal';
import InitailPopup from 'modals/InitailPopup';

export default function ClientDashboard() {
  const { coins, fiats } = useExchange();
  const transact = useQuery('transact') as TransactionType | undefined;
  // eslint-disable-next-line
  const isBuy = transact === TransactionType.Buy;
  const [selected, setSelected] = useState({ coin: coins[0], fiat: fiats[0] });
  const changeSelected = (currency: CurrencyType, object: Currency) =>
    setSelected((p) => ({ ...p, [currency]: object }));
  const { openModal } = useModal();

  // useEffect(() => {
  //   openModal(InitailPopup);
  //   // eslint-disable-next-line
  // }, []);
  return (
    <>
      <ClientScreen title="Dashboard" transact={transact}>
        <div className={classes.container}>
          <div className={classes.coins}>
            {/* header+++++++++++++++++++++++++++++++++++ */}
            {!transact && (
              <div className={classes.header}>
                <h3>Available Cryptocurrencies</h3>
              </div>
            )}
            {/* body+++++++++++++++++++++++++++++++ */}
            {!transact && (
              <div className={classes.body}>
                {!transact &&
                  coins.map((coin, i) => (
                    <div key={i.toString()}>
                      <div>
                        <CoinCard
                          changeSelected={changeSelected}
                          transact={!transact}
                          {...coin}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {transact === 'Buy' && <BuyCryptoForm coin={selected.coin} />}
          {transact === 'Sell' && <SellCryptoForm coin={selected.coin} />}
        </div>
      </ClientScreen>
      <Footer />
    </>
  );
}
