import React, { useRef, useState, useEffect } from 'react';
import classes from '../styles/AdminHeader.module.scss';
import logo from '../assets/logo.png';
import useOutsideClick from '../hooks/useOutsideClick';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from './Dropdown';
import Home from 'assets/Navbar svg/Home';
import Wallet from 'assets/Navbar svg/Wallet';
import Setting from 'assets/Navbar svg/Setting';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { useAdmin } from 'context/admin';
import { useNotification } from 'context/notification';
import NotificationsDropdown from './NotificationsDropdown';
import NotificationBell from 'assets/Navbar svg/NotificationBell';
import ReactTooltip from 'react-tooltip';

export default function AdminHeader() {
  const [showDropdown, setShowDropDown] = useState(false);
  const dropRef = useRef(null);
  const { logout, admin } = useAdmin();
  const { notifications } = useNotification();
  const [isNotificationsOpen, setIsNotificationsOpen] =
    useState<boolean>(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const notificationRef = useRef();
  const { push } = useHistory();

  useEffect(() => {
    const checkIfClickedOutsideNotificationMenu = (e: { target: any }) => {
      if (
        isNotificationsOpen &&
        notificationRef.current &&
        !(notificationRef.current as any).contains(e.target)
      ) {
        setIsNotificationsOpen(false);
      }
    };
    document.addEventListener(
      'mousedown',
      checkIfClickedOutsideNotificationMenu,
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        checkIfClickedOutsideNotificationMenu,
      );
    };
  }, [isNotificationsOpen]);

  useEffect(() => {
    setUnreadNotifications(notifications.length);
  }, [notifications]);

  const togglingNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setUnreadNotifications(0);
  };

  useOutsideClick(dropRef, () => {
    setShowDropDown(false);
  });

  // console.log(admin.role)

  return (
    <header className={classes.container}>
      <img
        src={logo}
        alt="ENCRYPTUS LOGO"
        className={classes.left}
        onClick={() => push('/admin/dashboard')}
      />
      {admin?.role && (
        <span className={classes.adminType}>
          {admin?.role === 'admin'
            ? 'Super'
            : (admin.role as string)[0].toUpperCase() +
              (admin.role as string).slice(1)}
          {' Admin'}
        </span>
      )}
      <div ref={dropRef} className={classes.right}>
        {/* navigation links++++++++++++++++++++++ */}
        <div className={classes.navigation}>
          <Link
            to="/admin/dashboard"
            className={classes.icons}
            data-tip
            data-for="registerTip1"
          >
            <Home />
            <ReactTooltip
              id="registerTip1"
              place="bottom"
              effect="float"
              textColor="#DBB42C"
              backgroundColor="#173486"
            >
              Home
            </ReactTooltip>
          </Link>
          <Link
            to="/admin/bank_applications"
            className={classes.icons}
            data-tip
            data-for="registerTip2"
          >
            <Wallet />
            <ReactTooltip
              id="registerTip2"
              place="bottom"
              effect="float"
              textColor="#DBB42C"
              backgroundColor="#173486"
            >
              Bank Application
            </ReactTooltip>
          </Link>
          {admin?.role === 'admin' ? (
            <Link
              to="/admin/settings"
              className={classes.icons}
              data-tip
              data-for="registerTip3"
            >
              <Setting />
              <ReactTooltip
                id="registerTip3"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Settings
              </ReactTooltip>
            </Link>
          ) : (
            <></>
          )}
          <div
            className={classes.icons}
            ref={notificationRef as any}
            data-tip
            data-for="registerTip4"
          >
            <div
              className={classes.notificationIcon}
              onClick={togglingNotifications}
            >
              <NotificationBell />
              <ReactTooltip
                id="registerTip4"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Notifications
              </ReactTooltip>
            </div>
            {unreadNotifications > 0 && (
              <div className={classes.newNotifications}>
                {unreadNotifications}
              </div>
            )}
            <NotificationsDropdown
              reference={notificationRef}
              isOpen={isNotificationsOpen}
              close={() => setIsNotificationsOpen(false)}
              data={notifications.reverse()}
            />
          </div>
        </div>
        <div
          className={classes.details}
          onClick={() => setShowDropDown((p) => !p)}
        >
          <span className={classes.initials}>JP</span>
          <p>{(admin && admin.email) || ''}</p>
          <img src={Arrowdown} alt="arrow-img" />
        </div>
        {/* Dropdown ++++++++++++++++++++++++++++ */}
        {showDropdown && (
          <Dropdown handleClick={() => setShowDropDown(false)}>
            <hr />
            <p onClick={() => logout()}>Logout</p>
          </Dropdown>
        )}
      </div>
    </header>
  );
}
