import axios from 'axios';
import { useState } from 'react';
import Button from '../../components/Button';
import { ROUTES } from '../../config';
import { useUser } from '../../context/user';
import { anyObject } from '../../interfaces';
import classes from '../../styles/Wallets.module.scss';
import * as yup from 'yup';
import Table from '../../components/Table';
import { toast } from 'react-toastify';
import Warning from '../../components/Warning';
import usePaginate from '../../hooks/usePaginate';
import { useModal } from '../../context/modal';
import { MainSteps } from '../../modals/Stepbar';
import Backdrop from 'assets/backdrop.png';
import { useHistory } from 'react-router-dom';
import { DATE_CONV } from 'utils/DateConv';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import Arrowdown from 'assets/arrow-down-black.svg';

// ? Crypto Asset Images
import bitcoin from 'assets/btc.png';
import eth from 'assets/eth.png';
import usdt from 'assets/usdt.png';
import usdc from 'assets/usdc.png';
import matic from 'assets/matic.png';
import Footer from 'components/common/Footer';

const schema = yup.object().shape({
  walletAddress: yup
    .string()
    .required()
    .min(26, 'Wallet address length should be at least 26'),
  inputLabel: yup.string().required(),
});

// ? Initial Values for the Form
const initialValues = {
  walletAddress: '',
  inputLabel: '',
};

const coinTypes: Array<string> = [
  'bitcoin',
  'ethereum',
  'tether',
  'matic',
  'usdc',
];

const networksList: any = {
  bitcoin: ['bitcoin'],
  ethereum: ['ethereum'],
  tether: [
    'ethereum',
    // 'algorand',
    // 'avalanche_c_chain',
    // 'binance',
    // 'binance_smart_chain',
    // 'omni',
    // 'solana',
    // 'tron',
  ],
  matic: [
    'ethereum',
    // 'binance',
    // 'binance_smart_chain'
  ],
  usdc: [
    'ethereum',
    // 'algorand',
    // 'avalanche_c_chain',
    // 'binance',
    // 'binance_smart_chain',
    // 'hedera',
    // 'solana',
    // 'stellar',
    // 'tron',
  ],
};

const cryptoAssetImages: any = {
  bitcoin: bitcoin,
  ethereum: eth,
  tether: usdt,
  matic: matic,
  usdc: usdc,
};

export default function Wallets() {
  const [loading, setLoading] = useState(false);

  // ? State for the form dropdowns
  const [coinType, setCoinType] = useState<string>(coinTypes[0]);
  const [associatedNetwork, setAssociatedNetwork] = useState<string>('bitcoin');

  const { headers, user } = useUser();
  const [wallets, { reload }] = usePaginate<any>({
    route: ROUTES.FETCH_USER_WALLETS,
  });
  const { openModal, closeModal } = useModal();

  const history = useHistory();

  const submitWallet = async (formData: any) => {
    try {
      const { data } = await axios.post(ROUTES.CREATE_WALLET, formData, {
        headers,
      });
      if (data.wflagged) {
        toast.warning(`Wallet under manual inspection,contact support`);
        await reload();
        closeModal();
        return {
          success: false,
        };
      }
      await reload();
      return {
        success: true,
      };
    } catch (error: any) {
      closeModal();
      return toast.error(error.response.data.message);
    }
  };

  const handleSubmit = async (formData: anyObject) => {
    setLoading(() => true);

    formData.coin_type = coinType;
    formData.associated_network = associatedNetwork;

    // ? Checking if the user has enabled MFA and if not
    // * If enabled, the user can proceed adding their wallet
    // * If not enabled, user will be redirect to settings page to enable MFA
    if (user?.enabled_3fa === false) {
      history.push('/settings_user');

      toast.warn(
        'Please enable Multifactor authentication (MFA) to add wallets!',
      );
    } else {
      try {
        openModal(() => (
          <MainSteps submit={submitWallet} walletData={formData} />
        ));
        setLoading(() => false);
        return;
      } catch (err: any) {
        if (
          err.response.data.message ===
          'EXPIRED ACCESS TOKEN SUPPLIED IN AUTHORIZATION HEADER'
        ) {
          toast.loading('Session expired...');
          setTimeout(() => {
            return window.location.reload();
          }, 2000);
        }
        return toast.error(err.response.data.message);
      }
    }

    setLoading(() => false);
  };

  const deleteWallet = async (walletId: string) => {
    try {
      const { data } = await axios.delete(ROUTES.DELETE_USER_WALLET(walletId), {
        headers,
      });
      toast.success(data.message);
      return await reload();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  // eslint-disable-next-line
  function copyText(walletAddress: any) {
    navigator.clipboard.writeText(walletAddress);
  }

  // ? Component that renders the Error message for the Formik Fields
  const renderError = (message: any) => {
    return <p className={classes.formErrorText}>{message}</p>;
  };
  // * End of renderError();

  return (
    <div className={classes.container}>
      {/* backdriop++++++++++++++++++++++ */}
      <div className={classes.backdrop}>
        <img src={Backdrop} alt="backdrop"></img>
      </div>
      {/* +++++++++++++++++++++++++++++++++++ */}
      {!user?.kycStatus ? (
        <Warning
          title="KYC not approved"
          description="You cannot add a wallet before your KYC is approved"
        />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <Form className={classes.addWalletForm} autoComplete="off">
              <div className={classes.formElement}>
                <label>Crypto Asset</label>
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropBtn}>
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gridGap: '10px',
                          color: 'black',
                        }}
                      >
                        <img
                          src={cryptoAssetImages[coinType]}
                          alt="coin"
                          style={{
                            width: '30px',
                            height: '30px',
                          }}
                        />
                        {coinType.toUpperCase()}
                      </span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName={`drop-down ${classes.currencyDropdown}`}
                  onItemClick={(e) => {
                    setCoinType(() => e.value);
                    setAssociatedNetwork(() => networksList[e.value][0]);
                  }}
                >
                  {coinTypes.map((coin: string) => (
                    <MenuItem
                      key={coin}
                      value={coin}
                      className={
                        coinType === coin ? 'dropdown-item-selected' : ''
                      }
                    >
                      {coin.toUpperCase()}{' '}
                      <img
                        src={cryptoAssetImages[coin]}
                        alt="coin"
                        style={{
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </div>

              <div className={classes.formElement}>
                <label>Associated Network</label>
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropBtn}>
                      <span
                        style={{ color: 'black', textTransform: 'capitalize' }}
                      >
                        {associatedNetwork}
                      </span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName={`drop-down ${classes.currencyDropdown}`}
                  onItemClick={(e) => setAssociatedNetwork(() => e.value)}
                >
                  {networksList[coinType].map((network: string) => (
                    <MenuItem
                      key={network}
                      value={network}
                      style={{ textTransform: 'capitalize' }}
                      className={
                        associatedNetwork === network
                          ? 'dropdown-item-selected'
                          : ''
                      }
                    >
                      {network}{' '}
                    </MenuItem>
                  ))}
                </Menu>
              </div>

              <div className={classes.formElement}>
                <label>Wallet address</label>
                <Field
                  type="text"
                  name="walletAddress"
                  autoComplete="off"
                  required
                />
                <div className={classes.formErrortextSection}>
                  <ErrorMessage name="accountHolderName" render={renderError} />
                </div>
              </div>

              <div className={classes.formElement}>
                <label>Label</label>
                <Field
                  type="text"
                  name="inputLabel"
                  autoComplete="off"
                  required
                />
                <div className={classes.formErrortextSection}>
                  <ErrorMessage
                    name="accountHolderAddress"
                    render={renderError}
                  />
                </div>
              </div>

              <div className={classes.formButtonSection}>
                <Button
                  type="submit"
                  style={{ width: '250px' }}
                  loading={loading}
                  disabled={loading}
                >
                  Add Wallet
                </Button>
              </div>
            </Form>
          </Formik>

          {!!wallets?.length && (
            <div className={classes.tableContainer}>
              <Table className={classes.wallets}>
                <thead>
                  <tr>
                    <th>Coin Type</th>
                    <th>Associated Network</th>
                    <th>Address</th>
                    <th>Date Added</th>
                    <th>Label</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {wallets.map(
                  ({
                    coin_type,
                    associated_network,
                    walletAddress,
                    createdAt,
                    _id,
                    isActive,
                    trm_flagged,
                    inputLabel,
                  }) => (
                    <tr>
                      <td>
                        {coin_type?.toUpperCase() ||
                          associated_network?.toUpperCase()}
                      </td>
                      <td>
                        {associated_network === 'bitcoin'
                          ? 'BITCOIN'
                          : associated_network?.toUpperCase()}
                      </td>
                      <td>
                        <>{walletAddress}</>
                      </td>
                      <td>{DATE_CONV.dateConvert(createdAt)}</td>
                      <td>{inputLabel}</td>
                      <td
                        className={[
                          classes.status, //
                          classes[isActive.toString()],
                        ].join(' ')}
                      >
                        {isActive ? 'ACTIVE' : 'INACTIVE'}
                      </td>
                      {trm_flagged ? (
                        <td>
                          <Button
                            invert
                            size={'s'}
                            onClick={() => {
                              toast.warn(
                                'Your wallet is inactive, please add another address or contact us at hello@encryptus.io',
                                {
                                  autoClose: false,
                                },
                              );
                              window.location.href =
                                'mailto:hello@encryptus.io';
                            }}
                          >
                            📞Contact
                          </Button>
                        </td>
                      ) : (
                        <td>
                          <Button
                            invert
                            onClick={() => deleteWallet(_id)}
                            size={'s'}
                          >
                            Remove
                          </Button>
                        </td>
                      )}
                    </tr>
                  ),
                )}
              </Table>
            </div>
          )}
        </>
      )}
      <Footer />
    </div>
  );
}
