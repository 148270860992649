import axios from 'axios';
import { useAdmin } from 'context/admin';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PaginateProps } from '../components/Paginate';

interface Props {
  route: string;
  params?: any;
  infinite?: boolean;
}

interface Options {
  reload: () => Promise<void>;
  loadMore: () => Promise<void>;
  loadPrev: () => Promise<void>;
  loadPage: () => Promise<void>;
  page: number;
  lapse: any;
  hasMore: boolean;
  total: number;
  lastPage: number;
  loading: boolean;
  paginateProps: PaginateProps;
}

interface Fetch {
  page?: number;
  append?: boolean;
}

interface Fetched<T> {
  list: T[];
  thisPage: number;
  total: number;
  hasMore: boolean;
}

export default function useAdminPaginate<T>(
  { route, params = {}, infinite }: Props,
  deps: any[] = [],
  cb: (list: T[]) => T[] = (list) => list,
) {
  const { headers } = useAdmin();
  const [list, setList] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lapse, setError] = useState({});
  let value = false;

  const getList = async ({
    page: fetchPage = page + 1,
    append = false,
  }: Fetch = {}) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data }: { data: Fetched<T> } = await axios.get(route, {
        params: {
          sort: { createdAt: -1 },
          page: fetchPage,
          ...params,
        },
        headers,
      });
      const l = cb(data.list);
      console.log(data.list);
      setList((p) => (infinite && append ? [...p, ...l] : l));
      setPage(data.thisPage);
      setHasMore(data.hasMore);
      setTotal(data.total);
    } catch (error: any) {
      if (error.response && !value) {
        if (error.response.status === 401) {
          setError(error.response);
          toast.warning('You Cannot Access This Flow');
          value = true;
          // console.log(lapse.response.status)
        }
        // else {
        //   toast.warn("We Encounter an Error, Please Try Again After Sometime");
        //   value = true
        // }
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () =>
    hasMore ? getList({ page: page + 1, append: true }) : undefined;
  const reload = () => getList({ page: 1 });
  const loadPrev = page === 1 ? undefined : () => getList({ page: page - 1 });
  const loadPage = (page: number) => getList({ page });
  const lastPage = Math.ceil(total / 10);

  useEffect(() => {
    getList({ page: 1 });
    // eslint-disable-next-line
  }, [headers, ...deps]);

  return [
    list,
    {
      reload,
      loadMore,
      loadPrev,
      loadPage,
      page,
      lapse,
      hasMore,
      total,
      lastPage,
      loading,
      paginateProps: {
        currentPage: page,
        lastPage,
        next: loadMore,
        prev: loadPrev,
        jumpTo: loadPage,
      },
    },
  ] as [T[], Options];
}
