import React from 'react';
import classes from 'styles/Depositemain.module.scss';
import Banklogo from 'assets/Group 75.svg';
import Bitcoinlogo from 'assets/bank logo 2.svg';
import Nextbtn from 'assets/nextarrow.svg';
import { NavLink } from 'react-router-dom';
import { useModal } from 'context/modal';
import SelectCurrency from './SelectCurrency';
import { useUser } from 'context/user';
import DepositCrypto from './DepositCrypto';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const data = [
  {
    imgurl: Banklogo,
    heading: 'Bank Transfer',
    para: '1-2 Business Days',
    link: SelectCurrency,
  },
  { imgurl: Bitcoinlogo, heading: 'Cryptocurrency', para: '', link: '/' },
];

function Depositemain() {
  const { user } = useUser();
  const { openModal, closeModal } = useModal();
  const history = useHistory();

  // ? Method to open the corresponding modal based on the user choice
  const handleMethodSelection = (option: string) => {
    if (user?.enabled_3fa === false) {
      closeModal();

      history.push('settings_user');

      toast.warn('Please enable MFA to proceed with Deposit!');
    } else {
      if (option === 'Bank Transfer') {
        openModal(() => <SelectCurrency />);
      } else {
        openModal(DepositCrypto);
      }
    }
  };
  // * End of handleMethodSelection()

  return (
    <>
      <div className={classes.container}>
        <h2>Deposit</h2>
        <div className={classes.innerContainer}>
          {data.map((data) => {
            return (
              <>
                <div
                  className={classes.options}
                  onClick={() => handleMethodSelection(data.heading)}
                >
                  <img src={data.imgurl} alt="logo" />
                  <h4>{data.heading}</h4>
                  <p>{data.para}</p>
                  <NavLink to="/">
                    <span>
                      <img src={Nextbtn} alt="arrowbtns" />
                    </span>
                  </NavLink>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Depositemain;
