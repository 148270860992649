import React, { useRef, useState, useEffect } from 'react';
import classes from '../styles/Header.module.scss';
import logo from '../assets/logo.png';
import useOutsideClick from '../hooks/useOutsideClick';
import { useUser } from '../context/user';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from './Dropdown';
import Home from 'assets/Navbar svg/Home';
import Wallet from 'assets/Navbar svg/Wallet';
import Arrowdown from 'assets/Arrow - Down 4.png';
import { useNotification } from 'context/notification';
import { useModal } from 'context/modal';
import Depositemain from 'modals/Depositemain';
import Withdrawmain from 'modals/Withdrawmain';
import Bank from 'assets/Navbar svg/Bank';
import NotificationsDropdown from './NotificationsDropdown';
import NotificationBell from 'assets/Navbar svg/NotificationBell';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { ROUTES } from 'config';
import axios from 'axios';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import ForgotPasswordOTP from 'modals/ForgotPasswordOTP';
import { Steps } from 'intro.js-react';
import '../styles/Introtooltip.scss';

const steps = [
  {
    element: '.stepOne',
    intro: 'Enable 3FA',
    position: 'left',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '.stepTwo',
    intro: 'Add Wallet',
    position: 'left',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '.stepThree',
    intro: 'Add Bank Account',
    position: 'left',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
];

export default function Header() {
  const { notifications } = useNotification();
  const [fiatBalance, setFiatBalance] = useState<any>({
    USD: 0,
    EUR: 0,
    GBP: 0,
    AED: 0,
  });

  const [verificationCompleteScreen, setVerificationCompleteScreen] =
    useState(false);
  const [enableSteps, setEnableSteps] = useState(false);
  const [showDummyDropdown, setShowDummyDropdown] = useState(false);

  const [isNotificationsOpen, setIsNotificationsOpen] =
    useState<boolean>(false);
  const notificationRef = useRef();

  const [showDropdown, setShowDropDown] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [exitscreenload, setexitscreenload] = useState(false);
  const dropRef = useRef(null);
  const { openModal } = useModal();
  const { push } = useHistory();

  useOutsideClick(dropRef, () => {
    setShowDropDown(false);
  });

  // eslint-disable-next-line
  const { logout, user } = useUser();
  const { headers } = useUser();

  useEffect(() => {
    const checkIfClickedOutsideNotificationMenu = (e: { target: any }) => {
      if (
        isNotificationsOpen &&
        notificationRef.current &&
        !(notificationRef.current as any).contains(e.target)
      ) {
        setIsNotificationsOpen(false);
      }
    };
    document.addEventListener(
      'mousedown',
      checkIfClickedOutsideNotificationMenu,
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        checkIfClickedOutsideNotificationMenu,
      );
    };
    // eslint-disable-next-line
  }, [isNotificationsOpen]);

  useEffect(() => {
    setUnreadNotifications(notifications.length);
    // eslint-disable-next-line
  }, [notifications]);

  const togglingNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setUnreadNotifications(0);
  };

  // checking for the balance
  useEffect(() => {
    const fetching = async () => {
      try {
        const { data: fiatBalanceData } = await axios.get(ROUTES.Fiat_Balance, {
          headers,
        });
        if (fiatBalanceData?.data?.fiat_bal) {
          const USDBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'USD',
          );
          const AEDBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'AED',
          );
          const GBPBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'GBP',
          );
          const EURBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'EUR',
          );
          setFiatBalance({
            USD: CURRENCY_CONV.fiatAmtConv(USDBalance.fiat_coins),
            AED: CURRENCY_CONV.fiatAmtConv(AEDBalance.fiat_coins),
            GBP: CURRENCY_CONV.fiatAmtConv(GBPBalance.fiat_coins),
            EUR: CURRENCY_CONV.fiatAmtConv(EURBalance.fiat_coins),
          });
        }
      } catch (err) {
        console.log('error in header');
      }
    };
    fetching();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const balance =
    fiatBalance.AED > 0 ||
    fiatBalance.USD > 0 ||
    fiatBalance.EUR > 0 ||
    fiatBalance.GBP > 0;

  const handleChangePassword = async () => {
    try {
      toast.success(`A verification code will be sent at ${user?.email}`);
      openModal(() => (
        <ForgotPasswordOTP email={user?.email} selfChange={true} />
      ));
      let body = {
        userEmail: user?.email,
      };

      const { data } = await axios.post(ROUTES.FORGOT_PASSWORD, body, {
        headers,
      });

      if (!data || data?.status !== 'success') {
        toast.error(data.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.response?.data);
    }
  };

  // ? Method to render the 'VERIFICATION COMPLETE' text and the 'LET'S GET STARTED' screen
  const walkthroughTextScreen = () => {
    console.log('user data ', user);
    setTimeout(() => {
      setVerificationCompleteScreen(false);

      setEnableSteps(true);
    }, 2000);

    if (verificationCompleteScreen) {
      return (
        <div className={classes.walkthroughBackdrop}>
          <span>VERIFICATION COMPLETE</span>
        </div>
      );
    }
  };
  // * End of walkthroughTextScreen();

  const onExit = () => {
    // this.setState(() => ({ stepsEnabled: false }));
    // alert('EXIT')
    !localStorage.getItem('Overview') &&
      user?.kycStatus &&
      setexitscreenload(true);
    setShowDummyDropdown(false);
  };

  const ExitScreen = () => {
    return (
      <div className={classes.walkthroughBackdrop}>
        <span>Let's Get Started!</span>
        <button
          onClick={() => {
            localStorage.setItem('Overview', 'true');
            setexitscreenload(false);
            setShowDummyDropdown(false);
          }}
        >
          Click Here
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (!localStorage.getItem('Overview') && user?.kycStatus) {
      console.log('Show dropdown');
      setVerificationCompleteScreen(true);
      setShowDummyDropdown(true);
    }
  }, [user?.kycStatus]);

  return (
    <>
      {localStorage.getItem('Overview') && user?.kycStatus
        ? ''
        : !localStorage.getItem('Overview') && !user?.kycStatus
        ? ''
        : walkthroughTextScreen()}
      {exitscreenload ? ExitScreen() : ''}
      {localStorage.getItem('Overview') && user?.kycStatus ? (
        ''
      ) : !localStorage.getItem('Overview') && !user?.kycStatus ? (
        ''
      ) : (
        <Steps
          enabled={enableSteps}
          steps={steps}
          initialStep={0}
          onExit={onExit}
        />
      )}

      <header className={classes.container}>
        <img
          src={logo}
          alt="crypto trading platform"
          className={classes.left}
          onClick={() => push('/dashboard')}
        />

        <div ref={dropRef} className={classes.right}>
          {/* navigation links++++++++++++++++++++++ */}
          <div className={classes.navigation}>
            <Link
              to="/dashboard"
              className={classes.icons}
              data-tip
              data-for="registerTip1"
            >
              <Home />
              <ReactTooltip
                id="registerTip1"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Home
              </ReactTooltip>
            </Link>
            <Link
              to="/wallets"
              className={classes.icons}
              data-tip
              data-for="registerTip2"
            >
              <Wallet />
              <ReactTooltip
                id="registerTip2"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Wallets
              </ReactTooltip>
            </Link>
            <Link
              to="/bank_accounts"
              className={classes.icons}
              data-tip
              data-for="registerTip3"
            >
              <Bank />
              <ReactTooltip
                id="registerTip3"
                place="bottom"
                effect="float"
                textColor="#DBB42C"
                backgroundColor="#173486"
              >
                Bank Accounts
              </ReactTooltip>
              {/* <Chat /> */}
            </Link>
            <div
              className={classes.icons}
              ref={notificationRef as any}
              data-tip
              data-for="registerTip4"
            >
              <div
                className={classes.notificationIcon}
                onClick={togglingNotifications}
              >
                <NotificationBell />
                <ReactTooltip
                  id="registerTip4"
                  place="bottom"
                  effect="float"
                  textColor="#DBB42C"
                  backgroundColor="#173486"
                >
                  Notification
                </ReactTooltip>
              </div>
              {unreadNotifications > 0 && (
                <div className={classes.newNotifications}>
                  {unreadNotifications}
                </div>
              )}
              <NotificationsDropdown
                reference={notificationRef}
                isOpen={isNotificationsOpen}
                close={() => setIsNotificationsOpen(false)}
                data={notifications.reverse()}
              />
            </div>
          </div>
          {/* withdraw btn ++++++++++++++++++++++++++++++ */}
          {user &&
          user.kycStatus === true &&
          user.associated_banks.length > 0 ? (
            <Link to="/">
              <button
                className={classes.depositebtn}
                onClick={() => openModal(Withdrawmain)}
              >
                Withdraw
              </button>
            </Link>
          ) : (
            <></>
          )}
          {/* deposite btn ++++++++++++++++++++++++++++++ */}
          {user &&
          user.kycStatus === true &&
          user.associated_banks.length > 0 ? (
            <Link to="/">
              <button
                className={classes.depositebtn}
                onClick={() => openModal(Depositemain)}
              >
                Deposit
              </button>
            </Link>
          ) : (
            <></>
          )}
          <div
            className={classes.details}
            onClick={() =>
              !showDropdown ? setShowDropDown(true) : setShowDropDown(false)
            }
          >
            <span className={classes.initials}>{user?.name_initials}</span>
            <p>{user?.email ? user?.email?.slice(0, 20) + '..' : '...'}</p>
            <img src={Arrowdown} alt="arrow-img" />
          </div>
          {/* Dropdown ++++++++++++++++++++++++++++ */}
          {showDropdown && (
            <Dropdown
              handleClick={() => setShowDropDown(false)}
              handleShut={() => setShowDropDown(true)}
            >
              <Link to="/bank_accounts">
                <p>My Bank Account</p>
              </Link>
              <hr />
              <Link to="/wallets">
                <p>My Wallets</p>
              </Link>
              <hr />
              <Link to="/balance">
                <p>My Balance</p>
              </Link>
              <hr />
              <div onClick={handleChangePassword}>
                <p>Change Password</p>
              </div>
              <hr />
              <Link to="/settings_user">
                <p>Settings</p>
              </Link>
              <hr />
              <p onClick={() => logout()}>Logout</p>
            </Dropdown>
          )}

          {showDummyDropdown ? (
            <Dropdown
            // handleClick={() => setShowDropDown(false)}
            // handleShut={() => setShowDropDown(true)}
            >
              <Link to="/bank_accounts" className="stepThree">
                <p>My Bank Account</p>
              </Link>
              <hr />
              <Link to="/wallets" className="stepTwo">
                <p>My Wallets</p>
              </Link>
              <hr />
              <Link to="/balance">
                <p>My Balance</p>
              </Link>
              <hr />
              <div onClick={handleChangePassword}>
                <p>Change Password</p>
              </div>
              <hr />
              <Link to="/settings_user" className="stepOne">
                <p>Settings</p>
              </Link>
              <hr />
              <p onClick={() => logout()}>Logout</p>
            </Dropdown>
          ) : (
            ''
          )}
        </div>
      </header>
    </>
  );
}
