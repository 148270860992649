import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classes from 'styles/Approvalmodal.module.scss';
import * as yup from 'yup';
import { useAdmin } from 'context/admin';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_HOST } from 'config';
import { useModal } from 'context/modal';

interface Id {
  id: string;
  render: () => void;
}

function CryptoApprovalmodal({ id, render }: Id) {
  const url = `${API_HOST}/encryptus/sadmin/rsigs/update/CSTicket/${id}`;
  const { headers } = useAdmin();
  const { closeModal } = useModal();
  const handlesubmit = async (values: any) => {
    try {
      const body = {
        approval_needed: parseInt(values.approval),
      };
      console.log(body);
      const result = await axios.patch(url, body, {
        headers,
      });
      toast.success('Completed Successfully');
      closeModal();
      render();
      console.log('render again');
      console.log(result);
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };

  const initialValues = {
    approval: '',
  };

  const schema = yup.object().shape({
    approval: yup.number().required('Must give input'),
  });

  return (
    <div className={classes.Container}>
      <h2>Set Approval count</h2>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handlesubmit}
        >
          <Form>
            <div className={classes.inputgroup}>
              <label>Approval Count</label>
              <Field type="text" name="approval" className={classes.inputs} />
              <ErrorMessage name="approval" />
            </div>
            <div className={classes.btndiv}>
              <button>Submit</button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default CryptoApprovalmodal;
