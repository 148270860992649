import { useState } from 'react';
import axios from 'axios';
import { ROUTES } from 'config';
import { useModal } from 'context/modal';
import { useUser } from 'context/user';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import classes from 'styles/ModalStep1.module.scss';
import { modalcss } from '../ExtraDiv';
import MicrosoftAuthenticator from 'assets/microsoftAuthenticator.png';
import TwilioAuthy from 'assets/twilioAuthy.png';

interface Props {
  clickhandler: () => void;
  qrGenerated: boolean;
  qrcode: string;
}

function ModalStep2({ clickhandler, qrGenerated, qrcode }: Props) {
  const { closeModal } = useModal();

  const [gacode, setGacode] = useState('');
  const { headers } = useUser();

  const handleSubmit2 = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // // opt try catch+++++++++++++++++++++
    try {
      // make api call to enable 3fa for user
      const { data } = await axios.post(
        ROUTES.ENABLE_3FA,
        {
          token: gacode,
        },
        {
          headers,
        },
      );
      closeModal();

      console.log({
        data,
      });

      window.location.href = '/settings_user?mfa=true';
    } catch (err: any) {
      toast.error(err?.response?.data?.message);
      return closeModal();
    }
  };

  return (
    <div className={classes.ParentContainer}>
      <h1>Multifactor authentication (MFA) </h1>
      <p>
        Please download and install a two-factor authenticator application, and
        scan the QR code from the installed app to enable Multifactor
        authentication (MFA):
        <br />
        {/* <b>On Android:</b>{' '} */}
        <div className={classes.appsSection}>
          <div className={classes.appNamesSection}>
            {googleAuthenticatorIcon()}

            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US"
              rel="noreferrer"
              target="_blank"
            >
              Google Authenticator
            </a>
          </div>

          <div className={classes.appNamesSection}>
            <img src={MicrosoftAuthenticator} width="22" alt="" />

            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN&gl=US"
              rel="noreferrer"
              target="_blank"
            >
              Microsoft Authenticator
            </a>
          </div>

          <div className={classes.appNamesSection}>
            <img src={TwilioAuthy} width="22" alt="" />

            <a
              href="https://apps.apple.com/us/app/twilio-authy/id494168017"
              rel="noreferrer"
              target="_blank"
            >
              Twilio Authy
            </a>
          </div>
        </div>
      </p>

      <div className={classes.Qrcode}>
        <img src={qrcode} alt="barcode" width={200} height={200} />
        <div className={classes.forms}>
          <form onSubmit={handleSubmit2}>
            <label htmlFor="otp">Enter Google Authenticator OTP</label>
            <div className={classes.grid}>
              <div>
                <OtpInput
                  value={gacode}
                  onChange={setGacode}
                  numInputs={6}
                  inputStyle={modalcss}
                  // isDisabled={disableOtp}
                  disabledStyle={{ opacity: '0.3' }}
                  separator={<span>-</span>}
                  isInputNum={true}
                  isInputSecure={false}
                />
              </div>
              <div className={classes.resetSection}>
                <Link className={classes.reset} to="/">
                  Need Help?
                </Link>
              </div>
            </div>
            <p>
              In-case you have forgot/lost access to these, please contact{' '}
              <Link to="/" className={classes.smtext}>
                Encryptus Support.
              </Link>
            </p>
            <div className={classes.btndiv}>
              <button onClick={closeModal}>Cancel Setup</button>

              <button type="submit" className={classes.verifybtn}>
                Verify Code and Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalStep2;

const googleAuthenticatorIcon = (props?: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2481.9 2481.9"
    style={{
      enableBackground: 'new 0 0 2481.9 2481.9',
    }}
    xmlSpace="preserve"
    {...props}
    width="25"
    height="25"
  >
    <style type="text/css">
      {
        '\n\t.st0{fill:#616161;}\n\t.st1{fill:#9E9E9E;}\n\t.st2{fill:#424242;}\n\t.st3{fill:#212121;fill-opacity:0.1;}\n\t.st4{fill:#FFFFFF;fill-opacity:5.000000e-02;}\n\t.st5{opacity:0.5;fill:#BDBDBD;enable-background:new    ;}\n\t.st6{fill:#BDBDBD;}\n\t.st7{fill:#757575;}\n\t.st8{fill:#FFFFFF;fill-opacity:0.2;}\n\t.st9{fill:#212121;fill-opacity:0.2;}\n\t.st10{fill:url(#SVGID_1_);}\n'
      }
    </style>
    <g transform="translate(-27 -27)">
      <circle className="st0" cx={1268} cy={1268} r={1241} />
      <path
        className="st1"
        d="M1268,2057.7c-436.2,0-789.7-353.5-789.7-789.7c0-436.2,353.5-789.7,789.7-789.7c218,0,415.4,88.4,558.4,231.3 l319.1-319.1C1920.9,165.9,1610.6,27,1268,27C582.6,27,27,582.6,27,1268s555.6,1241,1241,1241c342.7,0,652.9-138.9,877.6-363.4 l-319.1-319.1C1683.4,1969.3,1486,2057.7,1268,2057.7z"
      />
      <path
        className="st2"
        d="M2057.7,1268h-394.9c0-218-176.8-394.9-394.9-394.9S873.1,1049.9,873.1,1268c0,106.2,42,202.5,110.3,273.6 l-0.3,0.3l488.9,488.9l0.1,0.1C1809.3,1940.9,2057.7,1633.5,2057.7,1268L2057.7,1268z"
      />
      <path
        className="st0"
        d="M2508.9,1268h-451.3c0,365.5-248.5,672.9-585.5,762.9l348.5,348.5C2228.6,2176.1,2508.9,1754.8,2508.9,1268z"
      />
      <path
        className="st3"
        d="M1268,2494.8C585,2494.8,31,1943,27.1,1260.9c0,2.4-0.1,4.7-0.1,7.1c0,685.4,555.6,1241,1241,1241 s1241-555.6,1241-1241c0-2.4-0.1-4.7-0.1-7.1C2505,1943,1950.9,2494.8,1268,2494.8L1268,2494.8z"
      />
      <path
        className="st4"
        d="M1472.2,2030.9l11,11c331.4-93.6,574.5-398.2,574.5-759.8V1268C2057.7,1633.5,1809.2,1940.9,1472.2,2030.9 L1472.2,2030.9z"
      />
      <g transform="translate(236.455 236.455)">
        <path
          className="st1"
          d="M2046.9,918.7H1031.5c-62.3,0-112.8,50.5-112.8,112.8c0,62.3,50.5,112.8,112.8,112.8h1015.3 c62.3,0,112.8-50.5,112.8-112.8C2159.7,969.2,2109.2,918.7,2046.9,918.7z"
        />
        <path
          className="st5"
          d="M2046.9,918.7H1031.5c-62.3,0-112.8,50.5-112.8,112.8c0,62.3,50.5,112.8,112.8,112.8h1015.3 c62.3,0,112.8-50.5,112.8-112.8C2159.7,969.2,2109.2,918.7,2046.9,918.7z"
        />
      </g>
      <g>
        <g>
          <circle className="st6" cx={252.6} cy={1268} r={84.6} />
          <circle className="st6" cx={1268} cy={252.6} r={84.6} />
          <circle className="st6" cx={1268} cy={2283.3} r={84.6} />
          <circle className="st6" cx={548.8} cy={550} r={84.6} />
          <circle className="st6" cx={548.8} cy={1987.2} r={84.6} />
        </g>
        <circle className="st7" cx={1987.2} cy={1987.2} r={84.6} />
        <path
          className="st8"
          d="M1268,1169.3h1015.3c59.9,0,108.9,46.8,112.4,105.8c0.1-2.4,0.4-4.7,0.4-7.1c0-62.3-50.5-112.8-112.8-112.8 H1268c-62.3,0-112.8,50.5-112.8,112.8c0,2.4,0.1,4.7,0.4,7.1C1159.1,1216.1,1208,1169.3,1268,1169.3z"
        />
        <path
          className="st9"
          d="M2395.7,1275c-3.7,58.9-52.6,105.8-112.4,105.8H1268c-59.9,0-108.9-46.8-112.4-105.8c-0.3,2.4-0.4,4.7-0.4,7 c0,62.3,50.5,112.8,112.8,112.8h1015.3c62.3,0,112.8-50.5,112.8-112.8C2396.1,1279.7,2396,1277.4,2395.7,1275z"
        />
        <path
          className="st3"
          d="M1268,492.4c218,0,415.4,88.4,558.4,231.3l326-326.2c-2.4-2.4-4.7-4.8-7-7.1l-319,319.1 c-143-142.9-340.4-231.3-558.4-231.3c-436.2,0-789.7,353.5-789.7,789.7c0,2.4,0.1,4.7,0.1,7.1C482.2,842.1,834.2,492.4,1268,492.4 L1268,492.4z"
        />
        <radialGradient
          id="SVGID_1_"
          cx={706.7253}
          cy={1774.0293}
          r={0.9983}
          gradientTransform="matrix(2481.9333 0 0 -2481.9333 -1753654.125 4403410.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#FFFFFF',
              stopOpacity: 0.1,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#FFFFFF',
              stopOpacity: 0,
            }}
          />
        </radialGradient>
        <circle className="st10" cx={1268} cy={1268} r={1241} />
      </g>
    </g>
  </svg>
);
