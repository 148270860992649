import classes from 'styles/BankDetail_card.module.scss';
import { copyText } from 'utils/CopyContent';

interface Props {
  accountHolderName: string;
  accountHolderAddress: string;
  iban_number: string;
  bankIdentifierCode: string;
  currency: string;
  createdAt: string;
  status: any;
  ref_userID: { name: string };
  bankName: string;
  bankAddress: string;
  bankCountry: string;
}

export default function BankDetailsCard({
  accountHolderName,
  accountHolderAddress,
  iban_number,
  bankIdentifierCode,
  currency,
  status,
  bankName,
  createdAt,
  bankAddress,
  bankCountry,
  ref_userID: { name },
}: Props) {
  return (
    <section className={classes.ParentDiv}>
      <div className={classes.Innerdiv}>
        <h6 className={classes.headings}>Account Holder Name</h6>
        <p
          className={classes.paragraph}
          onDoubleClick={() => copyText(accountHolderName)}
        >
          {accountHolderName}
        </p>
      </div>
      <div className={classes.section}>
        <h6>Account Holder Address</h6>
        <p onDoubleClick={() => copyText(accountHolderAddress)}>
          {accountHolderAddress}
        </p>
      </div>
      <div className={classes.section}>
        <h6>IBAN/AccountNumber</h6>
        <p onDoubleClick={() => copyText(iban_number)}>{iban_number}</p>
      </div>
      <div className={classes.section}>
        <h6>BIC/Swift</h6>
        <p onDoubleClick={() => copyText(bankIdentifierCode)}>
          {bankIdentifierCode}
        </p>
      </div>
      <div className={classes.section}>
        <h6>Beneficiary Bank Name</h6>
        <p onDoubleClick={() => copyText(bankName)}>{bankName}</p>
      </div>
      <div className={classes.section}>
        <h6>Beneficiary Bank Address</h6>
        <p onDoubleClick={() => copyText(bankAddress)}>
          {bankAddress ? bankAddress : 'Not Defined'}
        </p>
      </div>
      <div className={classes.section}>
        <h6>Country of Beneficiary Bank </h6>
        <p
          onDoubleClick={() =>
            copyText(bankCountry ? bankCountry : 'Not Defined')
          }
        >
          {bankCountry ? bankCountry : 'Not Defined'}
        </p>
      </div>
      <div className={classes.section}>
        <h6>Supported Currency</h6>
        <p onDoubleClick={() => copyText(currency)}>{currency}</p>
      </div>
      <div className={classes.section}>
        <h6>Applied On</h6>
        <p onDoubleClick={() => copyText(createdAt.slice(0, 10))}>
          {createdAt.slice(0, 10)}
        </p>
      </div>
      <div className={classes.section}>
        <h6>Approval Status</h6>
        <p
          className={`${classes.status} ${classes[status]}`}
          onDoubleClick={() =>
            copyText(
              status === 'approved'
                ? 'Approved'
                : status === 'pending'
                ? 'Pending'
                : 'Rejected',
            )
          }
        >
          {status === 'approved'
            ? 'Approved'
            : status === 'pending'
            ? 'Pending'
            : 'Rejected'}
        </p>
      </div>
    </section>
  );
}
