import React, { useState } from 'react';
import Warning from '../components/Warning';
import { FormProp } from '../interfaces';
import classes from '../styles/AddBankAccount.module.scss';
import * as yup from 'yup';
import Button from '../components/Button';
import { useUser } from '../context/user';
import axios from 'axios';
import { ROUTES } from '../config';
import { useModal } from '../context/modal';
import { toast } from 'react-toastify';
import usePaginate from '../hooks/usePaginate';
import Danger from 'assets/danger3.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Menu, MenuItem, MenuButton, FocusableItem } from '@szhsin/react-menu';
import Arrowdown from 'assets/arrow-down-black.svg';
import CurrencyList from 'currency-list';
import { currencyIcon } from 'components/CurrencyIcon';

const validator = require('validator');

// ? List of all the currencies of the world
const currenciesList = Object.keys(CurrencyList.getAll('en_US'));

// eslint-disable-next-line
const entries: FormProp[] = [
  {
    name: 'accountHolderName',
    label: 'Account Holder Name',
    placeholder: '',
    type: 'text',
  },
  {
    name: 'accountHolderAddress',
    label: 'Account Holder Address',
    placeholder: '',
    type: 'text',
  },
  {
    name: 'bankName',
    label: 'Beneficiary Bank Name',
    placeholder: '',
    type: 'text',
  },
  {
    name: 'bankAddress',
    label: 'Beneficiary Bank Address',
    placeholder: '',
    type: 'text',
  },
  {
    name: 'bankCountry',
    label: 'Country of Beneficiary Bank',
    placeholder: '',
    type: 'text',
  },
  {
    name: 'accountNumber',
    label: 'IBAN',
    placeholder: '',
    type: 'text',
  },
  [
    {
      name: 'bankIdentifierCode',
      label: 'Swift/BIC',
      placeholder: '',
      type: 'text',
    },
  ],
];

interface Props {
  getAccounts: () => Promise<any>;
}

export default function AddBankAccount({ getAccounts }: Props) {
  const { user, headers } = useUser();
  const { closeModal } = useModal();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<string>(' ');
  const [formCurrency, setFormCurrency] = useState<string>('USD');
  // eslint-disable-next-line
  const [accountNumberField, setAccountNumberField] = useState<boolean>(false); // ? To disable the input field, when 'onBlur' is called

  // ? Filter value for the Currencies dropdown search
  const [currencyFilter, setCurrencyFilter] = useState<any>('');

  // eslint-disable-next-line
  const [accounts, { reload }] = usePaginate<any>({
    route: ROUTES.FETCH_USER_BANK_APPLICATIONS,
  });

  // ? Schema for Form Validation
  const schema = yup.object().shape({
    accountHolderName: yup
      .string()
      .required('Account Holder Name is required')
      .min(6, 'Account holder name is too short'),
    accountHolderAddress: yup
      .string()
      .required('Account Holder Address is required')
      .min(20, 'Account holder address is too short'),
    // accountNumber: yup.string().required('Account Number is required'),
    accountNumber: yup
      .string()
      .trim('Please Remove Spaces')
      .strict(true)
      .matches(/^[A-Z0-9]*$/, 'Only UPPER-CASE Letters & Numerics are allowed')
      .required('IBAN is required'),
    bankName: yup.string().required('Bank Name is required'),
    bankAddress: yup
      .string()
      .required('Beneficiary Bank Address is required')
      .min(20, 'Beneficiary Bank Address is too short'),
    bankCountry: yup
      .string()
      .required('Country of Beneficiary Bank is required'),
    confirmAccountNumber: yup
      .string()
      .required('IBAN is required')
      .oneOf([yup.ref('accountNumber')], 'IBANs must match'),
    // .min(15, 'Account Number invalid'),
    bankIdentifierCode: yup
      .string()
      .required('Bank Identifier Code is required')
      .trim('Please Remove Spaces')
      .strict(true),
    // .matches(/^[A-Z0-9]*$/, 'Only UPPER-CASE Letters & Numerics are allowed')
    // .min(8, 'BIC minimum length needs to be 8')
    // .max(25, 'BIC maximum length is 11'),
    // .matches(/^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?/, 'Is not in correct format'),
    // currency: yup.string().required('Currency is required'),
  });

  // ? Initial Values for the Form
  const initialValues = {
    accountHolderName: '',
    accountHolderAddress: '',
    accountNumber: '',
    confirmAccountNumber: '',
    bankIdentifierCode: '',
    currency: 'USD',
  };

  // ? Component that renders the Error message for the Formik Fields
  const renderError = (message: any) => {
    return <p className={classes.formErrorText}>{message}</p>;
  };
  // * End of renderError();

  // ? Method to render the From Errors
  const renderFormError = () => {
    return <p className={classes.formErrorText}>{formError}</p>;
  };
  // * End of renderFormError()

  // eslint-disable-next-line
  const handlesSubmit = async (formData: any) => {
    try {
      const { data } = await axios.post(
        ROUTES.CREATE_BANK_APPLICATION,
        formData,
        {
          headers,
        },
      );
      const { message } = data || {};
      if (message) {
        await getAccounts();
        closeModal();
        toast.success(message);
        return { success: data };
      }
      await reload();
      return {};
    } catch (err: any) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleSubmit = async (values: any) => {
    console.log({
      formVal: values,
    });

    setFormError('');

    let {
      accountHolderName,
      accountHolderAddress,
      accountNumber,
      confirmAccountNumber,
      bankIdentifierCode,
      bankName,
      bankAddress,
      bankCountry,
    } = values;

    console.log({
      values: validator.isIBAN(accountNumber),
      val2: validator.isIBAN(confirmAccountNumber),
      BIC: validator.isBIC(bankIdentifierCode),
    });

    // if (validator.isIBAN(accountNumber) === false) {
    //   setFormError('Invalid IBAN1. Please enter a valid IBAN');
    // } else if (validator.isIBAN(confirmAccountNumber) === false) {
    //   setFormError('Invalid IBAN2. Please enter a valid IBAN');
    // } else
    if (!validator.isBIC(bankIdentifierCode)) {
      setFormError('Please enter a valid Swift/BIC');
    } else {
      // from  here to (Praveenraj)
      let noSpaceIBANFirst = validator.trim(accountNumber);
      let noSpaceIBANSecond = validator.trim(confirmAccountNumber);
      let noSpaceSwift = validator.trim(bankIdentifierCode);

      // To remove the space between characters
      let noSpaceIBANFirst_NoSpaceBetween = noSpaceIBANFirst.replace(/\s/g, '');
      let noSpaceIBANSecond_NoSpaceBetween = noSpaceIBANSecond.replace(
        /\s/g,
        '',
      );
      let noSpaceSwift_NoSpaceBetween = noSpaceSwift.replace(/\s/g, '');

      if (
        noSpaceIBANFirst_NoSpaceBetween === noSpaceIBANSecond_NoSpaceBetween
      ) {
        accountNumber =
          noSpaceIBANFirst_NoSpaceBetween || noSpaceIBANSecond_NoSpaceBetween;
      } else {
        setFormError('Please enter a valid IBAN without spaces');
        console.log('Spaces in IBAN');
        console.log('Swift' + noSpaceSwift_NoSpaceBetween);
      }

      bankIdentifierCode = noSpaceSwift_NoSpaceBetween;
      // here for Trimming the white spaces on both sides
      let reqBody = {
        accountHolderName: accountHolderName,
        accountHolderAddress: accountHolderAddress,
        iban_number: accountNumber,
        bankIdentifierCode: bankIdentifierCode,
        currency: formCurrency,
        bankName,
        bankAddress,
        bankCountry,
      };

      setLoading(true);

      try {
        const { data } = await axios.post(
          ROUTES.CREATE_BANK_APPLICATION,
          reqBody,
          {
            headers,
          },
        );
        const { message } = data || {};
        if (message) {
          await getAccounts();
          setLoading(() => false);
          closeModal();
          toast.success(message);
          return { success: data };
        }
        await reload();
        return {};
      } catch (err: any) {
        setLoading(() => false);
        toast.error(err?.response?.data?.message);
      }
    }
  };

  if (!user?.kycStatus)
    return (
      <div className={classes.warning}>
        <Warning
          title="KYC not approved"
          description="You cannot add a bank account before your KYC is approved"
          imgtype=""
        />
      </div>
    );
  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Add Bank Account</h1>
      {/* new warning div++++++++++++++++++ */}
      <div className={classes.division}>
        <div className={classes.innerdiv}>
          <h3>No Third Party Payments</h3>
          <img src={Danger} alt="dangericon" style={{ marginLeft: '8px' }} />
        </div>
        <p>Third Party Payments are not supported.</p>
      </div>

      <div className={classes.formContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <Form className={classes.addBankAccForm} autoComplete="off">
            <label>Account Holder Name</label>
            <Field
              type="text"
              name="accountHolderName"
              autoComplete="off"
              required
            />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="accountHolderName" render={renderError} />
            </div>

            <label>Account Holder Address</label>
            <Field
              type="text"
              name="accountHolderAddress"
              autoComplete="off"
              required
            />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="accountHolderAddress" render={renderError} />
            </div>

            <label>Beneficiary Bank Name</label>
            <Field type="text" name="bankName" autoComplete="off" required />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="bankName" render={renderError} />
            </div>

            <label>Beneficiary Bank Address</label>
            <Field type="text" name="bankAddress" autoComplete="off" required />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="bankAddress" render={renderError} />
            </div>

            <label>Country of Beneficiary Bank</label>
            <Field type="text" name="bankCountry" autoComplete="off" required />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="bankCountry" render={renderError} />
            </div>

            <label>Your IBAN/Account Number</label>
            <Field
              type="text"
              name="accountNumber"
              autoComplete="new-password"
              required
              disabled={accountNumberField}
            />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="accountNumber" render={renderError} />
            </div>

            <label>Confirm IBAN/Account Number</label>
            <Field
              type="text"
              name="confirmAccountNumber"
              autoComplete="off"
              required
            />
            <div className={classes.formErrortextSection}>
              <ErrorMessage name="confirmAccountNumber" render={renderError} />
            </div>

            <div className={classes.formSplit}>
              <div className={classes.splitInner}>
                <label>Swift/BIC</label>
                <Field
                  type="text"
                  name="bankIdentifierCode"
                  autoComplete="off"
                  required
                />
                <div className={classes.formErrortextSection}>
                  <ErrorMessage
                    name="bankIdentifierCode"
                    render={renderError}
                  />
                </div>
              </div>
              <div className={classes.splitInner}>
                <label>Currency</label>

                <Menu
                  menuButton={
                    <MenuButton className={classes.dropBtn}>
                      <span>{formCurrency}</span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName={`drop-down ${classes.currencyDropdown}`}
                  onItemClick={(e) => setFormCurrency(() => e.value)}
                >
                  <FocusableItem>
                    {({ ref }: any) => (
                      <input
                        ref={ref}
                        type="text"
                        placeholder="Type to filter"
                        value={currencyFilter}
                        onChange={(e) => setCurrencyFilter(e.target.value)}
                      />
                    )}
                  </FocusableItem>

                  {currenciesList
                    .filter((currency: any) =>
                      currency
                        .toUpperCase()
                        .includes(currencyFilter.trim().toUpperCase()),
                    )
                    .map((currency) => (
                      <MenuItem
                        key={currency}
                        value={currency}
                        className={
                          formCurrency === currency
                            ? 'dropdown-item-selected'
                            : ''
                        }
                      >
                        {currency}{' '}
                        {currencyIcon(CurrencyList.get(currency)?.symbol)}
                      </MenuItem>
                    ))}
                </Menu>

                <div className={classes.formErrortextSection}>
                  <ErrorMessage name="currency" render={renderError} />
                </div>
              </div>
            </div>

            {renderFormError()}

            <div className={classes.checkboxSection}>
              <label>
                <input type="checkbox" required />
                <span style={{ marginLeft: '3px' }}>
                  I confirm that this is not a third party bank account and as a
                  user I have full control over this bank account
                </span>
              </label>
            </div>

            <div className={classes.checkboxSectionBank}>
              <label>
                <input type="checkbox" required />
                <span style={{ marginLeft: '3px' }}>
                  I understand that banks will apply International conversion
                  rates to settle funds in native currency in relation to the
                  destination bank
                </span>
              </label>
            </div>

            <div className={classes.formButtonSection}>
              <Button
                type="submit"
                style={{ width: '250px' }}
                loading={loading}
                disabled={loading}
              >
                Add Bank Account
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
