import React, { SyntheticEvent, useState } from 'react';
import classes from 'styles/DepositeAmount.module.scss';
import { useUser } from 'context/user';
import { useModal } from 'context/modal';
import Withdrawmain from './Withdrawmain';
import Withdrawfiat from './Withdrawfiat';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import Arrowdown from 'assets/Arrow - Down 4.png';

interface Head {
  currency: string;
  symbol: string;
  uuid: string;
  type: string;
  wallets?: any[];
  bankAccounts?: any[];
}

function WithdrawAmount({
  currency,
  symbol,
  uuid,
  type,
  wallets,
  bankAccounts,
}: Head) {
  const { user } = useUser();
  const { openModal } = useModal();

  const [withdrawAmount, setWithdrawAmount] = useState<any>();
  const [receiverAddress, setReceiverAddress] = useState<any>();
  const [bankName, setbankName] = useState<any>();
  const [fromError, setFormError] = useState<any>('');

  const formatIBAN = (data: any) => {
    return (
      Object.keys(data).length &&
      `${data.accountHolderName} - ${
        data.currency
      } - ****${data.iban_number.slice(-4)}`
    );
  };

  // eslint-disable-next-line
  const data = {
    tagger_email: user.email,
  };
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setFormError(() => '');
    if (isNaN(withdrawAmount)) {
      setFormError(() => 'Please enter a valid amount');
    } else if (type === 'crypto' && !receiverAddress) {
      setFormError(() => 'Please select a wallet');
    } else if (type === 'fiat' && !receiverAddress) {
      setFormError(() => 'Please select a bank account');
    } else {
      openModal(() => (
        <Withdrawfiat
          symbol={symbol}
          type={type}
          currency={currency}
          withdrawAmount={withdrawAmount}
          uuid={uuid}
          receiverAddress={receiverAddress}
          bankName={bankName}
        />
      ));
    }
  };

  return (
    <div className={classes.Container}>
      <h2>Withdraw {currency}</h2>
      <form className={classes.depositForm}>
        <label>Amount</label>

        <input
          type="number"
          value={withdrawAmount}
          onChange={(event) => setWithdrawAmount(event.target.value)}
          placeholder="Enter Withdraw Amount"
          required
        />
        {type === 'crypto'
          ? wallets &&
            wallets.length > 0 && (
              <>
                <label>Wallet Address</label>
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropdownBtn}>
                      <span>{receiverAddress || 'Select Wallet'}</span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  onItemClick={(e: any) => setReceiverAddress(e.value)}
                >
                  {
                    // eslint-disable-next-line
                    wallets.map((options, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={options.walletAddress}
                          className={
                            receiverAddress === options.walletAddress
                              ? 'dropdown-item-selected'
                              : ''
                          }
                        >
                          {`${options.walletAddress} ${
                            options?.inputLabel ? '-' : ''
                          } ${options?.inputLabel ? options?.inputLabel : ''}`}
                        </MenuItem>
                      );
                    })
                  }
                </Menu>
              </>
            )
          : bankAccounts &&
            bankAccounts.length > 0 && (
              <>
                <label>Bank Account</label>
                <Menu
                  menuButton={
                    <MenuButton className={classes.dropdownBtn}>
                      <span>{receiverAddress || 'Select Bank Account'}</span>
                      <img src={Arrowdown} alt="arrow-img" />
                    </MenuButton>
                  }
                  transition
                  menuClassName="drop-down"
                  onItemClick={(e: any) => {
                    console.log(e.value.split(',')[0]);
                    console.log(e.value.split(',')[1]);
                    setReceiverAddress(e.value.split(',')[0]);
                    setbankName(e.value.split(',')[1]);
                  }}
                >
                  {bankAccounts.map((options, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={options.iban_number + ',' + options.bankName}
                        className={
                          receiverAddress === options.iban_number
                            ? 'dropdown-item-selected'
                            : ''
                        }
                      >
                        {formatIBAN(options)},{options.bankName}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
        <label>Currency:</label>

        <input type="text" value={currency} disabled />

        <label>Reference ID</label>

        <input type="text" value={uuid} disabled />

        <span style={{ color: 'red', marginTop: '1rem' }}>{fromError}</span>

        <div className={classes.btndiv}>
          <span onClick={() => openModal(() => <Withdrawmain />)}>Back</span>

          <button type="submit" onClick={handleSubmit}>
            Withdraw
          </button>
        </div>
      </form>
    </div>
  );
}

export default WithdrawAmount;
