import React, { useState } from 'react';
import Form from '../components/Form';
import classes from '../styles/Login.module.scss';
import { FormProp, LoginForm } from '../interfaces';
import { ReactComponent as emailIcon } from '../assets/email.svg';
import { ReactComponent as keyIcon } from '../assets/key.svg';
import { ReactComponent as eyeIcon } from '../assets/eye.svg';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import { useUser } from '../context/user';
import OTP from './OTP';
import { Link } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';

const entries: FormProp[] = [
  {
    name: 'email',
    label: 'E-Mail',
    icon: emailIcon,
    placeholder: '',
    type: 'email',
  },
  {
    name: 'password',
    label: 'Password',
    icon: keyIcon,
    placeholder: '',
    toolIcon: eyeIcon,
    type: 'password',
  },
];

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export default function Login() {
  const [loading, setLoading] = useState(false);
  const { openModal, closeModal } = useModal();
  const { login } = useUser();

  const handleSubmit = async (data: LoginForm) =>
    login(data, closeModal, (email: string) =>
      openModal(() => <OTP email={email} />),
    );

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Log into your Account</h1>
      <Form
        setLoading={setLoading}
        onSubmit={handleSubmit}
        schema={schema}
        entries={entries}
      >
        <Link
          className={classes.forgot}
          to="#"
          onClick={() => openModal(ForgotPassword)}
        >
          Forgot Password?
        </Link>
        <div className={classes.action}>
          <Button loading={loading}>Login</Button>
        </div>
      </Form>
    </div>
  );
}
