import React from 'react';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { DATE_CONV } from 'utils/DateConv';
import CurrencyList from 'currency-list';
import classes from '../../styles/TransactionList.module.scss';

// ? Method to conditionally render the type of trasaction
const renderTransactionType = (transactionType: string) => {
  switch (transactionType) {
    case 'fiatdeposit':
      return 'Fiat Deposit';

    case 'fiatwithdraw':
      return 'Fiat Withdraw';

    case 'cryptograb':
      return 'Crypto Buy';

    case 'cryptodeposit':
      return 'Crypto Deposit';

    case 'cryptorelease':
      return 'Crypto Sell';

    case 'cryptowithdraw':
      return 'Crypto Withdraw';

    default:
      return '';
  }
};
// * End of renderTransactionType();

type FiatTransactionItemProp = {
  _id: string;
  depos_curr: string;
  fiat_curr: string;
  transc_status: string;
  depos_amt: any;
  withdraw_amt: any;
  quot_amount: any;
  createdAt: any;
  withdraw_curr: any;
  transac_type: any;
};

function FiatTransactionItem({
  _id,
  depos_curr,
  fiat_curr,
  quot_amount,
  transc_status,
  depos_amt,
  withdraw_amt,
  createdAt,
  withdraw_curr,
  transac_type,
}: FiatTransactionItemProp) {
  return (
    <div className={classes.transactionItem}>
      <div className={classes.transactionDetail}>
        <div className={classes.date}>
          <p>{renderTransactionType(transac_type)}</p>
          {DATE_CONV.dateConvert(createdAt)}
          &nbsp;-&nbsp;
          {depos_curr || withdraw_curr || fiat_curr || 'USD'}
        </div>
      </div>
      <div className={classes.transactionStatus}>
        <div className={classes.amount}>
          {CurrencyList.get(depos_curr || fiat_curr || withdraw_curr)?.symbol ||
            '$'}
          &nbsp;
          {CURRENCY_CONV.fiatAmtConv(depos_amt || quot_amount || withdraw_amt)}
        </div>
        <div
          className={classes.status}
          style={{
            color:
              transc_status.toUpperCase() === 'APPROVED'
                ? '#5F9816'
                : transc_status.toUpperCase() === 'PENDING'
                ? '#d68b00'
                : 'red',
          }}
        >
          {transc_status.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

type CryptoTransactionItemProp = {
  _id: string;
  transc_status: any;
  lp_order_response: {
    lp_executedQuantity: number;
    lp_baseCurrency: string;
  };
  received_amt: any;
  received_amt_curr: any;
  createdAt: any;
  transac_type: any;
  withdraw_amt: any;
  withdraw_curr: any;
};

function CryptoTransactionItem(
  {
    _id,
    transc_status,
    lp_order_response,
    received_amt,
    received_amt_curr,
    createdAt,
    transac_type,
    withdraw_amt,
    withdraw_curr,
  }: CryptoTransactionItemProp,
  selection: any,
) {
  return (
    <div className={classes.transactionItem}>
      <div className={classes.transactionDetail}>
        <div className={classes.date}>
          <p>{renderTransactionType(transac_type)}</p>
          {DATE_CONV.dateConvert(createdAt)}&nbsp;-&nbsp;
          {lp_order_response?.lp_baseCurrency ||
            received_amt_curr ||
            withdraw_curr}
        </div>
      </div>
      <div className={classes.transactionStatus}>
        <div className={classes.amount}>
          {CURRENCY_CONV.cryptoAmtConv(
            lp_order_response?.lp_executedQuantity ||
              received_amt ||
              withdraw_amt,
          )}
        </div>
        <div
          className={classes.status}
          style={{
            color:
              transc_status === 'approved'
                ? '#5F9816'
                : transc_status === 'rejected'
                ? 'red'
                : '#D68B00',
          }}
        >
          {transc_status.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export default function AllTransactionList({ transactions, selection }: any) {
  return (
    <div className={classes.transactionList}>
      {transactions.length > 0 ? (
        transactions?.map((item: any, i: any) => {
          return (
            <div key={i.toString()}>
              {item.transac_type === 'fiatwithdraw' ||
              item.transac_type === 'fiatdeposit' ? (
                <FiatTransactionItem {...item} />
              ) : (
                <CryptoTransactionItem {...item} selection={selection} />
              )}

              {transactions?.length - 1 !== i && (
                <div className={classes.gutters} key={i.toString()}>
                  <div className={classes.dashedLine}></div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className={classes.no_transaction}>
          <h3>No Transactions found!</h3>
        </div>
      )}
    </div>
  );
}
