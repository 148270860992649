import React from 'react';
import classes from '../styles/Submitted.module.scss';
import check from '../assets/check.svg';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

function Submitted() {
  const history = useHistory();
  const given = useQuery('given');
  const taken = useQuery('taken');
  console.log({ given, taken, page: 'Submitted' });
  // const location = useLocation();
  return (
    <div className={classes.submitted}>
      <img src={check} alt="check" />
      <div className={classes.orderStatus}>Order Submitted</div>
      <div style={{ color: '#DBB42D' }} className={classes.orderNumber}>
        Order Number ENC3732089048
      </div>
      <Button
        onClick={() =>
          // history.push({
          //   pathname: `/orders/${location.pathname.substring(-1, -3)}`,
          // })
          history.push('/usertransactions')
        }
        style={{ background: '#DBB42D', borderRadius: '10px' }}
        className={classes.trackOrderBtn}
      >
        Track Order Status
      </Button>
    </div>
  );
}

export default Submitted;
