import axios from 'axios';
import ClientScreen from '../../components/ClientScreen';
import classes from '../../styles/UserSettings.module.scss';
import Button from '../../components/Button';
import { useUser } from '../../context/user';
import { useModal } from '../../context/modal';
import { ROUTES } from '../../config';
import { toast } from 'react-toastify';
import Form from '../../components/Form';
import { anyObject, FormProp } from '../../interfaces';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import ModalHandler from 'modals/MFA modals/ModalHandler';
import { useHistory } from 'react-router-dom';
import Footer from 'components/common/Footer';
import ChangeUserData from 'modals/ChangeUserData';
// import VerifyPhone from 'modals/VerifyPhone';
// import OTP from 'modals/OTP';
import VerifyPhoneOTP from 'modals/VerifyPhoneOTP';
import VerifyEmailOTP from 'modals/VerifyEmailOTP';

const emailEntry: FormProp[] = [
  [
    {
      label: 'Email',
      name: 'email',
      placeholder: '',
      type: 'email',
    },
  ],
];

const phoneEntry: FormProp[] = [
  [
    {
      label: 'Phone Number',
      name: 'phone_no',
      phoneNumberInput: true,
      type: 'tel',
      country: 'at',
    },
  ],
];

const entries: FormProp[] = [
  [
    {
      name: 'OTPchoice',
      label: 'This will set your medium of receiving OTPs',
      type: 'select',
      options: [{ value: 'Phone' }, { value: 'Email' }],
    },
  ],
];

const schema = yup.object().shape({
  OTPchoice: yup.string().required(),
});

export default function UserSettings() {
  const { headers, user } = useUser();
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);

  const history = useHistory();

  /** set user 3fa choice b/w phone-or-email*/
  const handleSumbit = async (formData: anyObject) => {
    try {
      setLoading(true);
      let pd: string | undefined;
      if (formData.OTPchoice === 'Phone') {
        pd = 'via_phone';
      }
      if (formData.OTPchoice === 'Email') {
        pd = 'via_ga';
      }
      const { data } = await axios.patch(
        ROUTES.SET_CHOICE_3FA,
        {
          choice_3fa: pd,
        },
        {
          headers,
        },
      );
      setLoading(false);
      if (data.updated) {
        return toast.success(
          `You will receive OTP's at ${formData.OTPchoice} from now on you can Add Bank and Wallet address`,
        );
      }
      return toast.error(`Choice could not be updated`);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message);
      return {};
    }
  };

  // ? Method to handle the page refresh once the MFA is enabled by the user
  const handlePageRefresh = () => {
    const { search } = window.location;
    const mfa = new URLSearchParams(search).get('mfa');

    // console.log({
    //   mfa,
    //   user,
    // });

    if (mfa === 'true') {
      history.push('/bank_accounts');
      toast.success('MFA Enabled Successfully!');
    }
  };
  // * End of handlePageRefresh();

  const changeEmailModal = async () => {
    try {
      if (isPhoneVerified) {
        openModal(() => (
          <ChangeUserData
            entries={emailEntry}
            title="Change Email"
            onSubmit={(body: any) => updateUser(body)}
          />
        ));
      } else {
        try {
          openModal(() => (
            <VerifyPhoneOTP validation={() => setIsPhoneVerified(true)} />
          ));
        } catch (error) {
          console.error({ error });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changePhoneModal = async () => {
    try {
      if (isEmailVerified) {
        openModal(() => (
          <ChangeUserData
            entries={phoneEntry}
            title="Change Phone Number"
            onSubmit={(body: any) => updateUser(body)}
          />
        ));
      } else {
        openModal(() => (
          <VerifyEmailOTP
            email={user.email}
            validation={() => setIsEmailVerified(true)}
          />
        ));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (body: any) => {
    try {
      const data = await axios.patch(ROUTES.UPDATE_USER, body, { headers });
      toast.success(data.data.message);
      window.location.reload();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    console.log('USE EFFECT');

    handlePageRefresh();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ClientScreen title="Settings" hideTransact>
        <div className={classes.wrapper}>
          {!user?.enabled_3fa ? (
            <>
              <p>Enable MFA Flow</p>
              <Button size="m" onClick={() => openModal(ModalHandler)}>
                Enable MFA
              </Button>
            </>
          ) : (
            <div className={classes.grid}>
              <p>MFA enabled successfully.</p>
              <Form schema={schema} onSubmit={handleSumbit} entries={entries}>
                <Button loading={loading} style={{ marginTop: '20px' }}>
                  Set Choice
                </Button>
              </Form>
            </div>
          )}
        </div>
        <div className={classes.wrapper2}>
          <p>Update E-mail and Phone.</p>
          <div className={classes.grid2}>
            <Button
              loading={loading}
              style={{ marginTop: '20px' }}
              onClick={changeEmailModal}
            >
              Change E-mail
            </Button>
            <Button
              loading={loading}
              style={{ marginTop: '20px' }}
              onClick={changePhoneModal}
            >
              Change Phone
            </Button>
          </div>
        </div>
      </ClientScreen>
      <Footer />
    </>
  );
}
