import { handle, paradata, schema } from '../ExtraDiv';
import React, { useState } from 'react';
import classes from 'styles/stepbar.module.scss';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { useUser } from 'context/user';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { color } from '../ExtraDiv';

function Stepbar(props: handle) {
  // states+++++++++++++++++++
  const [disable, setDisbale] = useState(false);
  const { headers, user } = useUser();

  // submition handleer is here +++++++++++++++++++++++
  const handleSubmit = async (value: object) => {
    try {
      const result = await axios.post(ROUTES.Check_Pass, value, { headers });
      const data = result.data;

      if (data.validated === true) {
        setDisbale(true);
        props.handler();
        props.nextbtn();
      }
    } catch (error: any) {
      toast.warning(error?.response?.data?.message);
    }
  };
  // initial values ++++++++++++++++++
  const initialvalues = {
    p_fSteps: '',
  };

  // JSX starts here
  return (
    <div className={classes.Subcontainer}>
      <div className={classes.forms}>
        <label htmlFor="password">Email Address</label>
        <span className={classes.prime}>Primary Account</span>
        <input
          type="text"
          value={user.email}
          className={classes.inputs}
          disabled
        />
      </div>
      <Formik
        initialValues={initialvalues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form className={classes.forms}>
              <label htmlFor="password">Your Password</label>
              <Field
                name="p_fSteps"
                type="password"
                id="password"
                placeholder="Enter Your Password"
                className={classes.inputs}
                disabled={disable}
              />
              <ErrorMessage
                name="p_fSteps"
                render={(msg) => <div style={color}>{msg}</div>}
              ></ErrorMessage>

              {/* paragraph++++++++++++++ */}
              {paradata.map((data) => {
                return (
                  <p className={classes.para}>
                    {data.para}{' '}
                    <Link to="/contact" className={classes.links}>
                      {data.Linktext}
                    </Link>
                  </p>
                );
              })}
              <button className={classes.reset2} disabled={disable}>
                Send OTP
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Stepbar;
