import React, { useEffect, useState } from 'react';
import classes from 'styles/CookieConsent.module.scss';

export default function CookieConsent() {
  const [userConsent, setUserConsent] = useState(false);

  // ? Method to check if user has consented
  // ? Checks the data in the localStorage
  const checkConsent = () => {
    console.log({
      message: 'GOING TO CHECK CONSENT',
    });

    try {
      var consent = localStorage.getItem('cpa_con');

      if (consent === 'Y') {
        setUserConsent(() => true);
      } else {
        setUserConsent(() => false);
      }
    } catch (error) {
      setUserConsent(() => false);
    }
  };
  // * End of checkConsent()

  // ? Method called when the used clicks on ACCEPT button
  const handleUserConsent = () => {
    setUserConsent(() => true);

    localStorage.setItem('cpa_con', 'Y');
  };
  // * End of handleUserConsent()

  useEffect(() => {
    checkConsent();

    // eslint-disable-next-line
  }, []);

  // ? Method to conditionally render the content
  const renderContent = () => {
    if (!userConsent) {
      return (
        <div className={classes.cookieConsetConatainer}>
          <div className={classes.cookieConcentBanner}>
            <p>
              We use cookies to enhance your experience, analyze our traffic,
              and for security and marketing.
              <br />
              By visiting our website you agree to our use of cookies.
            </p>

            <button className={classes.acceptBtn} onClick={handleUserConsent}>
              Accept
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  // * End of renderContent()

  return renderContent();
}
