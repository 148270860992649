import { useState, useRef, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import useOutsideClick from '../hooks/useOutsideClick';
import { SelectOption } from '../interfaces';
import classes from '../styles/Select.module.scss';

//cryptocurrencies icon imports
import bitcoin from '../assets/btc.png';
import algorand from '../assets/cryptocurrencies/algorand.svg';
import binance from '../assets/cryptocurrencies/binance.svg';
import bitcoinCash from '../assets/cryptocurrencies/bitcoinCash.svg';
import bitcoinsv from '../assets/cryptocurrencies/bitcoinsv.svg';
import cardano from '../assets/cryptocurrencies/cardano.svg';
import celo from '../assets/cryptocurrencies/celo.svg';
import cosmos from '../assets/cryptocurrencies/cosmos.svg';
import dash from '../assets/cryptocurrencies/dash.svg';
import dogecoin from '../assets/cryptocurrencies/dogecoin.svg';
import elrond from '../assets/cryptocurrencies/elrond.svg';
import hedera from '../assets/cryptocurrencies/hedera.svg';
import litecoin from '../assets/cryptocurrencies/litecoin.svg';
import solana from '../assets/cryptocurrencies/solana.svg';
import stellar from '../assets/cryptocurrencies/stellar.svg';
import tezos from '../assets/cryptocurrencies/tezos.svg';
import tron from '../assets/cryptocurrencies/tron.svg';
import zcash from '../assets/cryptocurrencies/zcash.svg';
import ripple from '../assets/cryptocurrencies/ripple.png';
import eth from '../assets/eth.png';
import usdt from 'assets/usdt.png';
import usdc from 'assets/usdc.png';
import matic from 'assets/matic.png';

type Props = {
  options: SelectOption[];
  register: UseFormRegister<FieldValues>;
  error?: boolean;
  name: string;
  setValue: UseFormSetValue<FieldValues>;
};

const Select = ({ options, register, name, setValue }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(options?.[0].value);
  const selectRef = useRef(null);
  useOutsideClick(selectRef, () => {
    setOpen(false);
  });

  //icon setter
  const setIcon = (coin: string) => {
    switch (coin) {
      case 'bitcoin':
        return bitcoin;
      case 'bitcoin_cash':
        return bitcoinCash;
      case 'tron':
        return tron;
      case 'solana':
        return solana;
      case 'bitcoin_sv':
        return bitcoinsv;
      case 'binance_smart_chain':
        return binance;
      case 'algorand':
        return algorand;
      case 'dogecoin':
        return dogecoin;
      case 'hedera':
        return hedera;
      case 'cosmos':
        return cosmos;
      case 'litecoin':
        return litecoin;
      case 'celo':
        return celo;
      case 'stellar':
        return stellar;
      case 'tezos':
        return tezos;
      case 'zcash':
        return zcash;
      case 'cardano':
        return cardano;
      case 'dash':
        return dash;
      case 'elrond':
        return elrond;
      case 'ripple':
        return ripple;
      case 'ethereum':
        return eth;
      case 'tether':
        return usdt;
      case 'usdc':
        return usdc;
      case 'matic':
        return matic;
      default:
        break;
    }
  };

  useEffect(() => {
    const element = document.getElementById(name);
    if (element) {
      (element as HTMLInputElement).value = selected as string;
    }
  }, [selected, name]);

  const reg = register(name);

  return (
    <div
      style={{
        width: '100%',
      }}
      className={classes.container}
    >
      <select {...reg}>
        {options.map((item) => (
          <option key={item.value.toString()} value={item.value}>
            {item.label || item.value}
          </option>
        ))}
      </select>
      <div
        ref={selectRef}
        className={`${classes.selectSelected} ${isOpen ? classes.active : ''}`}
        onClick={() => {
          setOpen((p) => !p);
        }}
      >
        <span>
          {selected === 'bitcoin' ? 'BITCOIN' : selected.toUpperCase()}
        </span>
        {name === 'associated_network' && (
          <img src={setIcon(selected)} alt="img" />
        )}
      </div>
      {isOpen && (
        <div className={classes.selectItems}>
          {options.map((item) => (
            <div
              onClick={() => {
                setSelected(item.value);
                setValue(name, item.value);
                setOpen(false);
              }}
              key={item.value}
              className={selected === item.value ? classes.itemSelected : ''}
            >
              {item?.label?.toUpperCase() || item?.value?.toUpperCase()}
              {name === 'associated_network' && (
                <img src={setIcon(item.value)} alt="img" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
