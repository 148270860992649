import validator from 'validator';

export const validateAddress = ({
  address,
  addressType,
}: {
  address: string;
  addressType: string;
}) => {
  addressType = addressType.toLowerCase();
  if (addressType === 'bitcoin') return validator.isBtcAddress(address);
  else if (
    addressType === 'ethereum' ||
    addressType === 'tether' ||
    addressType === 'matic' ||
    addressType === 'usdc'
  )
    return validator.isEthereumAddress(address);
};
