import React from 'react';
import classes from '../styles/SidePane.module.scss';
import { ReactComponent as home } from '../assets/home.svg';
import { ReactComponent as customer } from '../assets/customer.svg';
import { ReactComponent as business } from '../assets/business.svg';
import { ReactComponent as bank } from '../assets/bank.svg';
import { ReactComponent as settings } from '../assets/settings.svg';
import { ReactComponent as permission } from '../assets/permission.svg';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAdmin } from 'context/admin';

const adminPages = [
  { name: 'Dashboard', path: '/admin/dashboard', icon: home },
  { name: 'Users', path: '/admin/users', icon: customer },
  { name: 'Deposit Transactions', path: '/admin/transactions', icon: business },
  { name: 'Buy Transactions', path: '/admin/Buytransactions', icon: business },
  {
    name: 'Sell Transactions',
    path: '/admin/selltransactions',
    icon: business,
  },
  {
    name: 'Withdraw Transactions',
    path: '/admin/withdraw_transactions',
    icon: business,
  },
  // {
  //   name: 'Mobile Topups',
  //   path: '/admin/mobile_topup',
  //   icon: business,
  // },
  // {
  //   name: 'Gift Cards',
  //   path: '/admin/gift_cards',
  //   icon: business,
  // },
  { name: 'Bank Applications', path: '/admin/bank_applications', icon: bank },
  { name: 'Add Admin', path: '/admin/create', icon: customer },
  { name: 'Permissions', path: '/admin/permissions', icon: permission },
  { name: 'Settings', path: '/admin/settings', icon: settings },
];

const localadmin = [
  { name: 'Dashboard', path: '/admin/dashboard', icon: home },
  { name: 'Users', path: '/admin/lusers', icon: customer },
  {
    name: 'Deposit Transactions',
    path: '/admin/financetransactions',
    icon: business,
  },
  { name: 'Buy Transactions', path: '/admin/ladmin_buy', icon: business },
  {
    name: 'Sell Transactions',
    path: '/admin/sellTransactions',
    icon: business,
  },
  {
    name: 'Withdraw Transactions',
    path: '/admin/withdrawtransactions',
    icon: business,
  },
  { name: 'Bank Applications', path: '/admin/bank_applications', icon: bank },
  { name: 'Change Password', path: '/admin/change_password', icon: settings },
];

export default function SidePane() {
  const {
    location: { pathname },
  } = useHistory();

  const { admin } = useAdmin();

  return (
    <div className={classes.container}>
      {admin &&
        admin.role === 'admin' &&
        adminPages.map(({ icon: Icon, name, path }) => (
          <NavLink to={path} key={name}>
            <div
              className={`${classes.item} ${
                pathname.includes(path) ? classes.active : ''
              }`}
            >
              <Icon />
              <p>{name}</p>
            </div>
          </NavLink>
        ))}

      {admin &&
        admin.role !== 'admin' &&
        admin.role !== 'admin' &&
        localadmin.map(({ icon: Icon, name, path }) => (
          <NavLink to={path} key={name}>
            <div
              className={`${classes.item} ${
                pathname.includes(path) ? classes.active : ''
              }`}
            >
              <Icon />
              <p>{name}</p>
            </div>
          </NavLink>
        ))}
    </div>
  );
}
