import React, { useState, useEffect } from 'react';
import classes from 'styles/stepbar.module.scss';
import { useUser } from 'context/user';
import { ROUTES } from 'config';
import axios from 'axios';
import { toast } from 'react-toastify';
import usePaginate from 'hooks/usePaginate';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { modalcss, walletdata, paradata } from '../ExtraDiv';
import { useModal } from 'context/modal';

function Stepbar2(this: any, props: walletdata) {
  // states+++++++++++++++++++++++++++++++++++++++
  const [mainstate, setstate] = useState(0);
  const [disable, setDisable] = useState(true);
  const [disableOtp, setDisableOtp] = useState(true);
  const [disableOtp2, setDisableOtp2] = useState(false);
  const [gacode, setGacode] = useState('');
  const [verify, setVerification] = useState('');
  const [otpval, setotpval] = useState('');
  const { headers, user } = useUser();
  const { closeModal } = useModal();
  // states ende here+++++++++++++++++++++++++++++++

  // eslint-disable-next-line
  const [wallets, { reload, paginateProps }] = usePaginate<any>({
    route: ROUTES.FETCH_USER_WALLETS,
  });

  // timer function for opt+++++++++++++++++++++++++++
  const Maintain = async () => {
    await axios.post(ROUTES.OTP_check, null, { headers });
    const timerstart = setInterval(async () => {
      setstate((state) => {
        if (state === 60) {
          setDisable(false);
          clearInterval(timerstart);
          return 0;
        }
        return state + 1;
      });
    }, 1000);
    setDisable(true);
  };

  useEffect(() => {
    Maintain();
    return () => {
      // clearInterval(timerstart)
    };
    // eslint-disable-next-line
  }, []);

  // google auth handler++++++++++++++++++++++++++++++++++++
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      const result = await axios.post(
        ROUTES.GACode_check,
        {
          ga_sSteps: gacode,
        },
        { headers },
      );
      const data = await result.data;

      if (data.validated === true) {
        await reload();
        closeModal();
        // Only Executed when buy/ sell flow working++++++++++++++++++
        if (props.validate) {
          props.validate();
          toast.success('Please Proceed to Next Step Now');
          return;
        }
        const walledata = await axios.post(
          ROUTES.Wallet_data,
          props.walletdata,
          { headers },
        );
        await walledata.data;
        toast.success('Google Auth. Success');
        // module close request
        window.location.reload();
      }
    } catch (err: any) {
      toast.warning(err.response.data.message);
    }
  };

  // Basic OTP handler++++++++++++++++++++++++++++++++++++++
  const handleSubmit2 = async () => {
    // opt try catch+++++++++++++++++++++
    try {
      const result = await axios.post(
        ROUTES.OTP_validation,
        { otp_sSteps: otpval },
        { headers },
      );
      const data = await result.data;
      if (data.validated === true) {
        setstate(60);
        toast.success('OTP verified');
        // disabling and enabling buttons and inputs based on request+++++++++++
        setVerification('OTP Verified!');

        setDisableOtp2(true);
        setDisableOtp(false);
      }
    } catch (err) {
      console.log(err);
      toast.error('OTP Verification Failed');
    }
  };

  useEffect(() => {
    if (otpval.length === 6) {
      handleSubmit2();
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [otpval]);
  return (
    <>
      {/* otp Verification++++++++++++++++++++++++++++++++++++++++ */}
      <div className={classes.optdiv}>
        <div className={classes.forms}>
          <label htmlFor="otp">Enter code recieved on {user.email}</label>
          <div className={classes.grid}>
            <div>
              {/* FORM SECTION++++++++++++++++++++++++++ */}
              <form>
                <OtpInput
                  value={otpval}
                  onChange={setotpval}
                  numInputs={6}
                  inputStyle={modalcss}
                  isDisabled={disableOtp2}
                  disabledStyle={{ opacity: '0.3' }}
                  separator={<span>-</span>}
                  isInputNum={true}
                  isInputSecure={false}
                />
              </form>
            </div>
            {/* RESEND OTP bUTTON+++++++++++++++++ */}
            <div>
              {!verify ? (
                <button
                  className={classes.reset}
                  disabled={disable}
                  onClick={Maintain}
                  id="rest_btn"
                >
                  Resend OTP
                </button>
              ) : (
                <></>
              )}
            </div>
            {/* OTP div +++++++++++++++++++++*/}
            <div>
              {mainstate !== 0 ? (
                <span className={classes.timer}>{`00:${60 - mainstate}`}</span>
              ) : null}
            </div>
          </div>
          {/* VERIFY SECTION++++++++++++++ */}
          {verify ? (
            <span className={classes.verify} style={{ margin: '-10px 0px' }}>
              <i
                className="fa fa-check"
                style={{
                  color: 'green',
                  marginRight: '4px',
                }}
                aria-hidden="true"
              ></i>
              {verify}
            </span>
          ) : null}
        </div>
      </div>

      {/* new GA code+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div>
        <div className={classes.forms}>
          <label htmlFor="otp">Enter Google Authenticator OTP</label>
          <form onSubmit={handleSubmit}>
            <div className={classes.grid}>
              <div>
                <OtpInput
                  value={gacode}
                  onChange={setGacode}
                  numInputs={6}
                  inputStyle={modalcss}
                  isDisabled={disableOtp}
                  disabledStyle={{ opacity: '0.3' }}
                  separator={<span>-</span>}
                  isInputNum={true}
                  isInputSecure={false}
                />
              </div>
              <div>
                <Link className={classes.reset} to="/">
                  Need Help?
                </Link>
              </div>
            </div>
            <div className={classes.paraDiv}>
              {/* paragraph++++++++++++++ */}
              {paradata.map((data, index) => {
                return (
                  <p className={classes.para2} key={index.toString()}>
                    Please open Google Authenticator on your registered device.
                    In-case you have forgot/lost access to these,
                    <Link to="/contact" className={classes.links}>
                      please contract encryptus support.
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className={classes.ResetDiv}>
              <button
                className={classes.reset2}
                disabled={disableOtp}
                onSubmit={handleSubmit}
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Stepbar2;
