import React, { useEffect, useState } from 'react';
import Form from '../components/Form';
import Warning from '../components/Warning';
import { anyObject, FormProp } from '../interfaces';
import classes from '../styles/AddBankAccount.module.scss';
import * as yup from 'yup';
import Button from '../components/Button';
import { useUser } from '../context/user';
import { useModal } from '../context/modal';
import { Menu, MenuItem, MenuButton, FocusableItem } from '@szhsin/react-menu';
import Arrowdown from 'assets/arrow-down-black.svg';
import CurrencyList from 'currency-list';
import { currencyIcon } from 'components/CurrencyIcon';

// ? List of all the currencies of the world
const currenciesList = Object.keys(CurrencyList.getAll('en_US'));

const defaultEntries: FormProp[] = [
  {
    name: 'accountHolderName',
    label: 'Account Holder Name',
    placeholder: 'Type Here',
    type: 'text',
  },
  {
    name: 'accountHolderAddress',
    label: 'Account Holder Address',
    placeholder: 'Type Here',
    type: 'text',
  },
  {
    name: 'iban_number',
    label: 'IBAN/Account Number',
    placeholder: 'Type Here',
    type: 'text',
  },
  {
    name: 'bankIdentifierCode',
    label: 'Swift/BIC',
    placeholder: 'Type Here',
    type: 'text',
  },
];

const schema = yup.object().shape({
  accountHolderName: yup
    .string()
    .required('Account Holder Name is required')
    .min(6, 'Account holder name is too short'),
  accountHolderAddress: yup
    .string()
    .required('Account Holder Address is required')
    .min(20, 'Account holder address is too short'),
  iban_number: yup.string().required('IBAN is required'),
  bankIdentifierCode: yup
    .string()
    .required('Bank Identifier Code is required')
    .min(8, 'BIC minimum length needs to be 8')
    .max(25, 'BIC maximum length is 11'),
});

interface Props {
  getAccounts: () => Promise<any>;
  submit: (body: anyObject) => any;
  account: any;
}

export default function EditBankAccount({
  getAccounts,
  submit,
  account,
}: Props) {
  const { user } = useUser();
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState<FormProp[]>([]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    account?.currency || 'USD',
  );

  // ? Filter value for the Currencies dropdown search
  const [currencyFilter, setCurrencyFilter] = useState<any>('');

  useEffect(() => {
    let entries_local: any = [];
    defaultEntries.map((entry: any, i) => {
      if (account.conflicting_keys.includes(entry.name)) {
        entries_local.push({
          ...entry,
          disabled: false,
          value: null,
        });
      } else {
        console.log({
          entry: entry,
        });

        entries_local.push({
          ...entry,
          disabled: true,
          value:
            entry.name === 'swift'
              ? account['bankIdentifierCode']
              : account[entry.name],
        });
      }
      return null;
    });
    setEntries(entries_local);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (formData: any) => {
    formData.currency = selectedCurrency;

    const data = await submit(formData);
    closeModal();
    return data;
  };
  if (!user?.kycStatus)
    return (
      <div className={classes.warning}>
        <Warning
          title="KYC not approved"
          description="You cannot add a bank account before your KYC is approved"
        />
      </div>
    );
  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Reapply Bank Application</h1>
      <span className={classes.sub_reason}>
        You application was rejected due to: {account.note}
      </span>
      <Form
        setLoading={setLoading}
        entries={entries}
        onSubmit={handleSubmit}
        schema={schema}
      >
        <label>Currency</label>
        <Menu
          menuButton={
            <MenuButton className={classes.editDropBtn}>
              <span>{selectedCurrency}</span>
              <img src={Arrowdown} alt="arrow-img" />
            </MenuButton>
          }
          transition
          menuClassName={`drop-down ${classes.currencyDropdown}`}
          onItemClick={(e) => setSelectedCurrency(e.value)}
        >
          <FocusableItem>
            {({ ref }: any) => (
              <input
                ref={ref}
                type="text"
                placeholder="Type to filter"
                value={currencyFilter}
                onChange={(e) => setCurrencyFilter(e.target.value)}
                className={classes.currencySearchInput}
              />
            )}
          </FocusableItem>

          {currenciesList
            .filter((currency: any) =>
              currency
                .toUpperCase()
                .includes(currencyFilter.trim().toUpperCase()),
            )
            .map((currency) => (
              <MenuItem
                key={currency}
                value={currency}
                className={
                  selectedCurrency === currency ? 'dropdown-item-selected' : ''
                }
              >
                {currency} {currencyIcon(CurrencyList.get(currency)?.symbol)}
              </MenuItem>
            ))}
        </Menu>
        <Button loading={loading}>Apply</Button>
      </Form>
    </div>
  );
}
