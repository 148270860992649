const alphaValues = [
  { above: 1000, letter: 'K' },
  { above: 1000000, letter: 'M' },
];

export const formatNumber = (
  n: number,
  {
    decimals = 2,
    alpha = false,
    symbol,
  }: {
    decimals?: number;
    alpha?: boolean;
    symbol?: boolean;
  } = {},
) => {
  var [num, suffix] = alpha
    ? alphaValues.reduce(
        ([num, suff], { above, letter }) => [
          n >= above ? n / above : num,
          n >= above ? letter : suff,
        ],
        [n, ''],
      )
    : [n, ''];

  const ret = Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(num);
  const sym = symbol && n > 0 ? '+' : '';
  return sym + ret + suffix;
};

export const capitalize = (s: string) => {
  const arr = s.split('');
  arr[0] = arr[0].toUpperCase();
  return arr.join('');
};

export const kmFormatter = (n: number) => {
  return Math.abs(n) > 999999
    ? (Math.abs(n) / 1000000).toFixed(1) + 'M'
    : Math.abs(n) > 999
    ? (Math.abs(n) / 1000).toFixed(1) + 'K'
    : Math.sign(n) * Math.abs(n);
};
