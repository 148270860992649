
import React from 'react';

import classes from "../../styles/AdminHeader.module.scss";

function Home() {
    return <div>
        <svg className={ classes.headerIcon } width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2095 26.6952V22.6062C10.2095 21.5662 11.0574 20.7211 12.1079 20.7141H15.956C17.0116 20.7141 17.8673 21.5612 17.8673 22.6062V22.6062V26.7079C17.867 27.5909 18.5789 28.3126 19.4706 28.3333H22.036C24.5934 28.3333 26.6666 26.2809 26.6666 23.7491V23.7491V12.1171C26.6529 11.1211 26.1806 10.1858 25.3839 9.57737L16.6102 2.58039C15.0732 1.36208 12.8882 1.36208 11.3511 2.58039L2.61595 9.59007C1.81626 10.196 1.3431 11.1329 1.33325 12.1298V23.7491C1.33325 26.2809 3.40642 28.3333 5.9638 28.3333H8.5292C9.44306 28.3333 10.1839 27.5999 10.1839 26.6952V26.6952" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        

    </div>;
}

export default Home;
