import AdminScreen from '../../components/AdminScreen';
import Button from '../../components/Button';
import { ROUTES } from '../../config';
import classes from '../../styles/Reports.module.scss';
import Paginate from '../../components/Paginate';
import Table from '../../components/Table';
import { useSearch } from '../../context/search';
import { useModal } from '../../context/modal';
import { FilterListItem } from '../../interfaces';
import useFilters from '../../hooks/useFilters';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';

const filterList: FilterListItem[] = [
  {
    field: 'type',
    label: 'Type',
    options: [
      { value: 'incoming', label: 'Incoming' },
      { value: 'outgoing', label: 'Outgoing' },
    ],
  },
  {
    field: 'subType',
    label: 'Sub-type',
    options: [
      { value: 'fiatdeposit', label: 'Fiat Deposit' },
      { value: 'cryptograb', label: 'Crypto Buy' },
      { value: 'cryptodeposit', label: 'Crypto Deposit' },
      { value: 'cryptorelease', label: 'Crypto Release' },
    ],
  },
  {
    field: 'createdAt',
    label: 'Created',
    options: [
      {
        value: `7`,
        label: 'Last 7 days',
      },
      { value: '15', label: 'Last 15 days' },
      { value: '30', label: 'Last 30 days' },
    ],
  },
];

// ? Method to render the content in the Type column dynamically
const renderFinanceType = (finance: any) => {
  let { subType } = finance;

  if (subType === 'fiatdeposit') {
    return 'FIAT DEPOSIT';
  } else if (subType === 'cryptograb') {
    return 'CRYPTO BUY';
  } else if (subType === 'cryptodeposit') {
    return 'CRYPTO DEPOSIT';
  } else if (subType === 'cryptorelease') {
    return 'CRYPTO RELEASE';
  }
};
// * End of renderFinanceType();

export default function Reports() {
  const { openModal } = useModal();
  const { filters, updateFilters } = useFilters();
  const { search } = useSearch();
  let [reports, { paginateProps, loading }] = useAdminPaginate<any>(
    {
      route: ROUTES.FIN_REPORTS,
      params: { filters: { ...filters } },
    },
    [search, filters],
  );

  console.log({
    reports,
  });

  // ? Method to render the content in the Logs/Info column dynamically
  const renderInfoColumn = (finance: any) => {
    if (finance.subType === 'fiatdeposit') {
      return (
        <td className={classes.logsTableCell}>
          <Button
            onClick={() => openModal(() => <FinDetails finance={finance} />)}
          >
            Details
          </Button>
        </td>
      );
    } else {
      return (
        <td className={classes.logsTableCell}>
          <Button>Logs</Button>
        </td>
      );
    }
  };
  // * End of renderInforColumn();

  return (
    <AdminScreen className={classes.container}>
      <Table
        filters={filters}
        updateFilters={updateFilters}
        filterList={filterList}
        title="Reports"
        loading={loading}
      >
        <thead>
          <tr>
            <th>Email</th>
            <th>Type</th>
            <th>Transaction Type</th>
            <th>Amount</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((finance) => (
            <tr key={finance.tagger_email}>
              <td onDoubleClick={() => copyText(finance.tagger_email)}>
                {finance.tagger_email}
              </td>
              <td onDoubleClick={() => copyText(finance?.type.toUpperCase())}>
                {finance?.type.toUpperCase()}
              </td>
              <td onDoubleClick={() => copyText(renderFinanceType(finance))}>
                {renderFinanceType(finance)}
              </td>
              <td
                onDoubleClick={() =>
                  copyText(
                    `${CURRENCY_CONV.fiatAmtConv(
                      finance?.subType === 'fiatdeposit'
                        ? finance?.deposit_amt
                        : finance.profit_amt,
                    )}` +
                      ' ' +
                      `${
                        finance.subType === 'fiatdeposit'
                          ? finance.deposit_curr
                          : finance.profit_curr
                      }`,
                  )
                }
              >
                {CURRENCY_CONV.fiatAmtConv(
                  finance?.subType === 'fiatdeposit'
                    ? finance?.deposit_amt
                    : finance.profit_amt,
                )}
                &nbsp;
                {finance.subType === 'fiatdeposit'
                  ? finance.deposit_curr
                  : finance.profit_curr}
              </td>
              {renderInfoColumn(finance)}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className={classes.foot}>
        <Paginate {...paginateProps} />
      </div>
    </AdminScreen>
  );
}

function FinDetails({ finance }: any) {
  let { userrefID, tagger_email, type, deposit_amt, deposit_curr, createdAt } =
    finance;

  return (
    <div className={classes.finDetailsModal}>
      <h2>Details</h2>

      <div className={classes.finDetailsSection}>
        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Reference ID</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(userrefID)}
          >
            {userrefID}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Email</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(tagger_email)}
          >
            {tagger_email}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Type</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(type.toUpperCase())}
          >
            {type.toUpperCase()}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Transaction Type</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(renderFinanceType(finance))}
          >
            {renderFinanceType(finance)}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Deposit Amount</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() =>
              copyText(CURRENCY_CONV.fiatAmtConv(deposit_amt))
            }
          >
            {CURRENCY_CONV.fiatAmtConv(deposit_amt)}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Deposit Currency</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(deposit_curr)}
          >
            {deposit_curr}
          </span>
        </div>

        <div className={classes.finDetailItem}>
          <span className={classes.finTitle}>Date - Time of Deposit</span>
          <span
            className={classes.finDetail}
            onDoubleClick={() => copyText(DATE_CONV.dateTimeConvert(createdAt))}
          >
            {DATE_CONV.dateTimeConvert(createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
}
