import React from 'react';
import classes from '../styles/BankApplicationStatus.module.scss';
import { anyObject, FormProp } from '../interfaces';
import * as yup from 'yup';
import { useModal } from '../context/modal';
import Form from '../components/Form';
import Button from '../components/Button';
import EditBankAccount from './EditBankAccount';
import usePaginate from '../hooks/usePaginate';
import { ROUTES } from '../config';

const entries: FormProp[] = [];

const schema = yup.object().shape({});

const BankApplicationStatus = ({
  submit,
  account,
}: {
  submit: (body: anyObject) => any;
  account: any;
}) => {
  // eslint-disable-next-line
  const [accounts, { reload }] = usePaginate<any>({
    // there is not any routes for editing bank applications so change it when
    route: ROUTES.FETCH_USER_BANK_APPLICATIONS,
  });

  const { openModal } = useModal();
  const handleSubmit = async (formData: any) => {
    console.log(formData);
    openModal(() => (
      <EditBankAccount getAccounts={reload} submit={submit} account={account} />
    ));
    return { error: '', success: '' };
  };
  return (
    <div className={classes.container}>
      <h1 style={{ textAlign: 'center', margin: '0 0 35px' }}>
        Re-apply Bank Application
      </h1>

      <div className={classes.form}>
        <Form onSubmit={handleSubmit} schema={schema} entries={entries}>
          <Button>Re-apply</Button>
        </Form>
      </div>
    </div>
  );
};

export default BankApplicationStatus;
