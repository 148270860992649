import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from '../styles/CryptoDetails.module.scss';
import { DATE_CONV } from 'utils/DateConv';
import { CURRENCY_CONV } from 'utils/CurrencyConv';
import { copyText } from 'utils/CopyContent';
import { ROUTES } from 'config';
import { useAdmin } from 'context/admin';

function CryptoDetails({ transactionData }: any) {
  const [banks, setBanks] = useState([]);
  const [wallets, setWallets] = useState([]);
  const { headers } = useAdmin();

  useEffect(() => {
    getBanks();
    getWallets();
    // eslint-disable-next-line
  }, []);

  const getBanks = async () => {
    const { data } = await axios.get(
      ROUTES.S_FETCH_USER_BANK_APPLICATIONS_ADMIN(transactionData.tagger_id),
      { headers },
    );
    setBanks(data);
  };

  const getWallets = async () => {
    const { data } = await axios.get(
      ROUTES.FETCH_USER_WALLETS_ADMIN(transactionData.tagger_id),
      { headers },
    );
    setWallets(data.list);
  };

  return (
    <div className={classes.detailsContainer}>
      <h1>Crypto Buy Details</h1>
      <section className={classes.box}>
        <>
          <div>
            <h4>Transaction Reference ID</h4>
            <p onDoubleClick={() => copyText(transactionData._id)}>
              {transactionData._id}
            </p>
          </div>

          <div>
            <h4>Customer Email</h4>
            <p onDoubleClick={() => copyText(transactionData.tagger_email)}>
              {transactionData.tagger_email}
            </p>
          </div>

          <div>
            <h4>Logged IP</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData?.client_recorded_IP)
              }
            >
              {transactionData?.client_recorded_IP}
            </p>
          </div>

          {/* <div>
            <h4>Customer ID</h4>
            <p onDoubleClick={() => copyText(transactionData.tagger_id)}>
              {transactionData.tagger_id}
            </p>
          </div> */}

          <div>
            <h4>Transaction Status</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData.transc_status.toUpperCase())
              }
              className={
                transactionData?.transc_status === 'approved'
                  ? 'textStatusApproved'
                  : transactionData?.transc_status === 'pending'
                  ? 'textStatusPending'
                  : 'textStatusRejected'
              }
            >
              {transactionData.transc_status.toUpperCase()}
            </p>
          </div>

          <div>
            <h4>Approval Given</h4>
            <p
              className={
                transactionData?.fin_approval_given === true
                  ? 'textStatusApproved'
                  : 'textStatusPending'
              }
              onDoubleClick={() =>
                copyText(
                  transactionData.fin_approval_given === true
                    ? 'APPROVED'
                    : 'NOT APPROVED',
                )
              }
            >
              {transactionData.fin_approval_given === true
                ? 'APPROVED'
                : 'NOT APPROVED'}
            </p>
          </div>

          <div>
            <h4>Approval Count</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData.need_approval_count === -1
                    ? 'APPROVAL COUNT NOT SET'
                    : transactionData.need_approval_count,
                )
              }
            >
              {transactionData.need_approval_count === -1
                ? 'APPROVAL COUNT NOT SET'
                : transactionData.need_approval_count}
            </p>
          </div>

          <div>
            <h4>Original Price</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData.lp_original_price),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData.lp_original_price)}{' '}
              {transactionData.profit_curr}
            </p>
          </div>

          <div>
            <h4>Buying Price</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  CURRENCY_CONV.fiatAmtConv(transactionData.user_buying_price),
                )
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData.user_buying_price)}{' '}
              {transactionData.profit_curr}
            </p>
          </div>

          <div>
            <h4>Profit Amt</h4>
            <p
              onDoubleClick={() =>
                copyText(CURRENCY_CONV.fiatAmtConv(transactionData.profit_amt))
              }
            >
              {CURRENCY_CONV.fiatAmtConv(transactionData.profit_amt)} USD
            </p>
          </div>

          <div>
            <h4>Profit Currrency</h4>
            <p onDoubleClick={() => copyText(transactionData.profit_curr)}>
              {transactionData.profit_curr}
            </p>
          </div>

          <div>
            <h4>Liminal Deposit Wallet Address</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData.lim_deposit_walletAddress)
              }
            >
              {transactionData.lim_deposit_walletAddress}
            </p>
          </div>

          <div>
            <h4>Associated Network</h4>
            <p
              onDoubleClick={() =>
                copyText(
                  transactionData.user_wallet_associatedNetwork.toUpperCase(),
                )
              }
            >
              {transactionData.user_wallet_associatedNetwork.toUpperCase()}
            </p>
          </div>

          <div>
            <h4>User Wallet Address</h4>
            <p
              onDoubleClick={() =>
                copyText(transactionData.user_destination_walletAddress)
              }
            >
              {transactionData.user_destination_walletAddress}
            </p>
          </div>

          {transactionData.lp_order_response ? (
            <>
              <div>
                <h4>LP Order Status</h4>
                <p
                  onDoubleClick={() =>
                    copyText(
                      transactionData.lp_order_response.lp_status
                        ? transactionData.lp_order_response.lp_status
                        : 'Unavailable',
                    )
                  }
                >
                  {transactionData.lp_order_response.lp_status
                    ? transactionData.lp_order_response.lp_status
                    : 'Unavailable'}
                </p>
              </div>

              <div>
                <h4>Quantity</h4>
                <p
                  onDoubleClick={() =>
                    copyText(
                      transactionData.lp_order_response.lp_executedQuantity
                        ? transactionData.lp_order_response.lp_executedQuantity
                        : 'Unavailable',
                    )
                  }
                >
                  {transactionData.lp_order_response.lp_executedQuantity
                    ? transactionData.lp_order_response.lp_executedQuantity
                    : 'Unavailable'}
                </p>
              </div>

              <div>
                <h4>LP Price</h4>
                <p
                  onDoubleClick={() =>
                    copyText(
                      transactionData.lp_order_response.lp_price
                        ? CURRENCY_CONV.fiatAmtConv(
                            transactionData.lp_order_response.lp_price,
                          )
                        : 'Unavailable',
                    )
                  }
                >
                  {transactionData.lp_order_response.lp_price
                    ? CURRENCY_CONV.fiatAmtConv(
                        transactionData.lp_order_response.lp_price,
                      )
                    : 'Unavailable'}
                </p>
              </div>

              <div>
                <h4>LP Currency</h4>
                <p
                  onDoubleClick={() =>
                    copyText(
                      transactionData.lp_order_response.lp_baseCurrency
                        ? transactionData.lp_order_response.lp_baseCurrency
                        : 'Unavailable',
                    )
                  }
                >
                  {transactionData.lp_order_response.lp_baseCurrency
                    ? transactionData.lp_order_response.lp_baseCurrency
                    : 'Unavailable'}
                </p>
              </div>

              <div>
                <h4>Transaction Date - Time</h4>
                <p
                  onDoubleClick={() =>
                    copyText(
                      transactionData.lp_order_response.lp_timestamp
                        ? DATE_CONV.dateTimeConvert(
                            transactionData.lp_order_response.lp_timestamp,
                          )
                        : 'Unavailable',
                    )
                  }
                >
                  {transactionData.lp_order_response.lp_timestamp
                    ? DATE_CONV.dateTimeConvert(
                        transactionData.lp_order_response.lp_timestamp,
                      )
                    : 'Unavailable'}
                </p>
              </div>
            </>
          ) : (
            ''
          )}
        </>
      </section>
      <hr />
      <h1>User Bank Accounts</h1>
      {banks.map((bank: any, index) => (
        <div key={index.toString()}>
          <section className={classes.box}>
            <div>
              <h4>Account Holder Name</h4>
              <p>{bank.accountHolderName}</p>
            </div>
            <div>
              <h4>Account Holder Address</h4>
              <p>{bank.accountHolderAddress}</p>
            </div>
            <div>
              <h4>IBAN/AccountNumber</h4>
              <p>{bank.iban_number}</p>
            </div>
            <div>
              <h4>Swift/BIC</h4>
              <p>{bank.bankIdentifierCode}</p>
            </div>
            <div>
              <h4>Supported Currency</h4>
              <p>{bank.currency}</p>
            </div>
            <div>
              <h4>Approval Status</h4>
              <p
                className={
                  bank.status === 'approved'
                    ? 'textStatusApproved'
                    : bank.status === 'pending'
                    ? 'textStatusPending'
                    : 'textStatusRejected'
                }
              >
                {bank.status.toUpperCase()}
              </p>
            </div>
          </section>
        </div>
      ))}
      <hr />
      <h1>User Wallets</h1>
      {wallets.map((wallet: any, index: any) => (
        <div>
          <section className={classes.box}>
            <div>
              <h4>Wallet Address</h4>
              <p>{wallet.walletAddress}</p>
            </div>
            <div>
              <h4>Associated Network</h4>
              <p>{wallet.associated_network}</p>
            </div>
            <div>
              <h4>Coin type</h4>
              <p>{wallet.coin_type}</p>
            </div>
            <div>
              <h4>Active</h4>
              <p
                className={
                  wallet.isActive ? 'textStatusApproved' : 'textStatusRejected'
                }
              >
                {wallet.isActive ? 'Active' : 'Inactive'}
              </p>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
}

export default CryptoDetails;
