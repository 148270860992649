import { useState } from 'react';
import Form from '../components/Form';
import classes from 'styles/Login.module.scss';
import { FormProp, LoginForm } from '../interfaces';
import { ReactComponent as emailIcon } from 'assets/email.svg';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import axios from 'axios';
import { ROUTES } from 'config';
import { toast } from 'react-toastify';
import ForgotPasswordOTP from './ForgotPasswordOTP';
import { useAdmin } from 'context/admin';

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function ChangeAdminPassword() {
  const { admin, headers } = useAdmin();

  const entries: FormProp[] = [
    {
      name: 'email',
      label: 'E-Mail',
      icon: emailIcon,
      type: 'email',
      value: admin.email,
      disabled: true,
    },
  ];
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();

  const handleSubmit = async (formData: LoginForm) => {
    try {
      let body = {
        adminEmail: admin.email,
      };

      const { data } = await axios.post(ROUTES.ADMIN_CHANGE_PASSWORD, body, {
        headers,
      });

      console.log({
        responseData: data,
      });

      if (data && data?.status === 'success') {
        toast.success(data.message);

        openModal(() => <ForgotPasswordOTP isAdmin email={formData.email} />);
      } else {
        console.log(data);

        toast.error(data.message);
      }
    } catch (error: any) {
      console.log({ error });

      toast.error(error?.response?.data?.message || error?.response?.data);
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Change Password</h1>
      <Form
        setLoading={setLoading}
        onSubmit={handleSubmit}
        schema={schema}
        entries={entries}
      >
        <div className={classes.action}>
          <div />
          <Button loading={loading} type="submit">
            Send OTP
          </Button>
          <div />
        </div>
      </Form>
    </div>
  );
}
