import classes from '../../styles/AdminHeader.module.scss';

function Bank() {
  return (
    <div>
      <svg
        className={classes.headerIcon}
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="rgba(255, 255, 255, 0.85)"
      >
        <path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z"></path>
      </svg>
    </div>
  );
}

export default Bank;
