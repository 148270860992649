import React, { useState, useEffect } from 'react';
import classes from '../styles/Register.module.scss';
import inputClasses from '../styles/InputField.module.scss';
import { FormProp, RegisterForm } from '../interfaces';
import Form from '../components/Form';
import Button from '../components/Button';
import { useModal } from '../context/modal';
import * as yup from 'yup';
import { useUser } from '../context/user';
import 'react-phone-input-2/lib/style.css';
import passStrength from 'zxcvbn';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ROUTES } from '../config';
import ReCAPTCHA from 'react-google-recaptcha';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone_no: yup
    .string()
    .required('Phone Number is Required')
    .min(8, 'Phone Number minimum length needs to be 8')
    .max(22, 'Phone Number maximum length is 22'),
});

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [passwordError, setPasswordError] = useState<string>('');
  const [country, setCountry] = useState<string>('ae');
  const [sumSubLevelName, setSumSubLevelName] = useState('');
  // const [email, setEmail] = useState('');
  // const [OTP, setOTP] = useState('');
  // const [verifyEmail, SetVerifyEmail] = useState(false);
  // eslint-disable-next-line
  const [verifyOTP, SetVerifyOTP] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const { closeModal } = useModal();
  const { register } = useUser();

  const entries: FormProp[] = [
    [
      { label: 'Your Name', name: 'firstName', placeholder: 'First Name' },
      { name: 'lastName', placeholder: 'Last Name' },
    ],
    [
      {
        label: 'Email',
        name: 'email',
        placeholder: '',
        type: 'email',
      },
      {
        label: 'Phone Number',
        name: 'phone_no',
        phoneNumberInput: true,
        type: 'tel',
        country: country,
      },
    ],
  ];

  useEffect(() => {
    checkLocation();
  }, []);

  const checkLocation = async () => {
    try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(ROUTES.CHECK_LOCATION);
      if (data) {
        setCountry(data.shortName.toLowerCase());
        return;
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        toast.error(
          `Thank you for visiting Encryptus. Regrettably, you are accessing Encryptus from a country, including the USA, Canada and UK, where our services are not provided. We kindly ask you to review our Public Disclosure for additional details. 
          If you are neither in a restricted country nor a national or resident of one, 
          please reach out to our customer service team at hello@encryptus.io.`,
        );
      }
    }
  };

  const handleSubmit = (f: RegisterForm) => {
    console.log({
      loc: 'handleSubmit',
      f,
    });

    if (!passwordRegex.test(password)) {
      setPasswordError(
        () =>
          'Password Must Contain More than 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
      );
    } else if (password !== confirmPassword) {
      setPasswordError(() => 'Passwords must match');
    } else {
      const passInfo: any = passStrength(password);

      if (passInfo && passInfo.Strength < 4) {
        setPasswordError(() => 'Please enter a strong password to proceed');
      } else if (sumSubLevelName === '') {
        setPasswordError(
          () => 'Please select if you are an Individual or Institution',
        );
      } else {
        f.password = password;
        f.passwordConfirmation = password;
        f.levelName = sumSubLevelName;
        // f.email = email;

        console.log({
          updatedF: f,
        });

        return register(f, closeModal);
      }
    }
  };

  // ? Method to handle Password field text input
  const handlePasswordChange = (password: any) => {
    setPassword(() => password);

    if (!passwordRegex.test(password)) {
      setPasswordError(
        () =>
          'Password Must Contain More than 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
      );
    } else {
      setPasswordError(() => '');
    }

    let passInfo: any = passStrength(password);

    if (passInfo && passInfo.score) {
      setPasswordStrength(passInfo.score);
    }
  };
  // * End of handlePasswordChange()

  // ? Method to handle Email field text input
  // const handleEmailChange = (email: any) => {
  //   setEmail(email);
  // };
  // * End of handlePasswordChange()

  // ? Method to handle Confirm Password field text input
  const handleConfirmPasswordChange = (confirmPassword: any) => {
    setConfirmPassword(() => confirmPassword);

    if (confirmPassword !== password) {
      setPasswordError(() => 'Passwords must match');
    } else {
      setPasswordError(() => '');
    }
  };
  // * End of handleConfirmPasswordChange();

  // ? Method to handle the change in the radio button selection
  const handleLevelNameChange = (event: any) => {
    setSumSubLevelName(event.target.value);
  };

  // const handleOTP = (event: any) => {
  //   setOTP(event.target.value);
  // };

  // const handleVerify = async () => {
  //   try {
  //     axios.defaults.withCredentials = false;
  //     const result = await axios.post(
  //       ROUTES.REG_SEND_EMAIL_OTP,
  //       {
  //         userEmail: email,
  //       },
  //       { headers },
  //     );

  //     if (result) {
  //       toast.success(`OTP is sent on ${email}`);
  //       SetVerifyEmail(true);
  //     }
  //   } catch (err) {
  //     toast.warn('Please Provide a Proper Email');
  //     console.log(err);
  //   }
  // };

  // const handleOTPSubmit = async (e: any) => {
  //   try {
  //     e.preventDefault();
  //     const result = await axios.post(
  //       ROUTES.REG_VALIDATE_OTP,
  //       {
  //         userOTP: OTP,
  //       },
  //       { headers },
  //     );
  //     if (result) {
  //       toast.success('Email Validation Success');
  //       SetVerifyEmail(false);
  //       SetVerifyOTP(true);
  //     }
  //   } catch (err) {
  //     toast.error('Error While Validating OTP');
  //   }
  // };

  function onChange(value: any) {
    if (value) {
      setCaptcha(true);
      toast.success('Verified');
      console.log('Captcha value:', value);
    } else {
      toast.warn('Please verify Yourself');
      setCaptcha(false);
    }
  }

  function Errorhandler(value: any) {
    if (value) {
      setCaptcha(false);
    }
  }

  // * End of handleLevelNameChange()

  // // link choose function
  // function mode(type: string) {
  //   switch (type) {
  //     case 'development':
  //       return `http://localhost:3000/termsCondition`;
  //     case 'staging':
  //       return 'https://nncblkxdm.encryptus.io/termsCondition';
  //     case 'production':
  //       return 'https://app.encryptus.io/termsCondition';
  //   }
  // }

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>
        Enter Your Details to Register your Account
      </h1>
      <div className={classes.FormContainer}>
        <Form
          setLoading={setLoading}
          schema={schema}
          onSubmit={
            captcha ? handleSubmit : () => toast.warn('Please complete captcha')
          }
          entries={entries}
        >
          {/* <div className={inputClasses.container}>
            <label>Email Address</label>
            <div className={inputClasses.inputContainer}>
              <div className={inputClasses.icon}></div>
              <input
                name="email"
                type="email"
                value={email}
                onChange={(event) => handleEmailChange(event.target.value)}
                required
              />
              {verifyOTP ? (
                <p>Verified</p>
              ) : (
                <button className={classes.handleVerify} onClick={handleVerify}>
                  Verify Email
                </button>
              )}

              <div className={inputClasses.tool}></div>
            </div>
          </div> */}
          {/* ++++++++++++++++++++++++ */}
          {/* {verifyEmail ? (
            <div className={inputClasses.container}>
              <div className={inputClasses.inputContainer}>
                <div className={inputClasses.icon}>
                  <input
                    type="text"
                    placeholder="Enter OTP Here"
                    value={OTP}
                    onChange={(e: any) => handleOTP(e)}
                    className={inputClasses.OTpinput}
                  />
                  <button
                    className={classes.handleVerify}
                    onClick={(e: any) => handleOTPSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          ) : null} */}
          {/* ++++++++++++++++++++++++ */}
          <div
            className={inputClasses.flex}
            style={{
              marginTop: '-10px',
            }}
          >
            <div>
              <div className={inputClasses.container}>
                <label>Password</label>
                <div className={inputClasses.inputContainer}>
                  <div className={inputClasses.icon}></div>
                  <input
                    name="password"
                    id="clean"
                    placeholder=""
                    type="password"
                    value={password}
                    onChange={(event) =>
                      handlePasswordChange(event.target.value)
                    }
                    required
                  />

                  <div className={inputClasses.tool}></div>
                </div>
              </div>
            </div>

            <div>
              <div className={inputClasses.container}>
                <label>Confirm Password</label>
                <div className={inputClasses.inputContainer}>
                  <div className={inputClasses.icon}></div>
                  <input
                    name="passwordConfirmation"
                    id="clean"
                    placeholder=""
                    type="password"
                    value={confirmPassword}
                    onChange={(event) =>
                      handleConfirmPasswordChange(event.target.value)
                    }
                    required
                  />
                  <div className={inputClasses.tool}></div>
                </div>
              </div>
            </div>
          </div>
          <p className={classes.Para}>
            Please do not use any part of your full name or email address, and
            avoid using commonly used or guessable passwords.
          </p>

          <div
            style={
              {
                // marginTop: '-12px',
              }
            }
          >
            <div>
              <div className={classes.passwordStrengthSection}>
                <div
                  className={classes.passwordStrengthBar}
                  style={{
                    width: `${passwordStrength * 25}%`,
                    backgroundColor:
                      passwordStrength < 3
                        ? 'red'
                        : passwordStrength === 3
                          ? '#F6BE00'
                          : passwordStrength > 3
                            ? 'green'
                            : 'none',
                  }}
                />
              </div>
              <span>
                Password Strength: &nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color:
                      passwordStrength < 3
                        ? 'red'
                        : passwordStrength === 3
                          ? '#F6BE00'
                          : passwordStrength > 3
                            ? 'green'
                            : 'none',
                  }}
                >
                  {passwordStrength < 3
                    ? 'WEAK'
                    : passwordStrength === 3
                      ? 'FAIR'
                      : passwordStrength > 3
                        ? 'STRONG'
                        : ''}
                </span>
              </span>
            </div>
            <div>
              <div className={classes.passErrSection}>
                <span>{passwordError}</span>
              </div>
            </div>
          </div>

          <div
            onChange={handleLevelNameChange}
            className={classes.levelSelectionSection}
          >
            <span>Are you an Individual or Institution ?</span>

            <div>
              <label>
                <input type="radio" value="Individual v2" name="levelName" />
                <span> Individual</span>
              </label>
              &nbsp; &nbsp; &nbsp;
              <label>
                <input type="radio" value="Institution v2" name="levelName" />
                <span> Institution</span>
              </label>
            </div>
          </div>

          {/* <div className={classes.passErrSection}>
            <span>{passwordError}</span>
          </div> */}

          <div className={classes.inputSection}>
            <label>
              <input type="checkbox" required />
              <p>
                Please Agree To Our{' '}
                <a
                  href="https://encryptus.io/termsCondition"
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </p>
            </label>
          </div>
          <div className="text-center" style={{ margin: '1px auto' }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_KEY as string}
              onChange={onChange}
              onError={Errorhandler}
            // onloadCallback=
            />
          </div>
          {captcha ? (
            <Button loading={loading} className={classes.submit}>
              Register
            </Button>
          ) : (
            <button
              onClick={() =>
                !verifyOTP ? toast.warn('Please Verify Email First') : ''
              }
              className={classes.DiabledBtn}
            >
              Register
            </button>
          )}
        </Form>
      </div>
    </div>
  );
}
