import React from 'react';
import { HTMLELEMENT } from '../interfaces';
import classes from '../styles/Card.module.scss';

interface Props extends HTMLELEMENT<HTMLDivElement> {
  variant?: 1 | 2;
}

export default function Card({ variant = 1, className, ...rest }: Props) {
  return (
    <div
      {...rest}
      className={`${classes.container} ${className || ''} ${
        classes[`variant${variant}`]
      }`}
    />
  );
}
