import React, { useState } from 'react';
import classes from 'styles/DepositeAmount.module.scss';
import { useUser } from 'context/user';
import { useModal } from 'context/modal';
import Depositefiat from './Depositefiat';
import SelectCurrency from './SelectCurrency';
import CurrencyList from 'currency-list';
import { currencyIcon } from 'components/CurrencyIcon';
import { Menu, MenuItem, MenuButton, FocusableItem } from '@szhsin/react-menu';
import Arrowdown from 'assets/arrow-down-black.svg';

// ? List of all the currencies of the world
const currenciesList = Object.keys(CurrencyList.getAll('en_US'));

interface Head {
  currency: string;
  symbol: string;
  uuid: string;
}

function DepositAmount({ currency, symbol, uuid }: Head) {
  const { user } = useUser();
  const { openModal } = useModal();

  const [depositAmount, setDepositAmount] = useState<any>();
  const [fromError, setFormError] = useState<any>('');

  // ? Filter value for the Currencies dropdown search
  const [currencyFilter, setCurrencyFilter] = useState<any>('');

  const [formCurrency, setFormCurrency] = useState<string>('USD');

  // eslint-disable-next-line
  const data = {
    tagger_email: user.email,
  };
  const handleSubmit = async () => {
    setFormError(() => '');

    if (isNaN(depositAmount)) {
      setFormError(() => 'Please enter a valid amount');
    } else {
      openModal(() => (
        <Depositefiat
          currency={formCurrency}
          symbol={symbol}
          depositAmount={depositAmount}
          uuid={uuid}
        />
      ));
    }
  };

  // ? Method to conditionally render the currency input
  const renderCurrencyInput = () => {
    if (symbol !== 'USD') {
      return <input type="text" value={`${currency} (${symbol})`} disabled />;
    } else {
      return (
        <Menu
          menuButton={
            <MenuButton className={classes.dropdownBtn}>
              <span>{formCurrency}</span>
              <img src={Arrowdown} alt="arrow-img" />
            </MenuButton>
          }
          transition
          menuClassName={`drop-down ${classes.currencyDropdown}`}
          onItemClick={(e) => setFormCurrency(() => e.value)}
        >
          <FocusableItem>
            {({ ref }: any) => (
              <input
                ref={ref}
                type="text"
                placeholder="Type to filter"
                value={currencyFilter}
                onChange={(e) => setCurrencyFilter(e.target.value)}
              />
            )}
          </FocusableItem>

          {currenciesList
            .filter((currency: any) =>
              currency
                .toUpperCase()
                .includes(currencyFilter.trim().toUpperCase()),
            )
            .map((currency) => (
              <MenuItem
                key={currency}
                value={currency}
                className={
                  formCurrency === currency ? 'dropdown-item-selected' : ''
                }
              >
                {currency} {currencyIcon(CurrencyList.get(currency)?.symbol)}
              </MenuItem>
            ))}
        </Menu>
      );
    }
  };
  // * End of renderCurrencyInput();

  return (
    <div className={classes.Container}>
      <h2>Deposit {currency}</h2>
      <form className={classes.depositForm}>
        <label>Amount</label>

        <input
          type="number"
          value={depositAmount}
          onChange={(event) => setDepositAmount(event.target.value)}
          placeholder="Enter Deposit Amount"
          required
        />

        <label>Currency:</label>

        {renderCurrencyInput()}

        <label>Reference ID</label>

        <input type="text" value={uuid} disabled />

        <span>{fromError}</span>

        <div className={classes.btndiv}>
          <span onClick={() => openModal(() => <SelectCurrency />)}>Back</span>

          <button type="submit" onClick={handleSubmit}>
            Deposit
          </button>
        </div>
      </form>
    </div>
  );
}

export default DepositAmount;
