import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClientScreen from '../../components/ClientScreen';
import classes from '../../styles/Checkout.module.scss';
import btn from '../../styles/ClientDashboard.module.scss';
import Loader from 'react-loader-spinner';
import Footer from 'components/common/Footer';
import { useSHdigital } from 'context/SHdigital';
import { useFalconx } from 'context/falconx';

export default function SellCheckout() {
  const [count, setCount] = useState(20);
  const [timedOut, setTimedOut] = useState(false);
  const [flag, setFlag] = useState(0);
  const history = useHistory();
  const location: any = useLocation();
  const [quntity, setQuantity] = useState<any>('');
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const { changeRequest, quote } = useSHdigital();
  const { changeRequest2, quote2 } = useFalconx();
  const [finalquote, setFinalQuote] = useState<any>();
  // eslint-disable-next-line
  const [datamain, setData] = useState<any>({});
  const { headers } = useUser();

  useEffect(() => {
    let c = 20;
    let timerId = setInterval(() => {
      c -= 1;
      setCount(c);
      if (c === 0) {
        toast('Session timed out!', { type: 'error' });
        setTimedOut(true);
        setCount(20);
        clearInterval(timerId);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clearInterval(timerId);
    };
  }, [flag]);

  useEffect(() => {
    updateQuote();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (quote && (location.state.quoteCurrency !== 'USD' || location.state.baseCurrency === 'USDC')) {
      setFinalQuote(quote);
      setAmount(
        location.state.toggler === 'sent'
          ? (quote.sellingPriceForUser * quote.quantity).toFixed(2)
          : location.state.cryptovalue,
      );
    }
    if (quote2 && (location.state.quoteCurrency === 'USD' && location.state.baseCurrency !== 'USDC')) {
      setFinalQuote(quote2);
      setAmount(
        location.state.toggler === 'sent'
          ? (quote2.sellingPriceForUser * quote2.quantity).toFixed(2)
          : location.state.cryptovalue,
      );
    }
  }, [location.state.quoteCurrency, quote, quote2]);

  // eslint-disable-next-line
  const updateQuote = async () => {
    const value =
      location.state.toggler === 'crypto'
        ? location.state.quantity
        : location.state.cryptovalue;
    const quoteRequestStream = {
      baseCurrency: location.state.baseCurrency,
      quoteCurrency: location.state.quoteCurrency,
      quantity: Number(value),
    };
    if (location.state.quoteCurrency !== 'USD' || location.state.baseCurrency === 'USDC') {
      // @ts-ignore
      await changeRequest(quoteRequestStream, location.state.PriceValues);
    } else {
      await changeRequest2(quoteRequestStream, location.state.PriceValues);
    }
    setQuantity(
      location.state.toggler === 'sent'
        ? location.state.quantity
        : location.state.quantity2,
    );
  };

  const handleSubmit = async (quantity: number) => {
    try {
      const body = {
        baseCurrency: String(location.state.cointype.toUpperCase()),
        quoteCurrency: 'USD',
        quantity: Number(quantity),
        maxSlippage: 4,
      };
      const { data } = await axios.post(ROUTES.Crypto_Fetch, body, {
        headers,
      });
      setData(data.currQuoteInfo);
      setAmount(data.currQuoteInfo.sellingPriceForUser);
      toast.success('Price Updated');
    } catch (err) {
      console.log(err);
    }
  };

  const handleRequest = async () => {
    setLoading(true);

    try {
      const body = {
        quoteId: location.state.quoteId,
        baseCurrency: location.state.baseCurrency,
        quoteCurrency: location.state.quoteCurrency,
        quantity: parseFloat(quntity),
        originalPrice: Number(finalquote.originalPrice) * quntity,
        originalSellPrice: Number(finalquote.originalSellPrice * finalquote.quantity),
        displayedPrice: Number(
          (Number(finalquote.sellingPriceForUser) * Number(quntity)).toFixed(2),
        ),
        unit_buyPrice_lp: Number(finalquote.unit_buyPrice_lp),
        unit_sellPrice_lp: Number(finalquote.unit_sellPrice_lp),
        maxSlippage: location.state.maxSlippage,
        side: 'Sell',
        clientReference: location.state.clientReference,
        userbankAccNo: location.state.userbankAccNo,
        userSwiftBIC: location.state.userSwiftBIC,
        commisions_applied: Number(finalquote.commisions_applied),
        margins_applied: Number(finalquote.margins_applied),
        transc_fee: Number(finalquote.transc_fee),
        fees_charged: Number(finalquote.fees_charged),
        revenue: Number(
          finalquote.originalSellPrice * finalquote.quantity -
          finalquote.sellingPriceForUser * finalquote.quantity,
        ),
      };
      // console.log(body);
      if (location.state.quoteCurrency === 'USD' || location.state.baseCurrency !== 'USDC') {
        const { data } = await axios.post(
          ROUTES.Falconx_Place_Sell_Order,
          body,
          {
            headers,
          },
        );

        if (data?.status === 'Success') {
          toast.success(data?.message || 'Sell Crypto Successful');
          history.push('/orders/1');
        }
      } else if (location.state.quoteCurrency === 'EUR' || location.state.quoteCurrency === 'GBP') {
        const { data } = await axios.post(ROUTES.SHD_Order_Place_Api, body, {
          headers,
        });

        if (data?.status === 'Success') {
          toast.success(data?.message || 'Sell Crypto Successful');
          history.push('/orders/1');
        }
      }
    } catch (err: any) {
      toast.error(err.response?.data?.message);
      console.log(err);
      history.replace('/dashboard');
    }

    setLoading(false);
  };

  return (
    <>
      <ClientScreen title="Check Out">
        <div className={classes.Outline_Div}>
          <div className={classes.Inner_DIV}>
            {/* Value to Give+++++++++++++++++++ */}
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={classes.label_main}>You Get</label>
                <label className={classes.label_parent}>
                  {quntity * finalquote?.sellingPriceForUser} {location.state.quoteCurrency}
                </label>
              </div>
            </div>
            {/* Value to Get++++++++++++++++++++ */}
            <div className={classes.inputs}>
              <div className={classes.labels}>
                <label className={classes.label_main}>You Give</label>
                <label className={classes.label_parent}>
                  {quntity} {location.state.cointype}
                </label>
              </div>
            </div>
          </div>
          {/* Address++++++++++++++++++++++ */}
          <div className={classes.inputs}>
            <div className={classes.labels}>
              <label className={classes.label_main}>Fiat Payout Address</label>
              <label className={classes.label_parent}>
                <br />
                {location.state.userbankAccNo}
              </label>
            </div>
          </div>
        </div>
        {/* New Button Divion++++++++++++++++++++++++ */}
        <div className={classes.btn_divs}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
            className={btn.button}
          >
            <button
              onClick={async () => {
                if (timedOut) {
                  await handleSubmit(quntity);
                  setFlag(flag + 1);
                  setTimedOut(false);
                } else {
                  await handleRequest();
                }
              }}
              className={btn.buttonTransact}
              disabled={loading}
            >
              {loading ? (
                <Loader type="ThreeDots" color={'#FFFFFF'} height={15} />
              ) : timedOut ? (
                'Try Again!'
              ) : (
                `Confirm & Pay ${' '}(${count})`
              )}
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
            className={btn.button}
          >
            <button
              className={btn.buttonTransact}
              onClick={() => history.goBack()}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ClientScreen>
      <Footer />
    </>
  );
}
