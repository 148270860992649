import React, { useEffect } from 'react';
import { ROUTES } from 'config';
import classes from 'styles/KycDetails.module.scss';
import { useSearch } from 'context/search';
import useFilters from 'hooks/useFilters';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { DATE_CONV } from 'utils/DateConv';
import { copyText } from 'utils/CopyContent';

interface Props {
  user: any;
}

export default function LKycDetails({ user }: Props) {
  const { filters } = useFilters();
  const { search } = useSearch();
  const [kycDetail] = useAdminPaginate<any>(
    {
      route: ROUTES.L_FETCH_ALL_KYC_APPLICATIONS,
      params: {
        filters: { 'user.email': { $regex: search }, ...filters },
      },
    },
    [search, filters],
  );
  const init = () => {
    console.log({
      user,
    });

    let i = 0,
      temp = [];
    while (i < kycDetail.length) {
      temp.push(false);
      i++;
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  // ? Method to render the Details of the user
  const renderUserDetails = () => {
    return (
      <>
        <div>
          <h3 className={classes.Mainhead}>User Details</h3>
        </div>
        <div className={classes.userDetailsContainer}>
          <div className={classes.detailsSection}>
            <div className={classes.detailContainer} key={user.name}>
              <h6>Name of the Applicant</h6>
              <p onDoubleClick={() => copyText(user?.name)}>{user?.name}</p>
            </div>

            <div className={classes.detailContainer}>
              <h6>Email</h6>
              <p onDoubleClick={() => copyText(user?.email)}>{user?.email}</p>
            </div>

            <div className={classes.detailContainer}>
              <h6>Phone Number</h6>
              <p onDoubleClick={() => copyText(user?.phone_no)}>
                {user?.phone_no}
              </p>
            </div>

            <div className={classes.detailContainer}>
              <h6>KYC Status</h6>
              {user?.kycStatus ? (
                <p
                  style={{ color: '#1cb46b' }}
                  onDoubleClick={() => copyText('COMPLETE')}
                >
                  COMPLETE
                </p>
              ) : (
                <p
                  style={{ color: '#d68b00' }}
                  onDoubleClick={() => copyText('INCOMPLETE')}
                >
                  INCOMPLETE
                </p>
              )}
            </div>

            <div className={classes.detailContainer}>
              <h6>Phone Verified</h6>
              {user?.isPhoneVerified ? (
                <p
                  style={{ color: '#1cb46b' }}
                  onDoubleClick={() => copyText('VERIFIED')}
                >
                  VERIFIED
                </p>
              ) : (
                <p
                  style={{ color: '#d68b00' }}
                  onDoubleClick={() => copyText('NOT VERIFIED')}
                >
                  NOT VERIFIED
                </p>
              )}
            </div>

            <div className={classes.detailContainer}>
              <h6>Email Verified</h6>
              {user?.isEmailVerified ? (
                <p
                  style={{ color: '#1cb46b' }}
                  onDoubleClick={() => copyText('VERIFIED')}
                >
                  VERIFIED
                </p>
              ) : (
                <p
                  style={{ color: '#d68b00' }}
                  onDoubleClick={() => copyText('NOT VERIFIED')}
                >
                  NOT VERIFIED
                </p>
              )}
            </div>

            <div className={classes.detailContainer}>
              <h6>Active Status</h6>
              {user?.isActive ? (
                <p
                  style={{ color: '#1cb46b' }}
                  onDoubleClick={() => copyText('ACTIVE')}
                >
                  ACTIVE
                </p>
              ) : (
                <p
                  style={{ color: '#d68b00' }}
                  onDoubleClick={() => copyText('INACTIVE')}
                >
                  INACTIVE
                </p>
              )}
            </div>

            <div className={classes.detailContainer}>
              <h6>3FA Status</h6>
              {user?.enabled_3fa ? (
                <p
                  style={{ color: '#1cb46b' }}
                  onDoubleClick={() => copyText('ENABLED')}
                >
                  ENABLED
                </p>
              ) : (
                <p
                  style={{ color: '#d68b00' }}
                  onDoubleClick={() => copyText('NOT ENABLED')}
                >
                  NOT ENABLED
                </p>
              )}
            </div>

            <div className={classes.detailContainer}>
              <h6>Transaction Fee</h6>
              <p onDoubleClick={() => copyText(`${user?.transc_fee}%`)}>
                {user?.transc_fee}%
              </p>
            </div>
            <div className={classes.detailContainer}>
              <h6>Registered on</h6>
              <p>{user?.createdAt.substr(0, 10)}</p>
            </div>
          </div>
        </div>
      </>
    );
  };
  // * End of renderUserDetails();

  // ? Method to render the KYC details of the user
  const renderKycDetails = () => {
    if (
      (user?.sumsub_kycver_resp_logs && user?.sumsub_webhook_verf_logs) ||
      user?.sumsub_webhook_verf_logs
    ) {
      const {
        correlationId,
        inspectionId,
        levelName,
        reviewStatus,
        type,
        createdAt,
      } = user?.sumsub_webhook_verf_logs;

      return (
        <>
          <div>
            <h3 className={classes.Mainhead}>
              KYC Details <p>These are webhook logs</p>
            </h3>
          </div>
          <div className={classes.userDetailsContainer}>
            <div className={classes.detailsSection}>
              <div className={classes.detailContainer} key={user.name}>
                <h6>Correlation ID</h6>
                <p onDoubleClick={() => copyText(correlationId)}>
                  {correlationId}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Inspection ID</h6>
                <p onDoubleClick={() => copyText(inspectionId)}>
                  {inspectionId}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Type</h6>
                <p onDoubleClick={() => copyText(type)}>{type}</p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Sumsub ID</h6>
                <p onDoubleClick={() => copyText(user.external_sumsub_ID)}>
                  {user.external_sumsub_ID}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Level</h6>
                <p onDoubleClick={() => copyText(levelName)}>{levelName}</p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Applied Date</h6>
                <p
                  onDoubleClick={() =>
                    copyText(DATE_CONV.dateConvert(createdAt))
                  }
                >
                  {DATE_CONV.dateConvert(createdAt)}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Review Status</h6>
                <p onDoubleClick={() => copyText(reviewStatus.toUpperCase())}>
                  {reviewStatus.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (user?.sumsub_kycver_resp_logs) {
      const {
        reviewId,
        attemptId,
        attemptCnt,
        levelName,
        createDate,
        reviewDate,
        reviewStatus,
        external_sumsub_ID,
      } = user?.sumsub_kycver_resp_logs;

      return (
        <>
          <div>
            <h3 className={classes.Mainhead}>
              KYC Details <p>These are SDK logs</p>
            </h3>
          </div>
          <div className={classes.userDetailsContainer}>
            <div className={classes.detailsSection}>
              <div className={classes.detailContainer} key={user.name}>
                <h6>Review ID</h6>
                <p onDoubleClick={() => copyText(reviewId)}>{reviewId}</p>
              </div>

              <div className={classes.detailContainer} key={user.name}>
                <h6>Sumsub ID</h6>
                <p onDoubleClick={() => copyText(external_sumsub_ID)}>
                  {external_sumsub_ID}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Attempt ID</h6>
                <p onDoubleClick={() => copyText(attemptId)}>{attemptId}</p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Attempt Count</h6>
                <p onDoubleClick={() => copyText(attemptCnt)}>{attemptCnt}</p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Level</h6>
                <p onDoubleClick={() => copyText(levelName)}>{levelName}</p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Applied Date</h6>
                <p
                  onDoubleClick={() =>
                    copyText(DATE_CONV.dateConvert(createDate))
                  }
                >
                  {DATE_CONV.dateConvert(createDate)}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Review Date</h6>
                <p
                  onDoubleClick={() =>
                    copyText(DATE_CONV.dateConvert(reviewDate))
                  }
                >
                  {DATE_CONV.dateConvert(reviewDate)}
                </p>
              </div>

              <div className={classes.detailContainer}>
                <h6>Review Status</h6>
                <p onDoubleClick={() => copyText(reviewStatus.toUpperCase())}>
                  {reviewStatus.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <h3 className={classes.Mainhead}>KYC Details</h3>
          </div>
          <div className={classes.userDetailsContainer}>
            <div className={classes.detailsSection}>
              <div className={classes.detailContainer} key={user.name}>
                <h6>NOT LOGS</h6>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  // * End of renderKycDetails();
  // * End of renderKycDetails();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.kycContainer}>
          {kycDetail.length > 0 ? <h3>KYC Details</h3> : ''}

          {kycDetail.map(
            ({
              createdAt,
              status,
              _id,
              user: [{ email, name, phone_no, transc_fee }],
              userKycDocuments,
            }) => (
              <>
                <div className={classes.section} key={name}>
                  <h6>Name of the Applicant</h6>
                  <p>{name}</p>
                </div>
                <div className={classes.section}>
                  <h6>Applied On</h6>
                  <p>{new Date(createdAt).toLocaleDateString()}</p>
                </div>
                <div className={classes.section}>
                  <h6>Application ID</h6>
                  <p>{_id}</p>
                </div>
                <div className={classes.section}>
                  <h6>Email ID</h6>
                  <p>{email}</p>
                </div>
                <div className={classes.section}>
                  <h6>Contact Number</h6>
                  <p>{phone_no}</p>
                </div>
                <div className={classes.section}>
                  <h6>Transaction Fee</h6>
                  <p>{transc_fee}</p>
                </div>
                <div className={classes.section}>
                  <h6>Status</h6>
                  <p className={[classes[status], classes.status].join(' ')}>
                    {status === 'approved' ? 'Approved' : 'Rejected'}
                  </p>
                </div>
                <div className={classes.section}>
                  <h6>Uploaded Documnets</h6>
                  <p>
                    {userKycDocuments?.length > 0 ? (
                      <ol>
                        {userKycDocuments.map((doc: any, i: number) => (
                          <li key={i}>{doc}</li>
                        ))}
                      </ol>
                    ) : (
                      'Documents Not Uploaded'
                    )}
                  </p>
                </div>
              </>
            ),
          )}
        </div>

        {renderUserDetails()}

        {renderKycDetails()}
      </div>
    </>
  );
}
