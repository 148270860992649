import React from 'react';
import classes from '../styles/Warning.module.scss';
import { THEMES } from '../interfaces';
import Emaillogo from 'assets/Emailverify.svg';
import Phonelogo from 'assets/phoneverify.svg';
import Button from './Button';

interface Props {
  title?: string;
  description?: any;
  note?: any;
  conflicting_keys?: any[];
  action?: string;
  onAction?: () => any;
  updateAction?: string;
  onUpdateAction?: () => any;
  emailSent?: boolean;
  loading?: boolean;
  theme?: THEMES;
  imgtype?: string;
  counter?: number;
}

export default function Warning({
  note,
  conflicting_keys,
  description,
  title,
  action,
  onAction,
  updateAction,
  onUpdateAction,
  emailSent,
  loading,
  theme = THEMES.PRIMARY,
  imgtype,
  counter = 0,
}: Props) {
  return (
    <div
      style={{ alignItems: description ? undefined : 'center' }}
      className={[classes.container, classes[theme.toLowerCase()]].join(' ')}
    >
      {theme === THEMES.SUCCESS && <p className={classes.icon}>✔️</p>}
      <div className={classes.text}>
        <div className={classes.imgsection}>
          {imgtype === 'email' ? (
            <img src={Emaillogo} alt="email logo" className={classes.logos} />
          ) : (
            <img src={Phonelogo} alt="phone icon" className={classes.logos} />
          )}
        </div>
        <div className={classes.writtendiv}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>

        {note && <p>{note}</p>}
        {conflicting_keys && (
          <>
            <p>The following points are incorrect or already persist</p>
            <ul>
              {conflicting_keys.map((key: any, i) => (
                <li key={i.toString()}>{key}</li>
              ))}
            </ul>
          </>
        )}
      </div>
      {action && (
        <Button onClick={onAction} className={classes.action} loading={loading}>
          {!emailSent ? action : counter > -1 ? `Resend in ${counter}` : ''}
        </Button>
      )}
      {updateAction && (
        <Button onClick={onUpdateAction} className={classes.action}>
          {updateAction}
        </Button>
      )}
    </div>
  );
}
