import classes from '../styles/ViewResponse.module.scss';

interface Props {
  wallet_reports: Object;
}

const ViewResponse = ({ wallet_reports }: Props) => {
  return (
    <>
      <div className={classes.container}>
        <h1>Raw JSON Response</h1>
        <div className={classes.response}>
          <pre>{JSON.stringify(wallet_reports, null, 2)}</pre>
        </div>
      </div>
    </>
  );
};

export default ViewResponse;
