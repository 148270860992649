import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router';
import { ROUTES } from '../config';
import { useModal } from '../context/modal';
import Button from './Button';
import classes from '../styles/AdminWallets.module.scss';
import { toast } from 'react-toastify';
import ViewResponse from '../modals/ViewResponse';
import { useAdmin } from 'context/admin';
import useAdminPaginate from 'hooks/useAdminPaginate';
import { DATE_CONV } from 'utils/DateConv';
import { copyText } from 'utils/CopyContent';

interface Props {
  wallet_reports?: Object;
  riskScoreLevelLabel?: string;
}

export default function AdminWallets({ wallet_reports }: Props) {
  const [disable] = useState(true);
  const { openModal } = useModal();
  const { id } = useParams() as { id: string };
  const { headers } = useAdmin();
  const [wallets, { reload }] = useAdminPaginate<any>({
    route: ROUTES.FETCH_USER_WALLETS_ADMIN(id),
  });

  console.log({
    wallets,
  });

  const changeStatus = async (walletId: string, status: boolean) => {
    const { data } = await axios.patch(
      ROUTES.UPDATE_WALLET_STATUS(walletId),
      {
        updateWS: status,
      },
      { headers },
    );
    console.log({ data });
    toast.success(data.message);
    await reload();
  };

  return (
    <>
      <div className={classes.container}>
        {wallets?.length > 0 ? (
          wallets.map(
            ({
              walletAddress,
              createdAt,
              _id,
              isActive,
              wallet_reports,
              trm_flagged,
              associated_network,
              coin_type,
            }) => (
              <>
                <div className={classes.section} key={walletAddress}>
                  <h6>Wallet Address</h6>
                  <p onDoubleClick={() => copyText(walletAddress)}>
                    {walletAddress}
                  </p>
                </div>
                <div className={classes.section}>
                  <h6>Date</h6>
                  <p
                    onDoubleClick={() =>
                      copyText(DATE_CONV.dateTimeConvert(createdAt))
                    }
                  >
                    {DATE_CONV.dateTimeConvert(createdAt)}
                  </p>
                </div>
                <div className={classes.section}>
                  <h6>Status</h6>
                  <p
                    className={`${classes.status} ${classes[isActive]}`}
                    onDoubleClick={() =>
                      copyText(isActive ? 'Active' : 'Inactive')
                    }
                  >
                    {isActive ? 'Active' : 'Inactive'}
                  </p>
                </div>
                <div className={classes.section}>
                  <h6> Action</h6>
                  <Button
                    className={classes.button}
                    invert
                    onClick={() => changeStatus(_id, !isActive)}
                    size={'s'}
                  >
                    {isActive ? 'Disable' : 'Enable'}
                  </Button>
                </div>
                <div className={classes.section}>
                  <h6>Currency</h6>
                  <p>{coin_type}</p>
                </div>

                <div className={classes.section}>
                  <h6>Risk Category</h6>
                  {wallet_reports?.entites?.length > 0
                    ? wallet_reports?.entities.map((entity: any) => (
                        <p
                          style={{
                            background: '#FD5800',
                            borderRadius: '30px',
                            color: '#fff',
                            fontSize: '14px',
                            padding: '5px 20px',
                          }}
                          onDoubleClick={() => copyText(entity?.category)}
                        >
                          {entity?.category}
                        </p>
                      ))
                    : 'None'}
                </div>

                <div className={classes.section}>
                  <h6>Associated Network</h6>
                  <p
                    onDoubleClick={() =>
                      copyText(
                        associated_network === 'bitcoin'
                          ? 'Bitcoin'
                          : associated_network,
                      )
                    }
                  >
                    {associated_network === 'bitcoin'
                      ? 'Bitcoin'
                      : associated_network}
                  </p>
                </div>

                <div className={classes.section}>
                  <h6>Risk Label</h6>
                  {trm_flagged ? (
                    <p
                      style={{
                        background: '#da1919',
                        borderRadius: '30px',
                        color: '#fff',
                        fontSize: '14px',
                        padding: '5px 20px',
                      }}
                      onDoubleClick={() =>
                        copyText(
                          wallet_reports?.entities[0]?.riskScoreLevelLabel,
                        )
                      }
                    >
                      {wallet_reports?.entities[0]?.riskScoreLevelLabel}
                    </p>
                  ) : (
                    <p onDoubleClick={() => copyText('None')}>None</p>
                  )}
                </div>

                <div className={classes.section}>
                  <h6>Show All</h6>
                  <Button
                    className={classes.button}
                    invert
                    disabled={!trm_flagged && disable}
                    onClick={() =>
                      openModal(() => (
                        <ViewResponse wallet_reports={wallet_reports} />
                      ))
                    }
                    size={'s'}
                  >
                    View
                  </Button>
                </div>
                <div className={classes.dividerSection} />
                <div className={classes.dividerSection} />
                <div className={classes.dividerSection} />
                <div className={classes.dividerSection} />
              </>
            ),
          )
        ) : (
          <>
            <div className={classes.section}>
              <h6>Wallet Address</h6>
              <p>-</p>
            </div>
            <div className={classes.section}>
              <h6>Date</h6>
              <p>-</p>
            </div>
            <div className={classes.section}>
              <h6>Status</h6>
              <p className={`${classes.status} `}>-</p>
            </div>
            <div className={classes.section}>
              <h6> Action</h6>-
            </div>

            <div className={classes.section}>
              <h6>Category</h6>-
            </div>

            <div className={classes.section}>
              <h6>Currency</h6>
              <p>-</p>
            </div>

            <div className={classes.section}>
              <h6>Risk Label</h6>-
            </div>

            <div className={classes.section}>
              <h6>Show All</h6>-
            </div>
          </>
        )}
      </div>
    </>
  );
}
