import { createContext, FC, useContext, useState } from 'react';

interface Props {
  search: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchContext = createContext<Props>({
  search: '',
  onChange: (e) => undefined,
});

export const SearchProvider: FC = ({ children }) => {
  const [text, setText] = useState('');

  const inputProps = {
    search: text,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setText(e.target.value),
  };
  return (
    <SearchContext.Provider value={inputProps}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
