import React from 'react';
import AdminScreen from '../../components/AdminScreen';
import classes from '../../styles/AdminSettings.module.scss';
import CurrencySetting from '../../components/CurrencySetting';
import useAdminexchange from 'hooks/useAdminexchange';
import { useModal } from 'context/modal';
import WhitelistSelection from 'modals/WhitelistSelection';
import ChangeAdminPassword from 'modals/ChangeAdminPassword';

export default function AdminSettings() {
  const {
    coins,
    coinOptions: { reload },
  } = useAdminexchange();

  const { openModal } = useModal();

  console.log(coins);

  return (
    <AdminScreen className={classes.container}>
      <div className={classes.header_section}>
        <div>
          <h1>Settings</h1>
          <h6>My Account / Settings</h6>
        </div>
        <div>
          {/* <button onClick={() => openModal(TrezorWallet)}>
            Set Trezor Wallet
          </button> */}
          <button onClick={() => openModal(WhitelistSelection)}>
            Whitelist Wallets
          </button>
          <button onClick={() => openModal(ChangeAdminPassword)}>
            Change Password
          </button>
        </div>
      </div>
      <div className={classes.grid}>
        {coins.map((coin) => (
          <CurrencySetting key={coin.name} {...coin} reload={reload} />
        ))}
      </div>
    </AdminScreen>
  );
}
