
import React from 'react';

function Arrow() {
    return <div>
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.125 8.75L14.875 21L27.125 33.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>;
}

export default Arrow;
