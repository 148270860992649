import { useState, useEffect } from 'react';
import moment from 'moment';
import classes from '../styles/LoginHistory.module.scss';

interface Props {
  user: any;
}

export default function LoginHistory({ user }: Props) {
  const [ipDetails, setIpDetails] = useState<any>();
  useEffect(() => {
    if (user.ipLogs.length > 0) {
      let length = user.ipLogs.length;
      setIpDetails(user.ipLogs[length - 1]);
      console.log(user.ipLogs[length - 1]);
    }
    // eslint-disable-next-line
  }, [user.ipLogs]);

  return (
    <>
      {ipDetails ? (
        <div className={classes.userDetailsContainer}>
          <div className={classes.detailsSection}>
            <div className={classes.detailContainer}>
              <h6>IP Address</h6>
              <p>{ipDetails.ipAddress}</p>
            </div>
            <div className={classes.detailContainer}>
              <h6>VPN Detected</h6>
              <p>{ipDetails.vpnDetected === true ? 'True' : 'Not Available'}</p>
            </div>
            <div className={classes.detailContainer}>
              <h6>Country</h6>
              <p>
                {ipDetails.country
                  ? ipDetails.country
                  : 'Not Available for now'}
              </p>
            </div>
            <div className={classes.detailContainer}>
              <h6>Last Loggedin Time</h6>
              <p>{moment(ipDetails.time).format('DD-MM-YYYY HH:mm:ss A')}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.noLogsContainer}>No logs exists!</div>
      )}
    </>
  );
}
