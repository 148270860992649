import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from '../styles/TermsCondition.module.scss';

function TermsCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <section className={classes.HeroSection}>
        <div className={classes.textContainer}>
          {/* <h4>Legal</h4> */}
          <h1>
            <span>Terms & Conditions </span>for
            <br />
            Digital Asset Trading Service
          </h1>
        </div>
      </section>
      <section className={classes.SpecialDiv}>
        <div>
          <p>
            This agreement is made by you (hereinafter <b>“You”</b>,
            <b> “Your”</b>, or <b>“Offeror”</b>) and Encryptus (hereinafter “the
            Company”, “ourselves”, “we”, “us” or “Encryptus ”).
          </p>
          <br />
          <p>
            Encryptus  refers to both (a) Encryptus DMCC, a company incorporated
            in the UAE; and (b) UAB Encryptus Europe, a company incorporated in
            Lithuania.  Encryptus DMCC and UAB Encryptus Europe together provide
            services to middle east regions, Europe, as well as other countries
            around the world.
          </p>
          <br />
          <p>
            The Offeror and Encryptus (individually as a “Party” and
            collectively as “Parties”) agree that the terms and conditions set
            forth in this Agreement, apply to all offers make by the you to
            purchase or to sale any digital assets in a specific quantity to
            Encryptus.
          </p>
          <br />
          <p>
            The Parties may therefore, from time to time, enter into separate
            transactions for the sale or purchase of digital asset by agreeing
            an Accepted Sales Order that incorporates by reference the this
            Agreement as the general terms and conditions.{' '}
          </p>
          <br />
          <p>
            PLEASE READ THE AGREEMENT CAREFULLY BEFORE USING OUR TRADING SERVICE
            AND REVIEW THEM PERIODICALLY AND REGULARLY FOR CHANGES. BY USING OUR
            TRADING SERVICE, INCLUDING MAKING AND OFFER OF SALE ORDER AND
            REGARDLESS WHETHER SUCH OFFER IS ACCEPTED BY ENCRYPTUS, YOU AGREE TO
            BE BOUND BY THIS AGREEMENT AND AGREE THAT SUCH AGREEMENT CONSTITUTES
            A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US. IF YOU DO NOT AGREE
            TO BE FULLY BOUND BY ALL OR ANY OF THE AGREEMENT, YOU SHOULD NOT USE
            OUR TRADING SERVICE
          </p>
        </div>
      </section>
      <div className={classes.Container}>
        <div className={classes.textDiv}>
          <div className={classes.InnerDiv}>
            <h5>The Parties hereby agree as the following:</h5>
            <h4>1. Definitions and Interpretation</h4>{' '}
            <p>
              {' '}
              1.1 In this Agreement and in the Schedule, unless the context
              requires otherwise:
            </p>
            <p>
              <b>Accepted Sales Order</b> means a Sales Order that has been
              validly accepted by Encryptus.
            </p>
            <p>
              <b>Applicable Laws </b>means, with respect to any person, any and
              all applicable treaties, statutes, legislation, laws, regulations,
              ordinances, codes, rules, rulings, judgments, orders, awards, or
              any form of decisions, determinations or requirements of or made
              or issued by, any governmental, statutory, regulatory or
              supervisory bodies (including without limitation, any relevant
              stock exchange or securities council) or any court or tribunal
              with competent jurisdiction, whether in the United of Arab
              Emirates,the UK or elsewhere, as amended, modified or replaced
              from time to time, and to which such person is subject.
            </p>
            {/* <p>
              <b>Business Day</b> means a day (other than a Saturday, Sunday or
              public holiday) on which commercial banks are open for business in
              the United of Arab Emirates.
            </p> */}
            <p>
              <b>Your Encryptus Account</b> means your account on Encryptus
              Platform which has successfully passed all KYC procedures imposed
              by Encryptus and be verified.{' '}
            </p>
            <p>
              <b>Your Bank Account</b> means the bank account is held by your
              name and controlled by you, and successfully registered with
              Encryptus
            </p>
            <p>
              <b>Your Wallet Address</b> means the applicable digital wallet
              address hold by and control by you, and successfully registered
              with Encryptus.
            </p>
            <p>
              <b>Confidential Information </b> means any information which is
              proprietary and confidential to Encryptus including but not
              limited to the terms and conditions of this Agreement, each Sales
              Order, each Accepted Sales Order, information concerning or
              relating in any way whatsoever to the Encryptus’ investments or
              other arrangements, principals, any of the trade secrets or
              confidential operations, processes or inventions carried on or
              used by Encryptus, any information concerning the organisation,
              business, finances, transactions, investments or affairs of
              Encryptus, our’s dealings, secret or confidential information
              which relates to its business or any of its investee companies’
              transactions or affairs, financial statements or information, any
              information therein in respect of trade secrets and information
              and material which is either marked confidential or is by its
              nature intended to be exclusively for the knowledge of the
              recipient alone.
            </p>
            <p>
              <b>Digital Asset</b> means any digital assets, cryptocurrency,
              digital tokens or units of other blockchain-based assets which is
              available for purchase or sale on Encryptus’ Platform.
            </p>
            <p>
              <b>Encumbrance</b> means any mortgage, assignment of receivables,
              debenture, lien, hypothecation, charge, pledge, title retention,
              right to acquire, security interest, option, pre-emptive or other
              similar right, right of first refusal, restriction, third-party
              right or interest, any other encumbrance, condition or security
              interest whatsoever or any other type of preferential arrangement
              (including without limitation, a title transfer or retention
              arrangement) having similar effect.
            </p>
            <p>
              <b>Encryptus’ Platform</b> means the site{' '}
              {/* <span>www.encryptus.io</span> */}
            </p>
            <p>
              <b>Representatives</b> means, in relation to a Party, its
              advisers, agents, employees, officers or other representatives.
            </p>
            <p>
              <b>Settlement Date</b> refers to the date Encryptus transfer any
              Digital Asset or fiat currency to you for the purpose of
              fulfilling the Accepted Sale Order.{' '}
            </p>
            <p>
              1.2 This Agreement includes all amendments, additions, and
              variations thereto agreed between the Parties and "clauses" are to
              the clauses of, and the schedules to, this Agreement (unless the
              context otherwise requires);
              <br /> "person" shall include an individual, corporation, company,
              partnership, firm, trustee, trust, executor, administrator or
              other legal personal representative, unincorporated association,
              joint venture, syndicate or other business enterprise, any
              governmental, administrative or regulatory authority or agency
              (notwithstanding that "person" may be sometimes used herein in
              conjunction with some of such words), and their respective
              successors, legal personal representatives and assigns, as the
              case may be, and pronouns shall have a similarly extended meaning;
              <br /> and "written" and "in writing" include any means of visible
              reproduction (including, for the avoidance of doubt, by way of
              electronic mail.
            </p>
            <p>
              1.3 Unless the context otherwise requires, words importing the
              singular shall include the plural and vice versa and words
              importing a specific gender shall include the other genders (male,
              female or neuter). The Schedules form part of this Agreement and
              have the same force and effect as if expressly set out in the body
              of this Agreement.
            </p>
            <p>
              1.4 All transactions for the sale and purchase of Digital Assets
              are entered into by the Parties in reliance on the fact that the
              Accepted Sales Order and this Agreement together form part of a
              single agreement between the Parties.
            </p>
          </div>
        </div>
        <div className={classes.SecondContainer}>
          {/* 2 nd para ++++++++++++++++++++++++++++++ */}
          <h4>2. Scope of this Agreement</h4>
          <p>
            2.1 By using Encryptus’ Trading service, you are deemed to have read
            this Agreement and accept the terms of this Agreement.
          </p>
          <p>
            2.2 The terms under this Agreement shall apply exclusively.
            Encryptus do not accept any conflicting or deviating terms of this
            Agreement.
          </p>
          <p>
            2.3 This Agreement contains the terms and conditions applicable to
            all sale and purchase of Digital Assets on Encryptus’ Platform but
            does not specify the quantities of the Digital Assets to be
            delivered and purchased pursuant to such sale and purchase. There
            shall be no binding commitment to sell and purchase Digital Assets
            on the part of Encryptus unless a Sale Order has been accepted by
            Encryptus.
          </p>
          <p>
            2.4 Upon acceptance of Sale Order, all the terms and conditions in
            this Agreement shall be incorporated and made a part of the Accepted
            Sales Order.
          </p>
          <p>
            2.5 In the event of any inconsistency between the provision of this
            Agreement and the terms contained in an Accepted Sales Order, the
            Accepted Sales Order shall prevail to the extent of such
            inconsistency.
          </p>
          {/* 3rd para +++++++++++++++++++++++++ */}
          <h4>3. Purchase or Sales Order </h4>
          <p>
            3.1 The prices of Digital Assets shown on Encryptus’ Platform are
            the reference prices of Digital Assets which may change from time to
            time. The price is for your reference only and does not represent
            any offer or any invitation to offer from Encryptus.{' '}
          </p>
          <p>
            3.2 By clicking the “Agree” to purchase or to sale any Digital
            Assets on the Platform, you are sending a binding offer of a Sale
            Order in a particular amount of Digital Assets with the relevant
            price.
          </p>
          <p>
            3.3 The Sale Order shall particularize and stating:
            <br />
            (a) The type of Digital Asset (“Relevant Digital Assets”);
            <br />
            {/* <br /> */}
            (b) Whether the Relevant Digital Assets is to be purchased or sold
            by the Offeror;
            <br />
            {/* <br /> */}
            (c) The total amount (i.e. number of units) of the Relevant Digital
            Assets;
            <br />
            {/* <br /> */}
            (d) The price per unit of the Relevant Digital Assets (“Relevant
            Price”);
            <br />
            {/* <br /> */}
            (e) The total price in fiat currency or in digital asset to be paid
            in case of purchase of Relevant Digital Asset(“Total Price”) or the
            total price to be received in fiat currency or in digital asset in
            case of sale of Relevant Digital Asset (“Total Sale Price”).
            <br />
            <br />
            For the avoidance of doubt, the Relevant Digital Assets refers to
            the digital assets to be purchased by an Offeror in case of purchase
            order, and refers to the digital assets to be sold by an Offeror in
            case of sale order.
          </p>
          <p>
            3.4 The Sale Order may be rejected for any reason by Encryptus
            without reason.
          </p>
          <p>
            3.5 The Sale Order shall only be accepted by Encryptus by notifying
            you the acceptance of Sales Order, i.e. Accepted Sales Order, by
            sending a confirmation email. For the avoidance of doubt, the
            Relevant Price indicated in the Accepted Sale Order is the final
            price agreed by the Parties, any further price movement shall not
            affect the Relevant Price.{' '}
          </p>
          <p>
            3.6 In the event of the purchase of Relevant Digital Asset,
            {/* <br /> */}
            (a). you must have sufficient fiat currency or other digital asset
            on your Encryptus’ Account to settle the Total Price prior to any
            Sale Order;
            <br />
            {/* <br /> */}
            (b). the total Relevant Digital Assets will be transferred to your
            Wallet Address on the Settlement Date;
            <br />
            {/* <br /> */}
            (c). upon acceptance of the Sales Order, Encryptus is authorised and
            entitled to set off or net against without further notice or consent
            the full extent of the Total Price against any amounts (whether in
            fiat currency or digital asset (as the case may be) held in your
            Encryptus Account, whereupon Encryptus shall have full title and
            ownership to the Total Price (whether in fiat currency or digital
            asset (as the case may be)).
          </p>
          <p>
            3.7. In the event of the sale of Digital Assets,
            <br />
            (a). you must have sufficient Relevant Digital Asset on your
            Encryptus’ Account for sale prior to placing any Sale Order;
            <br />
            (b). the Total Sale Price will be transferred to your Bank Account
            by electronic transfer or your Wallet Address on the Settlement
            Date;
            <br />
            (c). upon acceptance of the Sales Order by Encryptus, Encryptus is
            authorised and entitled to transfer to itself without further notice
            or consent the full extent of the amount of Relevant Digital Asset
            held in your Encryptus Account, whereupon Encryptus shall have full
            title and ownership to the amount of Relevant Digital Asset.{' '}
          </p>
          <p>
            3.8. Notwithstanding of the above, Encryptus may in its sole and
            absolute discretion:
            <br />
            <br />
            <ol>
              <li>
                * Set the Settlement Date on the notification of Accepted Sale
                Order
              </li>
              {/* <br /> */}
              {/* <br /> */}
              <li>
                * Defer the Settlement Date by a written notification to the
                Offeror{' '}
              </li>
              {/* <br /> */}
              {/* <br /> */}
              <li>
                * Impose any restrictions and/or conditions on any Accepted Sale
                Order and not to effect the completion of any Accepted Sale
                until such restrictions are released or conditions are
                fulfilled;
              </li>
              {/* <br /> */}
              {/* <br /> */}
              <li>
                * Terminate the Accepted Order and without prejudice to its
                rights hereunder.{' '}
              </li>
            </ol>
          </p>
          <p>
            3.9 Save as otherwise set out herein or otherwise expressly agreed,
            payments or transfers to be made by the Offeror to Encryptus under
            an Accepted Sales Order are not be subject to any set off or netting
            against any other amounts due from Encryptus to the Offeror.
          </p>
          <p>
            3.10 Each payment of any amount owing to Encryptus hereunder shall
            be for the full amount due, without reduction, withholding or offset
            for any reason (including, without limitation, any exchange charges,
            bank transfer charges, any other fees, other than pursuant to any
            applicable withholding tax obligations).{' '}
          </p>
          <h4>4. Representation and Warranties</h4>
          <p>
            <h5>
              4.1 The Offeror hereby represents and warrants to Encryptus, as of
              the date hereof and on each date of Sale Order:
            </h5>
            <br />
            All statements and information provided by the Offeror are true,
            complete and correct and that no material fact has been willfully
            withheld;
            <br />
            In case of the Offeror is an entity, it is duly organized and
            validly existing and in good standing under the laws of its place of
            incorporation, and no proceedings have been commenced or are pending
            for the bankruptcy, winding up, liquidation or reorganisation of the
            Offeror and the Offeror is not insolvent ;
            <br />
            In case of the Offeror is an individual, he/she has reached the age
            of 18 and not under any undischarged bankruptcy order in any
            jurisdiction;
            <br />
            It has full power and authority to execute and deliver and perform
            all of its obligations under this Agreement and any other agreements
            to be executed by it hereunder and to consummate the transactions
            contemplated hereby and thereby;
            <br />
            All actions, conditions and things required to be taken, fulfilled
            and done (including the obtaining of any necessary consents) in
            order (i) to enable the Offeror lawfully to enter into, exercise its
            rights and perform or comply with its obligations under, this
            Agreement and any other agreements to be executed by it hereunder
            and to consummate the transactions contemplated hereby and thereby;
            <br /> and (ii) to ensure that those obligations are legally binding
            and enforceable have been taken, fulfilled and done;
            <br />
            This Agreement, the Accepted Sales Orders and all other agreements
            and instruments of the Offeror hereby shall be legal, valid and
            binding agreement of the Offeror, enforceable against the Offeror in
            accordance with their terms;
            <br />
            It has fully complied with all applicable laws or regulations of all
            relevant jurisdictions in connection with the source of funds or
            digital asset used or to be used for the settlement of any Accepted
            Sales Order, and the Offeror will not, by reason of acceptance and
            receipt of such proceeds, be in breach of any applicable law or
            regulation of any relevant jurisdiction, including but not limited
            to anti-money laundering laws;
            <br />
            Neither the execution and delivery of this Agreement, nor the
            consummation of the transactions contemplated hereby, does or will
            violate any statute, regulation, rule, judgment, order, decree,
            ruling, charge or other restriction of any government, governmental
            agency, or court to which the Offeror is subject or conflict with,
            violate or constitute a default under any agreement, debt or other
            instrument to which the Offeror is a party;
            <br />
            All transactions contemplated under Accepted Sales Orders are for
            Offer’s own account as principal and not as trustee or otherwise on
            behalf of any other person. The Offeror has not been the subject of
            any civil, criminal, arbitration, administrative or other proceeding
            or government investigations involving a default on its part, and to
            the best of the Offeror’s knowledge, no fact or circumstance exists
            which might give rise to such proceedings or investigations;
            <br />
            With respect to any digital assets to be sold or transferred by the
            Offeror to EncryptusT under any Accepted Sales Order, the Offeror is
            the lawful owner of such digital asset with good and marketable
            title thereto, and the Offeror has the absolute right to sell,
            assign, convey, transfer and deliver such digital asset and such
            digital asset are free and clear of any and all Encumbrances;
            <br /> and The Offeror is the legal and beneficial owner of each of
            his/her Encryptus Accounts, and the Encryptus Accounts is owned and
            operated solely for the benefit of the Offeror, and no Person, other
            than the Offeror, has any right, title or interest in the Encryptus
            Accounts.
          </p>
          <p>
            <h5>4.2 The Offeror agrees, understands and acknowledges that:</h5>
            {/* <br /> */}
            <br />
            Encryptus reserves the right to reject or delay executing any
            Accepted Sale Order if any such sale order is, in its sole
            determination, unclear, conflicting, incorrect, incomplete, in an
            unapproved format, unauthorised, fraudulent or not otherwise
            authentic, or in breach of (or may breach) any of the security
            procedures of Encryptus and/or any applicable law or regulation;
            <br />
            Encryptus shall only transfer digital assets to the Offeror’s Wallet
            Address, and Encryptus has no responsibility to ensure that the
            Offeror’s Wallet Address is compatible with the digital assets;
            <br />
            The Offeror shall be responsible to the security of the Wallet
            Address;
            <br />
            Encryptus’ responsibility shall be deemed fulfilled when Encryptus
            notify the Offeror that the digital assets or fiat currency (as the
            case may be) has sent to the Offeror’s Wallet Address or the
            Offeror’s Bank Account in accordance with the Accpeted Sale Order;
            <br />
            Encryptus shall not be responsible if the Offeror provide any wrong
            Wallet Address or Bank Account number to Encryptus;
            <br />
            Encryptus shall not be responsible in case of any technical error on
            the blockchain network when Encryptus transfers digital asset to the
            Wallet Address;
            <br />
            Encryptus shall not be responsible in any event if the Bank Account
            refused any transfer from Encryptus or the Bank Account is frozen;
            <br />
            Encryptus shall have the absolute discretion to delay the Settlement
            Date;
            <br />
            Encryptus shall perform any KYC and KYB against the Offeror and the
            Offeror agrees to provide all documents as requested by Encryptus to
            the satisfaction of Encryptus, failing which Encryptus shall be
            entitled to terminate this Agreement;
            <br />
            The Offeror would provide Encryptus at any time with all documents
            and information as required prior to the establishment of the
            account or from time to time, as Encryptus considers appropriate;
            <br />
            The digital asset to be delivered to the Offeror’s Wallet Address is
            at the sole risks of the Offeror and no representation or warranty
            is made in respect of the digital asset network is applicable to
            such digital asset;
            <br />
            The purchase or sale of digital assets involve significant risks,
            all of which the Offeror fully and completely acknowledges and
            assumes, including, but not limited to, the risk that the sale or
            purchase of digital asset may decrease in value over time and/or
            lose all monetary value;
            <br />
            No governmental authority has passed on or made any recommendation
            or endorsement of this Agreement, Accepted Sales Orders or the
            fairness or suitability of the investment in the digital assets, nor
            has any governmental authority passed upon or endorsed the merits of
            any Accepted Sales Order;
            <br />
            The Offeror bears sole responsibility for any taxes as a result of
            the matters and transactions that are the subject of this Agreement,
            Accepted Sales Orders, and any future acquisition, ownership, use,
            sale or other disposition of any digital assets pursuant to any
            Accepted Sales Orders held by or on behalf of the Offeror. The
            Offeror agrees to indemnify, defend and hold Encryptus and any of
            its affiliates, employees, officers or agents (including developers,
            auditors, contractors or founders) harmless on an after-tax basis
            for any claim, liability, assessment or penalty with respect to any
            taxes associated with or arising from any Accepted Sale Order;
            <br />
            The Offeror authorises and grants Encryptus the irrevocable consent
            to make the necessary checks and enquiries on the Offeror, and
            obtain from and/or verify with any source and/or disclose or release
            any information and/or data on the Offeror to any party or source as
            Encryptus, may from time to time, deem fit or appropriate, without
            any liabilities or notice to the Offeror;
            <br />
            If any of the representation, warranties or covenants given by the
            Offeror ceases to be true or if Encryptus no longer reasonably
            believes that it has satisfactory evidence as to their truth,
            Encryptus make take steps to terminate this Agreement;
            <br />
            All risk of unauthorised instructions, forgery, fraud,
            misunderstandings, errors and operation failure shall lie solely
            with the Offeror;
            <br />
            Encryptus shall be indemnified from and against any and all losses
            resulting directly or indirectly from and against all claims or
            losses to the extent any claim or loss is based on the breach of any
            representation, warranty or covenant of this Agreement by the
            Offeror or caused by the Offeror fraud, wilful misconduct or gross
            negligence;
            <br />
            Encryptus shall not be held responsible for any non-performance or
            delay of any of its obligations that is caused by circumstances
            beyond its control which includes, inter alia, acts of God,
            terrorism or the threat of terrorism or cyberterrorism, sabotage,
            equipment failures, issuance or operation of any adverse
            governmental law or regulation, or any other emergency that prevents
            Encryptus from operating normally;
            <br />
            and
            <br />
            the Offeror further agrees, represents and warrants that the Offeror
            is solely responsible for any decision to enter into a transaction
            subject to this Agreement, including the evaluation of any and all
            risks related to any such transaction and in entering into any such
            Sale Order and Accepted Sale Order, the Offeror has not relied on
            any statement or other representation of Encryptus other than as
            expressly set forth herein.
            <br />
            Encryptus shall charge a handling fees in a sum determine by
            Encryptus in an event if the Offeror does not proceed with the
            transaction after sending fiat currency or digital asset to
            Encryptus. In the event if the Offeror does not proceed with the
            transaction after sending fiat currency, Encryptus shall not
            entertain any withdrawal if the deposited sum is less that US$10,000
            or its equivalent in GBP or EUR.
          </p>
          <p>
            <h5>
              4.3 Encryptus hereby represents and warrants to the Offeror:
            </h5>
            <br />
            {/* <br /> */}
            It is duly organised and validly existing and in good standing under
            the laws of its place of incorporation;
            <br />
            It has full power and authority to execute and deliver and perform
            all of its obligations under this Agreement and any other agreements
            to be executed by it hereunder and to consummate the transactions
            contemplated hereby and thereby;
            <br />
            The execution, delivery and performance of this Agreement by
            Encryptus will not conflict with its constitution or any law, order,
            judgment, decree, rule or regulation of any court, arbitral,
            tribunal or government agency, or any agreement, instrument or
            indenture to which Encryptus or any of its related corporations is a
            party or by which Encryptus is bound;
            <br />
            No proceedings have been commenced or are pending for the
            bankruptcy, winding up, liquidation or reorganisation of Encryptus
            and Encryptus is not insolvent;
            <br />
            Encryptus has not been the subject of any civil, criminal,
            arbitration, administrative or other proceeding or government
            investigations involving a default on its part, and to the best of
            Encryptus 's knowledge, no fact or circumstance exists which might
            give rise to such proceedings or investigations;
            <br />
            With respect to any digital asset to be sold or transferred by
            Encyrptus to the Offeror under any Accepted Sales Order, Encryptus
            is the lawful owner of digital asset with good and marketable title
            thereto, and Encyrptus has the absolute right to sell, assign,
            convey, transfer and deliver such digital asset and such digital
            asset are free and clear of any and all Encumbrances
          </p>
          <h4>5. Confidentiality</h4>
          {/* <br /> */}
          <p>
            5.1 Each Party undertakes that it shall (except with the prior
            written consent of the other Party) treat as strictly confidential
            and not disclose any Confidential Information of the other Party
            received or obtained as a result of entering into this Agreement (or
            any agreement entered into, pursuant to or in connection with this
            Agreement).
          </p>
          <p>
            5.2 The confidentiality obligations shall not apply to any
            information as follows:
            <br />
            which is in, or becomes available in, the public domain without
            breach of this clause;
            <br />
            which is required to be disclosed pursuant to any Applicable Laws,
            provided that the disclosing Party shall, as far as legally
            permissible and to the extent practicable, inform the other Party in
            advance of the disclosure to be made, to provide the other Party
            with the reasonable opportunity to contest such disclosure and to
            consult with the other Party as to the form, timing, content and
            manner of such disclosure, and the disclosing Party shall take into
            account the reasonable comments of the other Party when making such
            disclosure;
            <br />
            which is disclosed by a Party to its related corporations,
            shareholders, affiliates or any of its respective (including
            prospective) officers, directors, employees, bankers, financiers,
            financial advisers, consultants and legal or other advisers on a
            need-to-know basis and solely for the purpose of the subject matter
            of this Agreement, and provided that such disclosure is on the basis
            that such recipients of the information agree to comply with this
            clause.
          </p>
          <p>
            5.3 Encryptus may collect, use and/or process any personal data
            relating to th<b></b>e Offeror for the purposes of, inter alia,
            performance of its obligations under this Agreement, compliance with
            any applicable laws, regulations, codes of practice, guidelines, or
            rules or to assist in law enforcement and investigations conducted
            by any governmental and/or regulatory authority, any other purposes
            for which the information has been provided for, and any other
            incidental business purposes related to or in connection with the
            foregoing.
          </p>
          <p>
            5.4 Encryptus may disclose and/or transfer your personal data,
            including transfer to foreign jurisdictions outside of the UAE for
            the purposes of, inter alia, performance of its obligations under
            this Agreement, compliance with any applicable laws, regulations,
            codes of practice, guidelines, or rules or to assist in law
            enforcement and investigations conducted by any governmental and/or
            regulatory authority, any other purposes for which the information
            has been provided for, and any other incidental business purposes
            related to or in connection with the foregoing.
          </p>
          <p>
            5.5 Encryptus reserves the rights to update its data protection
            arrangements including, without limitation, issuing of further
            notices, guidelines or policies as may be required by it or by law
            or as may be relevant from time to time. The Offeror agrees to abide
            by such updates and, generally, where necessary, agrees to respond
            promptly to any request for further consents (whether by ad hoc
            request or by way of such updates) as to the use of such personal
            data as required by Encryptus from time to time.
          </p>
          <h4>6. Miscellaneous</h4>
          {/* <br /> */}
          <p>
            6.1 Costs:Each Party will pay their own fees and expenses (including
            legal expenses) related to or arising out of any transactions under
            this Agreement.
          </p>
          <p>
            6.2 Illegality: The illegality, invalidity or unenforceability of
            any provision of this Agreement under the law of any jurisdiction
            shall not affect its legality, validity or enforceability under the
            law of any other jurisdiction nor the legality, validity or
            enforceability of any other provision.
          </p>
          <p>
            6.3 Further Assurance: Each Party shall do and execute or procure to
            be done and executed all such further acts, deeds, things and
            documents as may be necessary to give effect to the terms of this
            Agreement.
          </p>
          <p>
            6.4 Entire Agreement: This Agreement and any Accepted Sales Order
            together shall constitute the entire agreement and understanding
            among the Parties relating to the subject matter contemplated
            herein, and supersede any previous or contemporaneous written or
            oral agreements or representations (whether written or oral)
            affecting the subject matter. Each Party acknowledges that it has
            not entered into this Agreement in reliance upon any representation,
            warranty or undertaking of any other Party which is not set out or
            referred to in this Agreement. Nothing in this Clause shall however
            operate to limit or exclude liability for fraud.
          </p>
          <p>
            6.5 Independent Advice: The Offeror confirms that it has received
            independent legal and financial advice relating to all the matters
            provided for in this Agreement, including the provisions of this
            Clause, and agrees, having considered the terms of this Clause and
            this Agreement as a whole, that the provisions of this Clause are
            fair and reasonable.
          </p>
          <p>
            6.7 Limitation of liability: Encryptus’s total aggregate liability
            in tort (including negligence or breach of statutory duty),
            misrepresentation, restitution or otherwise, arising out of or in
            connection with the performance or contemplated performance of this
            Agreement and/or Accepted Sale Order shall be limited to the Total
            Price or the Total Sale Price.
          </p>
          <p>
            {' '}
            6.8 Governing Law: This Agreement shall be governed by, and
            construed in accordance with, the laws of the United Kingdom,
            without giving effect to the principles of conflicts of law thereo
          </p>
        </div>
        <div className={classes.links}>
          <Link to="/">Back To Dashboard</Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsCondition;
