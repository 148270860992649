import axios from 'axios';
import { API_HOST } from 'config';
import { useAdmin } from 'context/admin';
import { useModal } from 'context/modal';
import React from 'react';
import { toast } from 'react-toastify';
import classes from 'styles/Ethmol.module.scss';

interface main {
  request: () => void;
  id: any;
  userDestinationWalletAddress: any;
  coinQty: any;
}

function Ethmodal(props: main) {
  const { headers } = useAdmin();
  const { closeModal } = useModal();

  const approval = async (id: string, status: boolean) => {
    try {
      const body = {
        final_approval: true,
        transferInitiated: status,
      };
      await axios.patch(
        `${API_HOST}/encryptus/sadmin/rsigs/cgTicket/finalApproval/${id}`,
        body,
        {
          headers,
        },
      );
      toast.success('Completed Successfully');
      closeModal();
      props.request();
    } catch (err: any) {
      toast.warning(err?.response?.data?.message);
      console.log(err);
    }
  };
  return (
    <div className={classes.Container}>
      <h4>
        Please confirm wheather ETH {props.coinQty} were transferred to{' '}
        {props.userDestinationWalletAddress} address
      </h4>
      <div className={classes.btnDiv}>
        <button onClick={() => approval(props.id, true)}>Yes</button>
        <button onClick={() => approval(props.id, false)}>No</button>
      </div>
    </div>
  );
}

export default Ethmodal;
