import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HTMLELEMENT, TransactionType } from '../interfaces';
import classes from '../styles/ClientScreen.module.scss';
import Card from './Card';
import LatestTransactions from './LatestTransactions';
import Backdrop from 'assets/backdrop.png';
import Arrow from 'assets/Navbar svg/Arrow';
import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import { CURRENCY_CONV } from 'utils/CurrencyConv';

interface Props extends HTMLELEMENT<HTMLDivElement> {
  children: ReactNode;
  title?: string;
  transact?: TransactionType;
  hideTransact?: boolean;
  head?: ReactNode;
}

export default function ClientScreen({
  children,
  title,
  transact,
  hideTransact,
  head,
}: Props) {
  const location = useLocation();
  // eslint-disable-next-line
  const [selected, setSelected] = useState<number>(0);
  const options: TransactionType[] = Object.values(TransactionType);
  const { headers } = useUser();
  const [balance, setBalance] = useState({
    usdt: 0.0,
    btc: 0.0,
    eth: 0.0,
    // fiat: 0.0,
  });
  const [balance2, setBalance2] = useState({
    USD: '0',
    EUR: '0',
    GBP: '0',
    // fiat: 0.0,
  });

  // effect ++++++++++++++++++++

  useEffect(() => {
    if (!transact) return;
    setSelected(options.findIndex((t) => t === transact));
  }, [transact, options]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        let cryptoBalance: any = 0.0,
          cryptoBTCBalance: any = 0.0,
          cryptoETHBalance: any = 0.0;
        const { data: fiatBalanceData } = await axios.get(ROUTES.Fiat_Balance, {
          headers,
        });
        console.log(fiatBalanceData);

        const { data: cryptoBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'USDT',
          },
          { headers },
        );

        const { data: cryptoBTCBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'BTC',
          },
          { headers },
        );

        const { data: cryptoETHBalanceData } = await axios.post(
          ROUTES.Crypto_Balance,
          {
            crypto_currency: 'ETH',
          },
          { headers },
        );

        // if (fiatBalanceData?.data?.fiat_bal == null) {
        //   fiatBalance = 0.0;
        // }
        // else {
        //   const USDBalance = (fiatBalanceData.data.fiat_bal as any[]).find(
        //     (f: any) => f.fiat_curr === 'USD',
        //   );
        //   let roundOff = USDBalance.fiat_coins;
        //   fiatBalance = CURRENCY_CONV.fiatAmtConv(roundOff || 0.0);
        // }

        if (fiatBalanceData?.data?.fiat_bal == null) {
          setBalance2({
            USD: CURRENCY_CONV.fiatAmtConv(0),
            // AED: CURRENCY_CONV.fiatAmtConv(0),
            EUR: CURRENCY_CONV.fiatAmtConv(0),
            GBP: CURRENCY_CONV.fiatAmtConv(0),
          });
        } else {
          const USDBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'USD',
          );
          // const AEDBalance = fiatBalanceData.data.fiat_bal.find(
          //   (f: any) => f.fiat_curr === 'AED',
          // );
          const GBPBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'GBP',
          );
          const EURBalance = fiatBalanceData.data.fiat_bal.find(
            (f: any) => f.fiat_curr === 'EUR',
          );
          setBalance2({
            USD: CURRENCY_CONV.fiatAmtConv(USDBalance.fiat_coins),
            // AED: CURRENCY_CONV.fiatAmtConv(AEDBalance.fiat_coins),
            GBP: CURRENCY_CONV.fiatAmtConv(GBPBalance.fiat_coins),
            EUR: CURRENCY_CONV.fiatAmtConv(EURBalance.fiat_coins),
          });
        }
        if (cryptoBalanceData?.data?.crypto_coins == null) {
          cryptoBalance = 0.0;
        } else {
          cryptoBalance = CURRENCY_CONV.cryptoAmtConv(
            cryptoBalanceData.data.crypto_coins,
          );
        }
        // BTC balance
        if (cryptoBTCBalanceData?.data?.crypto_coins == null) {
          cryptoETHBalance = 0.0;
        } else {
          cryptoBTCBalance = CURRENCY_CONV.cryptoAmtConv(
            cryptoBTCBalanceData.data.crypto_coins,
          );
        }
        // ETH Balance
        if (cryptoETHBalanceData?.data?.crypto_coins == null) {
          cryptoETHBalance = 0.0;
        } else {
          cryptoETHBalance = CURRENCY_CONV.cryptoAmtConv(
            cryptoETHBalanceData.data.crypto_coins,
          );
        }

        setBalance({
          // fiat: fiatBalance,
          usdt: cryptoBalance,
          btc: cryptoBTCBalance,
          eth: cryptoETHBalance,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchBalance();
  }, [headers]);

  return (
    <div className={classes.container}>
      <div className={classes.backdrop}>
        <img src={Backdrop} alt="backdrop"></img>
      </div>
      <section className={classes.innerContainer}>
        <div className={classes.head}>
          {head || (
            <>
              {location.search.includes('?transact=Buy') ||
              location.search.includes('?transact=Sell') ? (
                <div className={classes.headtag}>
                  <Link to="/dashboard">
                    <Arrow />
                  </Link>
                  <h1>
                    {location.search.includes('?transact=Buy') ? 'Buy' : 'Sell'}{' '}
                    Cryptocurrency
                  </h1>
                </div>
              ) : location.pathname === '/dashboard' ? (
                <div className={classes.dashboardBalance}>
                  <h1>{title}</h1>
                  <h3 className={classes.balance}>
                    <span className={classes.cryptoCoinData}>
                      <span>
                        <span className={classes.coinTitle}>USD</span>
                        <span className={classes.coinAmount}>
                          {balance2.USD}
                        </span>
                      </span>
                      <span className={classes.coinData}>
                        <span className={classes.coinTitle}>GBP</span>
                        <span className={classes.coinAmount}>
                          {balance2.GBP}
                        </span>
                      </span>
                      <span className={classes.coinData}>
                        <span className={classes.coinTitle}>EUR</span>
                        <span className={classes.coinAmount}>
                          {balance2.EUR}
                        </span>
                      </span>
                    </span>
                    <span className={classes.cryptoCoinData}>
                      <span>
                        <span className={classes.coinTitle}>BTC</span>
                        <span className={classes.coinAmount}>
                          {balance.btc}
                        </span>
                      </span>
                      <span>
                        <span className={classes.coinTitle}>ETH</span>
                        <span className={classes.coinAmount}>
                          {balance.eth}
                        </span>
                      </span>
                      <span>
                        <span className={classes.coinTitle}>USDT</span>
                        <span className={classes.coinAmount}>
                          {balance.usdt}
                        </span>
                      </span>
                    </span>
                  </h3>
                </div>
              ) : (
                <h1>{title}</h1>
              )}
            </>
          )}
        </div>
        <div className={classes.content}>
          <Card className={classes.card}>{children}</Card>
          {!hideTransact && <LatestTransactions />}
        </div>
      </section>
    </div>
  );
}
