import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HTMLELEMENT } from '../interfaces';
import SidePane from './SidePane';
import PartnerSidePane from 'pages/admin/partners/PartnerSidePane';
import classes from '../styles/AdminScreen.module.scss';
import Backdrop from 'assets/backdrop.png';

interface Props extends HTMLELEMENT<HTMLDivElement> { }

export default function AdminScreen({ children, className, ...rest }: Props) {
  const location = useLocation();
  const path = location.pathname;
  // eslint-disable-next-line
  const [ref1, ref2, main] = path.split('/');

  const [togglePartner, setTogglePartner] = useState(false);

  useEffect(() => {
    const toggleValue = JSON.parse(
      localStorage.getItem('admin-partner-toggle')!,
    );

    if (toggleValue === 'true') {
      setTogglePartner(true);
    } else if (toggleValue === 'false') {
      setTogglePartner(false);
    }
  }, []);

  const handlePartnerSidePane = () => {
    if (togglePartner === false) {
      setTogglePartner(true);
      localStorage.setItem('admin-partner-toggle', JSON.stringify('true'));
    } else {
      setTogglePartner(false);
      localStorage.setItem('admin-partner-toggle', JSON.stringify('false'));
    }
    // setTogglePartner(!togglePartner);
  };

  // ? Method to render the header for the page
  const renderHeader = () => {
    switch (main) {
      case 'sellTransactions':
        return 'Sell Transactions';
      case 'selltransactions':
        return 'Sell Transactions';

      case 'bank_applications':
        return 'Bank Applications';

      case 'withdraw_transactions':
        return 'Withdraw Transactions';

      case 'create':
        return 'New Admin';

      case 'change_password':
        return 'Change Password';

      case 'financetransactions':
        return 'Finance transactions';

      case 'withdrawtransactions':
        return 'Withdraw Transactions';

      case 'lusers':
        return 'Users';

      case 'lreports':
        return 'Reports';

      case 'lusersData':
        return 'Users';

      case 'ladmin_buy':
        return 'Buy Transactions';

      case 'Buytransactions':
        return 'Buy Transactions';
      case 'mobile_topup':
        return 'Mobile Topup';
      case 'gift_cards':
        return 'Gift Cards';

      case 'partner_deposit_transactions':
        return 'Partner Deposit Transactions';

      case 'partner_withdraw_transactions':
        return 'Partner Withdraw Transactions';
      case 'partner_giftcards':
        return 'Partner Giftcard /Topup';

      default:
        return main;
    }
  };
  // * End of renderHeader()

  return (
    <div className={classes.container}>
      <div className={classes.header_div}>
        <h1 className={classes.header_text}>{renderHeader()}</h1>
        {/* <button
          className={classes.header_partnerBtn}
          onClick={handlePartnerSidePane}
        >
          {togglePartner ? 'Switch to Users' : 'Switch to Partners'}
        </button> */}
      </div>
      <section className={classes.innerContainer}>
        <div className={classes.sidepanel}>
          {/* {togglePartner ? <PartnerSidePane /> : <SidePane />} */}
          <SidePane />
        </div>
        <div {...rest} className={`${classes.child} ${className || ''}`}>
          {children}
        </div>
      </section>
      <img src={Backdrop} alt="backdrop" className={classes.backdrop}></img>
    </div>
  );
}
