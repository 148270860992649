import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import React, { useEffect } from 'react';
import classes from 'styles/PrivacyPolicy.module.scss';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      {/* Hero section++++++++++++++++ */}
      <section className={classes.HeroSection}>
        <div className={classes.textContainer}>
          <h1>
            <span>Privacy</span> Policy
          </h1>
        </div>
      </section>
      <div className="container_first">
      </div>
      <section className={classes.SpecialDiv}>
        <div>
          <p className="first_para">
            Last revised: 24-August-2023
          </p>
          <p className="second_para">
            Encryptus (hereinafter referred to as “we or our”) is committed to safeguarding personal information. This Privacy Policy relates to our use and collection of personal information relating to clients of our platforms, including directors, beneficial owners, principals, employees or consultants of our clients and/or vendors (hereinafter referred to as “User” or “you” or “your”).
            Unless otherwise defined herein, the terms used in this Privacy Policy shall have the same meaning in the User Agreement posted on the Website.
            <br />
          </p>
        </div>
        <div>
          <h2>APPLICABILITY OF THIS POLICY</h2>

          <p>1.1. This Policy has been prepared in accordance with the Federal Decree-Law No. 45/2021 on the Protection of Personal Data and its Executive Regulations (“UAE Data Protection Law”), VARA’s licensing conditions, VARA’s Rulebooks as well as all applicable UAE regulations, guidance and international best practices. We may make amendments to this Policy.</p>

          <p>1.2. When you visit our website, pages, features, mobile applications, API and or other products and services (“Platform”), and more generally use or participate in any of our services through our Platform, we appreciate you trusting us with your personal data. Personal data includes any data or information relating to you which may, by itself, or in combination with other data be able to identify you (“Personal Information”).</p>

          <p>1.3. By opening an account with Encryptus (“Account”) and/or using our services and Platform, you acknowledge that you accept terms of this Privacy Policy and consent to the processing of your Personal Information in accordance with this Privacy Policy. We encourage you to read this Policy carefully before proceeding further as it forms part of our User Agreement.</p>

          <p>1.6. This Privacy Policy applies to our Platform. If you do not agree with the terms of this Privacy Policy, you should not access our Platform.</p>

          <p>1.7. With respect to our Users, Encryptus is the data controller of your personal information processed under this Privacy Policy.</p>

          <p>1.8. We use commercially reasonable physical, electronic and procedural measures to protect your Personal Information. This Privacy Policy does not apply to any anonymized data which cannot be used to identify you.</p>

          <p>1.9. We will not use your Personal Information for purposes other than those purposes we have disclosed to you in this Privacy Policy without your permission.</p>

          <p>1.10. This Privacy Policy is intended to explain our privacy practices and covers the following areas:</p>

          <p>a. Principles on which your Personal Information is processed;<br />
            b. Legal bases on which your Personal Information is processed;<br />
            c. Personal Information we may process about you;<br />
            d. Purposes for which your Personal information is processed;<br />
            e. Automated decision making;<br />
            f. Transmission and storage of your Personal Information;<br />
            g. Security of your Personal Information;<br />
            h. Data retention;<br />
            i. Your rights to your Personal Information;<br />
            j. Updating your personal Information;<br />
            k. Information processing for marketing purposes and opting out of such processing;<br />
            l. Processing for AML and fraud prevention and detection purposes;<br />
            m. Cookies<br />
            n. Eligibility<br />
            o. Data Protection Officer<br />
            p. How to get in touch with us
          </p>

          <h2>2. PRINCIPLES ON WHICH PERSONAL INFORMATION IS PROCESSED</h2>

          <p>2.1. Your Personal Information is collected and processed in accordance with relevant principles, including: lawfulness, fairness and transparency; for specific and clear purpose; data minimisation; accuracy; storage limitation; integrity and confidentiality (security) and accountability; with all relevant laws and regulations considered; and however applicable.</p>

          <h2>3. LEGAL BASES FOR PROCESSING PERSONAL INFORMATION</h2>

          <p>3.1. We process your Personal Information on the following bases - because the information is necessary for the performance of a contract with you or to take steps at your request to enter into a contract; because you have given your consent if we expressly ask for consent to process your Personal Information for a specific purpose; and to comply with legal and regulatory obligations.</p>

          <h2>4. PERSONAL INFORMATION WE MAY PROCESS ABOUT YOU</h2>

          <p>4.1. We may collect the following personal information from you when you register with us or use our Platform:</p>

          <p>a. Contact Information: name, address, email address;<br />
            b. Account information: username and password;<br />
            c. Financial Information: bank and bank account numbers;<br />
            d. Identity Verification Information: date of birth, tax ID number, images of government issued ID, passport, national ID or driving license;<br />
            e. Tax identification number;<br />
            f. Address proof: Utility bill details or similar information;<br />
            g. In addition, for institutional customers:<br />
            i. Organizational Information: Certificate of incorporation, business registration, certificate of incumbency, business licenses;<br />
            ii. Identification Information for beneficial owners, principals, directors and executive management (as applicable).</p>

          <p>4.2. We may also automatically collect certain computer, device and browsing information when you access our website:</p>

          <p>a. Computer or mobile device information, including IP address, operating system, browser type<br />
            b. Website usage information<br />
            c. Location information</p>

          <p>4.3. We also may collect personal information about you (including your beneficial owners, principals and executive management, as applicable) from public databases and ID verification partners, including:</p>

          <p>a. public employment profile;<br />
            b. criminal history;<br />
            c. credit history;<br />
            d. status on any politically exposed person and sanctions lists;<br />
            e. address;<br />
            f. other information to help validate your identity.</p>

          <p>4.4. We also collect personal information disclosed by you when you contact us or respond to our communications (e.g., email, telephone, other writing).</p>

          <p>4.5. We also collect Personal Information in the form of Account Information from your usage of our Platform. This includes information that is generated by your account activity, including, but not limited to, purchases and redemptions, deposits, withdrawals and account balances.</p>

          <p>4.6. When we require certain Personal Information from you, it is because we are required by applicable law to collect this information, or it is relevant for specified purposes. We may not be able to serve you as effectively or offer you all of our services if you elect not to provide certain types of information.</p>

          <h2>5. PURPOSES FOR WHICH YOUR PERSONAL INFORMATION IS PROCESSED</h2>

          <p>5.1. We may process your Personal Information, in the ways and for the purposes listed below. Such processing may include sharing with third parties in the manner and for the purposes set out below:</p>

          <p>a. To provide our Platform’s services effectively to our Users and conduct our business: to administer our Platform’s services, including to carry out our obligations arising from any agreements entered into between our Users and us, which may include passing your data to our payment partners and third parties such as agents or contractors or to our advisors (e.g., legal, financial, business or other advisors). We may also use your Personal Information to develop our products and services, to evaluate new products and services and to improve our service quality, including by performing statistical analysis and reporting on transactions and site usage;<br />
            b. To manage our risk: We may use your Personal Information to managing the safety, security and integrity of you and our Platform, including (1) protect, investigate, and avoiding fraudulent, unauthorized or illegal activities, (2) monitor and identify combat spam, malware and/or other security risks, and (3) perform internal operation of our Service such as troubleshooting bugs and other operational problems;<br />
            c. To receive services from our vendors and conduct our business: to receive services, including to carry out our obligations arising from any agreements entered into between you and our vendors, which may include passing your data to third parties such as agents or contractors or to our advisors (e.g., legal, financial business or other advisors). This includes vendors that provide ID verification and sanctions tools, which we use to help verify your identity and comply with our legal obligations, such as anti-money laundering laws. ID verification partners use a combination of government records and publicly available information to verify identity. This also includes the financial institutions with which we partner to provide custody of your money and to process payments you have authorized. Our contracts require these vendors to only use your information in connection with the services they perform for us and prohibit them from selling your information to anyone else;<br />
            d. To verify your identity: for the purposes of providing facilities, products or services, including conducting screenings or due diligence checks as may be required under applicable law, regulation, directive or our Terms and Conditions;<br />
            e. In relation to fraud prevention: We and other organizations may also access and use certain information to prevent fraud as may be required by applicable law and regulation and best practice at any given time. If false or inaccurate information is provided or fraud is identified or suspected, details may be passed to law enforcement and fraud prevention agencies and may be recorded by us or by them. In addition, we may share information with the financial institutions with which we partner to process payments you have authorized. We may also share information with our partner or other financial institutions. For more information on the AML & anti-fraud measures adopted by us, please see section 13 of this Privacy Policy;<br />
            f. In connection with legal or regulatory obligations: Law enforcement, regulators and the court service. We may share your information with law enforcement, regulatory authorities, tax authorities, self-regulatory organizations (such as those that operate virtual currency derivative exchanges) and officials, or other third parties when we are compelled to do so by a subpoena, court order, or similar legal procedure, or when we believe in good faith that the disclosure of Personal Information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate violations of our Terms and Conditions / User Agreement or any other applicable policies. We may also use your personal information to otherwise comply with all applicable laws, regulations, rules, directives and orders;<br />
            g. In order to communicate with you: We may use your Personal Information to communicate with you, including providing you with updates on changes to products, services and banking facilities (whether made available by us or through us) including any additions, expansions, suspensions and replacements of or to such products, services and banking facilities and their terms and conditions;<br />
            h. In connection with disputes: We may use your Personal Information to address or investigate any complaints, claims or disputes and to enforce obligations owed to us;<br />
            i. For advertising: Subject to applicable laws and regulations, we may use your Personal Information to inform our advertising and marketing strategy and to tailor our messaging to your needs. Where required by law, we will ask for your consent at the time we collect your data to conduct such marketing. An opt-out mechanism will be provided to you in each communication to enable you to exercise your right to opt out of any direct marketing. We never sell your information. You may withdraw this consent or opt-out at any time without affecting the lawfulness of processing based on your prior consent. Where we use third party advertising companies to serve ads when you visit or use the Platforms, these companies may use information about your visits to our Platform and other platforms that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you, provided you have consented to the same.</p>

          <p>5.2. For the purpose stated in paragraph 5.1(f) we may collect and process some of your personal data without your knowledge or consent; and only where this is required or permitted by law. We may be compelled to surrender your personal data to legal authorities without your express consent, if presented with a court order or similar legal or administrative order, or as required or permitted by the laws, rules and regulations of any nation, state or other applicable jurisdiction.</p>

          <h2>6. AUTOMATED DECISION MAKING</h2>

          <p>6.1. Our operation of the Platform relies on automated analysis of Personal Information provided by you, alongside that received from AML service provider and compliance service provider, name screening agencies and fraud prevention agencies, for more information on the anti-fraud measures adopted by us, please see section 13 of this Privacy Policy.</p>

          <p>6.2. We may use criteria such as your identifying information (e.g., your name, tax id number or date of birth) to validate your identity against public records on an automated basis or without human/manual intervention.</p>

          <p>6.3. You may also request that we provide information about our methodology and ask us to verify that the automated decision has been made correctly, including requesting us to include a human element in review of automated processing decisions. We may reject the request, as permitted by applicable law. However, generally in these circumstances we will verify (or request the relevant third party to verify) that the source data are functioning as anticipated without error or bias.</p>

          <h2>7. TRANSMISSION AND STORAGE OF YOUR PERSONAL INFORMATION</h2>

          <p>7.1. Security over the internet: No data transmission over the Internet or website can be guaranteed to be secure from intrusion. Your personal data is stored and transferred in compliance with the applicable legislations and regulations of UAE.</p>

          <p>7.2. Anonymity: Virtual currencies may not be fully anonymous as a result of the public digital ledgers reflecting these currencies. Generally, anyone can view the balance and transaction history of any public wallet address. We, and others are able to match your public wallet address to other information about you, and also may be able to identify you from a blockchain transaction. Furthermore, third parties may use data analytics to identify other information about you. Please note that such third parties have their own privacy policies and that we do not accept any responsibility or liability for their policies or processing of your Personal Information.</p>

          <p>7.3. Secure Storage: All information you provide to us is stored on our or our vendors’ secure servers and accessed and used subject to our security policies and standards as well as data processing agreements we have in place with our vendors. Where we have given you (or where you have chosen) a password that enables you to access certain parts of our Platform, you are responsible for keeping this password confidential and for complying with any other security procedures that we notify you of. We ask you not to share your password with anyone.</p>

          <p>7.4. Access and transfer: Your Personal Information may be accessed by staff or suppliers, transferred to, and/or stored at, a destination outside the UAE in which data protection laws may be of a lower standard. Regardless of location or whether the person is an employee or contractor, we will impose the same data protection safeguards that we deploy herein UAE.</p>

          <p>7.5 Transfer outside the UAE: Some of the international organisations and countries to which your personal data may be transferred do not benefit from an appropriate data protection regulatory framework. For transfer of your personal data outside the UAE to such international organisations and countries, we shall transfer your personal data, upon ensuring that a suitable degree of protection is afforded to it through the implementation of the necessary safeguards, such as an adequacy decision by the relevant authority, adequate binding corporate rules or through the inclusion of standard contractual clauses in our agreements with such organisations and countries. We may also transfer your personal data to recipients outside the UAE based on your express consent; or if such transfer is necessary for judicial processes; or if such transfer is necessary for entering into or performing a contract between Encryptus and you or between Encryptus and a third party for your interests, or if such transfer is necessary for an act relating to international judicial cooperation; or if the transfer is necessary for protection of public interest.</p>

          <h2>8. SECURITY OF YOUR PERSONAL INFORMATION</h2>

          <p>8.1. Please note that no transmission over the Internet or any method of electronic storage can be guaranteed to be absolutely 100% secure, however, our best endeavors will be made to secure data and the ability to access your personal data.</p>

          <p>8.2. Without prejudice to our efforts on the protection of your data, nothing contained in this Policy constitutes a warranty of security of the facilities, and you agree to transmit data at your own risk.</p>

          <p>8.3. We do not guarantee that your data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>

          <p>8.4. Always check that any website on which you are asked for financial or payment information in relation to our Platform is in fact legitimately owned or operated by us. The risk of impersonating hackers exists and should be taken into account when using our Platform.</p>

          <p>8.5. If you do receive any suspicious communication of any kind or request, do not provide your information and report it us by contacting us via email at hello@encryptus.io. Please also immediately notify us at hello@encryptus.io if you become aware of any unauthorized access to or use of your Account.</p>

          <p>8.6. Since we cannot guarantee against any loss, misuse, unauthorized acquisition, or alteration of your data, please take the necessary steps to protect your own personal data, including the adoption of sufficient safety measures such as your choosing of an appropriate password of sufficient length and complexity and to not reveal this password to any third parties.</p>

          <p>8.7. Furthermore, we cannot ensure and do not warrant the security or confidentiality of data transmitted to us, or sent and received from us by Internet or wireless connection, including: email, phone, over-the-top (OTT) messaging services or SMS, since we have no way of protecting that information once it leaves and until it reaches us. If you have reason to believe that your data is no longer secure, please contact us at hello@encryptus.io.</p>

          <p>8.8. Should your personal data be breached, and the security of your rights be at high risk, we shall promptly and immediately communicate to you the nature of the breach which has taken place, the likely consequences of such a breach and shall describe thoroughly the measures we have implemented to address the breach and to mitigate any and all adverse effects to you and your rights. In the unlikely event of a breach occurring, please reach out to us at hello@encryptus.io for further information and for further advice on how to mitigate the potential adverse effects of such a breach.</p>

          <p>8.9. We also aim to conduct all applicable security risk assessments to ensure the availability of risk mitigation controls, to better safeguard the integrity of your data.</p>

          <h2>9. DATA RETENTION</h2>

          <p>9.1. We will retain your Personal Information for as long as is necessary for the processing purpose(s) for which they were collected and any other permitted linked purpose (for example certain transaction details and correspondence may be retained until the time limit for claims in respect of the transaction has expired or in order to comply with regulatory requirements regarding the retention of such data). So, if information is used for two purposes, we will retain it until the purpose with the latest period expires; but we will stop using it for the purpose with a shorter period once that period expires.</p>

          <p>9.2. We restrict access to your Personal Information to those persons who need to use it for the relevant purpose(s). Our retention periods are based on business needs, and your information that is no longer needed is either irreversibly anonymized (and the anonymized information may be retained) or securely destroyed. By way of example:
            <br />a. use to perform a contract: in relation to your Personal Information used to perform any contractual obligation to you, we may retain that Personal Information whilst the contract remains in force plus a further period (depending on jurisdiction and other factors) to deal with any queries or claims thereafter;
            <br />b. copies of evidence obtained in relation to AML checks: in relation to your Personal Information obtained in relation to AML checks, we may retain that Personal Information whilst our client relationship remains in force plus up to a further 8 years to deal with any queries or claims thereafter;
            <br />c. where claims are contemplated: in relation to any information where we reasonably believe it will be necessary to defend or prosecute or make a claim against you, us or a third party, we may retain that information for as long as that claim could be pursued.</p>

          <h2>10. YOUR RIGHTS TO YOUR PERSONAL INFORMATION</h2>

          <p>10.1. Depending on the country in which you are resident you may have certain rights. These rights may include:
            <br />a. Right of Access to information: This means you have a right to receive at no charge, the following information upon submitting a request to us:
            <br />i. The categories of personal data processed;
            <br />ii. The purposes of the processing of your personal data;
            <br />iii. The recipients or categories of recipients of your personal data both within and outside the UAE;
            <br />iv. Controls and standards relating to the duration of storage and archiving of your personal data;
            <br />v. Actions taken upon your requests for rectification, erasure or restriction of processing and objection to processing of your personal data;
            <br />vi. Safeguards in case of cross-border processing of your personal data;
            <br />vii. The existence of automated decision-making, including profiling;
            <br />viii. Actions to be taken in case of personal data breach;
            <br />ix. Procedure to lodge a complaint with the UAE Data Office.
            <br />b. Right to Rectification: This is your right have your personal data rectified if what is held by us is inaccurate/incomplete.
            <br />c. Right to Erasure: This is your right, under certain circumstances to ask for your personal data to be deleted. This would apply if your personal data is no longer required for the purposes it was collected for, or your consent for the processing of that data has been expressly withdrawn, or where your personal data has been unlawfully processed. Once deleted all your personal data will be removed from our systems and will not be recoverable. This right is subject to the restrictions laid down in the applicable law, including if the request is in conflict with other legislation(s) to which we are subject.
            <br />d. Right to Withdraw Consent: If you wish for Encryptus to stop processing your personal data, it is your right to withdraw consent at any time, where we have asked you for consent to use your information for that particular purpose. To withdraw consent for processing of your personal data, please email us at hello@encryptus.io.
            <br />e. Right to Restrict Processing: This is your right to ask for a restriction or stop in processing of your personal data, such as in the case where accuracy of personal data is contested by you, or you object to the processing of your personal data, or the processing is in contravention to applicable laws. This right is subject to the restrictions laid down in the applicable law, including for reasons such as where processing is necessary for judicial purposes, or is necessary for protection of public interest or third party rights, or where the processing is limited to the storage of data.
            <br />f. Right to Data Portability: This is your right to ask for your personal data supplied directly to us, which we have processed pursuant to your consent, under a contract, or by automated means, to be provided to you in a structured, commonly used, and machine-readable or electronic format.
            <br />g. Right to Object: This is your right to object to the further processing of your personal data which is inconsistent with the primary purpose for which it was collected, and includes processing for profiling, direct marketing and for statistical surveys.
            <br />h. Rights in Relation to Automated Decision Making and Profiling: This is your right to object to automated decision-making that has legal implications or seriously affects you. This right is subject to restrictions under applicable laws.</p>

          <p>10.2. We aim to respond to all legitimate requests without undue delay and within 2 calendar months of receipt of any request from you. Occasionally it may take us longer than 2 calendar months, if your request is particularly complex, or if you have made duplicated or numerous requests. In this case, we will notify you of receipt of such request(s) and keep you updated as to the status of progress concerning such request(s).</p>

          <p>10.3. If you have any of these rights under your domestic data protection laws and wish to exercise any of them, please contact us at hello@encryptus.io. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This security measure is to ensure that your personal data is not disclosed to any person who has no right to receive it.</p>

          <h2>11. UPDATING YOUR PERSONAL INFORMATION</h2>

          <p>11.1. Whenever possible, you can update your Personal Information, subject to verification by us. If you wish for us to update your personal data, please contact us at hello@encryptus.io, or proceed to amend the same in your Account on the Platform. We will retain your personal data for as long as your Account has not been closed or as may be needed to provide you access to your Account and/ or services, and in compliance with the law.</p>

          <h2>12. INFORMATION PROCESSING FOR MARKETING PURPOSES AND OPTING OUT OF SUCH PROCESSING</h2>

          <p>12.1. We may use your Personal Information to determine what may be of interest to you. This is how we decide which products, services, and offers may be relevant and of interest to you. By using our Platform, registering an Account, contacting us, requesting information from us, you consent to receiving marketing communications from us. We may communicate with you by e-mail, fax or telephone. You have the right to ask us not to process your Personal Information for marketing purposes. We will inform you if we intend to use your information for such purposes or if we intend to disclose your information to any third party for such purposes.</p>

          <p>12.2. Opting Out: You can exercise your right to prevent such processing by not checking certain boxes on our marketing consent form. You can also exercise the right at any time by contacting us at hello@encryptus.io.</p>

          <h2>13. PROCESSING FOR AML AND FRAUD PREVENTION AND DETECTION PURPOSES</h2>

          <p>13.1. Before we provide our services to you, we undertake checks for the purposes of preventing fraud and money laundering and to verify your identity. These checks require us to process Personal Information about you.</p>

          <p>13.2. The Personal Information you have provided, we have collected from you, or we have received from third parties will be used to prevent fraud and money laundering and to verify your identity.</p>

          <p>13.3. In order to do so, we may provide information to, obtain information from, and verify information with AML name screening and compliance agencies. We will continue to exchange information with such parties while you have a relationship with us.</p>

          <p>13.4. We or AML name screening and compliance agencies may also enable law enforcement agencies, as required by applicable law, to access and use your Personal Information to detect, investigate and prevent crime.</p>

          <p>13.5. AML name screening and compliance agencies can hold your Personal Information for different periods of time, and if you are considered to pose a fraud or money laundering risk, your data can be held for up to eight years.</p>

          <p>13.6. As part of the processing of your Personal Information, decisions may be made by automated means. This means we may automatically decide that you pose a fraud or money laundering risk if our processing reveals your behavior to be consistent with money laundering or known fraudulent conduct, or is inconsistent with your previous submissions, or you appear to have deliberately hidden your true identity. You have rights in relation to automated decision making as set out in section 6 of this Privacy Policy.</p>

          <p>13.7. As a consequence of processing, if we, AML name screening or compliance agencies, determine that you pose a fraud or money laundering risk, we may refuse to provide services through the Platform or we may stop providing existing services to you.</p>

          <h2>14. COOKIES</h2>

          <p>14.1. We use cookies on the websites. We may use the data collected through these technologies to better display our Platform, to save you time, to provide better technical support, for promotional purposes, and to measure and analyze Platform usage. The types of cookies we may use are set out below:
            <br />a. Essential cookies: Necessary for the operation of the website. We may use essential cookies to authenticate users, prevent fraudulent use of user accounts, or offer Platform features.
            <br />b. Functional cookies: Allow us to recognize and count the number of visitors and see how visitors move around the website when using it. This helps us improve the way the Platform works.
            <br />c. Analytic cookies: Used to recognize you when you return to the Platform. This enables us to personalize our content for you and remember your preferences.
            <br />d. Tracking cookies: Record your visit to the Platform, the pages you have visited, and the links you have followed. We will use this information to make the Platform and the content more relevant to your interests. We may also share this information with third parties for this purpose.</p>

          <p>14.2. You may be able to refuse or disable cookies by adjusting your web browser settings. Some browsers have options that allow the visitor to control whether the browser will accept cookies, reject cookies, or notify the visitor each time a cookie is sent. If you choose to refuse, disable, or delete these technologies, some of the functionality of the Platform, services and/or features may no longer be available to you or function properly.</p>

          <h2>15. ELIGIBILITY</h2>

          <p>15.1. We do not allow access to this Platform to anyone who is underage at your jurisdiction of residence or in the UAE. If in a circumstance we learn that we have received any information from an underage person, we shall inform the underaged individual that he/she cannot use the Website or the Service.</p>

          <h2>16. GENERAL</h2>

          <p>16.1. In the case of abuse or breach of security, we are not responsible for any breach of security or for any actions of any third parties which receive the information illegally.</p>

          <p>16.2. We will not distribute customer information to be used in mailing lists, surveys, or any other purpose other than what is required to perform our services.</p>

          <p>16.3. If you choose to restrict the collection or use of your confidential and personal data, please stop using the Platform immediately.</p>

          <h2>17. AMENDMENTS TO THIS PRIVACY POLICY</h2>

          <p>17.1. From time to time, we may revise, amend or supplement this Privacy Policy to reflect necessary changes in law, our Personal Information collection and usage practices, the features of our Platform, or certain advances in technology, or if directed by the VARA and/or the concerned regulators. If any material changes are made to this Privacy Policy, the changes may be prominently posted on the relevant or affected Platform. However, this is not obligatory for us; the onus is on you to regularly familiarize yourself with the contents of this Privacy Policy, for your own information; and particularly to do so every time you access our Platforms or make use of our services.</p>

          <h2>18. DATA PROTECTION OFFICER</h2>

          <p>18.1. We have a designated Data Protection Officer (“DPO”). The DPO is responsible for the management and protection of Personal Information and data in accordance with all applicable laws and is appropriate for the level of risk involved with such Personal Information. The DPO is responsible for implementing and maintaining appropriate policies, procedures, systems, and controls in relation to Personal Information.</p>

          <h2>19. HOW TO GET IN TOUCH WITH US</h2>

          <p>19.1. If you have any questions about our Policy as outlined above, or if you have any complaints, queries of issues pertaining to your personal data, then please contact our DPO at compliance@encryptus.io.</p>

          <h2>20. NOTICE TO EUROPEAN UNION RESIDENTS (“GDPR NOTICE”)</h2>

          <p>20.1. Under the European Union’s General Data Protection Regulation (GDPR), we are providing this GDPR notice to EU residents.</p>

          <p>20.2. We have already described in section 5 above as to how we use your Personal Information and how we disclose or share your information. You have the following rights with respect to your Personal Information:
            <br />a. You have the right to information about your Personal Information processed by us as to (i) the processing purposes; (ii) the categories of Personal Information being processed; (iii) the recipients or categories of recipients who Personal Information have been disclosed to; (iv) the planned duration for which Personal Information is to be stored; (v) the deletion or restricting the processing of Personal Information or to object to such processing; (vi) the appeal to a supervisory authority; (vii) any automated decision making in processing your Personal Information; (viii) the origin of your Personal Information if such information is not collected from you; and (ix) the designation of Personal Information in case of transfer of information;
            <br />b. You have the right to rectify any inaccurate Personal Information;
            <br />c. You have the right to request immediate erasure of Personal Information if (i) the Personal Information is no longer necessary to be retained; (ii) you revoke your consent; (iii) you object to the processing of your Personal Information as there is no legitimate ground to process it; and (iv) your Personal Information was processed unlawfully;
            <br />d. You have the right to restrict any processing of your Personal Information if (i) you object to the accuracy of Personal Information; (ii) the processing is unlawful but instead of erasure you choose to restrict the processing; (iii) we do not need your Personal Information but you may need it for other purposes (iv) you object to the processing pursuant to Article 21(1) of the GDPR;
            <br />e. You have the right to receive your Personal Information that you provided to us in a structured and readable format.</p>

          <p>20.3. If you wish to exercise your rights to your Personal Information, update, delete or restrict any process of Personal Information or revoke your consent to processing the same, please contact us at hello@encryptus.io. If there is any infringement of GDPR in relation to your Personal Information, you may lodge a complaint with a supervisory authority at your residence jurisdiction of European Union.</p>

        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
