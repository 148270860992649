import React, { ReactNode } from 'react';
import classes from '../styles/Paginate.module.scss';

export interface PaginateProps {
  currentPage: number;
  lastPage: number;
  next: () => any;
  prev: () => any;
  jumpTo: (page: number) => any;
}

export default function Paginate({
  lastPage,
  currentPage,
  jumpTo,
  prev,
  next,
}: PaginateProps) {
  if (!lastPage) return <></>;
  const arr = Array.apply(null, Array(lastPage));
  return (
    <div className={classes.container}>
      <NumberCircle disabled={currentPage === 1} onClick={prev}>
        {'<'}
      </NumberCircle>
      {arr.map((_, idx) => (
        <div key={idx.toString()}>
          <NumberCircle
            onClick={() => jumpTo(idx + 1)}
            active={idx + 1 === currentPage}
          >
            {idx + 1}
          </NumberCircle>
        </div>
      ))}
      <NumberCircle disabled={currentPage === lastPage} onClick={next}>
        {'>'}
      </NumberCircle>
    </div>
  );
}

interface NumberProps {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  children: ReactNode;
}

function NumberCircle({ active, disabled, onClick, children }: NumberProps) {
  return (
    <div
      className={`${classes.number} ${active ? classes.active : ''} ${
        disabled ? classes.disabled : ''
      }`}
      onClick={onClick}
    >
      <p>{children}</p>
    </div>
  );
}
