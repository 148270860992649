import axios from 'axios';
import { ROUTES } from 'config';
import { useUser } from 'context/user';
import React, { useEffect, useState } from 'react';
// import classes from 'styles/Modalhandler.module.scss';
import { toast } from 'react-toastify';
import ModalStep1 from './ModalStep1';
import ModalStep2 from './ModalStep2';

// Main parent handler of steps +++++++++++++++++++++++++++++++
function ModalHandler() {
  const [show, setShow] = useState(1);
  const [image, setImage] = useState('');
  const [qrGenerated, setQrGenerated] = useState<boolean>(false);
  const { headers } = useUser();
  const getSecret = async () => {
    try {
      const { data } = await axios.patch(ROUTES.GET_SECRET_3FA, null, {
        headers,
      });

      console.log({
        mfaData: data,
      });

      if (data) {
        setImage(data.displayTHIS);
        setQrGenerated(() => true);
      }
      return;
    } catch (err: any) {
      toast.dismiss();
      return toast.error(err.response.data.message);
    }
  };
  const handleClick = async () => {
    if (show === 2) {
      return;
    }
    getSecret();
    toast.success('Scan QR code');
    setShow(show + 1);
  };

  useEffect(() => {
    getSecret();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {show === 1 && (
          <ModalStep1
            clickhandler={handleClick}
            qrGenerated={qrGenerated}
            qrcode={image}
          />
        )}
        {show === 2 && <ModalStep2 qrcode={image} />}
      </div>
    </div>
  );
}

export default ModalHandler;
