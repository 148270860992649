import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/UserAgreement.scss';
import React, { useEffect } from 'react';

export const RiskDisclosurePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <div className="main_containter">
                <div className="container">
                    <div className="inner_div">
                        <h1>
                            Risk <span style={{ color: '#fff' }}> Disclosure </span>
                        </h1>
                    </div>
                </div>
                <div className="container_first">
                    <p className="first_para">
                        Last revised: 25-August-2023
                    </p>
                    <p className="second_para">
                        This Risk Disclosure Statement (the “Statement”) intends to
                        give users information on the potential risks
                        associated with virtual assets and the use of
                        Encryptus DMCC (hereafter referred to as “Encryptus”, “Company”, “we”,
                        “us”, “our” as the context requires) Services. This Statement has been prepared in accordance with VARA’s
                        licensing conditions as well as all applicable UAE regulations, guidance and international best practices.
                        Encryptus may make amendments to this Statement, as may be deemed appropriate, necessary or if so, directed by the VARA and/or the concerned regulators.
                        <br />

                    </p>
                    <p>
                        Each of our Services has its own distinct risks and this Statement provides you with the general risks associated with using our Services.
                        This Statement does not explain all of the risks or how such risks relate to your personal circumstances.
                        This list is not exhaustive and there may be additional risks that we have not anticipated or listed as part of this Statement.
                        The purpose of this Statement is simply to highlight the material risks involved in trading in virtual assets and does not go into depth on each individual risk.
                    </p>
                </div>
                <img src={UserAgreementTwo} alt="FooterImage" />
                <div className="container_second">
                    <ol className="ordered_list">

                        <p>

                            it is important that you fully understand the risks involved and we encourage all users to thoroughly read and understand this Statement before trading virtual assets or using our Services. By using our Services, you acknowledge and accept the risks detailed in this Statement as well as any other risks associated with trading in virtual assets and using our Services. You are requested to undertake an independent assessment of the risks associated with investing in virtual assets and make any investment decisions based on your own assessment, resources, research, inquiries and experience. You are solely responsible for decisions concerning whether an investment, strategy, or other product or service is suitable for you. You understand and acknowledge that Encryptus shall not be liable for any loss, claims or damages (whether direct or indirect) arising from or in connection with any of the risk events specified below.
                            <br />
                        </p>
                        <br />
                        <p>
                            <strong>a) Virtual assets are not legal tenders:</strong>
                            A majority of virtual assets are not backed by any central government or are not recognised as legal tender.
                            There is no guarantee that a vendor or an individual will continue to accept virtual assets as payment in the future merely because they do so now.
                            <br /></p>

                        <p><strong>b) General market risk:</strong> The market for virtual assets is new and constantly developing. Virtual asset trading is subject to high market risk and price volatility, where changes in value may be significant and/or occur rapidly and without warning. Whether the market for one or more virtual assets will move up or down, or whether a virtual asset will lose all or substantially all of its value, is unknown. Past performance is not a reliable indicator of future performance. You should be cautious about holding virtual assets and are warned that you should pay close attention to your position and holdings, and how you may be impacted by sudden and adverse shifts in trading and other market activities. Investment in virtual assets involves significant risks and it is possible that you may lose a proportion or substantial part of all of your investment. You should not invest funds that you are not prepared to lose in their entirety. Performance of virtual assets is subject to various factors, and you should carefully consider whether you can afford to bear the risks of investing in virtual assets.<br /><br /></p>

                        <p><strong>c) High price volatility:</strong> The value of virtual assets is entirely derived by market forces of supply and demand. It is generally understood that the market for virtual assets is more volatile than most markets for traditional fiat currencies and commodities like silver and gold. It is common for prices to increase or decrease significantly in a single day. Although this could result in profits, this could also result in losses. You should carefully consider whether you can afford to bear the risks of investing in virtual assets. Moreover, virtual assets are not backed by a central bank, a national or international organisation, assets or other forms of credit, although in some specific cases may be backed to an extent by physical assets. Virtual assets may have no inherent value; in most cases, the price of virtual assets is entirely dependent on the value that market participants place on them, meaning that any increase or loss of confidence in virtual assets may affect their value.<br /><br /></p>

                        <p><strong>d) Irreversible transactions and custody:</strong> Due to the decentralized nature, transfers of virtual assets may be irreversible and therefore, losses due to fraudulent or accidental transactions may not be recoverable. Customers of third-party service providers for virtual assets custody, trading, lending, staking or other purposes may not directly control the virtual assets held through such third-party service providers. The obligations associated with these custodial and other arrangements to safeguard virtual assets involve unique risks and uncertainties that are not present in arrangements for safeguarding conventional assets. For example, due to the unique characteristics of virtual assets and the lack of legal precedent, there are significant legal questions surrounding how such arrangements would be treated in a court proceeding arising from an adverse event (e.g., fraud, loss, theft or bankruptcy).<br /><br /></p>

                        <p><strong>e) Delays in transfers:</strong> All transactions of assets are subject to screening by us in line with the applicable regulatory standards and relevant applicable legislation. Certain patterns and sizes of transactions will be subject to enhanced scrutiny which could result in delays or rejection of the transfer request. Delays may be caused due to our internal systems and procedures including compliance and AML checks or other reasonable causes. Also, delays may be caused due to acts or omissions of any relevant third party. We do accept any responsibility for delays caused due to such third party or where we have provided prior notification of the potential delay.<br /><br /></p>

                        <p><strong>f) Liquidity risk:</strong> Markets for virtual assets have varying degrees of liquidity. Liquidity risk exists when particular investments are difficult to purchase or sell, possibly preventing you from selling out of these illiquid investments at an advantageous price, or at all. Thin markets can also amplify volatility and cause significant delays in executing trades. Investment markets of this nature are likely to exhibit broader price spreads and heightened responsiveness to buying and selling pressures compared to more actively traded markets. The lack of liquidity can stem from multiple factors, including market conditions, regulatory interventions, technological challenges, or unanticipated events. Such illiquidity could potentially affect the ability to initiate or terminate positions, potentially resulting in losses or delays in accessing funds.<br /><br /></p>

                        <p><strong>g) Liquidity slippage risk:</strong> We make proper efforts and work to monitor and maintain liquid order books. Nevertheless, market conditions might create a lack of liquidity that might create a severe devaluation of the asset or a strong slippage on price when creating a market order.<br /><br /></p>
                        <p><strong>h) Public record of transactions:</strong> Given the nature of virtual assets, some transactions may not be private and may be recorded on public decentralized databases.<br /><br /></p>

                        <p><strong>i) Market manipulation and fraud risk:</strong> Manipulative practices in the virtual asset market can create a distorted picture of liquidity or trading activity for a specific virtual asset and may affect its pricing. Further, the market is subject to an increased risk of fraud, including the potential for bucket shops, Ponzi schemes and pump and dump schemes. Such targeted schemes may result in the loss of virtual assets or a decrease in or loss of all value of your virtual assets, and you may not benefit from legal protections.<br /><br /></p>

                        <p><strong>j) Increased cyber-attack:</strong> Virtual assets are often targeted by hackers and criminals who commit fraud and/or theft. This includes attacks on the protocol or technologies on which our Services depend/function on, including, but not limited to distributed denial of service; sybil attacks; phishing; social engineering; hacking; smurfing; malware; double spending; majority-mining, consensus-based or other mining attacks; misinformation campaigns; forks; and spoofing. The very nature of virtual assets and distributed ledger technology may lead to an increased risk of cyber-attack. Such attacks may result in the loss of your virtual assets. Legal protections may not apply to such targeted malicious acts.<br /><br /></p>

                        <p><strong>k) Account security risk:</strong> Unauthorised access by third parties of your login credentials to gain access to your account, including through carelessness or forgetfulness by the account holder, or the third-party obtaining control over another device or account used by you in connection with any enhanced security measures enabled for your account. It is not possible to eliminate all security risks. You acknowledge and accept that you are responsible for keeping your account and log-in details safe, and you may be responsible for all the transactions conducted through your account, whether authorised by you or not.<br /><br /></p>

                        <p><strong>l) Unauthorised access:</strong> You must accept the provisions present in the Agreement regarding your care, due diligence and responsibility in the handling the unique log-in credentials to your account. The lack of your care, due diligence and responsibility in this regard may result in a third party gaining access to your account and hence your virtual assets and personal information. Losses resulting from the aforesaid may not benefit from legal protection.<br /><br /></p>

                        <p><strong>m) No personal advice:</strong> We do not provide personal advice in relation to our Services. No communication or information provided to you by us is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice. Any decision to use our Services is made by you. You are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you according to your personal investment objectives, financial circumstances and risk tolerance.<br /><br /></p>

                        <p><strong>n) Legal risk:</strong> The legal environment and status of virtual assets and related virtual asset services is continuously developing and may be uncertain. Changes in laws and regulations by VARA and/or any relevant regulatory authority may materially affect the value, price, use, transfer, exchange, operation, acceptance, control, and/or access of virtual assets. Whether and how one or more virtual assets constitute property, or assets, or rights of any kind may also be unclear.<br /><br /></p>

                        <p><strong>o) Taxation risks:</strong> The tax characterization of virtual assets is uncertain. You must seek your own tax advice in connection with acquisition, storage, transfer and use of any virtual asset, which may result in adverse tax consequences to you, including, without limitation, withholding taxes, transfer taxes, value added taxes, income taxes and similar taxes, levies, duties or other charges and tax reporting requirements. It is your responsibility to report and pay any taxes that may arise from transacting using our Services, and you acknowledge that Encryptus does not provide tax advice in relation to any transaction carried out using our Services.<br /><br /></p>

                        <p><strong>p) Enforcement actions:</strong> We may cease operations in a jurisdiction in the event that regulatory actions, or changes to law or regulation, make it illegal to operate in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction. This may result in you losing access to your account and may further result in the loss of any virtual assets stored or held on your account.<br /><br /></p>

                        <p><strong>q) Third party custody risks:</strong> Having virtual assets on deposit or with any third party in a custodial relationship has attendant risks. These risks include security breaches, risk of contractual breach, and risk of loss. You should be wary of allowing third parties to hold your property for any reason.<br /><br /></p>

                        <p><strong>r) Recording of transaction on public ledgers:</strong> Some virtual-asset transactions may be deemed to be made when they are recorded on a public ledger, which may or may not necessarily be the date or time when you initiated the transaction, resulting in any cancellation being rendered ineffectual in some situations.<br /><br /></p>

                        <p><strong>s) Risk of software weakness:</strong> Because the Services we provide are based on blockchains or other distributed ledgers, any malfunction, breakdown or abandonment of any blockchain may have a material adverse effect on the Services. Moreover, advances in cryptography, or technology could present risks to the Services, by rendering ineffective the cryptographic consensus or other technological component mechanisms that underpins blockchains.<br /><br /></p>

                        <p><strong>t) Risk of mining attack:</strong> Some blockchains are susceptible to mining attacks, including but not limited to double-spend attacks, majority mining power attacks, “selfish-mining” attacks, and rare condition attacks. Any successful attacks present a risk to the Services, and the expected proper execution and sequencing of transactions carried out through the Services.<br /><br /></p>

                        <p><strong>u) System risk:</strong> You acknowledge and understand that we may experience system interruptions or delays due to outages, power loss, telecommunications failure, disasters, cyberattacks, system malfunctions, or other events and such interruptions or delays may affect our Services to you temporarily. We do not guarantee that our Services will be completely free from harm and uninterrupted and/or available at any particular time or that Services will not be subject to unplanned service outages or network congestion.<br /><br /></p>

                        <p><strong>v) Third party outages:</strong> We may deliver our Services to you using third-party providers. While the performance of these providers is monitored on a constant basis, an outage at one of the firm’s partners could affect its service to you in the short-term.<br /><br /></p>

                        <p><strong>w) Third party risks:</strong> Third parties including payment service providers, liquidity providers, custodians, IT service providers and banking partners may be involved in the provision of our Services. You may be subject to the terms and conditions of these third parties. You acknowledge and understand that you may suffer losses incurred from actions or conditions regarding third parties, including those engaged or partnered by us for the provision of our own Services to you and those engaged by yourself for the use <br /><br /></p>

                        <p><strong>x) Information risk:</strong> There are risks associated with utilizing an internet-based system including, but not limited to, the failure of hardware, software, and internet connections, the risk of malicious software introduction. We do not control signal power, reception, routing via the internet, configuration of your equipment or the reliability of your connection. Failure of the foregoing may result in your transaction either not being executed according to your instructions, or not executed at all. Furthermore, when you communicate with us via electronic communication, you should be aware that electronic communications may fail, be delayed, be unsecure and/or not reach the intended destination.<br /><br /></p>

                        <p><strong>y) Unanticipated risks:</strong> The risks described herein are neither intended to be a comprehensive nor an exhaustive list of risk factors. You remain responsible for taking care to understand the technology, economic and legal nature of virtual assets and for carefully managing your exposure in accordance with that understanding and your risk appetite for innovative, volatile and speculative new technologies and virtual assets.<br /><br /></p>
                        <p><strong>z) Liquidity risk in Over-the-Counter (OTC):</strong> The Transactions you enter with Encryptus will be over-the-counter transactions i.e., off- exchange. The liquidity levels for instruments vary, while some markets are highly liquid. OTC transactions may involve greater risk than investing in on-exchange transactions due to low liquidity as there is no exchange market on which to close out an open position. It may be difficult and, in some cases, impossible to liquidate in full or in part an existing position or assess the value of the position arising from an off-exchange transaction or to assess the exposure to risk associated with holding or liquidating the position..<br /><br /></p>
                    </ol>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
};
