import React from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';

interface Props {
  data?: any;
}

const COLORS = ['#00b7cb', '#0070f0'];

export default function TreeMap(props: Props) {
  const data = [
    {
      name: 'axis',
      children: props.data,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        width={400}
        height={200}
        data={data}
        dataKey="size"
        stroke="#fff"
        fill={COLORS[1]}
      >
        <Tooltip />
      </Treemap>
    </ResponsiveContainer>
  );
}
