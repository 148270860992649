import React, { FC } from 'react';

export type HTMLELEMENT<T> = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<T>,
  T
>;

export interface SelectOption {
  value: string;
  label?: string;
}

export interface InputProp {
  label?: string;
  name: string;
  disabled?: any;
  type?: string;
  value?: any;
  select?: any;
  icon?: string | FC;
  toolIcon?: string | FC;
  placeholder?: string;
  options?: SelectOption[];
  component?: string | FC;
  phoneNumberInput?: boolean;
  country?: string;
}

export type FormProp = InputProp | InputProp[];

export interface anyObject {
  [x: string]: any;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ResetPasswordForm {
  email: string;
  newPassword: string;
  passwordConfirmation: string;
}

export interface RegisterForm {
  firstName: string;
  lastName: string;
  email: string;
  phone_no: string;
  password: string;
  passwordConfirmation: string;
  levelName?: string;
}
export interface PartnerRegisterForm {
  enterpriseName: string;
  location: string;
  email: string;
  phone_no: string;
  password: string;
  passwordConfirmation: string;
}

export const ANY = undefined as any;

export interface FormSubmitReturn {
  error?: string;
  success?: string;
}

export enum TransactionType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum CurrencyType {
  coin = 'coin',
  fiat = 'fiat',
}

export type Size = 's' | 'm' | 'l';

export interface Currency {
  icon?: string;
  name: string;
  symbol: string;
  price: number;
  change: number;
}

export type ValueOf<T> = T[keyof T];

export interface Coin {
  _id: string;
  icon: string;
  name: string;
  symbol: string;
  price: number;
  change: number;
  isGlobalActive: boolean;
  max_limit: number;
  min_limit: number;
}

export interface OTPForm {
  email: string;
  OTP: string;
}

export enum THEMES {
  PRIMARY = 'PRIMARY',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  SECONDARY = 'SECONDARY',
}

export enum COLORS {
  PRIMARY = '#E8CB69',
  ERROR = '#da1919',
  SUCCESS = '#14bc24',
  SECONDARY = '#373434',
  NEWVA = '',
}

export interface FilterListItem {
  field: string;
  label?: string;
  options: {
    value: any;
    label?: string;
  }[];
}

export interface LPStreamRequest {
  accountId?: string;
  baseCurrency?: string;
  clientReference?: string;
  fundId?: string;
  maxSlippage?: number;
  quantity: number;
  // quantityCurrency: string;
  quoteCurrency: string;
}
