import React, { FunctionComponent } from 'react';
import classes from '../styles/Tracker.module.scss';
import WalletIcon from '../assets/wallet.svg';
import saveMoney from '../assets/save-money.svg';
import exchange from '../assets/exchange.svg';
// import useQuery from 'hooks/useQuery';

// interface Main {
//   given: string;
//   taken: string;
// }

function Step({ icon = '' }) {
  return (
    <div
      style={{ background: 'transparent', border: '10px solid white' }}
      className={classes.step}
    >
      <div
        style={{ background: 'transparent' }}
        className={classes.iconContainer}
      >
        <img
          src={(() => {
            if (icon === 'exchange') return exchange;
            if (icon === 'savemoney') return saveMoney;
            if (icon === 'wallet') return WalletIcon;
          })()}
          alt="ico"
        />
      </div>
    </div>
  );
}

export interface StepDetailProps {
  title: string;
  desc: string;
}

const StepDetail: FunctionComponent<StepDetailProps> = ({ title, desc }) => {
  return (
    <div className={classes.stepDetail}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{desc}</div>
    </div>
  );
};

function Strip() {
  return <div className={classes.strip}></div>;
}

function Tracker() {
  // const given = useQuery('given');
  // const taken = useQuery('taken');
  // console.log({ given, taken, page: 'Tracker Final' });
  return (
    <div className={classes.submitted}>
      <div
        style={{
          color: 'white',
          fontWeight: 800,
          fontSize: '30px',
          marginBottom: '2rem',
        }}
        className={classes.orderNumber}
      >
        Order Number ENC3732089048
      </div>
      <div className={classes.trackerModule}>
        <Step icon={'exchange'}></Step>
        <Strip />
        <Step icon={'savemoney'}></Step>
        <Strip />
        <Step icon={'wallet'}></Step>
      </div>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          gap: '2.5rem',
        }}
      >
        <StepDetail
          title={`Exchange of Funds`}
          desc={'The process will take few minutes, please wait'}
        ></StepDetail>
        <StepDetail
          title={'Sending To Wallet'}
          desc={'We are now sending to you wallet'}
        ></StepDetail>
        <StepDetail
          title={'Order Complete'}
          desc={'Your fund has been received to your wallet'}
        ></StepDetail>
      </div>
    </div>
  );
}

export default Tracker;
