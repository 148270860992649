// ? UTIL FILE TO CONVERT DATES

export const DATE_CONV = {
  // ? Method to convert ISO format to `dd-mm-yy` format
  dateConvert: (isoDate: any) => {
    let date = new Date(isoDate);

    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear() % 100;

    return `${day}/${month}/${year}`;
  },

  // ? Method to convert ISO format to `dd-mm-yy - hh:mm:ss` format
  dateTimeConvert: (isoDate: any) => {
    let date = DATE_CONV.dateConvert(isoDate);

    let dateTimeFormat = new Date(isoDate);
    let time = dateTimeFormat.toLocaleTimeString();

    return `${date} - ${time}`;
  },
};
